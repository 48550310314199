import { ColDef } from "ag-grid-community";
import { Utils } from "@binale-tech/shared";
import { TransactionTableColumns } from "@banks/components/TransactionsTable/transactionTableColumns";

export class BankTransactionTableColumns extends TransactionTableColumns {
    static get amount(): Readonly<ColDef> {
        return {
            headerName: "Betrag",
            headerTooltip: "Betrag",
            field: "amount",
            valueGetter: params => Utils.CurrencyUtils.currencyFormat(params?.data?.amount),
            width: 100,
            sortable: false,
            filter: false,
            cellStyle: params => {
                const cellStyles = { textAlign: "right" };
                if (params?.data?.amount !== undefined && params?.data?.amount < 0) {
                    return {
                        ...cellStyles,
                        color: "#f5222d",
                        fontWeight: "500",
                        textAlign: "right",
                    };
                }
                return {
                    ...cellStyles,
                    color: "#52c41a",
                };
            },
        };
    }

    static get dynamicBalance(): Readonly<ColDef> {
        return {
            headerName: "Balance",
            headerTooltip: "Balance",
            field: "dynamicBalance",
            valueGetter: params => Utils.CurrencyUtils.currencyFormat(params?.data?.dynamicBalance),
            width: 120,
            sortable: false,
            filter: false,
            cellStyle: params => {
                const cellStyles = { fontWeight: "500", textAlign: "right" };
                if (params?.data?.dynamicBalance !== undefined && params?.data?.dynamicBalance < 0) {
                    return {
                        ...cellStyles,
                        color: "#f5222d",
                    };
                }
                return {
                    ...cellStyles,
                    color: "#52c41a",
                };
            },
        };
    }

    static get transactionActions(): Readonly<ColDef> {
        return {
            ...this.actions,
            filter: false,
        };
    }

    static get tableColumnConfig(): Readonly<ColDef>[] {
        return [
            this.selectRowColumn,
            this.transactionRowNumberColumn,
            this.bookingDate,
            this.transactionType,
            this.amount,
            this.currencyCode,
            this.dynamicBalance,
            this.bookingText,
            this.purpose,
            this.counterpartyName,
            this.counterpartyIbanOrAccountNum,
            this.counterpartyBicOrBlz,
            this.transactionActions,
        ];
    }
}
