import React, { type FC, useContext, useEffect, useState } from "react";
import { Button, Progress } from "antd";
import { DocumentsApi } from "@dms/scripts/DocumentsApi/DocumentsApi";
import { FileUploaderContext } from "../../context/FileUploaderContext";
import { ReloadOutlined } from "@ant-design/icons";
import { TUploadFiles } from "@dms/types";
import { type UploadChangeParam } from "antd/es/upload";
import { useGqlMutator } from "scripts/graphql/useGqlMutator";
import { CompanyContext } from "scripts/context/CompanyContext";
import { UserContext } from "scripts/context/UserProvider";

interface IProps {
    file: UploadChangeParam<any> & { hash: string; isUploaded: boolean };
}

export const UploadFileItem: FC<IProps> = ({ file }) => {
    const { companyGQL } = useContext(CompanyContext);
    const userCtx = useContext(UserContext);
    const { setUniqFiles, documentType } = useContext(FileUploaderContext);
    const mutator = useGqlMutator();
    const [fileStatus, setFileStatus] = useState<{ progress: number; isUploaded: boolean; error: false | string }>({
        progress: 0,
        isUploaded: false,
        error: false,
    });

    useEffect(() => {
        handleFileUploader();
    }, []);

    const handleFileUploader = async () => {
        const info = await DocumentsApi.createDocument({
            fileData: file,
            mutator,
            companyData: companyGQL,
            setFileStatus,
            documentType,
        });
        if (!info) {
            return;
        }

        const { fileRes, url } = info;

        setUniqFiles(prev => {
            if (!prev) {
                return;
            }
            return {
                ...prev,
                [fileRes.hash]: {
                    ...prev[fileRes.hash],
                    isUploaded: true,
                    url,
                },
            } as TUploadFiles;
        });
    };

    return (
        <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                    {file.file.name}
                    {fileStatus.error ? (
                        <span style={{ marginLeft: "15px" }}>
                            <Button
                                size={"small"}
                                shape={"circle"}
                                onClick={handleFileUploader}
                                icon={<ReloadOutlined />}
                            ></Button>
                        </span>
                    ) : null}
                </div>
                {fileStatus.error && (
                    <span style={{ color: "red", marginRight: "40px" }}>Error: {fileStatus.error}</span>
                )}
            </div>

            <Progress
                percent={fileStatus.progress ? (fileStatus.isUploaded ? 100 : fileStatus.progress - 2) : 0}
                status={fileStatus.error ? "exception" : fileStatus.isUploaded ? "success" : "active"}
            />
        </div>
    );
};
