import React, { type FC, useContext } from "react";
import { IHeaderParams } from "ag-grid-community";
import { ViewColumnsControl } from "@inv/components/ViewColumnsControl";
import { InvoicesContext, InvoicesControlContext } from "@inv/scripts/context";
import { InvoicesListColumn, InvoicesListColumnTranslate } from "@inv/types";
import { InvoicesListTableColumns } from "@inv/modules/InvocesListModule/config/tableColumns";
import { Flex, Typography } from "antd";
import { FormattedMessage } from "react-intl";

const { Text } = Typography;

export const SettingsColumns: FC<IHeaderParams> = () => {
    const { invoiceListColumnsConfig, isOpenSettingsColumns } = useContext(InvoicesContext);
    const { setColumnConfig, setViewConfig } = useContext(InvoicesControlContext);

    return (
        <Flex align="center" justify="space-between" style={{ width: "100%" }}>
            <Text>
                <FormattedMessage id={InvoicesListColumnTranslate[InvoicesListColumn.ACTION]} />
            </Text>
            <ViewColumnsControl
                columnsConfig={invoiceListColumnsConfig}
                setColumnsConfig={setColumnConfig}
                columnTranslations={InvoicesListColumnTranslate}
                setIsOpenSettingsCol={setViewConfig}
                isOpenSettingsCol={isOpenSettingsColumns}
                requiredColumns={InvoicesListTableColumns.requiredColumnsConfig}
            />
        </Flex>
    );
};
