import { Form, Typography } from "antd";
import cn from "classnames";
import React, { type FC, useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { FormWrapperContext } from "@dms/modules/DocumentFormModule/context/FormWrapperContext";
import { GroupFormContext } from "@dms/modules/GroupFormModules/context/GroupFormContext";
import { SingleFormContext } from "@dms/modules/SingleFormModule/context/SingleFormContext";

import { FieldLabel } from "../../../../appearance/components/shared/form/baseComponents/FieldLabel/FieldLabel";
import { FormItemCheckbox } from "../DocumentInputs/FormItemCheckbox";
import {
  ContactSelect
} from "../../../../appearance/components/shared/form/baseComponents/ContactSelect/ContactSelect";

import styles from "./DocumentFormField.module.scss";

const { Text } = Typography;

interface IProps {
    aiResult?: boolean;
}
const SingleContactField: FC<IProps> = ({ aiResult }) => {
    const { necessaryFields } = useContext(SingleFormContext);

    return (
        <FieldLabel
            className={cn("DMSForm__Item", {
                [styles.aiResult]: aiResult,
            })}
            label={
                <Text strong ellipsis type={necessaryFields?.includes("partner") ? "warning" : undefined}>
                    {<FormattedMessage id="app.fields.contact" />}
                </Text>
            }
            name={"partner"}
        >
            <ContactSelect />
        </FieldLabel>
    );
};

const GroupContactField: FC = () => {
    const [isDisabled, setIsDisabled] = useState<boolean>(true);

    const { notEqualField, isCancel, necessaryGroupFields } = useContext(GroupFormContext);

    useEffect(() => {
        if (isCancel) {
            setIsDisabled(true);
        }
    }, [isCancel]);

    const placeholder = notEqualField.includes("partner") ? "Diverse" : undefined;

    return (
        <>
            <div style={{ position: "relative" }}>
                <Form.Item initialValue={false} name={"partnerCheckbox"} valuePropName={"checked"} noStyle={true}>
                    <FormItemCheckbox parentFieldName={"partner"} setIsDisabled={arg => setIsDisabled(arg)} />
                </Form.Item>
            </div>
            <FieldLabel
                className={"DMSForm__Item"}
                label={
                    <Text strong ellipsis type={necessaryGroupFields?.includes("partner") ? "warning" : undefined}>
                        {<FormattedMessage id="app.fields.contact" />}
                    </Text>
                }
                name={"partner"}
            >
                <ContactSelect placeholder={placeholder} disabled={isDisabled} />
            </FieldLabel>
        </>
    );
};

export const DocumentContactField: FC<IProps> = ({ aiResult }) => {
    const { isGroupEditor } = useContext(FormWrapperContext);
    return isGroupEditor ? <GroupContactField /> : <SingleContactField aiResult={aiResult} />;
};
