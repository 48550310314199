import React from "react";
import {
    AlignmentType,
    convertInchesToTwip,
    Paragraph,
    patchDocument,
    PatchType,
    Table as DocxTable,
    TableCell,
    TableRow,
    TextRun,
    VerticalAlign,
    WidthType
} from "docx";
import { Button, Drawer, Dropdown, Form, Input, Row, Space, Spin, Table } from "antd";
import {
    CheckOutlined,
    ContainerOutlined,
    CreditCardOutlined,
    DownloadOutlined,
    EditOutlined,
    FilePdfOutlined,
    FileWordOutlined,
    PlusOutlined
} from "@ant-design/icons";
import { ColumnType } from "antd/es/table";
import { FormattedMessage } from "react-intl";
import { GQL, Utils } from "@binale-tech/shared";
import { gql } from "@apollo/client";

import dayjs from "dayjs";
import { CompanyTableRecord, useCompanyDataFetch } from "./useCompanyDataFetch";
import { FlexColumn, Page, PageHeader } from "../../components/shared/appearance/page";
import { ProductAccessUtils } from "scripts/models/utils/ProductAccessUtils";
import { UserCompaniesContext, UserContext } from "scripts/context/UserProvider";
import { YearSelect } from "../../components/shared/Toolbar/YearSelect";
import { getServerlessApi } from "../../../scripts/api/backendApi";
import { saveAs } from "file-saver";
import { useGqlMutator } from "scripts/graphql/useGqlMutator";
import { useSearchParams } from "react-router-dom";

const query = gql`
    query getCompanyForAdminOverview($id: ID!) {
        company(id: $id) {
            id
            name
            accountingYears
            legalForm
            legalName
            owner {
                id
                email
            }
            members {
                id
                emailKey
            }
            products {
                id
                productKey
            }
            invoiceEmail
            invoiceName
            invoiceReceiver
            invoiceAddress {
                addressLine2
                city
                countryCode
                house
                postCode
                street
            }
            paymentData {
                discount
                modules {
                    price
                    id
                }
            }
            paymentIntents {
                id
                companyId
                year
                month
                isPaid
                isReady
                intentDate
                invoiceNumber
                data {
                    discount
                    modules {
                        price
                        id
                    }
                }
            }
        }
    }
`;

const moduleIdToName = (id: string) => {
    switch (id) {
        case "B":
            return "Buchhaltung";
        case "K":
            return "Kontakte";
    }
    return id;
};

const handleDownload = async (company: GQL.ICompany, paymentIntent: GQL.ICompanyPaymentIntent, type: string) => {
    const amountNetto = paymentIntent.data.modules.reduce((sum, v) => sum + v.price, 0);
    const amountVat = Math.round(amountNetto * 0.19);
    const buf = await fetch("/templates/template2.docx").then(res => res.arrayBuffer());
    const invPeriod = `${paymentIntent.year}.${String(paymentIntent.month + 1).padStart(2, "0")}`;
    patchDocument(buf, {
        patches: {
            inv_period: {
                type: PatchType.PARAGRAPH,
                children: [new TextRun(invPeriod)],
            },
            inv_num: {
                type: PatchType.PARAGRAPH,
                children: [new TextRun(String(paymentIntent.invoiceNumber))],
            },
            legal_name: {
                type: PatchType.PARAGRAPH,
                children: [new TextRun(company.legalName || "")],
            },
            inv_date: {
                type: PatchType.PARAGRAPH,
                children: [new TextRun(dayjs(paymentIntent.intentDate).format("DD.MM.YYYY"))],
            },
            zu_hv: {
                type: PatchType.PARAGRAPH,
                children: [new TextRun(company.invoiceName || company.invoiceReceiver || "")],
            },
            addr_street: {
                type: PatchType.PARAGRAPH,
                children: [new TextRun(company.invoiceAddress?.street || "")],
            },
            addr_house: {
                type: PatchType.PARAGRAPH,
                children: [new TextRun(company.invoiceAddress?.house || "")],
            },
            addr_line2: {
                type: PatchType.PARAGRAPH,
                children: [new TextRun(company.invoiceAddress?.addressLine2 || "")],
            },
            addr_plz: {
                type: PatchType.PARAGRAPH,
                children: [new TextRun(company.invoiceAddress?.postCode || "")],
            },
            addr_city: {
                type: PatchType.PARAGRAPH,
                children: [new TextRun(company.invoiceAddress?.city || "")],
            },
            amount_netto: {
                type: PatchType.PARAGRAPH,
                children: [new TextRun(Utils.CurrencyUtils.currencyFormat(amountNetto))],
            },
            amount_vat: {
                type: PatchType.PARAGRAPH,
                children: [new TextRun(Utils.CurrencyUtils.currencyFormat(amountVat))],
            },
            amount_total: {
                type: PatchType.PARAGRAPH,
                children: [new TextRun(Utils.CurrencyUtils.currencyFormat(amountNetto + amountVat))],
            },
            table: {
                type: PatchType.DOCUMENT,
                children: [
                    new DocxTable({
                        columnWidths: [convertInchesToTwip(6.1), convertInchesToTwip(0.7)],
                        rows: paymentIntent.data.modules
                            .filter(v => v.price > 0)
                            .map(
                                module =>
                                    new TableRow({
                                        children: [
                                            new TableCell({
                                                width: {
                                                    size: convertInchesToTwip(6.1),
                                                    type: WidthType.DXA,
                                                },
                                                children: [
                                                    new Paragraph({
                                                        text: `Nutzungsentgelt für die Software ${moduleIdToName(
                                                            module.id
                                                        )}`,
                                                    }),
                                                    new Paragraph({
                                                        text: `(Pauschalpreis ${Utils.CurrencyUtils.currencyFormat(
                                                            module.price
                                                        )} EUR/Monat)`,
                                                    }),
                                                    new Paragraph({ text: `Zeitraum: ${invPeriod}` }),
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                            }),
                                            new TableCell({
                                                width: {
                                                    size: convertInchesToTwip(0.7),
                                                    type: WidthType.DXA,
                                                },
                                                children: [
                                                    new Paragraph({
                                                        text: `${Utils.CurrencyUtils.currencyFormat(module.price)}`,
                                                        alignment: AlignmentType.RIGHT,
                                                    }),
                                                ],
                                                verticalAlign: VerticalAlign.CENTER,
                                            }),
                                        ],
                                    })
                            ),
                    }),
                ],
            },
        },
    }).then(async uint8array => {
        const fileName = `${company.name}_${invPeriod}`;
        if (type === "docx") {
            saveAs(new Blob([uint8array]), `${fileName}.docx`);
            return;
        }
        const formData = new FormData();
        const file = new File([uint8array], fileName + ".docx", { lastModified: Date.now() });
        formData.append("files", file);

        const { host, headers } = await getServerlessApi();
        await fetch(`${host}/gotenberg/forms/libreoffice/convert`, {
            method: "POST",
            body: formData,
            headers,
        })
            .then(res => res.blob())
            .then(blob => {
                saveAs(blob, `${fileName}.pdf`);
            });
    });
};

export const AdminView: React.FC = () => {
    const [, setSearchParams] = useSearchParams();
    const mutator = useGqlMutator();
    const companies = React.useContext(UserCompaniesContext);
    const user = React.useContext(UserContext);
    const [priceDataCompany, setPriceDataCompany] = React.useState<GQL.ICompany>(null);
    const [paymentIntentData, setPaymentIntentData] = React.useState<{
        company: GQL.ICompany;
        paymentIntent: GQL.ICompanyPaymentIntent;
        year: number;
        month: number;
    }>(null);
    const [pageSize, setPageSize] = React.useState(10);
    const [page, setPage] = React.useState(1);
    const [year, setYear] = React.useState(new Date().getFullYear());
    const years = new Array(new Date().getFullYear() - 2020)
        .fill(undefined)
        .map((v, index) => index + 2020 + 1)
        .sort((a, b) => b - a);
    const { loading, dataSource, fetchAndSetCompaniesData } = useCompanyDataFetch(companies, query, page, pageSize);

    const renderCompanyPrice = (company: GQL.ICompany) => {
        return company.paymentData?.modules.map((v, idx) => (
            <Row key={idx} justify={"space-between"}>
                <div>{v.id}</div>
                <div>
                    <code>{Number(v.price / 100).toFixed(2)}</code>
                </div>
            </Row>
        ));
    };

    const columns: ColumnType<CompanyTableRecord>[] = [
        {
            title: <FormattedMessage id="app.fields.lfdnr" />,
            dataIndex: "nr",
            key: "nr",
            width: 75,
        },
        {
            title: <FormattedMessage id="app.fields.internalDesignation" />,
            dataIndex: "name",
            key: "name",
            width: 250,
            defaultSortOrder: "ascend",
            sorter: (a, b) => {
                return a.company.name.localeCompare(b.company.name);
            },
            render: (cell: string, record: CompanyTableRecord) => (
                <span data-uuid={record.company.id}>
                    <strong>{record.company.name}</strong>
                    <br />
                    <br />
                    <code>{record.company.id}</code>
                </span>
            ),
        },
        {
            title: "Price",
            key: "price",
            render: (cell, record) => (
                <div style={{ width: 100 }}>
                    <Row>
                        <Button
                            icon={<EditOutlined />}
                            onClick={() => setPriceDataCompany(record.company)}
                            shape={"circle"}
                        />
                    </Row>
                    {renderCompanyPrice(record.company)}
                </div>
            ),
        },
        {
            title: "Rechnungsadresse",
            key: "Rechnungsadresse",
            width: 200,
            render: (cell, record) => (
                <span>
                    {record.company.invoiceEmail}
                    <br />
                    <br />
                    {record.company.invoiceReceiver && (
                        <>
                            <i>Rechnungsempfänger:</i> <br />
                            {record.company.invoiceReceiver} <br />
                        </>
                    )}
                    {record.company.invoiceName && (
                        <>
                            <i>zu Händen von:</i>
                            <br />
                            {record.company.invoiceName} <br />
                        </>
                    )}
                    <br />
                    <strong>{record.company.legalName || "-"}</strong>
                    <br />
                    {record.company.invoiceAddress?.street ?? "-"} {record.company.invoiceAddress?.house ?? "-"}
                    <br />
                    {record.company.invoiceAddress?.postCode ?? "00000"} {record.company.invoiceAddress?.city ?? "-"}
                </span>
            ),
        },
        {
            title: <FormattedMessage id="app.companies.view.table.admin" />,
            key: "admin",
            render: (cell, record) => {
                return record.company?.owner?.email;
            },
        },
        {
            title: <FormattedMessage id="app.companies.view.table.user" />,
            dataIndex: "users",
            key: "users",
            render: (cell: string[] = []) => {
                return cell.length.toString();
            },
        },
        {
            title: "Products",
            key: "products",
            render: (cell, record) => {
                return (
                    <div>
                        {ProductAccessUtils.hasCompanyAccounting(record.company) && <pre style={{ margin: 0 }}>B</pre>}
                        {ProductAccessUtils.hasCompanyProductAccess(GQL.IProductKey.Dms, record.company) && (
                            <pre style={{ margin: 0 }}>DMS</pre>
                        )}
                        {ProductAccessUtils.hasCompanyProductAccess(GQL.IProductKey.Ocr, record.company) && (
                            <pre style={{ margin: 0 }}>PDF</pre>
                        )}
                        {ProductAccessUtils.hasCompanyProductAccess(GQL.IProductKey.Contacts, record.company) && (
                            <pre style={{ margin: 0 }}>K</pre>
                        )}
                    </div>
                );
            },
        },
        {
            title: "Accounting Years",
            key: "accountingYears",
            align: "right" as ColumnType<unknown>["align"],
            render: (cell, record) => {
                if (!ProductAccessUtils.hasCompanyAccounting(record.company)) {
                    return null;
                }
                const years = record.company.accountingYears;
                if (!years.length) {
                    return null;
                }
                const minMaxYears = new Set([Math.min(...years), Math.max(...years)]);
                return <pre style={{ margin: 0 }}>{[...minMaxYears].join("-")}</pre>;
            },
        },
        ...new Array(12).fill(undefined).map((i, month) => ({
            title: dayjs.months()[month],
            key: "month",
            render: (cell: any, record: CompanyTableRecord) => {
                const paymentIntent = record.company.paymentIntents?.find(v => v.year === year && v.month === month);
                const getColor = (pi: GQL.ICompanyPaymentIntent): React.CSSProperties["color"] => {
                    if (!pi) {
                        return "inherit";
                    }
                    if (pi.isPaid) {
                        return "rgb(191 229 194)";
                    }
                    if (pi.isReady) {
                        return "rgb(229,215,191)";
                    }
                    return "inherit";
                };
                return (
                    <div
                        style={{
                            width: 100,
                            backgroundColor: getColor(paymentIntent),
                            borderRadius: 5,
                            padding: 5,
                        }}
                    >
                        <Row justify={"space-between"} style={{ marginBottom: 5 }}>
                            {record.company.paymentData ? (
                                <Button
                                    disabled={paymentIntent?.isPaid}
                                    icon={paymentIntent ? <EditOutlined /> : <PlusOutlined />}
                                    shape={"circle"}
                                    onClick={() =>
                                        setPaymentIntentData({
                                            company: record.company,
                                            month,
                                            year,
                                            paymentIntent,
                                        })
                                    }
                                />
                            ) : (
                                <div>&nbsp;</div>
                            )}
                            {paymentIntent && !paymentIntent.isPaid && !paymentIntent.isReady && (
                                <Button
                                    icon={<CreditCardOutlined />}
                                    shape={"circle"}
                                    onClick={() =>
                                        mutator
                                            .mutate<"companySetPaymentIntentReady", GQL.ICompanyPaymentIntentInput>({
                                                mutation: companySetPaymentIntentReady,
                                                input: {
                                                    year,
                                                    month,
                                                    companyId: record.company.id,
                                                },
                                            })
                                            .then(handleHide)
                                    }
                                />
                            )}

                            {paymentIntent && !paymentIntent.isPaid && paymentIntent.isReady && (
                                <Button
                                    icon={<CheckOutlined />}
                                    shape={"circle"}
                                    onClick={() =>
                                        mutator
                                            .mutate<"companySetPaymentIntentPaid", GQL.ICompanyPaymentIntentInput>({
                                                mutation: companySetPaymentIntentPaid,
                                                input: {
                                                    year,
                                                    month,
                                                    companyId: record.company.id,
                                                },
                                            })
                                            .then(handleHide)
                                    }
                                />
                            )}
                            {paymentIntent && paymentIntent.isReady && (
                                <Dropdown
                                    menu={{
                                        onClick: type => handleDownload(record.company, paymentIntent, type.key),
                                        items: [
                                            {
                                                key: "docx",
                                                label: (
                                                    <Space>
                                                        <FileWordOutlined /> DOCX
                                                    </Space>
                                                ),
                                            },
                                            {
                                                key: "pdf",
                                                label: (
                                                    <Space>
                                                        <FilePdfOutlined /> PDF
                                                    </Space>
                                                ),
                                            },
                                        ],
                                    }}
                                    placement="bottomRight"
                                >
                                    <Button icon={<DownloadOutlined />} shape={"circle"} />
                                </Dropdown>
                            )}
                        </Row>
                        {paymentIntent?.data?.modules.map((v, idx) => (
                            <Row key={idx} justify={"space-between"}>
                                <div>{v.id}</div>
                                <div>
                                    <code>{Number(v.price / 100).toFixed(2)}</code>
                                </div>
                            </Row>
                        ))}
                        {!paymentIntent && renderCompanyPrice(record.company)}
                    </div>
                );
            },
        })),
    ];

    const handleHide = () => {
        fetchAndSetCompaniesData(true);
        setPriceDataCompany(null);
        setPaymentIntentData(null);
    };

    return (
        <Spin spinning={user.isLoading}>
            <Page className="CompaniesView">
                <PageHeader>
                    <FormattedMessage id="app.titles.company.pl" />
                </PageHeader>
                <FlexColumn style={{ width: 3000 }}>
                    <Space style={{ margin: "8px 0" }}>
                        <YearSelect years={years} value={year} onChange={setYear} />
                        <Input.Search
                            onSearch={search => setSearchParams({ search })}
                            allowClear
                            disabled={loading || companies.length === 0}
                        />
                    </Space>
                    <Table
                        loading={loading}
                        dataSource={dataSource}
                        columns={columns}
                        size="middle"
                        bordered
                        sticky
                        pagination={{
                            position: ["topLeft", "bottomRight"],
                            current: page,
                            pageSize,
                            onShowSizeChange: (_, size) => setPageSize(size),
                            onChange: (page, pageSize) => {
                                setPage(page);
                                setPageSize(pageSize);
                            },
                            pageSizeOptions: [10, 25],
                        }}
                    />
                </FlexColumn>
                <CompanyPriceDataModal company={priceDataCompany} onHide={handleHide} />
                <CompanyPaymentIntentModal
                    company={paymentIntentData?.company}
                    paymentIntent={paymentIntentData?.paymentIntent}
                    year={paymentIntentData?.year}
                    month={paymentIntentData?.month}
                    onHide={handleHide}
                />
            </Page>
        </Spin>
    );
};

const mutationSetPaymentData = gql`
    mutation companySetPaymentData($input: CompanyPaymentDataInput!) {
        companySetPaymentData(input: $input) {
            id
            paymentData {
                discount
                modules {
                    id
                    price
                }
            }
        }
    }
`;
const companyAddPaymentIntent = gql`
    mutation companyAddPaymentIntent($input: CompanyAddPaymentIntentInput!) {
        companyAddPaymentIntent(input: $input)
    }
`;
const companySetPaymentIntentPaid = gql`
    mutation companySetPaymentIntentPaid($input: CompanyPaymentIntentInput!) {
        companySetPaymentIntentPaid(input: $input)
    }
`;
const companySetPaymentIntentReady = gql`
    mutation companySetPaymentIntentReady($input: CompanyPaymentIntentInput!) {
        companySetPaymentIntentReady(input: $input)
    }
`;

type CompanyPriceDataModalProps = {
    company: GQL.ICompany;
    onHide: () => void;
};

type FormValues = {
    B: number;
    DMS: number;
    PDF: number;
    K: number;
};

const CompanyPriceDataModal: React.FC<CompanyPriceDataModalProps> = ({ company, onHide }) => {
    const mutator = useGqlMutator();
    const [form] = Form.useForm<FormValues>();
    const [loading, setLoading] = React.useState(false);
    const handleSubmit = React.useCallback(
        (values: FormValues) => {
            setLoading(true);
            mutator
                .mutate<"companySetPaymentData", GQL.ICompanyPaymentDataInput>({
                    mutation: mutationSetPaymentData,
                    input: {
                        companyId: company.id,
                        discount: 0,
                        modules: [
                            {
                                id: "B",
                                price: Number(values.B ?? 0),
                            },
                            {
                                id: "DMS",
                                price: Number(values.DMS ?? 0),
                            },
                            {
                                id: "PDF",
                                price: Number(values.PDF ?? 0),
                            },
                            {
                                id: "K",
                                price: Number(values.K ?? 0),
                            },
                        ],
                    },
                })
                .then(() => {
                    form.resetFields();
                    onHide();
                })
                .finally(() => setLoading(false));
        },
        [mutator, company, form, onHide]
    );
    const initialValues = company?.paymentData
        ? {
              B: company.paymentData.modules.find(v => v.id === "B")?.price ?? 0,
              DMS: company.paymentData.modules.find(v => v.id === "DMS")?.price ?? 0,
              PDF: company.paymentData.modules.find(v => v.id === "PDF")?.price ?? 0,
              K: company.paymentData.modules.find(v => v.id === "K")?.price ?? 0,
          }
        : { B: 0, DMS: 0, PDF: 0, K: 0 };
    return (
        <Drawer
            width={400}
            title="Edit Price Data"
            placement="left"
            open={Boolean(company)}
            onClose={onHide}
            destroyOnClose
        >
            <Spin spinning={loading}>
                <Form onFinish={handleSubmit} initialValues={initialValues} layout={"vertical"}>
                    <Form.Item name={"B"} label={"Buchhaltung"}>
                        <Input suffix={"EUR cents"} width={200} />
                    </Form.Item>
                    <Form.Item name={"DMS"} label={"DMS"}>
                        <Input suffix={"EUR cents"} />
                    </Form.Item>
                    <Form.Item name={"PDF"} label={"PDF"}>
                        <Input suffix={"EUR cents"} />
                    </Form.Item>
                    <Form.Item name={"K"} label={"Kontakte"}>
                        <Input suffix={"EUR cents"} />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" icon={<ContainerOutlined />} htmlType={"submit"}>
                            <FormattedMessage id="app.button.done" />
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        </Drawer>
    );
};

type CompanyPaymentIntentModalProps = {
    company: GQL.ICompany;
    onHide: () => void;
    year: number;
    month: number;
    paymentIntent?: GQL.ICompanyPaymentIntent;
};
const CompanyPaymentIntentModal: React.FC<CompanyPaymentIntentModalProps> = ({
    company,
    paymentIntent,
    year,
    month,
    onHide,
}) => {
    const mutator = useGqlMutator();
    const [form] = Form.useForm<FormValues>();
    const [loading, setLoading] = React.useState(false);
    const handleSubmit = React.useCallback(
        (values: FormValues) => {
            setLoading(true);
            mutator
                .mutate<"companyAddPaymentIntent", GQL.ICompanyAddPaymentIntentInput>({
                    mutation: companyAddPaymentIntent,
                    input: {
                        companyId: company.id,
                        discount: 0,
                        year,
                        month,
                        modules: [
                            {
                                id: "B",
                                price: Number(values.B ?? 0),
                            },
                            {
                                id: "DMS",
                                price: Number(values.DMS ?? 0),
                            },
                            {
                                id: "PDF",
                                price: Number(values.PDF ?? 0),
                            },
                            {
                                id: "K",
                                price: Number(values.K ?? 0),
                            },
                        ],
                    },
                })
                .then(() => {
                    form.resetFields();
                    onHide();
                })
                .finally(() => setLoading(false));
        },
        [mutator, company, form, onHide]
    );
    const initialDraftData = paymentIntent?.data || company?.paymentData;
    const initialValues = {
        B: initialDraftData?.modules.find(v => v.id === "B")?.price ?? 0,
        DMS: initialDraftData?.modules.find(v => v.id === "DMS")?.price ?? 0,
        PDF: initialDraftData?.modules.find(v => v.id === "PDF")?.price ?? 0,
        K: initialDraftData?.modules.find(v => v.id === "K")?.price ?? 0,
    };
    return (
        <Drawer
            width={400}
            title="Edit Monthly Payment"
            placement="right"
            open={Boolean(company)}
            onClose={onHide}
            destroyOnClose
        >
            <Spin spinning={loading}>
                <Form onFinish={handleSubmit} initialValues={initialValues} layout={"vertical"}>
                    <Form.Item name={"B"} label={"Buchhaltung"}>
                        <Input suffix={"EUR cents"} width={200} />
                    </Form.Item>
                    <Form.Item name={"DMS"} label={"DMS"}>
                        <Input suffix={"EUR cents"} />
                    </Form.Item>
                    <Form.Item name={"PDF"} label={"PDF"}>
                        <Input suffix={"EUR cents"} />
                    </Form.Item>
                    <Form.Item name={"K"} label={"Kontakte"}>
                        <Input suffix={"EUR cents"} />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" icon={<ContainerOutlined />} htmlType={"submit"}>
                            <FormattedMessage id="app.button.done" />
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        </Drawer>
    );
};
