import React, { FC, useContext, useMemo } from "react";
import { Flex, Space, Switch, Typography } from "antd";
import dayjs from "dayjs";
import { SaveButtons } from "@banks/components/SaveButton";
import { getTransactionBankTitle } from "@banks/scripts/helpers";
import { TransactionRangePicker } from "@banks/components/TransactionRangePicker";
import { Link } from "react-router-dom";
import { AppRoutes } from "../../../../scripts/routing/routeConstants";
import styles from "./ParseDataViewToolbar.module.scss";
import { LeftOutlined, RightOutlined, TableOutlined } from "@ant-design/icons";
import { ParseDataContext, ParseDataControlContext } from "@banks/modules/ParseDataModule/context/ParseDataContext";
import { useDebounce } from "../../../../scripts/infrastructure/hooks/useDebounce";

const { Title, Link: AntLink } = Typography;

type TProps = {
    handleSelectChecked: (arg: boolean) => void;
    handleHideIsAlreadyExist: (arg: boolean) => void;
};

export const ParseDataViewToolbar: FC<TProps> = ({ handleSelectChecked, handleHideIsAlreadyExist }) => {
    const { transactionBank, selectDateRange, selectedRows, filteredRows } = useContext(ParseDataContext);
    const { setSelectDateRange, saveParseData, saveSelectedTransactions } = useContext(ParseDataControlContext);

    const { transactions } = useDebounce(transactionBank, 1000);

    const isAlreadyExistTransaction = useMemo(() => {
        const alreadyExistTransaction = Object.values(transactions || {}).map(tr => tr.csvLine);

        return filteredRows.every(row => {
            return alreadyExistTransaction.includes(row.csvLine);
        });
    }, [transactions, filteredRows]);

    const handleChange = (range: [dayjs.Dayjs, dayjs.Dayjs]) => {
        setSelectDateRange({ firstData: range[0], lastData: range[1] });
    };

    return (
        <Flex vertical gap={10}>
            <Flex gap={15} align={"center"} justify={"space-between"} style={{ minHeight: 32, marginBottom: 10 }}>
                {transactionBank ? (
                    <Link to={AppRoutes.banks + `/bank-transactions/${transactionBank.id}`}>
                        <Title style={{ marginBottom: 0 }} level={5} className={styles.bankTitle}>
                            {getTransactionBankTitle(transactionBank ? { exist: transactionBank } : undefined)}
                            <TableOutlined style={{ marginLeft: 10 }} />
                            <RightOutlined />
                        </Title>
                    </Link>
                ) : (
                    <Title style={{ marginBottom: 0 }} level={5}>
                        Bank undefined
                    </Title>
                )}
                <Flex gap={15} align={"center"} justify={"space-between"} style={{ minWidth: 150 }}>
                    <Link to={-1 as any}>
                        <AntLink>
                            <LeftOutlined /> Prev.
                        </AntLink>
                    </Link>
                    <Link to={AppRoutes.banks}>
                        <AntLink>
                            All Banks <RightOutlined />
                        </AntLink>
                    </Link>
                </Flex>
            </Flex>
            <Flex justify={"space-between"} align={"center"}>
                <Flex gap={20} align={"center"} style={{ width: "100%" }}>
                    <TransactionRangePicker
                        onSelectRange={handleChange}
                        initialRange={[selectDateRange?.firstData, selectDateRange?.lastData]}
                    />
                    <Space align={"center"}>
                        <Space align={"center"}>
                            <Switch onChange={handleSelectChecked} />

                            <Typography.Text ellipsis>Checked</Typography.Text>
                        </Space>
                        <Space align={"center"}>
                            <Switch onChange={handleHideIsAlreadyExist} />

                            <Typography.Text ellipsis>Hide existing transactions</Typography.Text>
                        </Space>
                    </Space>
                </Flex>

                <SaveButtons
                    saveAll={saveParseData}
                    saveSelected={saveSelectedTransactions}
                    selectedCount={selectedRows.length}
                    isDisabled={isAlreadyExistTransaction}
                />
            </Flex>
        </Flex>
    );
};
