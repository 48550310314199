import React, { FC } from "react";
import { Divider, Modal, Typography } from "antd";
import { CloseButton } from "@dms/components/CloseButton/CloseButton";
import { IInvoiceFormLineItem, InvoiceFormBlocksTranslate } from "@inv/types";
import { AgGridTable } from "@app/components/shared/AgGridTable";
import { InvoiceFormColumnsConfig } from "@inv/modules/InvoiceTableModule/config/invoiceFormColumnsConfig";
import { baseOptions } from "@app/components/shared/AgGridTable/options/baseOptions";

import styles from "./LineItemsList.module.scss";
import { FormattedMessage } from "react-intl";

interface IProps {
    open: boolean;
    lineItemsList: IInvoiceFormLineItem[];
    onClose: () => void;
}

export const LineItemsModal: FC<IProps> = ({ open, lineItemsList, onClose }) => {
    if (lineItemsList.length === 0) {
        return null;
    }

    const columns = InvoiceFormColumnsConfig.ModalColumnsConfig;
    const gridOption = {
        ...baseOptions,
        pagination: false,
        defaultColDef: {
            enableCellChangeFlash: true,
            filter: false,
            suppressMovable: true,
        },
    };

    return (
        <Modal
            open={open}
            onCancel={onClose}
            destroyOnClose
            closable={false}
            footer={null}
            title={
                <Divider orientation={"left"}>
                    <Typography.Title level={5}>
                        <FormattedMessage id={InvoiceFormBlocksTranslate.LINE_ITEMS} />
                    </Typography.Title>
                </Divider>
            }
            className={styles.modal}
            styles={{
                body: {
                    minWidth: "70vw",
                    height: "300px",
                    backgroundColor: "white",
                    display: "flex",
                    paddingTop: 10,
                },
            }}
        >
            <CloseButton onClose={onClose} />
            <AgGridTable
                wrapperStyles={{ width: "100%", minHeight: "60px" }}
                gridOptions={gridOption}
                columnDefs={columns}
                rowData={lineItemsList}
            />
        </Modal>
    );
};
