import { Modal } from "antd";
import React, { type FC, useContext, useState } from "react";

import { ModalTitle } from "./components/ModalTitle/ModalTitle";
import { FileUploaderContext } from "./context/FileUploaderContext";
import { FileUploader } from "./FileUploader";

interface IProps {
    onFilesAdd?: (arg: string[]) => void;
    isOpen: boolean;
    onCancel: () => void;
}

export const FileUploaderModule: FC<IProps> = ({ onFilesAdd, isOpen, onCancel }) => {
    const [isModalOpen, setIsModalOpen] = useState(isOpen);
    const [addedFilesKeys] = useState<string[]>([]);

    const { isDndFile, setIsDndFile } = useContext(FileUploaderContext);

    const handleCancel = () => {
        if (onFilesAdd) {
            onFilesAdd(addedFilesKeys);
        }

        setIsModalOpen(false);
        onCancel();
    };

    const onDragEnter = () => {
        if (!isDndFile) {
            setIsDndFile(true);
        }
    };

    return (
        <div onDragEnter={onDragEnter}>
            <Modal
                title={<ModalTitle />}
                open={isModalOpen}
                confirmLoading={false}
                onCancel={handleCancel}
                footer={null}
                width={1300}
                style={{ top: 20 }}
            >
                {isModalOpen && <FileUploader onReady={() => handleCancel()} />}
            </Modal>
        </div>
    );
};
