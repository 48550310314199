import React, { FC } from "react";
import { Col, Form, Row } from "antd";
import { StringInput } from "@dms/components/DocumentForm/DocumentInputs/StringInput";
import { InvoiceInputs, InvoiceFormInputTranslate, InvoiceFormBlocksTranslate } from "@inv/types";
import { InvoiceFormItemWrapper } from "@inv/components/CreateInvoicingForm/components/InvoiceFormItemWrapper";
import { useIntl } from "react-intl";

export const HeaderBlock: FC = () => {
    const intl = useIntl();

    return (
        <InvoiceFormItemWrapper title={intl.formatMessage({ id: InvoiceFormBlocksTranslate.HEADER_SECTION })}>
            <Row>
                <Col span={24}>
                    <Form.Item name={InvoiceInputs.DOCUMENT_TITLE}>
                        <StringInput
                            placeholder={intl.formatMessage({ id: InvoiceFormInputTranslate.DOCUMENT_TITLE })}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name={InvoiceInputs.INTRODUCTION_TEXT}>
                        <StringInput
                            placeholder={intl.formatMessage({ id: InvoiceFormInputTranslate.INTRODUCTION_TEXT })}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </InvoiceFormItemWrapper>
    );
};
