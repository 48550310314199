import React, { type FC, ReactNode, useContext, useState } from "react";
import { DmsAppContext, DmsAppControlContext } from "@dms/types/ContextTypes";
import { useDrag } from "react-dnd";
import { Flex } from "antd";

interface IProps {
    id: string;
    children: ReactNode[] | ReactNode;
    typeDrag: "list" | "grid";
}

export const Drag: FC<IProps> = ({ id, children, typeDrag }) => {
    const { selectedRowKeys } = useContext(DmsAppContext);
    const { setDragID, toggleDraggingState, resetFocusedRows, resetSelectedRows } = useContext(DmsAppControlContext);

    const [dr, setDr] = useState(false);
    const isDraggingEnabled = selectedRowKeys.length === 0 || selectedRowKeys.includes(id);

    const [{ isDragging }, drag] = useDrag({
        type: "ITEM",
        item: () => {
            if (selectedRowKeys.length > 0) {
                return { fields: selectedRowKeys };
            } else {
                return { fields: id };
            }
        },
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult();
            if (dropResult) {
                setDragID(id);
                toggleDraggingState(false);
            }
        },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
        canDrag: isDraggingEnabled,
    });

    if (typeDrag === "list") {
        return (
            <Flex
                ref={drag}
                style={{
                    opacity: isDraggingEnabled ? (dr && !isDragging ? 0 : 1) : 0.5,
                    cursor: isDraggingEnabled ? "move" : "auto",
                    height: "100%",
                }}
                justify="center"
                align="middle"
                onDragStart={() => {
                    if (!isDraggingEnabled) {
                        return null;
                    }
                    setDr(true);
                }}
                onDragEnd={() => {
                    if (!isDraggingEnabled) {
                        return null;
                    }
                    resetFocusedRows();
                    resetSelectedRows();
                    setDr(false);
                }}
            >
                {children}
            </Flex>
        );
    }
    if (typeDrag === "grid") {
        return (
            <Flex
                ref={drag}
                style={{
                    opacity: dr && !isDragging ? 0 : 1,
                    cursor: isDraggingEnabled ? "move" : "auto",
                    height: "100%",
                }}
                justify="center"
                align="middle"
                onDragStart={() => {
                    if (!isDraggingEnabled) {
                        return null;
                    }

                    setDr(true);
                }}
                onDragEnd={() => {
                    if (!isDraggingEnabled) {
                        return null;
                    }

                    setDr(false);
                }}
            >
                {children}
            </Flex>
        );
    }

    return null;
};
