import { CurrencyValue } from "@binale-tech/ui-components";
import React, { createContext, type FC, type ReactNode, useEffect, useState } from "react";
import { necessaryFieldsForDocumentTypes } from "@dms/configs/constants";
import { DocumentsApi } from "@dms/scripts/DocumentsApi/DocumentsApi";
import { getFileNameAndSuffix } from "@dms/scripts/helpers/getFileNameAndSuffix";
import { DmsType, IDocumentEnriched, MovementType, type TDocumentInputs } from "@dms/types";
import { useGqlMutator } from "../../../../scripts/graphql/useGqlMutator";
import { getMovementType } from "@dms/scripts/helpers";

interface IProps {
    children: ReactNode;
    documentInitialValue?: IDocumentEnriched;
}

interface TValue {
    singleFormInitialValue: TDocumentInputs | undefined;
    actionConfirm: (arg: IDocumentEnriched) => Promise<void>;
    fileNameSuffix?: string;
    necessaryFields?: readonly (keyof Partial<IDocumentEnriched>)[];
    handleSetNecessaryFields: (type: string) => void;
    movementType: MovementType;
    setMovementType: (arg: MovementType) => void;
    changedDocumentType: DmsType;
    setChangedDocumentType: (arg: DmsType) => void;
}

/**
 Context for Document Form
 */
export const SingleFormContext = createContext({} as TValue);

/**
 Context Provider for Document Form
 */

export const SingleFormProvider: FC<IProps> = ({ children, documentInitialValue }) => {
    const [singleFormInitialValue, setSingleFormInitialValue] = useState<TDocumentInputs>({});
    const [fileNameSuffix, setFileNameSuffix] = useState<string>(".pdf");
    const [necessaryFields, setNecessaryFields] = useState<readonly (keyof Partial<IDocumentEnriched>)[]>([]);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [movementType, setMovementType] = useState<MovementType>(
        getMovementType(documentInitialValue.documentAmount, documentInitialValue.type as DmsType)
    );
    const [changedDocumentType, setChangedDocumentType] = useState<DmsType>(documentInitialValue.type as DmsType);

    const mutator = useGqlMutator();

    useEffect(() => {
        if (!documentInitialValue) {
            return;
        }

        if (isEditing) {
            return;
        }

        const type = documentInitialValue.subType ? documentInitialValue.subType : documentInitialValue.type;
        const { documentAmount, originalAmount, currency, currencyRate, ...restValue } = documentInitialValue;
        const currencyData: Partial<CurrencyValue> = {
            amount: documentAmount,
            originalAmount: originalAmount ?? undefined,
            currency: currency ? { code: currency, rate: currencyRate ?? 1 } : undefined,
        };

        const { fileName, suffix } = getFileNameAndSuffix(documentInitialValue.fileName);
        setFileNameSuffix(suffix || ".pdf");

        setSingleFormInitialValue({
            ...restValue,
            type,
            currencyData,
            fileName,
        });

        setIsEditing(true);

        setNecessaryFields(necessaryFieldsForDocumentTypes[documentInitialValue.type as DmsType] || []);
    }, [documentInitialValue, isEditing]);

    const actionConfirm = async (data: IDocumentEnriched): Promise<void> => {
        await DocumentsApi.updateDocuments({ documents: [data], mutator });
        setIsEditing(false);
    };

    const handleSetNecessaryFields = (type: string) => {
        setNecessaryFields(necessaryFieldsForDocumentTypes[type as DmsType] || []);
    };

    const value: TValue = {
        singleFormInitialValue,
        actionConfirm,
        fileNameSuffix,
        necessaryFields,
        handleSetNecessaryFields,
        movementType,
        setMovementType: (arg: MovementType) => setMovementType(arg),
        changedDocumentType,
        setChangedDocumentType: arg => setChangedDocumentType(arg),
    };

    return <SingleFormContext.Provider value={value}>{children}</SingleFormContext.Provider>;
};
