import { Flex, Tag, Typography } from "antd";
import React, { useContext } from "react";
import { transactionFieldsGroups, transactionFieldsGroupTitle } from "@banks/configs/constants/transactionFieldsDict";
import { PreviewParseDataContext } from "@banks/modules/PreviewParseDataModule/context/PreviewParseDataContext";

import styles from "./MatchKeyList.module.scss";

const { Text } = Typography;

export const MatchKeyList = () => {
    const { unmappedDataKeys } = useContext(PreviewParseDataContext);

    return (
        <Flex
            style={{
                width: "100%",
                backgroundColor: "rgb(250, 250, 250)",
                borderRadius: 8,
                border: "1px solid",
                borderColor: "rgba(210, 210, 210)",
                padding: "5px 10px",
            }}
        >
            {Object.entries(transactionFieldsGroups).map(([groupKey, group]) => {
                return (
                    <div key={groupKey} className={styles.groupWrapper}>
                        <Text type={"secondary"}>{transactionFieldsGroupTitle[groupKey]}</Text>
                        <Flex
                            vertical
                            wrap
                            style={{
                                maxHeight: 100,
                            }}
                            justify={"flex-start"}
                        >
                            {Object.entries(group).map(el => {
                                const isUnmatched = unmappedDataKeys.includes(el[0]);
                                return (
                                    <div key={el[0]} className={styles.tagWrapper}>
                                        <Tag
                                            style={{
                                                width: 130,
                                                minWidth: 50,
                                                textOverflow: "ellipsis",
                                                overflow: "hidden",
                                            }}
                                            color={isUnmatched ? "magenta" : "green"}
                                        >
                                            {el[1]}
                                        </Tag>
                                    </div>
                                );
                            })}
                        </Flex>
                    </div>
                );
            })}
        </Flex>
    );
};
