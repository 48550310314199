import React from "react";
import classNames from "classnames";
import { Button } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Utils } from "@binale-tech/shared";

import { ColumnOverrides, TableGetters } from "../../columns/ColumnConfig";
import { GenericRecord } from "scripts/models/GenericRecord";
import { GenericRecordProperties, TableProperties } from "scripts/core/Product";
import { GenericRecordTableItem, TableItem } from "../../components/shared/Table/Table";
import { GenericRecordUtils } from "scripts/models/utils/GenericRecordUtils";
import { GenericTableView, GenericTableViewState } from "../productSharedComponents/GenericTableView";
import { RecordsTableBlock, TableConfigFooters, TableSum } from "../productSharedComponents/RecordsTableBlock";

import "../../components/shared/Page.css";
import "./AbstractBillTableView.css";

export abstract class AbstractBillTableView<S extends GenericTableViewState> extends GenericTableView<S> {
    protected tableConfigGetters() {
        const getters: ColumnOverrides<GenericRecord>["getters"] = {
            [GenericRecordProperties.RecordCategoryCreditorNum]: (
                tableItem: TableItem<GenericRecord>,
                kontoExt?: number
            ) => tableItem.item.getRecordCategoryCreditor().getExtNumPrint(kontoExt),
            [GenericRecordProperties.ItemCategoryCreditorNum]: (
                tableItem: TableItem<GenericRecord>,
                kontoExt?: number
            ) => tableItem.item.getItemCategoryCreditor().getExtNumPrint(kontoExt),
            [GenericRecordProperties.ComputedZA]: (tableItem: GenericRecordTableItem) => {
                if (tableItem.key === null) {
                    return null;
                }
                if (tableItem.item.lastschrift) {
                    return <abbr title="Lastschrift">LS</abbr>;
                }
                if (GenericRecordUtils.isRecordPayed(tableItem.item, tableItem.extra.payments)) {
                    return null;
                }
                if (tableItem.item.avis) {
                    return (
                        <Button
                            type="link"
                            size="small"
                            style={{ padding: 0, color: "inherit" }}
                            tabIndex={-1}
                            onClick={e => this.handleAvisTableItems([tableItem], false, e)}
                        >
                            <CheckCircleOutlined />
                        </Button>
                    );
                }
                return (
                    <Button
                        type="link"
                        size="small"
                        style={{ padding: 0 }}
                        className="Table__ZA-button"
                        tabIndex={-1}
                        onClick={e => this.handleAvisTableItems([tableItem], true, e)}
                    >
                        <i className="st-icon-circle-thin " />
                    </Button>
                );
            },
            [GenericRecordProperties.ComputedStatus]: (tableItem: GenericRecordTableItem) => {
                const o = TableGetters.computedStatus(tableItem);
                return o ? (
                    <p
                        className={classNames("status", "status-clickable", {
                            "status-offenColor": o.abbr === "offen",
                            "status-bezahltColor": o.abbr === "bezahlt",
                            "status-avisColor": o.abbr === "AVIS",
                        })}
                        onClick={() => this.handleZahlungTableItem(tableItem)}
                    >
                        {o.abbr}
                    </p>
                ) : (
                    ""
                );
            },
            [TableProperties.ControlLog]: (tableItem: TableItem<GenericRecord>) => this.getControlLogButton(tableItem),
            [TableProperties.ControlAction]: (tableItem: TableItem<GenericRecord>) => this.getActionButtons(tableItem),
            [GenericRecordProperties.RecordReview]: (tableItem: TableItem<GenericRecord>) =>
                this.getRecordReview(tableItem, this.handleReviewItem(tableItem)),
        };
        return getters;
    }

    protected tableConfigFooters(tableItems: TableItem<GenericRecord>[], tableSum: TableSum): TableConfigFooters {
        return {
            [TableProperties.ComputedNr]: {
                mainFooter: tableItems.length.toString(),
                selectedFooter: tableItems.filter(i => i.selected).length.toString(),
            },
            [GenericRecordProperties.RecordBrutto]: {
                mainFooter: Utils.CurrencyUtils.currencyFormat(tableSum.mainFooter.bruttoSum),
                selectedFooter: Utils.CurrencyUtils.currencyFormat(tableSum.selectedFooter.bruttoSum),
            },
            [GenericRecordProperties.ComputedUSt]: {
                mainFooter: Utils.CurrencyUtils.currencyFormat(tableSum.mainFooter.vatSum),
                selectedFooter: Utils.CurrencyUtils.currencyFormat(tableSum.selectedFooter.vatSum),
            },
            [GenericRecordProperties.ComputedNetto]: {
                mainFooter: Utils.CurrencyUtils.currencyFormat(tableSum.mainFooter.nettoSum),
                selectedFooter: Utils.CurrencyUtils.currencyFormat(tableSum.selectedFooter.nettoSum),
            },
            [GenericRecordProperties.RecordCurrencyOriginalAmount]: {
                mainFooter: Utils.CurrencyUtils.currencyFormat(tableSum.mainFooter.originalAmountSum),
                selectedFooter: Utils.CurrencyUtils.currencyFormat(tableSum.selectedFooter.originalAmountSum),
            },
            [GenericRecordProperties.ComputedOffen]: {
                mainFooter: Utils.CurrencyUtils.currencyFormat(tableSum.mainFooter.openSum),
                selectedFooter: Utils.CurrencyUtils.currencyFormat(tableSum.selectedFooter.openSum),
            },
        };
    }

    protected getBlockTableView(h: number) {
        const {
            onSort,
            sortColumn,
            canWrite,
            focusIndex,
            onSetFocus,
            product,
            view,
            tableRef,
            tableItems,
            selectedPeriodEditBound,
        } = this.getTableLegacyCommonProps();
        return (
            <RecordsTableBlock
                view={view}
                tableRef={tableRef}
                tableItems={tableItems}
                tableHeight={h}
                tableSaldoHeader={undefined}
                selectedPeriodEditBound={selectedPeriodEditBound}
                tableRowClassFunc={this.rowClassFunc}
                onSort={onSort}
                sortColumn={sortColumn}
                product={product}
                canWrite={canWrite}
                onPayments={p => this.handleZahlungTableItem(p)}
                focusIndex={focusIndex}
                onSetFocus={onSetFocus}
                tableConfigFooters={this.tableConfigFooters}
                columnConfig={this.tableColumns}
                itemActions={{
                    handleEditItem: (v, e) => this.handleEditItem(v, e),
                    handleCopyItem: (v, e) => this.handleCopyItem(v, e),
                    handleDeleteItems: (v, e) => this.handleDeleteItems(v, e),
                    handleCancelItems: (v, e) => this.handleCancelItems(v, e),
                    handleUpdateItems: (v, e) => this.handleUpdateItems(v, e),
                    handleColorTableItems: (v, c) => this.handleColorTableItems(v, c),
                    handleAvisTableItems: (v, avis) => this.handleAvisTableItems(v, avis),
                    handleBulkEditItem: (v, e) => this.handleBulkEditItem(v, e),
                }}
            />
        );
    }
}
