import { Empty, Layout } from "antd";
import React, { type FC, type PropsWithChildren } from "react";
import { Route, Routes } from "react-router-dom";
import { CreateInvoicePage, InvoicesListPage } from "./pages";
import { themeConfig } from "@inv/theme";

import "./InvoicesApp.css";

const AppLayout: FC<PropsWithChildren> = ({ children }) => (
    <Layout
        style={{
            position: "relative",
            height: "100%",
            backgroundColor: themeConfig.colorLight,
        }}
    >
        <Layout.Content
            style={{
                padding: "15px",
                position: "relative",
            }}
        >
            {children}
        </Layout.Content>
    </Layout>
);

const InvoicesApp: FC = () => {
    return (
        <AppLayout>
            <Routes>
                <Route index element={<InvoicesListPage />} />
                <Route path={"create-invoice-form"} element={<CreateInvoicePage />} />
                <Route path="*" element={<Empty />} />
            </Routes>
        </AppLayout>
    );
};

export default InvoicesApp;
