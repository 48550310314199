import { IMessagesType } from "./de";

const auth = {
    "app.registration.caption": "Регистрация",
    "app.registration.description": "Пожалуйста заполните необходимые поля для создания учетной записи",
    "app.registration.firm": "Название фирмы / Имя",
    "app.registration.agb": "AGB",
    "app.registration.agb.agree": "Я согласен с {agbLink} и {datenschutzLink}.",
    "app.registration.message.send_verification_email": "Письмо с подтверждением почты выслано на ваш ящик",
    "app.registration.almost_done": "Почти готовы",
    "app.registration.almost_done_description":
        "Спасибо за Вашу регистрацию в Binale! Пожалуйста, подтвердите Вашу электронную почту. Для этого пройдите по ссылке, которую мы отправили Вам на Ваш электронный адрес.",
    "app.registration.send_again": "Послать еще раз",
    "app.registration.resend_again_in": "Вы можете отправить письмо повторно через",

    "app.login.description": "Добро пожаловать в Binale",
    "app.login.error.fail": "Неверные E-Mail адрес или пароль.",
    "app.login.loose_2fa_app": "Приложение для аутентификации недоступно?",
    "app.login.loose_2fa_app_instructions":
        "Если Ваше приложение для аутентификации больше недоступно, свяжитесь с нами по адресу {email}",

    "app.reset_password.caption": "Забыли пароль?",
    "app.reset_password.description":
        "Пожалуйста введите свой E-mail адрес и нажмите 'Отправить' чтобы сбросить пароль.",
    "app.reset_password.confirmation":
        "Мы отправили ссылку на ваш E-Mail адрес для ввода нового пароля. Пожалуйста проверьте свой почтовый ящик.",

    "app.new_password.caption": "Новый пароль",
    "app.new_password.description": "Ввведите новый пароль.",
    "app.new_password.confirmation": "Новый пароль был сохранен.",

    "app.2fa.button.enable2fa": "Активировать 2FA",
    "app.2fa.button.showPrivateKey": "Показать личный ключ",
    "app.2fa.button.disable2fa": "Отключить 2FA",
    "app.2fa.input_label": "Введите 6-значный код из приложения",
    "app.2fa.step.title": "Включить аутентификатор",
    "app.2fa.confirm_delete": "Вы уверены, что хотите отключить 2FA?",
    "app.2fa.description":
        "Благодаря <b>двухфакторной аутентификации</b> Вы повышаете уровень безопасности своей учетной записи. При входе в систему, помимо пароля, Вы используете случайный код одноразовой проверки, который генерируется приложением для двухфакторной аутентификации.",
    "app.2fa.enabled2fa_description":
        "<b>Двухфакторная аутентификация</b> успешна активирована для Вашей учетной записи!",

    "app.2fa.step.download.title": "Скачать приложение",
    "app.2fa.step.download.description":
        "Скачайте и установите одно из представленных ниже приложений двухфакторной аутентификации на Ваш смартфон.",

    "app.2fa.step.scan.title": "Сканировать QR-код",
    "app.2fa.step.scan.description": "Отсканируйте этот QR-код в приложении",
    "app.2fa.step.scan.manual_enter": "Если вы не можете отсканировать QR-код, введите этот код вручную в приложение.",

    "app.2fa.step.backup.title": "Резервный ключ",
    "app.2fa.step.backup.description":
        "Пожалуйста, сохраните этот ключ на бумаге. Этот ключ позволит вам восстановить доступ к сайту в случае потери телефона.",

    "app.2fa.step.enable.title": "Подключить аутентификатор",
    "app.2fa.token_error": "Неверный код подтверждения 2FA",
};

const errors = {
    // firebase errors
    "app.firebase.error.auth_requires_recent_login": "Для смены пароля, необходимо перезайти в систему.",
    "app.firebase.error.auth_user_not_found": "В системе нет пользователя с такой почтой. Или пользователь был удален.",
    "app.firebase.error.auth_too_many_requests": "Слишком много запросов.",
    "app.firebase.error.duplicate_email": "E-mail адрес уже используется.",
    "app.firebase.error.auth_weak_password": "Слабый пароль",
    "app.firebase.error.auth_invalid_email": "Неподходящий E-mail",
    "app.firebase.error.auth_invalid_token": "Неподходящий токен",

    // error pages
    "app.error_page.title.something_went_wrong": "Кажется, что-то пошло не так... ",
    "app.error_page.we_already_fix_it": "Мы позаботимся об этом как можно скорее. Пожалуйста, обновите страницу.",
    "app.error_page.button.reload_page": "Обновить",
    "app.error_page.title.problem_with_db": "Кажется, проблема с подключением к базе данных.",
    "app.error_page.fix_problems_with_db.1":
        "Сначала убедитесь, пожалуйста, что Вы не находитесь в так называемом «приватном окне» Вашего браузера. В этом случае данные не могут быть загружены.",
    "app.error_page.fix_problems_with_db.2": "Пожалуйста, перезагрузите страницу еще раз.",
    "app.error_page.fix_problems_with_db.3":
        "Если проблема осталась или появится снова, напишите нам, пожалуйста, короткое сообщение на {email}. Мы позаботимся об этом.",
    "app.error_page.not_found": "Этой страницы не существует.",
    "app.error_page.button.back_home": "На главную",

    // validation
    "app.validation.error.field_empty": "Поле не может быть пустым",
    "app.validation.error.short_password": "Пароль должен быть минимум {length} символов",
    "app.validation.error.passwords_not_match": "Пароли не совпадают",
    "app.validation.error.invalid_email": "Неправильный E-mail адрес",
    "app.validation.error.strictLength": "{length} {length, plural, one {знак} few {знака} other {знаков}}",

    "app.error.message.incomplete_data": "Данные неполные. Пожалуйста, проверьте записи",
    "app.error.message.bank_not_available": "Банк не существует в выбранном году",
    "app.error.message.kb_not_available": "Касса не существует в выбранном году",
    "app.error.message.some_incorrect": "Некоторые записи неверны",
};

export const messagesRu: IMessagesType = {
    ...auth,
    ...errors,

    "app.header.workplace": "Рабочий стол",
    "app.header.logout": "Выйти",
    "app.header.login": "Войти",
    "app.settings.button.columns.text": "Столбцы",
    "app.settings.button.columns.title": "Выбрать столбцы",
    "app.companies.view.table.admin": "Главный пользователь",
    "app.companies.view.table.user": "Пользователь",
    "app.companies.detail_request.select_skr": "Пожалуйста, выберите SKR вашей компании",
    "app.companies.detail_request.select_kb": "Пожалуйста, выберите или создайте кассовую книгу",
    "app.companies.detail_request.select_bank": "Пожалуйста, выберите или создайте банк",
    "app.companies.permission": "Доступ",
    "app.companies.permission.no": "Нет",
    "app.companies.permission.read": "Аудит (Чтение)",
    "app.companies.permission.write": "Участник (Запись)",
    "app.companies.permission.admin": "Admin",
    "app.companies.permission.admin-read": "Admin Read",

    "app.profile.select.title": "Язык",
    "app.profile.button.change_password": "Сбросить пароль",
    "app.profile.button.re_login": "Войти",
    "app.profile.email_verification.success_message": "Электронная почта успешно подтверждена.",
    "app.profile.email_verification.fail_message": "Ошибка при подтверждении почты",

    "app.messages.no_elements_found": "Элементы не найдены",
    "app.message.no_document_found": "Документ не найден",
    "app.items.create_tag": "Создать Тэг",
    "app.items.create_creditor": "Создать Кредитора",
    "app.items.create_debitor": "Создать Дебитора",
    "app.items.create_category": "Создать Акк./Категорию",
    "app.items.create_article": "Создать Номенклатуру",

    "app.titles.ER": "Входящие Счета",
    "app.titles.ER_A": "Предоплаты",
    "app.titles.Deb": "Исходящие счета",
    "app.titles.FE": "Свободный ввод",
    "app.titles.POS": "Кассовый отчет",
    "app.titles.KB": "Кассовая книга",
    "app.titles.Bank": "Банк",
    "app.titles.Bank.pl": "Банки",
    "app.titles.Common": "Общие данные",
    "app.titles.SuSa": "Суммы и Сальдо",
    "app.titles.Vat": "НДС Отчет",
    "app.titles.KB.pl": "Кассовые книги",
    "app.titles.ER.erfassung": "Входящие документы",
    "app.titles.ER.rechnungen": "Счета",
    "app.titles.ER.auswertung": "Анализ",
    "app.titles.filtern": "Фильтры и поиск",
    "app.titles.ER.festschreibung": "Festschreibung",
    "app.titles.ER.za": "Платежный Авис",
    "app.titles.Lager": "Склад",
    "app.titles.LA": "Зарплатные ведомости",
    "app.titles.AVM": "Основные средства",
    "app.titles.OCR": "PDF Инструменты",
    "app.titles.KA": "Обзор счетов",
    "app.titles.Contacts": "Контакты",
    "app.titles.DMS": "Документы",
    "app.titles.DMS_AI": "Документы: Binale AI",
    "app.titles.Banks": "Банки",
    "app.titles.Invoices": "Счета",
    "app.titles.payments": "Платежи",
    "app.titles.templates": "Шаблоны",
    "app.titles.data_export": "Экспорт данных",
    "app.titles.data_import": "Импорт данных",
    "app.titles.settings": "Настройки",
    "app.titles.settings.manage": "Управление",
    "app.titles.settings.formSettings": "Настройки формы",
    "app.titles.settings.optionalFields": "Дополнительные поля",
    "app.titles.security": "Безопасность",
    "app.titles.company": "Компания",
    "app.titles.company.pl": "Компании",
    "app.titles.category": "Категория",
    "app.titles.category.pl": "Категории",
    "app.titles.creditor": "Кредитор",
    "app.titles.creditor.pl": "Кредиторы",
    "app.titles.debitor": "Дебитор",
    "app.titles.debitor.pl": "Дебиторы",
    "app.titles.tag": "Тэг",
    "app.titles.tag.pl": "Тэги",
    "app.titles.article": "Номеклатура",
    "app.titles.article.pl": "Номеклатуры",
    "app.titles.profile": "Профиль",
    "app.titles.kontenverwaltung": "Управление счетами",
    "app.titles.import_export": "Импорт/Экспорт",
    "app.titles.accounting": "Бухгалтерия",
    "app.titles.contactPerson": "Контактное лицо",
    "app.titles.legalInformation": "Юридическая информация",
    "app.titles.termsOfPayment": "Условия оплаты",
    "app.titles.communication": "Коммуникация",
    "app.titles.contacts.client": "Клиент",
    "app.titles.contacts.partner": "Поставщик",
    "app.titles.contacts.other": "Прочий (Свободный ввод)",
    "app.titles.contacts.employee": "Сотрудник",
    "app.titles.contacts.shareholder": "Акционер",
    "app.titles.contacts.bankDetails": "Банковские реквизиты",
    "app.titles.contacts.classification": "Классификация",
    "app.titles.bulkEdit": "Множественное редактирование",
    "app.titles.reconciliation": "Авто-связывание",
    "app.titles.copy_data_from": "Скопировать новые счета из",
    "app.titles.bind_to_bank": "Привязать к Банку",
    "app.titles.bind_to_kb": "Привязать к Кассовой книге",
    "app.titles.unbind": "Удалить привязку",
    "app.titles.create_kb": "Добавить кассу",
    "app.titles.update_kb": "Редактировать кассу",
    "app.titles.create_bank": "Добавить банк",
    "app.titles.update_bank": "Редактировать банк",

    "app.button.login": "Войти",
    "app.button.register": "Зарегистрироваться",
    "app.button.confirm": "Подтвердить",
    "app.button.send": "Отправить",
    "app.button.send_to_dms": "в DMS",
    "app.button.save": "Сохранить",
    "app.button.create": "Создать",
    "app.button.add": "Добавить",
    "app.button.cancel": "Отменить",
    "app.button.confAndClose": "Подтвердить и закрыть",
    "app.button.close": "Закрыть",
    "app.button.finish": "Закончить",
    "app.button.done": "Готово",
    "app.button.apply": "Применить",
    "app.button.unselect": "Снять выделение",
    "app.button.buchen": "Записать",
    "app.button.delete": "Удалить",
    "app.button.bind": "Привязать",
    "app.button.unbind": "Удалить привязку",
    "app.button.storno": "Отменить запись",
    "app.button.edit": "Редактировать",
    "app.button.copy": "Копировать",
    "app.button.download": "Скачать",
    "app.button.select": "Выделить",
    "app.button.loading": "Загрузка...",
    "app.button.saving": "Сохранение...",
    "app.button.color.remove_color": "Убрать цвет",
    "app.button.color.red": "Красный",
    "app.button.color.blue": "Голубой",
    "app.button.color.yellow": "Желтый",
    "app.button.color.purple": "Фиолетовый",
    "app.button.color.green": "Зеленый",
    "app.button.reorganise": "Реорганизировать",
    "app.button.upload": "Загрузить",
    "app.button.next": "Дальше",
    "app.button.reset": "Сбросить",
    "app.button.default": "По умолчанию",
    "app.button.update": "Обновить",
    "app.button.openInNewTab": "Открыть в новой вкладке",

    "app.confirmation.pdf.finish": "Завершить работу?",
    "app.fields.vatNb": "НДС-Номер",
    "app.fields.taxNumber": "Налоговый номер",
    "app.fields.typeOfRegister": "Тип реестра",
    "app.fields.registerCourt": "Регистрационный суд",
    "app.fields.registerNumber": "Регистрационный номер",

    "app.confirmation.record_remove.body": "Вы действительно хотите удалить запись?",
    "app.confirmation.invoice_remove.body": "Вы действительно хотите удалить счет?",
    "app.confirmation.record_cancel.body": "Вы действительно хотите отменить запись?",
    "app.confirmation.festschreiben_edit.body": "Эта запись зафиксирована! Вы действительно хотите ее отредактировать?",
    "app.kb.journal.no_items_tooltip":
        "Не выбраны записи, среди выбранных есть негативное Сальдо или предыдущие записи не зафиксированы",
    "app.confirmation.header": "Подтверждение",
    "app.confirmation.header.record_delete": "Удалить запись!",
    "app.confirmation.header.record_cancel": "Отмена записи",
    "app.confirmation.header.confirmed_edit": "Измененить подтвержденную запись!",
    "app.confirmation.header.festschreiben_edit": "Измененить зафиксированную запись!",
    "app.confirmation.body": "Подтвердите действие!",
    "app.fields.lfdnr": "№ п/п",
    "app.fields.nr": "Nr.",
    "app.fields.date": "Дата",
    "app.fields.date.month": "Месяц",
    "app.fields.date.erfassung": "Период",
    "app.fields.date.year": "Год",
    "app.fields.creditor": "Кредитор",
    "app.fields.debitor": "Дебитор",
    "app.fields.creditor_view": "Обзор Кредиторов",
    "app.fields.konto_view": "Обзор Счетов",
    "app.fields.bezeichnung": "Наименование",
    "app.fields.name": "Название",
    "app.fields.contact": "Контакт",
    "app.fields.products": "Продукты",
    "app.fields.rechnung_num": "№ счета",
    "app.fields.internal_num": "Внутренний №",
    "app.fields.konto": "Номер",
    "app.fields.gkonto": "Исх. Счет",
    "app.fields.gkonto.full": "Исходящий Счет",
    "app.fields.brutto": "Брутто",
    "app.fields.betrag": "Сумма",
    "app.fields.betragType": "Вид",
    "app.fields.totalAmount": "Общая сумма",
    "app.fields.partialAmount": "Частичная сумма",
    "app.fields.originalAmount": "Сумма ввода",
    "app.fields.currency.rate": "Курс",
    "app.fields.vat%": "НДС, %",
    "app.fields.vat": "НДС",
    "app.fields.netto": "Нетто",
    "app.fields.open": "Откр.",
    "app.fields.buchtext": "Товар/Услуга",
    "app.fields.buchtext2": "Дополнительная информация",
    "app.fields.skonto": "Скидка",
    "app.fields.deadlineDays": "Отсрочка платежа",
    "app.fields.dueDate": "Дата платежа",
    "app.fields.discountDays": "Дней скидки",
    "app.fields.discountDate": "Дата скидки",
    "app.fields.discountPercent": "Скидка, %",
    "app.fields.discountAmount": "Сумма скидки",
    "app.fields.notes": "Заметки",
    "app.fields.verrechnung": "Предоплата",
    "app.fields.wählen": "выбрать",
    "app.fields.rabatt": "Rabatt",
    "app.fields.offner_betrag": "Непогашенная сумма",
    "app.fields.lastschrift": "Прямое списание/Lastschrift",
    "app.fields.draft": "Черновик",
    "app.fields.uberweisung": "Банковский перевод",
    "app.fields.barzahlung": "Оплата наличными",
    "app.fields.sonstiges": "Другое",
    "app.fields.falligkeit": "Срок оплаты",
    "app.fields.actions": "Действия",
    "app.fields.saldo": "Сальдо",
    "app.fields.payment": "Платеж",
    "app.fields.konto_len": "Длина аккаунта",
    "app.fields.color": "Цвет",
    "app.fields.user": "Пользователь",
    "app.fields.schleppend": "Следовать",
    "app.fields.search": "Поиск",
    "app.fields.address": "Адрес",
    "app.fields.company_name": "Имя компании",
    "app.fields.producer": "Производитель",
    "app.fields.description": "Описание",
    "app.fields.unit": "Единицы",
    "app.fields.taxation": "Налогообложение по НДС",
    "app.fields.favouritePaymentType": "Основное средство платежа",
    "app.fields.datevNrConsultant": "Номер консультанта",
    "app.fields.datevNrCompany": "Номер клиента",
    "app.fields.countryCode": "Страна",
    "app.fields.euroCountryCode": "Страна-ЕС",
    "app.fields.postcode": "Индекс",
    "app.fields.city": "Населенный пункт",
    "app.fields.street": "Улица",
    "app.fields.house": "Номер дома",
    "app.fields.additionalAddress": "Дополнение к адресу",
    "app.fields.13bUStG": "Варианты § 13b UStG",
    "app.fields.paymentPurpose": "Назначение платежа",
    "app.fields.paymentMethod": "Способ оплаты",
    "app.fields.days": "дней",
    "app.fields.phone": "Телефон",
    "app.fields.internalDesignation": "Внутреннее название",
    "app.fields.type": "Тип",
    "app.fields.subType": "Подтип",
    "app.fields.companyName": "Наименование компании",
    "app.fields.legalForm": "Юридическая форма",
    "app.fields.salutation": "Обращение",
    "app.fields.title": "Титул",
    "app.fields.firstName": "Имя",
    "app.fields.lastName": "Фамилия",
    "app.fields.customerNumber": "Номер клиента",
    "app.fields.accountOwner": "Владелец счета",
    "app.fields.bankCodeNumber": "Кодовый номер банка (BLZ)",
    "app.fields.bankAccountNumber": "Номер банковского счета",
    "app.fields.bankName": "Наименование банка",
    "app.fields.productService": "Товар/Услуга",
    "app.fields.position": "Позиция",
    "app.fields.department": "Отдел",
    "app.fields.currency": "Валюта",
    "app.fields.comment": "Примечание",
    "app.fields.module": "Модуль",
    "app.fields.attachment": "Приложение",
    "app.fields.invoice": "Счет",
    "app.fields.creditNote": "Кредит-нота",

    "app.fields.recordReview": "Проверка",
    "app.fields.recordReview.ok": "OK",
    "app.fields.recordReview.new": "Новый",
    "app.fields.recordReview.changed": "Изменен",
    "app.fields.recordReview.question": "Вопрос",
    "app.fields.recordReview.error": "Ошибка",

    "app.fields.fileUrl": "URL файла",
    "app.fields.numPages": "Количество страниц",
    "app.fields.rowNumber": "No",

    "app.fields.ertrag": "Доход",
    "app.fields.aufwand": "Расход",
    "app.fields.einnahme": "Приход",
    "app.fields.ausgabe": "Расход",

    "app.labels.allYear": "Весь год",
    "app.labels.paymentConditions": "Условия оплаты",

    "app.components.split.title": "Раздельный счет",
    "app.components.editing": "Редактирование",
    "app.components.not_selected": "Не выбрано",
    "app.components.all_selected": "Все выбрано",
    "app.components.nselected": "Выбрано",
    "app.components.all": "Все",
    "app.components.all_months": "Все месяцы",
    "app.components.used": "Мой список",
    "app.components.creating": "Создание",
    "app.components.date.range_error": "Дата должна быть в заданном промежутке",
    "app.components.table.no_items": "Нет записей",
    "app.components.form.attachment": "Прикрепить файл",
    "app.components.form.clear": "Очистить форму",

    // "app.cookie": "Возможно, мы будем использовать cookie. Продолжая использование программы, вы соглашаетесь на это.",
    "app.notification.update":
        "Мы выпустили обновление! Пожалуйста, обновите страницу, чтобы получить продолжить работу в обновленном приложении",
    "app.notification.copiedToClipboard": "Поле скопировано",

    "app.global.email": "E-Mail адрес",
    "app.global.password": "Пароль",
    "app.global.password_repeat": "Подтверждение пароля",
    "app.global.error": "Неизвестная ошибка.",
    "app.global.login_now": "Войти в систему",
    "app.global.register_now": "Зарегистрироваться",
    "app.global.do_not_have_account": "Новый клиент?",
    "app.global.already_have_account": "Уже есть аккаунт?",
    "app.global.not_selected": "Не выбрано",
    "app.global.total": "Общ.",
    "app.global.components.payment_connection.created": "Запись в модуле {module} была автоматически создана.",
    "app.global.components.payment_connection.updated": "Запись в модуле {module} была автоматически изменена.",
    "app.global.components.payment_connection.removed": "Запись в модуле {module} была удалена.",
    "app.global.components.payment_connection.markedRemoved": "Запись в модуле {module} была отмечена удаленной.",
    "app.global.components.num_corrector.title": "Назначение нового номера",
    "app.global.yes": "Да",
    "app.global.no": "Нет",
    "app.global.step": "Шаг",
    "app.global.previous_step": "Предыдущий шаг",
    "app.global.bank": "Банк",
    "app.global.confirmRemoveInfo": "Вы действительно хотите удалить эту информацию?",
    "app.global.confirmSentToTrash": "Отправить документ в корзину",
    "app.global.confirmRemoveDocument": "Удалить навсегда?",
    "app.global.saveChanges": "Сохранить изменения?",
    "app.global.binaleNeedUpdate": "Вышла новая версия Binale. Пожалуйста, обновите программу!",

    "app.payments.verrechnungen": "Выбрать предоплату.",
    "app.paymentConnections.record": "Платёжные данные (информация по платежу)",
    "app.paymentConnections": "Привязка платежа",
    "app.paymentConnections.helperText": "Пожалуйста, выберите один или несколько счетов, к которым относится платеж.",
    "app.paymentConnections.deleteAssignment": "Удалить связь",
    "app.paymentConnections.saveAssignment": "Сохранить связь",
    "app.paymentConnections.showAllOpenItems": "Показать все открытые позиции",

    "app.contacts.new": "Создать Контакт",
    "app.contacts.type.organization": "Организация",
    "app.contacts.type.individual": "Физическое лицо",
    "app.contacts.type.institution": "Учреждение",
    "app.contacts.add": "Добавить адрес",
    "app.contacts.confirmRemoveContact": "Вы действительно хотите удалить этот контакт?",

    "app.message.progress": "Действие выполняется...",
    "app.message.success": "Действие успешно выполнено",
    "app.message.userSelectCompany": "Активная компания была изменена",
    "app.message.companyCreateBank": "Банк успешно создан",
    "app.message.companyCreateKB": "Касса успешно создана",
    "app.message.companyUpdateBank": "Банк успешно обновлен",
    "app.message.companyUpdateKB": "Касса успешно обновлена",
    "app.message.companyDeleteBank": "Банк успешно удален",
    "app.message.companyDeleteKB": "Касса успешно удалена",
    "app.message.companySetProducts": "Доступные продукты для компании были обновлены",
    "app.message.companyCreate": "Компания была создана",
    "app.message.companyUpdate": "Компания была изменена",
    "app.message.companyDelete": "Компания была удалена",
    "app.message.companySaveMember": "Пользователь был удален",
    "app.message.companyDeleteMember": "Пользователь был сохранен",
    "app.message.nothingSelected": "Пожалуйста, выберите тип контакта ({ relations }) в разделе “Общее“.",

    "app.tooltip.manageFavouritePaymentType": "Изменить основное средство платежа",
    "app.tooltip.noOpenItems": "открытых позиций нет",
    "app.tooltip.form.correct": "Скорректируйте {amount} {currency}",

    "app.ocr.button.checkAll": "выделить все",
    "app.ocr.button.deleteEmptyPages": "удалить пустые страницы",
    "app.ocr.button.hideDeletedPages": "скрыть удаленные страницы",
    "app.ocr.button.showDeletedPages": "показать удаленные страницы",
    "app.ocr.button.deletePage": "удалить страницу",
    "app.ocr.button.restorePage": "восстановить страницу",
    "app.ocr.button.rotatePage": "повернуть",
    "app.ocr.button.splitDocs": "разъединить",
    "app.ocr.button.unifyDocs": "соединить",
    "app.ocr.button.uncheckAll": "убрать выделение",
    "app.ocr.button.addDocument": "добавить документ",
    "app.ocr.button.selectDocument": "Выбрать документ",

    "app.ocr.parking": "Паркинг",
    "app.ocr.page": "Страница",
    "app.ocr.document": "Документ",
    "app.ocr.allPages": "Все страницы",
    "app.ocr.totalPages": "Всего страниц",
    "app.ocr.documentsNumber": "Количество документов",
    "app.ocr.selectedPages": "Выделенные страницы",

    "app.ocr.mode.put": "Соединить",
    "app.ocr.mode.qr": "Разъединить (QR-Код)",
    "app.ocr.mode.every": "Разъединить (каждая страница)",
    "app.ocr.mode.second": "Разъединить (каждая вторая страница)",
    "app.ocr.mode.manual": "Ручная обработка",

    "app.ocr.error.encrypted": "Выбранный документ зашифрован или защищён и не может быть обработан.",
    "app.ocr.dragDocument": "Перетащите нужный документ сюда",
    "app.ocr.placeDocumentHere": "Поместить документ здесь",

    "app.titles.taxes": "Налоги",
    "app.titles.contracts": "Контракты",
    "app.titles.uncategorized": "Без категории",

    "app.dms.add_documents": "Добавить документы",
    "app.titles.new_documents": "Новые документы",

    "app.dms.typeOfDocuments": "Папка",
    "app.titles.trash": "Удаленные документы",
    "app.dms.add_subtype": "добавить элемент",

    "app.dms.list": "СПИСОК",
    "app.dms.grid": "Карточки",

    "app.dms.empty": "Пусто",
    "app.dms.fileName": "Имя файла",
    "app.dms.documentNumber": "Номер документа",
    "app.dms.documentDate": "Дата документа",
    "app.dms.userName": "Имя пользователя",
    "app.dms.fileInformation": "Информация о файле",
    "app.dms.createdBy": "Автор",
    "app.dms.createdAt": "Дата создания",
    "app.dms.updatedAt": "Дата обновления",
    "app.dms.status": "Статус",
    "app.dms.closeEditor": "Закрыть редактор",
    "app.dms.total": "Всего",
    "app.dms.totalDocuments":
        "{count, plural, one {# документ} few {# документа} many {# документов} other {# документов}}",

    "app.dms.selectAll": "Выбрать всё",
    "app.dms.copyId": "Копировать ID",
    "app.dms.copyUrl": "Копировать URL",
    "app.dms.idCopied": "ID скопирован",
    "app.dms.idCopyFailed": "Не удалось скопировать ID",
    "app.dms.urlCopied": "URL скопирован",
    "app.dms.clickOrDrug":
        "Нажмите, чтобы выбрать, или перетащите файлы  (макс. 20 МБ/файл) в эту область, чтобы загрузить их.",
    "app.dms.supportFor": "Возможен множественный выбор",
    "app.dms.identicalFiles": "Идентичные файлы. Выберите один файл для загрузки",
    "app.dms.confirmResolving": "Подтвердить решение",
    "app.dms.deleteAll": "Удалить всё",
    "app.dms.view": "Вид",
    "app.dms.moveTo": "Переместить",
    "app.dms.emptyDate": "без даты",

    "app.dms.complete": "Завершить",
    "app.dms.allDocuments": "Все документы",
    "app.dms.changeModeEnabled": "Режим изменения включен",
    "app.dms.noticeOfDelete": "Уведомление о режиме удаления",
    "app.dms.cantDelete": "Вы не можете удалить этот документ из корзины",
    "app.dms.moveSelectedDocuments": "Переместите выбранные документы в предпочтительный тип!",
    "app.dms.pages": "{count, plural, one {# страница} few {# страницы} many {# страниц} other {# страниц}}",
    "app.dms.mainTypes": "Основные типы",
    "app.dms.tooltipDocumentNumber": "Пожалуйста, используйте следующие символы",
    "app.dms.letters": "Буквы",
    "app.dms.number": "Числа",
    "app.dms.specialSymbols": "Спецсимволы",

    "app.dms.vat": "НДС",
    "app.dms.corporation_tax": "Корпоративный налог на прибыль",
    "app.dms.business_tax": "Торговый налог на прибыль",
    "app.dms.statement": "Раздельное и общее распределение",
    "app.dms.income_tax": "Подоходный налог",
    "app.dms.other_taxes": "Прочие налоги",
    "app.dms.tax_office": "Налоговая служба",
    "app.dms.more": "Более",
    "app.dms.isAttached": "учтены в бухгалтерии",
    "app.dms.isn'tAttached": "не учтены в бухгалтерии",
    "app.dms.showAll": "Показать все",
    "app.dms.filterActive": "фильтр активирован",
    "app.dms.selectBank": "выберите банк",
    "app.dms.selectCashJournal": "выберите кассу",
    "app.dms.selectTaxes": "выберите налоги",
    "app.dms.noInternetConnection": "Нет соединения с интернетом",
    "app.dms.addDocumentId": "Добавить ID документа",
    "app.dms.selectInDms": "Выбрать в DMS",
    "app.dms.noAssignment": "Без привязки",
    "app.dms.filterColor": "Фильтр по цвету",

    "app.invoice.createInvoice": "Создание счета",
    "app.invoice.invoicesList": "Список счетов",

    "app.invoice.brutto": "Брутто",
    "app.invoice.netto": "Нетто",

    "app.invoice.customerDetails": "Сведения о клиенте",
    "app.invoice.customer": "Поиск или ввод клиента",
    "app.invoice.address": "Адрес",
    "app.invoice.street": "Улица",
    "app.invoice.houseNumber": "Номер дома",
    "app.invoice.zeep": "Почтовый индекс",
    "app.invoice.city": "Город",
    "app.invoice.countryCode": "Страна",

    "app.invoice.invoiceDetails": "Детали счета",
    "app.invoice.invoiceNum": "Номер счета",
    "app.invoice.customerNum": "Номер клиента",
    "app.invoice.date": "Дата",
    "app.invoice.deliveryDate": "Дата доставки",
    "app.invoice.serviceDate": "Дата обслуживания",
    "app.invoice.servicePeriodDays": "Период работ(Дней)",
    "app.invoice.servicePeriodMonths": "Период работ(Месяцев)",
    "app.invoice.noShowDate": "Не показывать дату доставки",

    "app.invoice.head": "Раздел заголовка",
    "app.invoice.invoiceTitle": "Название счета",
    "app.invoice.introductionText": "Вводный текст",

    "app.invoice.lineItems": "Элементы строки",
    "app.invoice.position": "Позиция",
    "app.invoice.productService": "Продукт / услуга",
    "app.invoice.quantity": "Количество",
    "app.invoice.price": "Цена",
    "app.invoice.unit": "Единица измерения",
    "app.invoice.tax": "Налог",
    "app.invoice.discount": "Скидка",
    "app.invoice.total": "Итого",
    "app.invoice.newLine": "Новая строка",
    "app.invoice.subTotalNet": "Промежуточный итог",
    "app.invoice.piece": "Штук",
    "app.invoice.hour": "Час",
    "app.invoice.vat": "Налог",
    "app.invoice.totalAmount": "Общая сумма",

    "app.invoice.foot": "Нижняя часть страницы",
    "app.invoice.paymentTerm": "Срок оплаты",
    "app.invoice.description": "Описание",

    "app.invoice.caching": "Кэширование",
    "app.invoice.goToPreview": "Перейти к предварительному просмотру",

    "app.invoiceList.invoiceDate": "Дата выставления счета",
    "app.invoiceList.serviceDescription": "Наименование услуги",
    "app.invoiceList.project": "Проект",
    "app.invoiceList.amountNetto": "Сумма нетто (EUR)",
    "app.invoiceList.amountBrutto": "Сумма брутто (EUR)",
};
