import FileRender from "./FileRender";
import React from "react";
import { Empty } from "antd";

interface Props {
    fileId?: string;
}

class Preview extends React.PureComponent<Props> {
    render() {
        if (!this.props.fileId) {
            return (
                <div className={"FileUploader__empty"}>
                    <Empty />
                </div>
            );
        }

        return (
            <div className="Preview">
                <FileRender fileId={this.props.fileId} />
            </div>
        );
    }
}

export default Preview;
