import { GQL } from "@binale-tech/shared";
import { DmsType, IDocumentEnriched } from "@dms/types";

export class DocumentFieldConfig {
    static defaultDocumentFormField: Partial<Record<keyof IDocumentEnriched, keyof IDocumentEnriched>> = {
        fileName: "fileName",
        type: "type",
        subType: "subType",
        partner: "partner",
        isAttachment: "isAttachment",
        UStIdNr: "UStIdNr",
        landCode: "landCode",
        documentNumber: "documentNumber",
        documentDate: "documentDate",
        documentAmount: "documentAmount",
        originalAmount: "originalAmount",
        currencyRate: "currencyRate",
        currency: "currency",
        interneNumber: "interneNumber",
        description: "description",
    };

    static newDocumentFormField: Partial<Record<keyof IDocumentEnriched, keyof IDocumentEnriched>> = {
        fileName: "fileName",
        type: "type",
        subType: "subType",
        partner: "partner",
        documentNumber: "documentNumber",
        documentDate: "documentDate",
        isAttachment: "isAttachment",
        UStIdNr: "UStIdNr",
        landCode: "landCode",
        documentAmount: "documentAmount",
        originalAmount: "originalAmount",
        currencyRate: "currencyRate",
        currency: "currency",
        interneNumber: "interneNumber",
        description: "description",
    };

    static invoiceFormFields: Partial<Record<keyof IDocumentEnriched, keyof IDocumentEnriched>> = {
        fileName: "fileName",
        type: "type",
        subType: "subType",
        partner: "partner",
        documentNumber: "documentNumber",
        documentDate: "documentDate",
        isAttachment: "isAttachment",
        UStIdNr: "UStIdNr",
        landCode: "landCode",
        documentAmount: "documentAmount",
        originalAmount: "originalAmount",
        currencyRate: "currencyRate",
        currency: "currency",
        interneNumber: "interneNumber",
        description: "description",
        deadlineDays: "deadlineDays",
        discountAmount: "discountAmount",
        discountDate: "discountDate",
        discountDays: "discountDays",
        discountPercent: "discountPercent",
        dueDate: "dueDate",
        paymentType: "paymentType",
    };

    static getDocumentField(type: string) {
        switch (type) {
            case DmsType.new_documents:
                return this.newDocumentFormField;

            case GQL.IProductKey.Er:
                return this.invoiceFormFields;

            case GQL.IProductKey.Deb:
                return this.invoiceFormFields;

            default:
                return this.defaultDocumentFormField;
        }
    }
}
