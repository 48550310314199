import React, { useEffect } from "react";
import classNames from "classnames";
import { Base, GQL, Utils } from "@binale-tech/shared";
import { Button, Form, Modal } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";

import CommonKontoAnsichtToolbar from "./components/KontoAnsichtToolbar";
import GenericTableViewCtxWrapper from "../../../productSharedComponents/GenericTableViewCtxWrapper";
import TableExporter from "appearance/components/shared/TableExporter/TableExporter";
import TableUtils from "scripts/core/TableUtils";
import {
    CategoryCreditorMode,
    CategoryCreditorModes,
    GenericRecordProperties,
    ProductCommonKontenAnsicht,
    TableProperties,
} from "scripts/core/Product";
import { ColumnOverrides, TableGetters } from "appearance/columns/ColumnConfig";
import { DisabledFieldsResolver } from "./components/DisabledFieldsResolver";
import { Exporters, TableExportType } from "scripts/exporters/Exporters";
import { GenericRecord } from "scripts/models";
import { GenericRecordTableItem, TableItem } from "appearance/components/shared/Table/Table";
import {
    GenericTableView,
    GenericTableViewProps,
    GenericTableViewState,
} from "../../../productSharedComponents/GenericTableView";
import { ProductKeys } from "scripts/models/Product";
import { RecordsContext } from "scripts/context/recordsContext/RecordsCtx";
import { RecordsControlContext } from "scripts/context/recordsContext/RecordsControlCtx";
import { RecordsTableBlock, TableConfigFooters, TableSum } from "../../../productSharedComponents/RecordsTableBlock";
import { SHConverter } from "scripts/core/SollHaben";
import { SollHabenPrintHelper } from "scripts/core/SollHabenHelper";
import { logger } from "scripts/infrastructure/logger";
import { CompanyContext, YearPeriodContext } from "scripts/context/CompanyContext";
import {
    defaultPredicates,
    GenericToolbarFilters,
    searchPredicate,
} from "../../../../../scripts/context/tableViewContext/tableViewFilters";
import { IGenericItem } from "scripts/models/Interfaces";
import {
    getDefaultYearPeriodFilters,
    TableFiltersContext,
    TableViewContext,
    TableViewContextProvider,
} from "scripts/context/tableViewContext/tableViewContext";
import { getTaxMap, hasRecordTaxKonto, isKontoMatchesFilter } from "./components/kaFunctions";

interface KontoAnsichtState extends GenericTableViewState {
    exportShow?: boolean;
    kontoMode: CategoryCreditorMode;
}

class KontoAnsichtViewClass extends GenericTableView<KontoAnsichtState> {
    protected disabledFieldsResolver = new DisabledFieldsResolver();

    protected getInitialState(): KontoAnsichtState {
        const baseState = super.getInitialState();
        return { ...baseState, kontoMode: CategoryCreditorModes.CAT };
    }
    protected getInlineForm(): React.ReactNode {
        return null;
    }

    protected getHeader(): React.ReactNode {
        return null;
    }
    protected onTableDocumentsClick(v: GenericRecord) {
        const tableItem = Array.from(this.originalToUpdatedMappingTI.keys()).find(ti => ti.item.key === v.key);
        const originalTableItem = this.originalToUpdatedMappingTI.get(tableItem);
        super.onTableDocumentsClick(originalTableItem.item);
    }
    protected getDefaultSortColumn() {
        return GenericRecordProperties.RecordDatum;
    }

    protected getSchemeDisabledFields() {
        return this.disabledFieldsResolver.getDisabledFields(this.state.kontoMode, this.isTaxCatSelected());
    }

    componentDidMount() {
        this.receiveRecordsCCList(this.props);
        super.componentDidMount();
    }

    protected isLoading() {
        if (this.props.user.isLoading) {
            return true;
        }
        if (!this.props.companyGQL) {
            return true;
        }
        if (!this.props.buTimeframes?.length) {
            return true;
        }
        return false;
    }

    componentDidUpdate(prevProps: Readonly<GenericTableViewProps>, prevState: KontoAnsichtState) {
        if (
            this.props.records !== prevProps.records ||
            this.props.period !== prevProps.period ||
            this.props.year !== prevProps.year ||
            prevProps.buTimeframes !== this.props.buTimeframes
        ) {
            this.receiveRecordsCCList(this.props);
        }
        if (super.componentDidUpdate) {
            super.componentDidUpdate(prevProps, prevState);
        }
    }

    protected onExportShow = () => this.setState({ exportShow: true });
    protected onKontoModeChange = (kontoMode: CategoryCreditorMode) => this.setState({ kontoMode });
    protected onExportHide = () => this.setState({ exportShow: false });

    protected getExportBlock() {
        const filterKonto: Base.IExtNum = this.props.filters.get(GenericToolbarFilters.Konto)?.value;
        return (
            <Modal
                open={this.state.exportShow}
                width={1000}
                destroyOnClose
                style={{ top: 20 }}
                onOk={this.onExportHide}
                onCancel={this.onExportHide}
                footer={null}
                title={<FormattedMessage id="app.titles.data_export" />}
            >
                <Form layout="inline">
                    <Form.Item label={<FormattedMessage id="app.fields.date.year" />}>{this.props.year}</Form.Item>
                    <Form.Item label={<FormattedMessage id="app.fields.konto" />}>
                        {filterKonto && (
                            <>
                                {filterKonto.getExtNumPrint(this.props.yearConfig?.kontoExt)} - {filterKonto.name}
                            </>
                        )}
                        {!filterKonto && <FormattedMessage id="app.global.not_selected" />}
                    </Form.Item>
                    <Form.Item label={<FormattedMessage id="app.fields.date.month" />}>
                        {this.props.period || <FormattedMessage id="app.global.not_selected" />}
                    </Form.Item>
                </Form>
                <br />
                {this.dlBtn(this.state.tableItems.length)}
            </Modal>
        );
    }

    protected dlBtn = (count: number) => {
        const disabled = count === 0;
        const disabledCols = new Set(this.getSchemeDisabledFields());
        const columns = this.tableColumns
            .getColumnConfig()
            .map(k => k.key)
            .filter(v => !disabledCols.has(v));

        const btn = (
            <Button disabled={disabled} type="primary">
                {!disabled && (
                    <>
                        <FormattedMessage id="app.button.download" />
                        &nbsp;({count})&nbsp;
                        <DownOutlined />
                    </>
                )}
                {disabled && "Nothing to export"}
            </Button>
        );
        if (disabled) {
            return btn;
        }
        const defColumns = this.getDefaultExportColumns();
        return (
            <TableExporter
                columns={columns}
                onOk={(t, v) => this.handleExport(t, v || defColumns)}
                defaultColumnsKeys={defColumns}
            >
                {btn}
            </TableExporter>
        );
    };

    protected handleExport = async (type: TableExportType, columns: string[]) => {
        const labelOverrides = new Map();
        this.props.product.tableColumnOverrides().forEach((v, k) => {
            labelOverrides.set(k, v.label);
        });
        const disabledColumns = new Set(this.getSchemeDisabledFields());
        columns = columns.filter(c => !disabledColumns.has(c));

        const filterKonto: Base.IExtNum = this.props.filters.get(GenericToolbarFilters.Konto)?.value;
        const groupFake = [filterKonto.getExtNumPrint(this.props.yearConfig.kontoExt), filterKonto.name].join(" ");
        const data = Exporters.preprocessor.getExpandedExportData({
            columns,
            tableItems: this.state.tableItems,
            tableColumns: this.tableColumns,
            columnNames: labelOverrides,
            groupFake,
        });
        const suffix = Exporters.preprocessor.getSuffix(
            this.props.year,
            Number.isFinite(this.props.period) ? new Set([this.props.period]) : new Set(Array.from(Array(12).keys()))
        );
        const options: Parameters<typeof Exporters.builder.createExporter>[1] = {
            filename: ["Kontenansicht", suffix].join("_") + "." + type,
            subTitle: suffix,
            companyName: this.props.companyGQL.name,
            title: "Kontenansicht",
        };

        Exporters.builder.createExporter(type, options).download(data);
    };

    protected getDefaultExportColumns() {
        const disabled = new Set(this.getSchemeDisabledFields());
        return [
            TableProperties.ComputedNr,
            GenericRecordProperties.RecordDatum,
            GenericRecordProperties.RecordCategoryCreditorNum,
            GenericRecordProperties.RecordCategoryCreditorName,
            GenericRecordProperties.RecordBelegfeld1,

            GenericRecordProperties.ComputedKASoll,
            GenericRecordProperties.ComputedKAHaben,

            GenericRecordProperties.ComputedNetto,
            GenericRecordProperties.ComputedKABruttoSoll,
            GenericRecordProperties.ComputedKABruttoHaben,
            GenericRecordProperties.ComputedKASaldoBrutto,

            GenericRecordProperties.ItemBuchungstext,
        ].filter(v => !disabled.has(v));
    }

    // protected originalToUpdatedMapping = new Map<GenericRecord, GenericRecord>();
    protected originalToUpdatedMappingTI = new Map<GenericRecordTableItem, GenericRecordTableItem>();

    protected postProcessTableItems(vs: GenericRecordTableItem[]) {
        const { isSelected, konto: filterKonto } = this.getFilterAccount();
        const textSearchTerm = this.props.filters.get(GenericToolbarFilters.TextSearch)?.value;
        if (!this.props.companyGQL || !this.props.buTimeframes.length || !isSelected) {
            return [];
        }
        const { yearConfig, buTimeframes, payments } = this.props;

        this.originalToUpdatedMappingTI.clear();
        const converter = new SHConverter(yearConfig, buTimeframes, payments);
        const reProcessTableItem = (tableItem: GenericRecordTableItem) => {
            // calculate new children after filter
            tableItem.children = TableUtils.getChildren(tableItem.item);

            // overrive children soll haben map
            tableItem.extra.childrenSollHabenMap = new Map();
            tableItem.children.forEach(v => {
                tableItem.extra.childrenSollHabenMap.set(v, converter.convert(v));
            });
        };
        const cloneTableItem = (ti: GenericRecordTableItem): GenericRecordTableItem => {
            const record: GenericRecordTableItem = { ...ti };
            record.item = record.item.clone();
            record.extra = { ...record.extra };
            return record;
        };
        const filterKontoItems = (gi: IGenericItem) => {
            return isKontoMatchesFilter(gi.getCategoryCreditor(), filterKonto, yearConfig);
        };
        const filterKontoRecordFull = (record: GenericRecord) => {
            if (isKontoMatchesFilter(record.getRecordCategoryCreditor(), filterKonto, yearConfig)) {
                return true;
            }

            return record.items.some(i => isKontoMatchesFilter(i.getCategoryCreditor(), filterKonto, yearConfig));
        };
        const isTaxCatSelected = this.isTaxCatSelected();
        const res: GenericRecordTableItem[] = [];
        const selectedKeys = new Set(this.selectedKeys);
        vs.forEach(origRecord => {
            if (origRecord.item.draft) {
                return;
            }

            // pre-filter
            if (isTaxCatSelected) {
                const isTaxCatPresent =
                    hasRecordTaxKonto(origRecord.item, filterKonto, converter) ||
                    filterKontoRecordFull(origRecord.item);
                if (!isTaxCatPresent) {
                    return;
                }
            } else {
                const isFilterKontoPresent = filterKontoRecordFull(origRecord.item);
                if (!isFilterKontoPresent) {
                    return;
                }
            }

            // clone item before updates in items
            const record = cloneTableItem(origRecord);

            // of it depends what do we showCompanyModal in g.konto field
            const isRecordKontoSelected = isKontoMatchesFilter(
                record.item.getRecordCategoryCreditor(),
                filterKonto,
                yearConfig
            );
            record.extra.isRecordKontoSelected = isRecordKontoSelected;
            record.extra.isTaxCatSelected = isTaxCatSelected;

            // if selected konto is one from items, we need to filter items
            if (!isRecordKontoSelected) {
                const filteredRealItems = record.item.items.filter(filterKontoItems);
                if (isTaxCatSelected) {
                    if (filteredRealItems.length) {
                        // todo: in case of the split (one is is really 1406, another has it as a virtual taxCat) we might need to handle those items separately
                        // context: https://binale.atlassian.net/browse/FIBU-671
                        record.extra.isTaxCatSelected = false;
                        record.item.items = filteredRealItems;
                    } else {
                        record.item.items = record.item.items.filter(gi => {
                            const singleItemRecord = GenericRecord.genegateSingleItemRecord(record.item, gi);
                            return hasRecordTaxKonto(singleItemRecord, filterKonto, converter) || filterKontoItems(gi);
                        });
                    }
                } else {
                    record.item.items = filteredRealItems;
                }
                if (record.item.items.length === 0) {
                    logger.crit(new Error("no items left after filter"), {
                        isTaxCatSelected,
                    });
                    logger.log({ selected: filterKonto, record: origRecord.item });
                    return;
                }
            }
            const addTableItem = (ti: GenericRecordTableItem) => {
                reProcessTableItem(ti);
                res.push(ti);
                this.originalToUpdatedMappingTI.set(ti, origRecord);
            };
            if (this.state.kontoMode === CategoryCreditorModes.CAT) {
                record.item.items.forEach((gi, idx) => {
                    const newTableItem = cloneTableItem(record);
                    newTableItem.item = GenericRecord.genegateSingleItemRecord(record.item, gi, true);
                    newTableItem.item.key = `${newTableItem.item.key}/${idx}`;
                    newTableItem.selected = selectedKeys.has(newTableItem.item.key);
                    newTableItem.extra.sollHaben = converter.convert(newTableItem.item);
                    addTableItem(newTableItem);
                });
            } else {
                addTableItem(record);
            }
        });

        return res.filter(record => searchPredicate(record.item, textSearchTerm, this.props.yearConfig?.skr));
    }

    protected postSortTableItems(vs: GenericRecordTableItem[]) {
        let saldo = 0;
        vs.forEach(record => {
            const isActive = !record.item.draft;
            if (isActive) {
                const sh = this.getFinancialData(record);
                saldo += (sh.soll || 0) - (sh.haben || 0);
            }
            record.extra.saldo = isActive ? saldo : null;
        });
        return vs;
    }

    protected getFilterAccount(): { isSelected: boolean; konto?: Base.IExtNum } {
        const konto: Base.IExtNum = this.props.filters.get(GenericToolbarFilters.Konto)?.value;
        return { isSelected: Boolean(konto), konto };
    }

    protected isTaxCatSelected() {
        const { isSelected, konto } = this.getFilterAccount();
        return isSelected && this.taxMap.has(konto.getExtNum(this.props.yearConfig.kontoExt));
    }

    protected taxMap = new Map<string, Base.IExtNum>();
    protected receiveRecordsCCList = (props: Readonly<GenericTableViewProps>) => {
        if (!props.companyGQL) {
            this.taxMap = new Map();
            return;
        }
        const { kontoExt } = props.yearConfig;
        const converter = new SHConverter(this.props.yearConfig, props.buTimeframes, props.payments);

        this.taxMap = getTaxMap(props.records, converter, kontoExt);
    };

    protected handleUpdateItemsKA = (vs: TableItem<GenericRecord>[], e?: React.SyntheticEvent) => {
        const keyMapping = new Map();

        this.originalToUpdatedMappingTI.forEach((value, key) => {
            keyMapping.set(key.key, value);
        });
        const newMapping = new Map();
        vs.forEach(item => {
            newMapping.set(item, keyMapping.get(item.key));
        });
        this.originalToUpdatedMappingTI = newMapping;
        this.handleUpdateItems(vs, e);
    };

    protected getBlockTableView(h: number): React.ReactNode {
        const {
            onSort,
            sortColumn,
            canWrite,
            focusIndex,
            onSetFocus,
            product,
            view,
            tableRef,
            tableItems,
            selectedPeriodEditBound,
        } = this.getTableLegacyCommonProps();
        return (
            <RecordsTableBlock
                view={view}
                tableRef={tableRef}
                tableItems={tableItems}
                tableHeight={h}
                tableSaldoHeader={undefined}
                selectedPeriodEditBound={selectedPeriodEditBound}
                tableRowClassFunc={this.rowClassFunc}
                onSort={onSort}
                sortColumn={sortColumn}
                product={product}
                canWrite={canWrite}
                onPayments={p => this.handleZahlungTableItemKA(p)}
                focusIndex={focusIndex}
                onSetFocus={onSetFocus}
                tableConfigFooters={this.tableConfigFooters}
                columnConfig={this.tableColumns}
                tableSumOverride={this.getTableSum}
                extraHiddenColumns={this.getSchemeDisabledFields()}
                itemActions={{
                    handleEditItem: () => () => {},
                    handleCopyItem: () => () => {},
                    handleDeleteItems: () => () => {},
                    handleCancelItems: () => () => {},
                    handleUpdateItems: (v, e) => this.handleUpdateItemsKA(v, e),
                    handleColorTableItems: (v, c) => this.handleColorTableItemsKA(v, c),
                    handleAvisTableItems: (v, avis) => this.handleAvisTableItemsKA(v, avis),
                    handleBulkEditItem: () => () => {},
                }}
            />
        );
    }

    protected getHandlerOriginalTableItems(tableItems: TableItem<GenericRecord>[]): TableItem<GenericRecord>[] {
        const filtered = tableItems.filter(v => !v.item.cancellation);
        const originalTIs = filtered.map(v => this.originalToUpdatedMappingTI.get(v));
        return originalTIs.filter(Boolean);
    }

    protected handleColorTableItemsKA(tableItems: TableItem<GenericRecord>[], color: string) {
        const originalTI = this.getHandlerOriginalTableItems(tableItems);
        this.handleColorTableItems(originalTI, color);
    }

    protected handleAvisTableItemsKA(tableItems: TableItem<GenericRecord>[], avis: boolean) {
        const originalTI = this.getHandlerOriginalTableItems(tableItems);
        this.handleAvisTableItems(originalTI, avis);
    }
    protected handleReviewItemKA(tableItem: TableItem<GenericRecord>, e?: any) {
        const originalTI = this.getHandlerOriginalTableItems([tableItem])[0];
        if (!originalTI) {
            return () => {};
        }
        return this.handleReviewItem(originalTI, e);
    }
    protected handleZahlungTableItemKA(tableItem: TableItem<GenericRecord>) {
        const originalTI = this.getHandlerOriginalTableItems([tableItem])[0];
        if (!originalTI) {
            return () => {};
        }
        return this.handleZahlungTableItem(originalTI);
    }

    protected getTableSum = (vs: GenericRecordTableItem[]): TableSum => {
        const filterKonto: Base.IExtNum = this.props.filters.get(GenericToolbarFilters.Konto)?.value;
        const tableSum: TableSum = {
            mainFooter: {
                soll: 0,
                haben: 0,
                nettoSum: 0,
                saldoSum: 0,
                vatSum: 0,
            },
            selectedFooter: {
                soll: 0,
                haben: 0,
                nettoSum: 0,
                saldoSum: 0,
                vatSum: 0,
            },
        };
        const { mainFooter, selectedFooter } = tableSum;

        if (!filterKonto) {
            vs = [];
        }

        vs.forEach(v => {
            const sh = this.getFinancialData(v);
            if (v.selected) {
                selectedFooter.soll += sh.soll || 0;
                selectedFooter.haben += sh.haben || 0;
                selectedFooter.vatSum += sh.vatSum || 0;
                selectedFooter.nettoSum += sh.nettoSum || 0;
                selectedFooter.saldoSum = selectedFooter.soll - selectedFooter.haben;
            }
            mainFooter.soll += sh.soll || 0;
            mainFooter.haben += sh.haben || 0;
            mainFooter.vatSum += sh.vatSum || 0;
            mainFooter.nettoSum += sh.nettoSum || 0;
            mainFooter.saldoSum = v.extra.saldo;
        });
        return { mainFooter, selectedFooter };
    };

    protected tableConfigFooters = (tableItems: TableItem<GenericRecord>[], tableSum: TableSum): TableConfigFooters => {
        return {
            [TableProperties.ComputedNr]: {
                mainFooter: tableItems.length.toString(),
                selectedFooter: tableItems.filter(i => i.selected).length.toString(),
            },
            [GenericRecordProperties.ComputedSaldo]: {
                mainFooter: SollHabenPrintHelper.printSH(tableSum.mainFooter.saldoSum),
                selectedFooter: SollHabenPrintHelper.printSH(tableSum.selectedFooter.saldoSum),
            },
            [GenericRecordProperties.ComputedKASaldoBrutto]: {
                mainFooter: SollHabenPrintHelper.printSH(tableSum.mainFooter.saldoSum),
                selectedFooter: SollHabenPrintHelper.printSH(tableSum.selectedFooter.saldoSum),
            },
            [GenericRecordProperties.ComputedUSt]: {
                mainFooter: SollHabenPrintHelper.printSH(tableSum.mainFooter.vatSum),
                selectedFooter: SollHabenPrintHelper.printSH(tableSum.selectedFooter.vatSum),
            },
            [GenericRecordProperties.ComputedKASoll]: {
                mainFooter: (
                    <span>
                        {Utils.CurrencyUtils.currencyFormat(tableSum.mainFooter.soll)}
                        <code>S</code>
                    </span>
                ),
                selectedFooter: (
                    <span>
                        {Utils.CurrencyUtils.currencyFormat(tableSum.selectedFooter.soll)}
                        <code>S</code>
                    </span>
                ),
            },
            [GenericRecordProperties.ComputedKAHaben]: {
                mainFooter: (
                    <span>
                        {Utils.CurrencyUtils.currencyFormat(tableSum.mainFooter.haben)}
                        <code>H</code>
                    </span>
                ),
                selectedFooter: (
                    <span>
                        {Utils.CurrencyUtils.currencyFormat(tableSum.selectedFooter.haben)}
                        <code>H</code>
                    </span>
                ),
            },
            [GenericRecordProperties.ComputedKABruttoSoll]: {
                mainFooter: (
                    <span>
                        {Utils.CurrencyUtils.currencyFormat(tableSum.mainFooter.soll)}
                        <code>S</code>
                    </span>
                ),
                selectedFooter: (
                    <span>
                        {Utils.CurrencyUtils.currencyFormat(tableSum.selectedFooter.soll)}
                        <code>S</code>
                    </span>
                ),
            },
            [GenericRecordProperties.ComputedKABruttoHaben]: {
                mainFooter: (
                    <span>
                        {Utils.CurrencyUtils.currencyFormat(tableSum.mainFooter.haben)}
                        <code>H</code>
                    </span>
                ),
                selectedFooter: (
                    <span>
                        {Utils.CurrencyUtils.currencyFormat(tableSum.selectedFooter.haben)}
                        <code>H</code>
                    </span>
                ),
            },
            [GenericRecordProperties.ComputedNetto]: {
                mainFooter: SollHabenPrintHelper.printSH(tableSum.mainFooter.nettoSum),
                selectedFooter: SollHabenPrintHelper.printSH(tableSum.selectedFooter.nettoSum),
            },
        };
    };

    protected getterSoll = (tableItem: GenericRecordTableItem) => {
        const num = this.getFinancialData(tableItem).soll;
        return num === null ? null : Utils.CurrencyUtils.currencyFormat(num);
    };
    protected getterHaben = (tableItem: GenericRecordTableItem) => {
        const num = this.getFinancialData(tableItem).haben;
        return num === null ? null : Utils.CurrencyUtils.currencyFormat(num);
    };
    protected getterSaldo = (tableItem: GenericRecordTableItem) => {
        if (tableItem.key === null) {
            return null;
        }
        return SollHabenPrintHelper.printSH(tableItem.extra.saldo);
    };
    protected tableConfigGetters() {
        const getters: ColumnOverrides<GenericRecord>["getters"] = {
            [GenericRecordProperties.RecordCategoryCreditorNum]: (tableItem: GenericRecordTableItem, ext: number) => {
                const konto =
                    tableItem.extra.isRecordKontoSelected || tableItem.extra.isTaxCatSelected
                        ? tableItem.item.getItemCategoryCreditor()
                        : tableItem.item.getRecordCategoryCreditor();
                return konto.getExtNumPrint(ext);
            },
            [GenericRecordProperties.RecordCategoryCreditorName]: (tableItem: GenericRecordTableItem) => {
                const konto =
                    tableItem.extra.isRecordKontoSelected || tableItem.extra.isTaxCatSelected
                        ? tableItem.item.getItemCategoryCreditor()
                        : tableItem.item.getRecordCategoryCreditor();
                return konto.name;
            },
            // extended scheme
            [GenericRecordProperties.ComputedKABruttoSoll]: v => this.getterSoll(v),
            [GenericRecordProperties.ComputedKABruttoHaben]: v => this.getterHaben(v),
            [GenericRecordProperties.ComputedNetto]: (tableItem: GenericRecordTableItem) => {
                return SollHabenPrintHelper.printSH(this.getFinancialData(tableItem).nettoSum);
            },
            [GenericRecordProperties.ComputedUSt]: (tableItem: GenericRecordTableItem) => {
                return SollHabenPrintHelper.printSH(this.getFinancialData(tableItem).vatSum);
            },
            [GenericRecordProperties.ComputedKASaldoBrutto]: v => this.getterSaldo(v),

            // short scheme
            [GenericRecordProperties.ComputedKASoll]: v => this.getterSoll(v),
            [GenericRecordProperties.ComputedKAHaben]: v => this.getterHaben(v),
            [GenericRecordProperties.ComputedSaldo]: v => this.getterSaldo(v),

            // status/za overrides
            [GenericRecordProperties.ComputedStatus]: (tableItem: GenericRecordTableItem) => {
                const originalTableItem = this.originalToUpdatedMappingTI.get(tableItem);
                const o = TableGetters.computedStatus(originalTableItem);
                return o ? (
                    <p
                        className={classNames("status", "status-clickable", {
                            "status-offenColor": o.abbr === "offen",
                            "status-bezahltColor": o.abbr === "bezahlt",
                            "status-avisColor": o.abbr === "AVIS",
                        })}
                        onClick={() => this.handleZahlungTableItem(originalTableItem)}
                    >
                        {o.abbr}
                    </p>
                ) : (
                    ""
                );
            },
            [GenericRecordProperties.ComputedZA]: (tableItem: GenericRecordTableItem) => {
                const o = TableGetters.computedZA(this.originalToUpdatedMappingTI.get(tableItem));
                return o ? <abbr title={o.title}>{o.abbr}</abbr> : "";
            },
            [GenericRecordProperties.ControlBinding]: (tableItem: GenericRecordTableItem) =>
                this.getRecordBinding(tableItem),
            [GenericRecordProperties.RecordReview]: (tableItem: GenericRecordTableItem) =>
                this.getRecordReview(tableItem, this.handleReviewItemKA(tableItem)),
        };
        return getters;
    }

    protected getRecordBinding(tableItem: TableItem<GenericRecord>) {
        // if no status we can show binding button
        const originalItem = this.originalToUpdatedMappingTI.get(tableItem);
        const o = TableGetters.computedStatus(originalItem);
        const hideButton =
            !Number.isFinite(tableItem.key) ||
            !originalItem ||
            o ||
            (originalItem.children.length > 0 && tableItem.item.getProductKey() !== GQL.IProductKey.Fe) ||
            (originalItem.item.category && originalItem.item.items[0].category);
        if (hideButton) {
            return null;
        }
        return super.getRecordBinding(originalItem);
    }

    protected getFinancialData(
        tableItem: GenericRecordTableItem
    ): Pick<TableSum["mainFooter"], "soll" | "haben" | "vatSum" | "nettoSum"> {
        let soll: number | null = null;
        let haben: number | null = null;
        let vatSum = 0;
        let nettoSum = 0;
        const { items } = TableUtils.getTableItemSHContainer(tableItem);
        const recordSH = items.find(v => v.isRecordKonto);

        if (tableItem.extra.isRecordKontoSelected) {
            if (recordSH.isHaben) {
                haben = recordSH.amount;
            } else {
                soll = recordSH.amount;
            }

            vatSum = recordSH.extended.vatSH;
            nettoSum = recordSH.extended.nettoSH;
        } else {
            const { konto: filterKonto } = this.getFilterAccount();
            const filtered = items.filter(
                v => !v.isRecordKonto && isKontoMatchesFilter(v.konto, filterKonto, this.props.yearConfig)
            );
            // let amt = 0;
            filtered.forEach(v => {
                if (v.isHaben) {
                    haben = (haben || 0) + v.amount;
                } else {
                    soll = (soll || 0) + v.amount;
                }
                if (v.taxes) {
                    v.taxes.forEach(t => {
                        if (t.isHaben) {
                            vatSum -= t.amount;
                        } else {
                            vatSum += t.amount;
                        }
                    });
                }
            });
        }
        return {
            soll,
            haben,
            vatSum,
            nettoSum,
        };
    }

    protected getToolbarBlock() {
        const displayRecords = this.state.tableItems.some(v => v.selected)
            ? this.state.tableItems.filter(v => v.selected)
            : this.state.tableItems;
        return (
            <CommonKontoAnsichtToolbar
                tableColumns={this.tableColumns}
                displayRecords={displayRecords.map(v => this.originalToUpdatedMappingTI.get(v)?.item).filter(Boolean)}
                disabledTableColumns={this.getSchemeDisabledFields()}
                onExportClick={this.onExportShow}
                onKontoModeChange={this.onKontoModeChange}
                kontoMode={this.state.kontoMode}
                onSelectReconciliationRecords={ids =>
                    this.handleUpdateItemsKA(
                        this.state.tableItems.map(ti => {
                            ti.selected = ids.has(ti.item.key);
                            return ti;
                        })
                    )
                }
            />
        );
    }

    render() {
        return (
            <>
                {this.getPageWithoutLeftTable()}
                {this.getExportBlock()}
            </>
        );
    }
}

export const KontoAnsichtView: React.FC = () => {
    const { year, period, onChangePeriod } = React.useContext(YearPeriodContext);
    const { yearConfig, companyGQL } = React.useContext(CompanyContext);
    useEffect(() => {
        if (period !== null) {
            onChangePeriod(null);
        }
    }, []);

    const allRecords = React.useContext(RecordsContext);
    const allActions = React.useContext(RecordsControlContext);
    const tableCtx = React.useMemo<React.ContextType<typeof TableViewContext>>(
        () => ({
            view: "commonKontenAnsicht",
            product: new ProductCommonKontenAnsicht(yearConfig.skr, companyGQL),
            productKey: ProductKeys.AccountingCommon,
            // in KA we only work with records within one year (there is no cross-year KreditorenAnsicht)
            moduleRecords: (allRecords.allRecords.list || []).filter(v => defaultPredicates.year(yearConfig.year)(v)),
            moduleActions: allActions.blackhole,
            moduleLogLister: () => Promise.reject("not available for global overview"),
        }),
        [allActions.blackhole, allRecords.allRecords, yearConfig, companyGQL]
    );
    const defaultFilters = React.useMemo<React.ContextType<typeof TableFiltersContext>["filters"]>(() => {
        const filters = getDefaultYearPeriodFilters(year, period);
        filters.set(GenericToolbarFilters.Konto, { predicate: () => false, value: null });
        filters.set(GenericToolbarFilters.TextSearch, { predicate: () => true, value: "" });
        return filters;
    }, [year, period]);
    return (
        <TableViewContextProvider tableCtx={tableCtx} defaultFilters={defaultFilters}>
            <GenericTableViewCtxWrapper Component={KontoAnsichtViewClass} />
        </TableViewContextProvider>
    );
};
