import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import { Button, Divider, Flex, Modal, Radio, Spin, Typography } from "antd";
import { DmsType, TBindSubType } from "@dms/types";
import { DmsAppContext, DmsAppControlContext, DmsDataContext } from "@dms/types/ContextTypes";
import { DmsUtils } from "@dms/scripts/utils/DmsUtils";
import { CloseButton } from "@dms/components/CloseButton/CloseButton";
import { CompanyContext } from "../../../scripts/context/CompanyContext";
import { useGqlMutator } from "../../../scripts/graphql/useGqlMutator";
import { FormattedMessage } from "react-intl";

import styles from "./bindModal.module.scss";
import { CheckCircleOutlined, CloseCircleOutlined, LinkOutlined, LoadingOutlined } from "@ant-design/icons";
import { getSubTypeName } from "@dms/scripts/helpers/getSubTypeName";
import { useBindName } from "@dms/hooks/useBindName";
import { useBindData } from "@dms/hooks/useBindData";

const UNBIND = "unbind";

const titleKey = {
    [DmsType.Bank]: "app.titles.bind_to_bank",
    [DmsType.KB]: "app.titles.bind_to_kb",
};

export const BindModal: FC = () => {
    const [value, setValue] = useState<string | null>(null);
    const [isBindDisabled, setIsBindDisabled] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [isError, setIsError] = useState(false);
    const { bindModalData } = useContext(DmsAppContext);
    const { fetchTypes, documentTypes } = useContext(DmsDataContext);
    const { kbList, bankList } = useBindData();
    const { bindModalDataAction } = useContext(DmsAppControlContext);
    const { companyGQL } = useContext(CompanyContext);
    const getBindName = useBindName();

    const mutator = useGqlMutator();

    const { id: companyId } = companyGQL;

    const uniqueList = useMemo(() => {
        if (bindModalData.type === DmsType.KB) {
            return kbList;
        } else if (bindModalData.type === DmsType.Bank) {
            return bankList;
        } else {
            return [];
        }
    }, [bankList, bindModalData.type, kbList]);

    useEffect(() => {
        if (bindModalData.currentVal) {
            setIsBindDisabled(false);
            return setValue(UNBIND);
        }
        const currentValue = uniqueList.find(el => el?.id === bindModalData?.currentVal)?.id ?? null;
        setValue(currentValue);
    }, []);

    const handleClick = (id: string) => {
        setValue(id);
        return setIsBindDisabled(false);
    };

    const handleClose = () => {
        setValue(null);
        bindModalDataAction({ isOpen: false });
    };

    const handleBind = async (type: TBindSubType) => {
        await DmsUtils.bindSubTypeToItem(
            type,
            {
                subTypeId: bindModalData.id,
                targetId: value,
                companyId,
            },
            mutator
        );
        await fetchTypes();
    };

    const handleUnBind = async (type: TBindSubType) => {
        await DmsUtils.unBindSubTypeToItem(
            type,
            {
                subTypeId: bindModalData.id,
                targetId: value,
                companyId,
            },
            mutator
        );
        await fetchTypes();
    };

    const handleConfirm = async () => {
        setIsLoading(true);
        try {
            if (value === UNBIND) {
                await handleUnBind(bindModalData.type);
            } else {
                await handleBind(bindModalData.type);
            }
            setSuccess(true);
            setIsError(false);
        } catch {
            setIsError(true);
            setSuccess(false);
        } finally {
            setIsLoading(false);
            setTimeout(() => {
                handleClose();
            }, 500);
        }
    };

    return (
        <Modal
            open={bindModalData.isOpen}
            closable={false}
            footer={null}
            className={styles.modal}
            styles={{
                body: {
                    display: "flex",
                    flexDirection: "column",
                    minWidth: "450px",
                    maxWidth: "30vw",
                    height: "auto",
                    backgroundColor: "white",
                },
            }}
            title={bindModalData.currentVal ? <FormattedMessage id={`app.titles.unbind`} /> : null}
        >
            <CloseButton onClose={handleClose} />
            {!bindModalData.currentVal ? (
                <>
                    <Divider>
                        <Typography.Text>{<FormattedMessage id={titleKey[bindModalData.type]} />}</Typography.Text>
                    </Divider>
                    <div className={styles.modalBodyTextWrapper}>
                        <Typography.Text strong ellipsis>
                            {getSubTypeName(bindModalData.id, bindModalData.type, documentTypes)}
                        </Typography.Text>
                        <LinkOutlined style={{ margin: "10px 10px 0", color: "#52c41a" }} />
                        <div className={styles.modalListWrapper}>
                            <Radio.Group className={styles.modalList} value={value}>
                                {uniqueList.map(item => (
                                    <Radio
                                        className={styles.modalListItem}
                                        onClick={() => handleClick(item?.id)}
                                        value={item?.id}
                                        key={item?.id}
                                    >
                                        {item?.name}
                                    </Radio>
                                ))}
                            </Radio.Group>
                        </div>
                    </div>
                </>
            ) : (
                <div className={styles.modalBody}>
                    <div className={styles.modalBodyTextWrapper}>
                        <Typography.Text strong ellipsis>
                            {getSubTypeName(bindModalData.id, bindModalData.type, documentTypes)}
                        </Typography.Text>
                        <LinkOutlined style={{ margin: 10, color: "#52c41a" }} />
                        <Typography.Text strong ellipsis>
                            {getBindName(bindModalData.currentVal, bindModalData.type)}
                        </Typography.Text>
                    </div>

                    <Typography.Text style={{ marginTop: 20, textAlign: "end" }}>
                        <FormattedMessage id={`app.confirmation.body`} />{" "}
                    </Typography.Text>
                </div>
            )}
            <Divider />{" "}
            <Flex justify={"flex-end"} align={"center"} gap={10}>
                {success && !isLoading && <CheckCircleOutlined style={{ fontSize: 24, color: "#52c41a" }} />}
                {isError && !isLoading && <CloseCircleOutlined style={{ fontSize: 24, color: "#ff4d4f" }} />}
                {isLoading && <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />}
                <Button onClick={handleClose}>
                    <FormattedMessage id={`app.button.cancel`} />
                </Button>
                <Button type={"primary"} color={"blue"} onClick={handleConfirm} disabled={isBindDisabled}>
                    <FormattedMessage id={`app.button.confirm`} />
                </Button>
            </Flex>
        </Modal>
    );
};
