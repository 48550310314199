import React, { FC } from "react";
import { Flex, Typography } from "antd";
import { getTransactionBankTitle } from "@banks/scripts/helpers";
import { Utils } from "@binale-tech/shared";
import { IBank } from "@banks/types";

import styles from "./BankTransactionsToolbar.module.scss";
import cn from "classnames";
import dayjs from "dayjs";

const { Title } = Typography;

type TProps = {
    transactionBank: IBank;
};

export const BankTransactionsToolbar: FC<TProps> = ({ transactionBank }) => {
    return (
        <>
            <Flex gap={10} align={"center"}>
                <Title style={{ margin: 0 }} level={5}>
                    {`${getTransactionBankTitle(transactionBank ? { exist: transactionBank } : undefined)}`}
                </Title>
            </Flex>
            <Flex gap={10} align={"center"} style={{ fontSize: 16 }}>
                <span className={styles.balanceHeaderText}>{`Opening balance: `}</span>
                <span
                    className={cn(styles.balanceHeaderText, {
                        [styles.positive]: transactionBank.openingBalance > 0,
                        [styles.negative]: transactionBank.openingBalance < 0,
                    })}
                >
                    {`${
                        transactionBank.openingBalance
                            ? Utils.CurrencyUtils.currencyFormat(transactionBank.openingBalance)
                            : "0,00"
                    }`}
                </span>
                <span className={styles.balanceHeaderText}>EUR</span>
                <span className={styles.balanceHeaderText}>
                    {transactionBank.openingBalanceDate
                        ? `on ${dayjs(transactionBank.openingBalanceDate, "DD.MM.YYYY").format("DD MMMM YYYY")}`
                        : null}
                </span>
            </Flex>
        </>
    );
};
