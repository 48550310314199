import React, { FC, forwardRef } from "react";
import { StringInput } from "./StringInput";
import { InputRef } from "antd";

type TProps = {
    onChange?: (v: string) => void;
    value?: string;
    disabled?: boolean;
    placeholder?: string;
    suffix?: string;
};
export const DocumentFileNameInput: FC<TProps> = forwardRef<InputRef, TProps>((props, ref) => {
    return <StringInput {...props} maxLength={250} />;
});
