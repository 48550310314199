export const transactionFieldsDict = {
    myBankAccount: "My bank account", // auftragskonto
    myBankIban: "My bank's IBAN", // iban kontoinhaber
    bookingDate: "Booking date", // buchungstag
    transactionType: "Transaction type", // umsatzart
    bookingText: "Booking text", // buchungstext
    purpose: "Purpose", // verwendungszweck
    counterpartyName: "Counterparty name", // beguenstigter/zahlungspflichtiger
    counterpartyIbanOrAccountNum: "Counterparty IBAN/Account", // kontonummer/iban
    counterpartyBicOrBlz: "Counterparty BIC/BLZ",
    amount: "Amount", // betrag
    currencyCode: "Currency code", // waehrung

    // creditorId: "Creditor ID", // glaeubiger id
    // mandateReference: "Mandate reference", // mandatsreferenz
    // customerReference: "Customer reference (end-to-end)", // kundenreferenz (end-to-end)
    // collectorReference: "Collector reference", // sammlerreferenz
    // debitOriginalAmount: "Debit original amount", // lastschrift ursprungsbetrag
    // expenseReimbursementReturnDebit: "Expense reimbursement return debit", // auslagenersatz ruecklastschrift
};

export const transactionFieldsGroups = {
    myBank: {
        myBankAccount: transactionFieldsDict.myBankAccount,
        myBankIban: transactionFieldsDict.myBankIban,
    },
    transactionInfo: {
        bookingDate: transactionFieldsDict.bookingDate,
        amount: transactionFieldsDict.amount,
        currencyCode: transactionFieldsDict.currencyCode,
        purpose: transactionFieldsDict.purpose,
        bookingText: transactionFieldsDict.bookingText,
        transactionType: transactionFieldsDict.transactionType,
    },
    counterparty: {
        counterpartyName: transactionFieldsDict.counterpartyName,
        counterpartyIbanOrAccountNum: transactionFieldsDict.counterpartyIbanOrAccountNum,
        counterpartyBicOrBlz: transactionFieldsDict.counterpartyBicOrBlz,
    },
};

export const transactionFieldsGroupTitle: Record<string, string> = {
    myBank: "My Bank",
    transactionInfo: "Main Transaction info",
    additionalTransactionInfo: "Additional Transaction info",
    counterparty: "Counterparty",
};

export const autoMappingFields: Record<string, Partial<keyof typeof transactionFieldsDict>> = {
    buchungstag: "bookingDate",
    betrag: "amount",
    waehrung: "currencyCode",
    währung: "currencyCode",
};

export const excludedFields: Record<string, string> = {
    valutadatum: "valueDate",
    wertstellung: "valueDate",
    wert: "valueDate",
};

export const requiredFields: readonly Partial<keyof typeof transactionFieldsDict>[] = Object.freeze([
    "bookingDate",
    "amount",
]);
