import React, { type Dispatch, FC, PropsWithChildren, SetStateAction, useEffect, useState } from "react";

interface TValue {
    isGroupEditor: boolean;
    setIsGroupEditor: Dispatch<SetStateAction<boolean>>;
    checkboxCarousel: string[];
    setCheckboxCarousel: Dispatch<SetStateAction<string[]>>;
}

/**
 Context for Document Form
 */
export const FormWrapperContext = React.createContext({} as TValue);

/**
 Context Provider for Document Form
 */

export const FormWrapperProvider: FC<PropsWithChildren> = ({ children }) => {
    const [isGroupEditor, setIsGroupEditor] = useState(false);
    const [checkboxCarousel, setCheckboxCarousel] = useState<string[]>([]);

    useEffect(() => {
        setIsGroupEditor(checkboxCarousel.length > 1);
    }, [checkboxCarousel]);

    const value = {
        isGroupEditor,
        setIsGroupEditor,
        checkboxCarousel,
        setCheckboxCarousel,
    };

    return <FormWrapperContext.Provider value={value}>{children}</FormWrapperContext.Provider>;
};
