import React, { useState } from "react";
import { Alert, Button, Checkbox, Form, Input, message, Row } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { Link, useNavigate } from "react-router-dom";
import { MailOutlined } from "@ant-design/icons";
import { Rule } from "antd/es/form";
import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";

import { AppRoutes } from "scripts/routing/routeConstants";
import { AuthLayout } from "./AuthLayout";
import { LogoHeader } from "./LogoHeader";
import { auth } from "scripts/api/firebase/firebase";
import { getFirebaseErrorMessageByError } from "scripts/api/firebase/firebaseErrorMessages";
import { logger } from "scripts/infrastructure/logger";
import "./styles.css";

interface RegirsterForm {
    email: string;
    password: string;
    password_repeat: string;
}

export const RegisterView = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm<RegirsterForm>();
    const { formatMessage } = useIntl();
    const [loading, setLoading] = useState<boolean>(false);

    const [error, setError] = useState<string | undefined>();

    const handleSubmit = async (values: RegirsterForm) => {
        setLoading(true);

        try {
            await createUserWithEmailAndPassword(auth, values.email, values.password);
            await sendEmailVerification(auth.currentUser);
            message.info(formatMessage({ id: "app.registration.message.send_verification_email" }));
            navigate(AppRoutes.home);
        } catch (err) {
            logger.error({ err });
            setError(getFirebaseErrorMessageByError(err));
            setLoading(false);
        }
    };

    const handlePasswordRepeat = (rule: Rule, value: string) => {
        if (value && value !== form.getFieldValue("password")) {
            return Promise.reject(formatMessage({ id: "app.validation.error.passwords_not_match" }));
        }
        return Promise.resolve();
    };

    return (
        <AuthLayout>
            <LogoHeader />
            {error && <Alert type="error" message={<FormattedMessage id={error} />} />}

            <Form layout="vertical" onFinish={handleSubmit} form={form}>
                <Form.Item
                    name="email"
                    rules={[
                        { required: true, message: formatMessage({ id: "app.validation.error.field_empty" }) },
                        { type: "email", message: formatMessage({ id: "app.validation.error.invalid_email" }) },
                    ]}
                >
                    <Input
                        size="large"
                        type="email"
                        suffix={<MailOutlined />}
                        placeholder={formatMessage({ id: "app.global.email" })}
                        autoComplete="username"
                    />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[
                        { required: true, message: formatMessage({ id: "app.validation.error.field_empty" }) },
                        {
                            min: 6,
                            message: formatMessage(
                                { id: "app.validation.error.short_password" },
                                {
                                    length: 6,
                                }
                            ),
                        },
                    ]}
                >
                    <Input.Password
                        size="large"
                        placeholder={formatMessage({ id: "app.global.password" })}
                        autoComplete="new-password"
                    />
                </Form.Item>
                <Form.Item
                    name="password_repeat"
                    rules={[
                        { required: true, message: formatMessage({ id: "app.validation.error.field_empty" }) },
                        { validator: handlePasswordRepeat },
                    ]}
                >
                    <Input.Password
                        size="large"
                        placeholder={formatMessage({ id: "app.global.password_repeat" })}
                        autoComplete="new-password"
                    />
                </Form.Item>
                <Form.Item
                    name="agb"
                    rules={[
                        {
                            validator: (rule, value) => {
                                if (!value) {
                                    return Promise.reject(formatMessage({ id: "app.validation.error.field_empty" }));
                                }
                                return Promise.resolve();
                            },
                        },
                    ]}
                    valuePropName="checked"
                >
                    <Checkbox>
                        <FormattedMessage
                            id="app.registration.agb.agree"
                            values={{
                                agbLink: <Link to={AppRoutes.agb}>AGB</Link>,
                                datenschutzLink: <Link to={AppRoutes.datenschutz}>Datenschutzbestimmungen</Link>,
                            }}
                        />
                    </Checkbox>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" disabled={loading} loading={loading} block size="large">
                        <FormattedMessage id="app.button.register" tagName="span" />
                    </Button>
                </Form.Item>
            </Form>
            <Row>
                <FormattedMessage id="app.global.already_have_account" />
                &nbsp;&nbsp;
                <Link to={AppRoutes.authLogin}>
                    <FormattedMessage id="app.global.login_now" />
                </Link>
            </Row>
        </AuthLayout>
    );
};
