import React, { FC, useContext, useEffect, useState } from "react";
import { BankModal } from "banks/components/BankModal";
import { BanksAppContext, BanksAppControlContext, ParseControlContext } from "@banks/scripts/context";
import { ParseContext } from "@banks/scripts/context/ParseContext";
import { BankDataUploader } from "@banks/components";
import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../../scripts/routing/routeConstants";

export const CsvUploader: FC = () => {
    const [hasData, setHasData] = useState<boolean>(false);

    const { isUploadModalOpen, bankToUpload, lastBankCreateId } = useContext(BanksAppContext);
    const { closeTransactionUploader } = useContext(BanksAppControlContext);

    const { parseResult } = useContext(ParseContext);
    const { setParseResult } = useContext(ParseControlContext);

    const navigate = useNavigate();

    const handleCancel = () => {
        closeTransactionUploader();
        setHasData(false);
    };

    const handleMoveToPreview = () => {
        closeTransactionUploader();
        navigate(AppRoutes.banks + "/preview-parse-transaction-data", {
            state: { parseResult, bankToUpload, lastBankCreateId },
        });
    };

    useEffect(() => {
        if (!parseResult.length) {
            return setHasData(false);
        }

        setHasData(true);
    }, [parseResult]);

    return (
        <BankModal
            title={"Upload Bank data file"}
            open={isUploadModalOpen}
            footer={false}
            style={{ maxWidth: "95vw" }}
            onCancel={handleCancel}
            width={"1000px"}
            destroyOnClose
        >
            <div
                style={{
                    marginTop: 20,
                    height: "500px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                {!hasData ? (
                    <BankDataUploader setParseResult={setParseResult} />
                ) : (
                    <Result
                        status="success"
                        title="Bank transactions have been successfully recognized"
                        subTitle={bankToUpload?.bankName}
                        extra={[
                            <Button type="primary" key={"move-to-preview"} onClick={handleMoveToPreview}>
                                Go to Transaction Data
                            </Button>,
                        ]}
                    />
                )}
            </div>
        </BankModal>
    );
};
