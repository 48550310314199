import React, { FC, useContext, useMemo } from "react";
import { Flex } from "antd";
import { TransactionsTable } from "@banks/components";
import { BanksAppContext } from "@banks/scripts/context";
import { BanksContext } from "scripts/context/BanksContext";
import { BankTransactionTableColumns } from "@banks/modules/BankTransactionsModule/bankTransactionTableColumns";
import { gridOptions } from "@banks/modules/BankTransactionsModule/gridOptions";
import { useParams } from "react-router-dom";
import { BankTransactionsToolbar } from "@banks/modules/BankTransactionsModule/components/BankTransactionsToolbar";
import dayjs, { extend as dayjsExtend } from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

const TRANSACTION_TABLE_CONTAINER_HEIGHT = "calc(100vh - 140px)";

export const BankTransactionsModule: FC = () => {
    const { banksData } = useContext(BanksContext);
    const { activeBankId } = useContext(BanksAppContext);

    const { id } = useParams();

    const { transactionBank, bankTransactions } = useMemo(() => {
        const bankId = activeBankId ?? id;
        const bankData = banksData[bankId];
        const transactionsObj = bankData?.transactions || {};

        dayjsExtend(isSameOrAfter);
        const transactionsArr = Object.values(transactionsObj).sort((a, b) => {
            return dayjs(a.bookingDate, "DD.MM.YYYY").unix() - dayjs(b.bookingDate, "DD.MM.YYYY").unix();
        });

        return { bankTransactions: transactionsArr, transactionBank: bankData };
    }, [activeBankId, banksData, id]);

    const reducedBankTransactions = useMemo(() => {
        let balanceCount = transactionBank.openingBalance ?? 0;
        return bankTransactions.map(tr => {
            const dynamicBalance = balanceCount + tr.amount;
            balanceCount = dynamicBalance;
            return {
                ...tr,
                dynamicBalance,
            };
        });
    }, [bankTransactions, transactionBank]);

    return (
        <Flex vertical gap={10}>
            <BankTransactionsToolbar transactionBank={transactionBank} />
            <TransactionsTable
                rowData={(reducedBankTransactions as unknown as Record<string, string>[]) || []}
                gridOptions={gridOptions}
                tableColumns={BankTransactionTableColumns.tableColumnConfig}
                viewHeight={TRANSACTION_TABLE_CONTAINER_HEIGHT}
            />
        </Flex>
    );
};
