import React, { FC } from "react";
import { Divider, Typography } from "antd";
import { FormattedMessage } from "react-intl";

interface IProps {
    titleId: string;
}

export const PageHeader: FC<IProps> = ({ titleId }) => {
    return (
        <Divider orientation={"left"}>
            <Typography.Title level={2}>
                <FormattedMessage id={titleId} />
            </Typography.Title>
        </Divider>
    );
};
