import React, { createContext, FC, ReactNode } from "react";

// type TValue = {};
//
// type TActionValue = {};

const initialValue = {};

const initialActionValue = {};

export const InvoicesListContext = createContext(initialValue);
export const InvoicesListControlContext = createContext(initialActionValue);

type TProps = {
    children?: ReactNode;
};

export const InvoicesListContextProvider: FC<TProps> = ({ children }) => {
    const value = {};
    const actions = {};

    return (
        <InvoicesListContext.Provider value={value}>
            <InvoicesListControlContext.Provider value={actions}>{children}</InvoicesListControlContext.Provider>
        </InvoicesListContext.Provider>
    );
};
