import { DmsType, IDocumentType, IInitColumnsType, TFilterConfigItem, TTableCols } from "@dms/types";
import { DmsTypeOptions } from "@dms/configs/constants";
import { DmsTableCols } from "@dms/modules/DocumentTableModule/consts";

export class AppConfigUtils {
    private static initColumns: Readonly<TTableCols> = {
        [DmsTableCols.DRAG]: true,
        [DmsTableCols.ROW_NUMBER]: true,
        [DmsTableCols.IS_ATTACHED]: true,
        [DmsTableCols.DOCUMENT_DATE]: true,
        [DmsTableCols.PARTNER]: true,
        [DmsTableCols.DOCUMENT_NUMBER]: false,
        [DmsTableCols.INTERNE_NUMBER]: false,
        [DmsTableCols.DOCUMENT_AMOUNT]: false,
        [DmsTableCols.DOCUMENT_AMOUNT_TYPE]: false,
        [DmsTableCols.DOCUMENT_ORIGINAL_AMOUNT]: false,
        [DmsTableCols.CURRENCY_RATE]: false,
        [DmsTableCols.CURRENCY]: false,
        [DmsTableCols.DESCRIPTION]: false,
        [DmsTableCols.FILE_NAME]: false,
        [DmsTableCols.NUM_PAGES]: false,
        [DmsTableCols.CREATED_AT]: false,
        [DmsTableCols.CREATED_BY]: false,
        [DmsTableCols.VAT_NB]: false,
        [DmsTableCols.DEADLINE_DAYS]: false,
        [DmsTableCols.DISCOUNT_AMOUNT]: false,
        [DmsTableCols.DISCOUNT_DATE]: false,
        [DmsTableCols.DISCOUNT_DAYS]: false,
        [DmsTableCols.DISCOUNT_PERCENT]: false,
        [DmsTableCols.DUE_DATE]: false,
        [DmsTableCols.NOTES]: false,
        [DmsTableCols.PAYMENT_TYPE]: false,
        [DmsTableCols.TYPE]: false,
        [DmsTableCols.SUB_TYPE]: false,
        [DmsTableCols.UPDATED_AT]: false,
        [DmsTableCols.ACTIONS]: true,
    };

    public static getInitColumnsArray(type: keyof IInitColumnsType): Partial<Record<keyof TTableCols, boolean>>[] {
        const config = this.getInitColumns(type);
        return Object.entries(config).map(val => {
            const key = val[0] as keyof TTableCols;
            return {
                [key]: val[1],
            };
        });
    }

    public static getInitColumns(type: keyof IInitColumnsType): TTableCols {
        const customConfigs: Record<string, Partial<TTableCols>> = {
            all_documents: {
                [DmsTableCols.TYPE]: true,
                [DmsTableCols.DOCUMENT_NUMBER]: true,
                [DmsTableCols.DOCUMENT_AMOUNT]: true,
                [DmsTableCols.DOCUMENT_ORIGINAL_AMOUNT]: true,
                [DmsTableCols.CURRENCY]: true,
                [DmsTableCols.DOCUMENT_AMOUNT_TYPE]: true,
                [DmsTableCols.DESCRIPTION]: true,
                [DmsTableCols.FILE_NAME]: true,
                [DmsTableCols.SUB_TYPE]: true,
                [DmsTableCols.CREATED_AT]: true,
            },
            [DmsType.new_documents]: {
                [DmsTableCols.FILE_NAME]: true,
                [DmsTableCols.CREATED_AT]: true,
                [DmsTableCols.CREATED_BY]: true,
            },
            [DmsType.uncategorized]: {
                [DmsTableCols.DOCUMENT_NUMBER]: true,
                [DmsTableCols.DOCUMENT_AMOUNT]: true,
                [DmsTableCols.DOCUMENT_ORIGINAL_AMOUNT]: true,
                [DmsTableCols.DOCUMENT_AMOUNT_TYPE]: true,
                [DmsTableCols.CURRENCY]: true,
                [DmsTableCols.DESCRIPTION]: true,
                [DmsTableCols.FILE_NAME]: true,
                [DmsTableCols.CREATED_AT]: true,
            },
        };

        return { ...this.initColumns, ...(customConfigs[type] || {}) };
    }

    static getDefaultSubTypeItemKey = (typeKey: string, defaultSubTypeKey: string) => {
        return `${typeKey}-${defaultSubTypeKey}`;
    };

    private static defaultConfig: TFilterConfigItem = {
        modalModeConfig: {
            sortState: null,
            filterState: null,
        },
        year: "all",
        month: -1,
        isDraftSelected: false,
        sortState: null,
        filterState: null,
        paginationState: {
            pageSize: 50,
            currentPage: 0,
        },
    };
    static getDefaultUserConfig = (
        documentTypes: IDocumentType[]
    ): {
        defaultFilterConfig: Map<string, TFilterConfigItem>;
        defaultColumnsTableConfig: Map<string, Record<keyof TTableCols, boolean>[]>;
    } => {
        const defaultFilterConfig = new Map();
        const defaultColumnsTableConfig = new Map();

        documentTypes.forEach(type => {
            const { id: typeId, subTypes } = type;

            if (subTypes && subTypes.length) {
                subTypes.forEach(subType => {
                    const { id: subTypeId } = subType;
                    defaultFilterConfig.set([subTypeId, typeId].toString(), this.defaultConfig);
                    defaultColumnsTableConfig.set([subTypeId, typeId].toString(), this.getInitColumnsArray(typeId));
                });
            } else {
                defaultFilterConfig.set(typeId, this.defaultConfig);
                defaultColumnsTableConfig.set(typeId, this.getInitColumnsArray(typeId));
            }
        });

        defaultFilterConfig.set("all_documents", this.defaultConfig);
        defaultColumnsTableConfig.set("all_documents", this.getInitColumnsArray("all_documents"));

        DmsTypeOptions.forEach(el => {
            if (el.defaultSubtypes) {
                el.defaultSubtypes.forEach(defaultSubtype => {
                    if (el.value && defaultSubtype.value) {
                        defaultFilterConfig.set(
                            [this.getDefaultSubTypeItemKey(el.value, defaultSubtype.value), el.value].toString(),
                            this.defaultConfig
                        );
                        defaultColumnsTableConfig.set(
                            [this.getDefaultSubTypeItemKey(el.value, defaultSubtype.value), el.value].toString(),
                            this.getInitColumnsArray(el.value)
                        );
                    }
                });
            }
        });
        return { defaultFilterConfig, defaultColumnsTableConfig };
    };

    static getDefaultConfig = (type: keyof IInitColumnsType) => {
        const tableCols = this.getInitColumns(type);
        const tableColsArray = this.getInitColumnsArray(type);

        return {
            defaultFilterConfig: this.defaultConfig,
            defaultColumnsConfig: tableCols,
            defaultColumnsTableConfig: tableColsArray as Record<keyof TTableCols, boolean>[],
        };
    };

    static concatColumnConfig = (
        documentTypes: IDocumentType[],
        dbColumnConfig?: Record<string, TTableCols[]>
    ): Map<string, TTableCols[]> => {
        const { defaultColumnsTableConfig } = this.getDefaultUserConfig(documentTypes);

        if (Object.keys(dbColumnConfig ?? {}).length === 0) {
            return defaultColumnsTableConfig;
        }

        const concatConfig: Record<string, TTableCols[]> = {};

        Object.entries(dbColumnConfig).forEach(([key, configArr]) => {
            const resultConfigArr = [...configArr];

            (defaultColumnsTableConfig.get(key) || []).forEach((el: TTableCols, i: number) => {
                const defaultConfigKey = Object.keys(el)[0];
                const index = configArr.findIndex((config: TTableCols) => Object.keys(config)[0] === defaultConfigKey);

                if (index === -1) {
                    resultConfigArr[i] = el;
                }
            });
            concatConfig[key] = resultConfigArr;
        });

        return new Map([...defaultColumnsTableConfig, ...Object.entries(concatConfig)]);
    };
}
