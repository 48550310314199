import React, { FC } from "react";
import { CreateInvoicingForm } from "@inv/components/CreateInvoicingForm";
import { InvoiceFormContextProvider } from "@inv/modules/CreateInvoicingModule/context/InvoiceFormContext";

export const CreateInvoicingModule: FC = () => {
    return (
        <InvoiceFormContextProvider>
            <CreateInvoicingForm />
        </InvoiceFormContextProvider>
    );
};
