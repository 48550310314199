const auth = {
    "app.registration.caption": "Registrierung",
    "app.registration.description": "Bitte füllen Sie die Daten zum Anlegen eines Zugangskontos aus",
    "app.registration.firm": "Firma / Name",
    "app.registration.agb": "AGB",
    "app.registration.agb.agree": "Ja, ich stimme den {agbLink} und den {datenschutzLink} zu.",
    "app.registration.message.send_verification_email": "Bestätigungs-E-Mail verschickt",
    "app.registration.almost_done": "Fast fertig",
    "app.registration.almost_done_description":
        "Vielen Dank für Ihre Registrierung bei Binale! Bitte bestätigen Sie noch Ihre E-Mail-Adresse. Gehen Sie dazu auf den Link, den wir Ihnen auf Ihre E-Mail-Adresse gesendet haben. ",
    "app.registration.send_again": "Erneut senden",
    "app.registration.resend_again_in": "Sie können die E-Mail erneut senden in",

    "app.login.description": "Willkommen bei Binale",
    "app.login.error.fail": "E-Mail-Adresse oder Passwort ist nicht gültig.",
    "app.login.loose_2fa_app": "Authentifizierungs-App ist nicht verfügbar",
    "app.login.loose_2fa_app_instructions":
        "Wenn Ihre Authentifizierungs-App nicht mehr verfügbar, kontaktieren Sie uns bitte unter {email}",

    "app.reset_password.caption": "Passwort vergessen?",
    "app.reset_password.description":
        "Bitte geben Sie Ihre E-Mail-Adresse an und klicken Sie auf 'Abschicken', um ein neues Passwort zu erhalten",
    "app.reset_password.confirmation":
        "Wir haben soeben einen Link für die Erstellung eines neuen Passowrts an Ihre E-Mail-Adresse gesendet. Bitte überprüfen Sie Ihren Postfach.",

    "app.new_password.caption": "Neues Passwort",
    "app.new_password.description": "Geben Sie ein neues Passwort an.",
    "app.new_password.confirmation": "Neues Passwort wird gesichert.",

    "app.2fa.button.enable2fa": "2FA aktivieren",
    "app.2fa.button.showPrivateKey": "Privaten Schlüssel anzeigen",
    "app.2fa.button.disable2fa": "2FA deaktivieren",
    "app.2fa.input_label": "Gib den 6-stelligen Authentifizierungscode ein.",
    "app.2fa.step.title": "Authenticator aktivieren",
    "app.2fa.confirm_delete": "Sind Sie sicher, dass Sie die 2FA deaktivieren möchten?",
    "app.2fa.description":
        "Mit <b>Zwei-Faktor-Authentifizierung</b> fügen Sie Ihrem Benutzerkonto eine zusätzliche Sicherheitsebene hinzu. Beim Einloggen verwenden Sie zusätzlich zu Ihrem Passwort einen einmaligen Verifizierungs-Zufallscode, der mit einer Authentifizierungs-App generiert wird.",
    "app.2fa.enabled2fa_description":
        "Die <b>Zwei-Faktor-Authentifizierung</b> wurde für Ihr Benutzerkonto erfolgreich aktiviert!",

    "app.2fa.step.download.title": "App herunterladen",
    "app.2fa.step.download.description":
        "Laden Sie eine der unten aufgeführten Authentifizierungs-Apps herunter und installieren Sie sie auf Ihrem Smartphone",

    "app.2fa.step.scan.title": "QR-Code scannen",
    "app.2fa.step.scan.description": "Scanne diesen QR-Code mit der Authenticator App.",
    "app.2fa.step.scan.manual_enter":
        "Wenn du den QR-Code nicht scannen kannst, gib diesen Code bitte manuell in die App ein.",

    "app.2fa.step.backup.title": "Backup-Schlüssel",
    "app.2fa.step.backup.description":
        "Bitte speichere diesen Schlüssel auf Papier. Dieser Schlüssel ermöglicht es dir, deinen Authenticator App im Falle eines Telefonverlustes wiederherzustellen.",

    "app.2fa.step.enable.title": "Authenticator aktiviert",
    "app.2fa.token_error": "Falscher 2FA-Verifizierungscode",
};

const errors = {
    // firebase errors
    "app.firebase.error.auth_requires_recent_login":
        "Um das Kennwort zu ändern, müssen Sie sich erneut beim System anmelden.",
    "app.firebase.error.auth_user_not_found":
        "Es gibt keinen Benutzerdatensatz, der dieser Kennung entspricht. Der Benutzer wurde möglicherweise gelöscht.",
    "app.firebase.error.auth_too_many_requests": "Zu viele Anfragen",
    "app.firebase.error.duplicate_email": "E-Mail wird bereits verwendet.",
    "app.firebase.error.auth_weak_password": "Passwort ist schwach",
    "app.firebase.error.auth_invalid_email": "Ungültige E-Mail",
    "app.firebase.error.auth_invalid_token": "Token is invalid",

    // error pages
    "app.error_page.title.something_went_wrong": "Etwas scheint nicht gut gelaufen zu sein... ",
    "app.error_page.we_already_fix_it": "Wir kümmern uns schnellstmöglich darum. Bitte aktualisieren Sie die Seite.",
    "app.error_page.button.reload_page": "Neu laden",
    "app.error_page.title.problem_with_db": "Es gibt scheinbar aktuell ein Problem mit Verbindung zur Datenbank. ",
    "app.error_page.fix_problems_with_db.1":
        "Bitte überprüfen Sie zunächst, dass Sie sich nicht in einem sogenannten “Privaten-Fenster“ Ihres Browsers befinden. In diesem Fall können die Daten nicht geladen werden.",
    "app.error_page.fix_problems_with_db.2": "Bitte laden Sie die Seite noch einmal neu.",
    "app.error_page.fix_problems_with_db.3":
        "Wenn das Problem weiterhin besteht oder wiederholt auftritt, schreiben Sie uns bitte kurz über {email} an. Wir kümmern uns darum.",
    "app.error_page.not_found": "Diese Seite existiert nicht.",
    "app.error_page.button.back_home": "Startseite",

    // validation
    "app.validation.error.field_empty": "Das Feld darf nicht leer sein",
    "app.validation.error.short_password": "Das Passwort muss mindestens {length} stellig sein",
    "app.validation.error.passwords_not_match": "Passwörter stimmen nicht überein",
    "app.validation.error.invalid_email": "E-mail is not valid",
    "app.validation.error.strictLength": "{length} Zeichen",

    "app.error.message.incomplete_data": "Die Daten sind unvollständig. Bitte überprüfen Sie die Einträge",
    "app.error.message.bank_not_available": "Die Bank ist im ausgewählten Jahr nicht vorhanden",
    "app.error.message.kb_not_available": "Die Kasse ist im ausgewählten Jahr nicht vorhanden",
    "app.error.message.some_incorrect": "Einige Einträge sind fehlerhaft ",
};

export const messagesDe = {
    ...auth,
    ...errors,

    "app.header.workplace": "Arbeitsplatz",
    "app.header.logout": "Abmelden",
    "app.header.login": "Anmelden",
    "app.settings.button.columns.text": "Spalten",
    "app.settings.button.columns.title": "Spalten auswählen",
    "app.companies.view.table.admin": "Hauptnutzer",
    "app.companies.view.table.user": "Benutzer",
    "app.companies.detail_request.select_skr": "Bitte wählen Sie einen Sachkontenrahmen für Ihr Unternehmen",
    "app.companies.detail_request.select_kb": "Bitte erstellen oder wählen Sie eine Kasse",
    "app.companies.detail_request.select_bank": "Bitte erstellen oder wählen Sie eine Bank",
    "app.companies.permission": "Genehmigung",
    "app.companies.permission.no": "Keine",
    "app.companies.permission.read": "Audit (Lesen)",
    "app.companies.permission.write": "Teilnehmer (Schreiben)",
    "app.companies.permission.admin": "Admin",
    "app.companies.permission.admin-read": "Admin Read",
    "app.profile.select.title": "Sprache",
    "app.profile.button.change_password": "Passwort ändern",
    "app.profile.button.re_login": "Anmelden",
    "app.profile.email_verification.success_message": "Die E-Mail wurde erfolgreich bestätigt.",
    "app.profile.email_verification.fail_message": "E-Mail-Bestätigungsfehler",
    "app.messages.no_elements_found": "Keine Elemente gefunden",
    "app.message.no_document_found": "Keine Dokumente gefunden",
    "app.items.create_tag": "Kostenstelle erstellen",
    "app.items.create_creditor": "Kreditor erstellen",
    "app.items.create_debitor": "Debitor erstellen",
    "app.items.create_category": "Konto/Kategorie erstellen",
    "app.items.create_article": "Artikel erstellen",

    "app.titles.ER": "Eingangsrechnungen",
    "app.titles.ER_A": "Anzahlungen",
    "app.titles.Deb": "Ausgangsrechnungen",
    "app.titles.FE": "Freie Erfassung",
    "app.titles.POS": "Kassenabrechnung",
    "app.titles.KB": "Kasse",
    "app.titles.Bank": "Bank",
    "app.titles.Bank.pl": "Banken",
    "app.titles.Common": "Gemeinsame",
    "app.titles.KB.pl": "Kassen",
    "app.titles.ER.erfassung": "Erfassung",
    "app.titles.ER.rechnungen": "Rechnungen",
    "app.titles.ER.auswertung": "Auswertung",
    "app.titles.filtern": "Filtern und Suchen",
    "app.titles.ER.festschreibung": "Festschreibung",
    "app.titles.ER.za": "Zahlungsavis",
    "app.titles.Lager": "Lager",
    "app.titles.LA": "Entgeltabrechnungen",
    "app.titles.AVM": "Anlagevermögen",
    "app.titles.OCR": "PDF-Tool",
    "app.titles.KA": "Kontenauskunft",
    "app.titles.Contacts": "Kontakte",
    "app.titles.DMS": "Unterlagen",
    "app.titles.DMS_AI": "Unterlagen: Binale KI",
    "app.titles.Banks": "Banken",
    "app.titles.Invoices": "Fakturierung",
    "app.titles.payments": "Zahlungen",
    "app.titles.templates": "Vorlagen",
    "app.titles.data_export": "Datenexport",
    "app.titles.data_import": "Datenimport",
    "app.titles.SuSa": "Summen und Salden",
    "app.titles.Vat": "UStVA",
    "app.titles.settings": "Einstellungen",
    "app.titles.settings.manage": "Verwalten",
    "app.titles.settings.formSettings": "Einstellungen Erfassungsform",
    "app.titles.settings.optionalFields": "Optionale Felder",
    "app.titles.security": "Sicherheit",
    "app.titles.company": "Gesellschaft",
    "app.titles.company.pl": "Gesellschaften",
    "app.titles.category": "Sachkonto",
    "app.titles.category.pl": "Sachkonten",
    "app.titles.creditor": "Kreditor",
    "app.titles.creditor.pl": "Kreditoren",
    "app.titles.debitor": "Debitor",
    "app.titles.debitor.pl": "Debitoren",
    "app.titles.tag": "Kostenstelle",
    "app.titles.tag.pl": "Kostenstellen",
    "app.titles.article": "Artikel",
    "app.titles.article.pl": "Artikeln",
    "app.titles.profile": "Profile",
    "app.titles.kontenverwaltung": "Kontenverwaltung",
    "app.titles.import_export": "Import/Export",
    "app.titles.accounting": "Buchhaltung",
    "app.titles.contactPerson": "Ansprechpartner",
    "app.titles.legalInformation": "Rechtliche Informationen",
    "app.titles.termsOfPayment": "Zahlungsbedingungen",
    "app.titles.communication": "Kommunikation",
    "app.titles.contacts.client": "Kunde",
    "app.titles.contacts.partner": "Lieferant",
    "app.titles.contacts.other": "Sonstiges (Freie Erfassung)",
    "app.titles.contacts.employee": "Mitarbeiter",
    "app.titles.contacts.shareholder": "Gesellschafter",
    "app.titles.contacts.bankDetails": "Bankverbindung",
    "app.titles.contacts.classification": "Zuordnung",
    "app.titles.bulkEdit": "Multi-Änderung",
    "app.titles.reconciliation": "Verknüpfung",
    "app.titles.copy_data_from": "Neue Konten kopieren aus",
    "app.titles.bind_to_bank": "Bank verknüpfen",
    "app.titles.bind_to_kb": "Kasse verknüpfen",
    "app.titles.unbind": "Verknüpfung löschen",
    "app.titles.create_kb": "Kasse hinzufügen",
    "app.titles.update_kb": "Kasse bearbeiten",
    "app.titles.create_bank": "Bank hinzufügen",
    "app.titles.update_bank": "Bank bearbeiten",

    "app.button.login": "Anmelden",
    "app.button.register": "Registrieren",
    "app.button.send_to_dms": "an DMS",
    "app.button.save": "Speichern",
    "app.button.create": "Erstellen",
    "app.button.add": "Anlegen",
    "app.button.cancel": "Abbrechen",
    "app.button.confAndClose": "Bestätigen und Schließen",
    "app.button.close": "Schließen",
    "app.button.finish": "Beenden",
    "app.button.confirm": "Bestätigen",
    "app.button.send": "Abschicken",
    "app.button.done": "Fertig",
    "app.button.apply": "Anwenden",
    "app.button.unselect": "Deselektieren",
    "app.button.buchen": "Buchen",
    "app.button.delete": "Löschen",
    "app.button.bind": "Verknüpfen",
    "app.button.unbind": "Verknüpfung löschen",
    "app.button.storno": "Stornieren",
    "app.button.edit": "Bearbeiten",
    "app.button.copy": "Kopieren",
    "app.button.select": "Wahlen",
    "app.button.loading": "Laden...",
    "app.button.saving": "Schpichern...",
    "app.button.color.remove_color": "Markierung löschen",
    "app.button.color.red": "Rot",
    "app.button.color.blue": "Blau",
    "app.button.color.purple": "Violett",
    "app.button.color.yellow": "Gelb",
    "app.button.color.green": "Grün",
    "app.button.download": "Herunterladen",
    "app.button.reorganise": "Reorganisieren",
    "app.button.upload": "Hochladen",
    "app.button.next": "Weiter",
    "app.button.reset": "Zurücksetzen",
    "app.button.default": "Standardeinstellungen",
    "app.button.update": "Aktualisieren",
    "app.button.openInNewTab": "In neuem Tab öffnen",

    "app.confirmation.pdf.finish": "Arbeit beenden?",
    "app.confirmation.record_remove.body": "Wollen Sie diesen Buchungssatz wirklich löschen?",
    "app.confirmation.invoice_remove.body": "Möchten Sie diese Rechnung wirklich entfernen?",
    "app.confirmation.record_cancel.body": "Wollen Sie diesen Buchungssatz wirklich stornieren?",
    "app.confirmation.festschreiben_edit.body":
        "Der Buchungssatz ist festgeschrieben und kann nicht bearbeitet werden.\n" +
        "Wollen Sie diese Buchung stornieren und eine neue anlegen?",
    "app.kb.journal.no_items_tooltip":
        "Keine Buchungssätze ausgewählt, Saldo für einige ausgewählte Buchungssätze ist negativ oder frühere Buchungssätze werden nicht festgeschriben",
    "app.confirmation.header": "Bestätigung",
    "app.confirmation.header.record_delete": "Buchungssatz löschen!",
    "app.confirmation.header.record_cancel": "Buchungssatz stornieren",
    "app.confirmation.header.confirmed_edit": "Bestätigte Buchung bearbeiten!",
    "app.confirmation.header.festschreiben_edit": "Festschriben Buchungssatz bearbeiten!",
    "app.confirmation.body": "Bitte bestätigen!",

    "app.fields.lfdnr": "Lfd.Nr.",
    "app.fields.nr": "Nr.",
    "app.fields.date": "Datum",
    "app.fields.date.month": "Monat",
    "app.fields.date.year": "Jahr",
    "app.fields.date.erfassung": "Erfassung",
    "app.fields.creditor": "Kreditor",
    "app.fields.debitor": "Debitor",
    "app.fields.creditor_view": "Kreditorenansicht",
    "app.fields.konto_view": "Kontenansicht",
    "app.fields.bezeichnung": "Bezeichnung",
    "app.fields.name": "Name",
    "app.fields.contact": "Kontakt",
    "app.fields.products": "Produkten",
    "app.fields.rechnung_num": "Rechnung Nr.",
    "app.fields.internal_num": "Interne Nr.",
    "app.fields.konto": "Konto",
    "app.fields.gkonto": "G.Konto",
    "app.fields.gkonto.full": "Gegenkonto",
    "app.fields.brutto": "Brutto",
    "app.fields.betrag": "Betrag",
    "app.fields.betragType": "Art",
    "app.fields.totalAmount": "Gesamtbetrag",
    "app.fields.partialAmount": "Teilbetrag",
    "app.fields.originalAmount": "FW-Betrag",
    "app.fields.currency.rate": "Kurs",
    "app.fields.vat%": "USt, %",
    "app.fields.vat": "USt",
    "app.fields.netto": "Netto",
    "app.fields.open": "Offen",
    "app.fields.buchtext": "Ware/Leistung",
    "app.fields.buchtext2": "Zusatz-Info",
    "app.fields.skonto": "Skonto",
    "app.fields.deadlineDays": "Fälligkeit (Tage)",
    "app.fields.dueDate": "Fälligkeit (Datum)",
    "app.fields.discountDays": "Skonto (Tage)",
    "app.fields.discountDate": "Skonto (Datum)",
    "app.fields.discountPercent": "Skonto (%)",
    "app.fields.discountAmount": "Skonto (EUR)",
    "app.fields.notes": "Notizen",
    "app.fields.verrechnung": "Verrechnung",
    "app.fields.wählen": "wählen",
    "app.fields.rabatt": "Rabatt",
    "app.fields.offner_betrag": "Offener Betrag",
    "app.fields.lastschrift": "Abbuchung/Lastschrift",
    "app.fields.draft": "Entwurf",
    "app.fields.uberweisung": "Überweisung",
    "app.fields.barzahlung": "Barzahlung",
    "app.fields.sonstiges": "Sonstiges",
    "app.fields.falligkeit": "Fälligkeit",
    "app.fields.actions": "Aktion",
    "app.fields.saldo": "Saldo",
    "app.fields.payment": "Zahlung",
    "app.fields.konto_len": "Kontenlänge",
    "app.fields.color": "Farbe",
    "app.fields.user": "Benutzer",
    "app.fields.schleppend": "Schleppend",
    "app.fields.search": "Suchen",
    "app.fields.address": "Adresse",
    "app.fields.company_name": "Firma",
    "app.fields.producer": "Produzent",
    "app.fields.description": "Beschreibung",
    "app.fields.unit": "Einheit",
    "app.fields.taxation": "Versteuerung der Umsätze",
    "app.fields.favouritePaymentType": "Hauptzahlungsmittel",
    "app.fields.datevNrConsultant": "Beraternummer",
    "app.fields.datevNrCompany": "Mandantennummer",
    "app.fields.countryCode": "Land",
    "app.fields.euroCountryCode": "EU-Land",
    "app.fields.postcode": "PLZ",
    "app.fields.city": "Ort",
    "app.fields.street": "Straße",
    "app.fields.house": "Haus Nr.",
    "app.fields.additionalAddress": "Adresszusatz",
    "app.fields.vatNb": "USt-IdNr.",
    "app.fields.taxNumber": "Steuernummer",
    "app.fields.typeOfRegister": "Registerart",
    "app.fields.registerCourt": "Registergericht",
    "app.fields.registerNumber": "Registernummer",
    "app.fields.13bUStG": "Sachverhalt § 13b UStG",
    "app.fields.paymentPurpose": "Verwendungszweck",
    "app.fields.paymentMethod": "Zahlungsart",
    "app.fields.days": "Tage",
    "app.fields.phone": "Telefon",
    "app.fields.internalDesignation": "Interne Bezeichnung",
    "app.fields.type": "Typ",
    "app.fields.subType": "Untertyp",
    "app.fields.companyName": "Unternehmensname",
    "app.fields.legalForm": "Rechtsform",
    "app.fields.salutation": "Anrede",
    "app.fields.title": "Titel",
    "app.fields.firstName": "Vorname",
    "app.fields.lastName": "Nachname",
    "app.fields.customerNumber": "Kundennummer",
    "app.fields.accountOwner": "Kontoinhaber",
    "app.fields.bankCodeNumber": "BLZ",
    "app.fields.bankAccountNumber": "Kontonummer",
    "app.fields.bankName": "Bankbezeichnung",
    "app.fields.productService": "Ware/Leistung",
    "app.fields.position": "Position",
    "app.fields.department": "Abteilung",
    "app.fields.currency": "Währung",
    "app.fields.comment": "Bemerkung",
    "app.fields.module": "Modul",
    "app.fields.attachment": "Anhang",
    "app.fields.invoice": "Rechnung",
    "app.fields.creditNote": "Gutschrift",

    "app.fields.fileUrl": "File URL",
    "app.fields.numPages": "Num Pages",
    "app.fields.rowNumber": "No",

    "app.fields.recordReview": "Kontrolle",
    "app.fields.recordReview.ok": "OK",
    "app.fields.recordReview.new": "Neu",
    "app.fields.recordReview.changed": "geändert",
    "app.fields.recordReview.question": "Frage",
    "app.fields.recordReview.error": "Fehler",

    "app.fields.ertrag": "Ertrag",
    "app.fields.aufwand": "Aufwand",
    "app.fields.einnahme": "Einnahme",
    "app.fields.ausgabe": "Ausgabe",

    "app.labels.allYear": "ganzes Jahr",
    "app.labels.paymentConditions": "Zahlungsbedingungen",

    "app.components.split.title": "Rechnungsaufteilung",
    "app.components.editing": "Bearbeitung",
    "app.components.not_selected": "Nicht ausgewählt",
    "app.components.all_selected": "Alle ausgewählt",
    "app.components.nselected": "Ausgewählt",
    "app.components.all": "Alle",
    "app.components.all_months": "Alle Monate",
    "app.components.used": "meine Liste",
    "app.components.creating": "Erstellung",
    "app.components.date.range_error": "Datum sollte in Reichweite sein",
    "app.components.table.no_items": "Keine Treffer",
    "app.components.form.attachment": "Datei anhängen",
    "app.components.form.clear": "Leeren",

    // "app.cookie":
    // "Wir verwenden Cookies für eine bestmögliche Nutzererfahrung. Durch die Nutzung unserer Internetseite erklären Sie sich damit einverstanden, dass wir Cookies auf Ihrem Gerät speichern.",
    "app.notification.update":
        "Wir haben etwas verbessert! Bitte aktualisieren Sie das Programm, um top aktuell zu bleiben.",
    "app.notification.copiedToClipboard": "Feld kopiert",

    "app.global.email": "E-Mail-Adresse",
    "app.global.password": "Passwort",
    "app.global.password_repeat": "Passwort wiederholen",
    "app.global.error": "Unbekannter Fehler.",
    "app.global.login_now": "Jetzt anmelden",
    "app.global.register_now": "Jetzt registrieren",
    "app.global.do_not_have_account": "Neuer Kunde?",
    "app.global.already_have_account": "Haben Sie bereits einen Account?",
    "app.global.not_selected": "Keine Auswahl",
    "app.global.total": "Gesamt",
    "app.global.components.payment_connection.created": "Die Eintragung im Modul {module} wurde automatisch erzeugt.",
    "app.global.components.payment_connection.updated": "Die Eintragung im Modul {module} wurde entsprechend geändert.",
    "app.global.components.payment_connection.removed": "Die Eintragung im Modul {module} wurde ebenfalls gelöscht.",
    "app.global.components.payment_connection.markedRemoved":
        "Die Eintragung im Modul {module} wurde als entfernt markiert.",
    "app.global.components.num_corrector.title": "Zuordnung auf eine neue Nummer",
    "app.global.yes": "Ja",
    "app.global.no": "Nein",
    "app.global.step": "Schritt",
    "app.global.previous_step": "Vorheriger Schritt",
    "app.global.bank": "Bank",
    "app.global.confirmRemoveInfo": "Wollen Sie diese Informationen wirklich löschen?",
    "app.global.confirmSentToTrash": "In Papierkorb verschieben",
    "app.global.confirmRemoveDocument": "Endgültig löschen?",
    "app.global.saveChanges": "Änderungen speichern?",
    "app.global.binaleNeedUpdate": "Neue Binale Version ist verfügbar. Bitte aktualisieren Sie das Programm!",

    "app.payments.verrechnungen": "Anzahlung wählen.",
    "app.paymentConnections.record": "Zahlungsdaten",
    "app.paymentConnections": "Zahlungszuordnung",
    "app.paymentConnections.helperText":
        "Bitte wählen Sie eine oder mehrere Rechnungen für die Zuordnung der Zahlung aus",
    "app.paymentConnections.deleteAssignment": "Zuordnung löschen",
    "app.paymentConnections.saveAssignment": "Zuordnung speichern",
    "app.paymentConnections.showAllOpenItems": "Alle offene Posten anzeigen",

    "app.contacts.new": "Kontakt anlegen",
    "app.contacts.type.organization": "Unternehmen",
    "app.contacts.type.individual": "natürliche Person",
    "app.contacts.type.institution": "Institution",
    "app.contacts.add": "Adresse hinzufügen",
    "app.contacts.confirmRemoveContact": "Wollen Sie diesen Kontakt wirklich löschen?",

    "app.message.progress": "Aktion im Gange...",
    "app.message.success": "Aktion erfolgreich abgeschlossen",
    "app.message.userSelectCompany": "Ausgewählte Firma wurde gewechselt",
    "app.message.companyCreateBank": "Bank wurde erstellt",
    "app.message.companyCreateKB": "Kasse wurde erstellt",
    "app.message.companyUpdateBank": "Bank wurde geändert",
    "app.message.companyUpdateKB": "Kasse wurde geändert",
    "app.message.companyDeleteBank": "Bank wurde gelöscht",
    "app.message.companyDeleteKB": "Kasse wurde gelöscht",
    "app.message.companySetProducts": "Produkten wurde geändert",
    "app.message.companyCreate": "Firma wurde erstellt",
    "app.message.companyUpdate": "Firma wurde geändert",
    "app.message.companyDelete": "Firma wurde gelöscht",
    "app.message.companySaveMember": "Benutzer wurde gespeichert",
    "app.message.companyDeleteMember": "Benutzer wurde gelöscht",
    "app.message.nothingSelected":
        "Bitte wählen Sie eine Zuordnung des Kontakts ({ relations }) im Bereich “Allgemein“.",

    "app.tooltip.manageFavouritePaymentType": "Hauptzahlungsmittel verwalten",
    "app.tooltip.noOpenItems": "keine offene Posten vorhanden",
    "app.tooltip.form.correct": "Bitte korrigieren Sie {amount} {currency}",

    "app.ocr.button.checkAll": "alle Seiten auswählen",
    "app.ocr.button.deleteEmptyPages": "leere Seiten löschen",
    "app.ocr.button.hideDeletedPages": "gelöschte Seiten ausblenden",
    "app.ocr.button.showDeletedPages": "gelöschte Seiten anzeigen",
    "app.ocr.button.deletePage": "Seite löschen",
    "app.ocr.button.restorePage": "Löschen rückgängig",
    "app.ocr.button.rotatePage": "drehen",
    "app.ocr.button.splitDocs": "trennen",
    "app.ocr.button.unifyDocs": "verbinden",
    "app.ocr.button.uncheckAll": "Auswahl aufheben",
    "app.ocr.button.addDocument": "Dokument hinzufügen",
    "app.ocr.button.selectDocument": "Dokument auswählen",

    "app.ocr.parking": "Parking",
    "app.ocr.page": "Seite",
    "app.ocr.document": "Dokument",
    "app.ocr.allPages": "Alle Seiten",
    "app.ocr.totalPages": "Seiten insgesamt",
    "app.ocr.documentsNumber": "Anzahl Dokumente",
    "app.ocr.selectedPages": "Ausgewählte Seiten",

    "app.ocr.mode.put": "Verbinden",
    "app.ocr.mode.qr": "Trennen (QR-Code)",
    "app.ocr.mode.every": "Trennen (jede Seite)",
    "app.ocr.mode.second": "Trennen (jede zweite Seite)",
    "app.ocr.mode.manual": "Manuelle Bearbeitung",

    "app.ocr.error.encrypted":
        "Das gewählte Dokument ist verschlüsselt oder geschützt und kann nicht bearbeitet werden.",
    "app.ocr.dragDocument": "ziehen Sie das gewünschte Dokument hierher",
    "app.ocr.placeDocumentHere": "Dokument hier ablegen",

    "app.titles.taxes": "Steuern",
    "app.titles.contracts": "Verträge",
    "app.titles.uncategorized": "Nicht kategorisiert",

    "app.dms.add_documents": "Dokumente hinzufügen",
    "app.titles.new_documents": "Neue Dokumente",

    "app.dms.typeOfDocuments": "Ordner",
    "app.titles.trash": "gelöschte Dokumente",
    "app.dms.add_subtype": "Element hinzufügen",

    "app.dms.list": "Listenansicht",
    "app.dms.grid": "Bilder",

    "app.dms.empty": "Leer",
    "app.dms.fileName": "Dateiname",
    "app.dms.documentNumber": "Dokumentennummer",
    "app.dms.documentDate": "Datum des Dokuments",
    "app.dms.userName": "Nutzername",
    "app.dms.fileInformation": "Dateiinformationen",
    "app.dms.createdBy": "Erstellt von",
    "app.dms.createdAt": "Erstellt am",
    "app.dms.updatedAt": "Aktualisiert am",
    "app.dms.status": "Status",
    "app.dms.closeEditor": "Editor schließen",
    "app.dms.total": "Gesamt",
    "app.dms.totalDocuments": "{count, plural, one {# Dokument} other {# Dokumente}}",

    "app.dms.selectAll": "Alles auswählen",
    "app.dms.copyId": "ID kopieren",
    "app.dms.copyUrl": "Kopieren URL",
    "app.dms.idCopied": "ID kopiert",
    "app.dms.idCopyFailed": "Kopieren ID fehlgeschlagen",
    "app.dms.urlCopied": "URL kopiert",
    "app.dms.clickOrDrug":
        "Klicken Sie, um auszuwählen, oder ziehen Sie Dokumente (max. 20 MB/Dokument) in diesen Bereich, um diese hochzuladen.",
    "app.dms.supportFor": "Mehrauswahl ist möglich",
    "app.dms.identicalFiles": "Identische Dateien. Wählen Sie eine Datei zum Hochladen aus",
    "app.dms.confirmResolving": "Bestätigen Sie die Lösung",
    "app.dms.deleteAll": "alles löschen",
    "app.dms.view": "Ansicht",
    "app.dms.moveTo": "verschieben",
    "app.dms.emptyDate": "ohne Datum",

    "app.dms.complete": "Vollständig",
    "app.dms.allDocuments": "Alle Dokumente",
    "app.dms.changeModeEnabled": "Änderungsmodus aktiviert",
    "app.dms.noticeOfDelete": "Benachrichtigung über den Löschmodus",
    "app.dms.cantDelete": "Sie können dieses Dokument nicht aus dem Papierkorb löschen",
    "app.dms.moveSelectedDocuments": "Ausgewählte dokumente in den bevorzugten typ verschieben!",
    "app.dms.pages": "{count, plural, one {# Seite} other {# Seiten}}",
    "app.dms.mainTypes": "Haupttypen",
    "app.dms.tooltipDocumentNumber": "Bitte verwenden Sie die folgenden Zeichen",
    "app.dms.letters": "Briefe",
    "app.dms.number": "Nummer",
    "app.dms.specialSymbols": "Spezielle Symbole",

    "app.dms.vat": "Umsatzsteuer",
    "app.dms.corporation_tax": "Körperschaftsteuer",
    "app.dms.business_tax": "Gewerbesteuer",
    "app.dms.statement": "Gesonderte und Einheitliche Feststellung",
    "app.dms.income_tax": "Einkommensteuer",
    "app.dms.other_taxes": "Sonstige Steuern",
    "app.dms.tax_office": "Finanzamt",
    "app.dms.more": "Mehr",
    "app.dms.isAttached": "gebucht",
    "app.dms.isn'tAttached": "nicht gebucht",
    "app.dms.showAll": "Alle anzeigen",
    "app.dms.filterActive": "filter ist aktiviert",
    "app.dms.selectBank": "wählen Bankbelege",
    "app.dms.selectCashJournal": "wählen Kasse",
    "app.dms.selectTaxes": "wählen Steuern",
    "app.dms.noInternetConnection": "Keine Internetverbindung",
    "app.dms.addDocumentId": "Dokument-ID hinzufügen",
    "app.dms.selectInDms": "im DMS auswählen",
    "app.dms.noAssignment": "kein Zuordnung",
    "app.dms.filterColor": "Farbfilter",

    "app.invoice.createInvoice": "Rechnung erstellen",
    "app.invoice.invoicesList": "Rechnungsliste",

    "app.invoice.brutto": "Brutto",
    "app.invoice.netto": "Netto",
    "app.invoice.customerDetails": "Kundenangaben",
    "app.invoice.customer": "Kunde suchen oder erfassen",
    "app.invoice.address": "Adresszusatz",
    "app.invoice.street": "Straße",
    "app.invoice.houseNumber": "Hausnummer",
    "app.invoice.zeep": "PLZ",
    "app.invoice.city": "Stadt",
    "app.invoice.countryCode": "Land",

    "app.invoice.invoiceDetails": "Rechnungsdaten",
    "app.invoice.invoiceNum": "Rechnungsnummer",
    "app.invoice.customerNum": "Kundennummer",
    "app.invoice.date": "Datum",
    "app.invoice.deliveryDate": "Lieferdatum",
    "app.invoice.serviceDate": "Leistungsdatum",
    "app.invoice.servicePeriodDays": "Leistungszeitraum(Tage)",
    "app.invoice.servicePeriodMonths": "Leistungszeitraum(Monate)",
    "app.invoice.noShowDate": "Kein Lieferdatum anzeigen",

    "app.invoice.head": "Kopfbereich",
    "app.invoice.invoiceTitle": "Belegtitel",
    "app.invoice.introductionText": "Einleitungstext",

    "app.invoice.lineItems": "Belegpositionen",
    "app.invoice.position": "Position",
    "app.invoice.productService": "Produkt / Service",
    "app.invoice.quantity": "Menge",
    "app.invoice.price": "Preis",
    "app.invoice.unit": "Einheit",
    "app.invoice.tax": "Steuer",
    "app.invoice.discount": "Rabatt",
    "app.invoice.total": "Gesamt",
    "app.invoice.newLine": "Neue Position",
    "app.invoice.subTotalNet": "Zwischensumme netto",
    "app.invoice.piece": "Stück",
    "app.invoice.hour": "Stunde",
    "app.invoice.vat": "USt",
    "app.invoice.totalAmount": "Gesamtbetrag",

    "app.invoice.foot": "Fußbereich",
    "app.invoice.paymentTerm": "Zahlungsbedingung",
    "app.invoice.description": "Beschreibung",

    "app.invoice.caching": "Zwischenspeichern",
    "app.invoice.goToPreview": "Weiter zur Vorschau",

    "app.invoiceList.invoiceDate": "Rechnung datum",
    "app.invoiceList.serviceDescription": "Leistungs bezeichnung",
    "app.invoiceList.project": "Projekt",
    "app.invoiceList.amountNetto": "Betrag Netto (EUR)",
    "app.invoiceList.amountBrutto": "Betrag Brutto (EUR)",
};

export type IMessagesType = typeof messagesDe;
