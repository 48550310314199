import React, { useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Skeleton, Space, Spin } from "antd";

import TitledPage from "./TitledPage";
import { AppRoutes } from "./routeConstants";
import { CompanyContext, YearPeriodContext } from "../context/CompanyContext";
import { ReadinessContext } from "../context/DataReadyness";
import { UserContext } from "../context/UserProvider";
import { CompanyStepper } from "../../appearance/views/auth/CompanyStepper";
import { CompanyYearFormModal } from "../../appearance/components/company/CompanyYearFormModal";
import { GQL } from "@binale-tech/shared";
import { NotConfirmed } from "../../appearance/views/auth/NotConfirmed";
import { ProductAccessUtils } from "../models/utils/ProductAccessUtils";
import { ProductKey } from "../models/Product";

type ProductRouteProps = {
    productKey: ProductKey;
    role?: GQL.IUserRole;
    skipCompanySetup?: boolean;
    disableTransition?: boolean;
    verticalTransition?: boolean;
};
const ProductRoute: React.FC<React.PropsWithChildren<ProductRouteProps>> = props => {
    const { children, productKey } = props;
    const user = React.useContext(UserContext);
    const { year } = React.useContext(YearPeriodContext);
    const navigate = useNavigate();
    const { companyGQL } = React.useContext(CompanyContext);
    const { isReady } = React.useContext(ReadinessContext);
    const hasRoleAccess = !props.role || ProductAccessUtils.hasUserRole(companyGQL, user, props.role);
    const hasAccessProductAccess = ProductAccessUtils.canRead(productKey, companyGQL, user);
    const hasAccess = hasAccessProductAccess && hasRoleAccess;

    const hasAccountingYear = companyGQL?.accountingYears?.includes(year);

    if (!isReady) {
        return <SkeletonPage />;
    }
    if (!hasAccess || !companyGQL) {
        return <Navigate to={AppRoutes.home} />;
    }

    if (!props.skipCompanySetup && !hasAccountingYear) {
        return <CompanyYearFormModal onHide={() => navigate(AppRoutes.home)} companyId={companyGQL.id} />;
    }
    return (
        <TitledPage
            productKey={productKey}
            disableTransition={props.disableTransition}
            verticalTransition={props.verticalTransition}
        >
            {children}
        </TitledPage>
    );
};

const AuthenticatedRoute: React.FC<React.PropsWithChildren> = ({ children }) => {
    const user = React.useContext(UserContext);
    const { companyGQL, isLoaded } = useContext(CompanyContext);

    if (user.isLoading) {
        return <LoaderPage />;
    }
    if (!user.isAuthenticated) {
        return <Navigate to={AppRoutes.authLogin} />;
    }
    if (!user.fireUser.emailVerified) {
        return <NotConfirmed />;
    }
    if (isLoaded && !companyGQL) {
        return <CompanyStepper />;
    }
    // if (isLoaded && !ProductAccessUtils.hasCompanyInvoiceData(companyGQL)) {
    //     return <CompanyStepper stepInvoice companyGQL={companyGQL} />;
    // }
    return <TitledPage verticalTransition>{children}</TitledPage>;
};

const AdminRoute: React.FC<React.PropsWithChildren> = ({ children }) => {
    const user = React.useContext(UserContext);

    if (user.isLoading) {
        return <LoaderPage />;
    }
    if (!user.isAuthenticated) {
        return <Navigate to={AppRoutes.authLogin} />;
    }
    if (!user.isAdmin) {
        return <NotConfirmed />;
    }

    return <TitledPage>{children}</TitledPage>;
};

const GuestOnlyRoute: React.FC<React.PropsWithChildren> = ({ children }) => {
    const user = React.useContext(UserContext);

    if (user.isLoading) {
        return <LoaderPage />;
    }
    if (user.isAuthenticated) {
        return <Navigate to="/" />;
    }
    return <TitledPage>{children}</TitledPage>;
};

const LoaderPage = () => {
    return (
        <Spin spinning style={{ paddingTop: "20rem", width: "100%" }}>
            <p>Benutzerdaten werden geladen…</p>
        </Spin>
    );
};

const SkeletonPage = () => {
    return (
        <div style={{ padding: "30px 10px 10px", display: "flex", flexDirection: "column", height: "100%" }}>
            <div style={{ flexGrow: 1 }}>
                <Space>
                    <Skeleton.Input style={{ width: 75 }} active />
                    <Skeleton.Input style={{ width: 130 }} active />
                    <Skeleton.Input style={{ width: 150 }} active />
                    <Skeleton.Input style={{ width: 75 }} active />
                    <Skeleton.Input style={{ width: 200 }} active />
                </Space>
                <div style={{ margin: "20px 0" }}>
                    <Skeleton title={false} active paragraph={{ rows: 7 }} />
                </div>
            </div>
            <Space>
                <Skeleton.Input style={{ width: 95 }} active />
                <Skeleton.Input style={{ width: 130 }} active />
                <Skeleton.Input style={{ width: 150 }} active />
                <Skeleton.Input style={{ width: 180 }} active />
                <Skeleton.Input style={{ width: 200 }} active />
            </Space>
        </div>
    );
};

class AuthWrapper {
    static readonly ProductRoute = ProductRoute;
    static readonly AuthenticatedRoute = AuthenticatedRoute;
    static readonly AdminRoute = AdminRoute;
    static readonly GuestOnlyRoute = GuestOnlyRoute;
}
export default AuthWrapper;
