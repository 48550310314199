import { useEffect, useRef, useState } from "react";
import { DatabaseReference, DataSnapshot, onChildAdded, onChildChanged, onChildRemoved } from "firebase/database";
import { map, Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

export const useCollectionListener = <T,>(
    ref: DatabaseReference,
    initializer: (v: DataSnapshot) => T,
    shouldSkipLoad?: boolean
) => {
    const subjectRef = useRef(new Subject<{ type: "set" | "delete"; snap: DataSnapshot }>());

    const [collectionMap, setCollectionMap] = useState<Map<string, T>>(new Map());

    useEffect(() => {
        if (shouldSkipLoad) {
            return;
        }
        // const t = Date.now();
        const data = new Map<string, T>();
        const subscription = subjectRef.current
            .pipe(
                map(event => {
                    if (event.type === "set") {
                        data.set(event.snap.key, initializer(event.snap));
                    }
                    if (event.type === "delete") {
                        data.delete(event.snap.key);
                    }
                    return data;
                }),
                debounceTime(100)
            )
            .subscribe(v => {
                setCollectionMap(new Map(v));
                // console.log(Date.now() - t, "subscribe", v.size, ref.toString());
            });

        return () => {
            setCollectionMap(new Map());
            subscription.unsubscribe();
        };
    }, [shouldSkipLoad, initializer]);

    useEffect(() => {
        setCollectionMap(prevState => {
            if (prevState.size === 0) {
                return prevState;
            }
            return new Map();
        });
        if (shouldSkipLoad) {
            return () => {};
        }

        const unsubAdded = onChildAdded(ref, snap => {
            subjectRef.current.next({ type: "set", snap });
        });
        const unsubChanged = onChildChanged(ref, snap => {
            subjectRef.current.next({ type: "set", snap });
        });
        const unsubRemoved = onChildRemoved(ref, snap => {
            subjectRef.current.next({ type: "delete", snap });
        });

        return () => {
            unsubAdded();
            unsubChanged();
            unsubRemoved();
        };
    }, [initializer, ref, shouldSkipLoad]);

    return collectionMap;
};
