import { ColDef } from "ag-grid-community";
import { AbstractTableColumns } from "@app/components/shared/AgGridTable/columns/AbstractTableColumns";

export class TransactionTableColumns extends AbstractTableColumns {
    static get transactionRowNumberColumn(): Readonly<ColDef> {
        return {
            ...this.rowNumberColumn,
            pinned: "left",
        };
    }

    static get bookingDate(): Readonly<ColDef> {
        return {
            headerName: "Buchungstag",
            headerTooltip: "Buchungstag",
            field: "bookingDate",
            width: 120,
            sortable: false,
            filter: false,
        };
    }

    static get transactionType(): Readonly<ColDef> {
        return {
            headerName: "Umsatzart",
            headerTooltip: "Umsatzart",
            field: "transactionType",
            width: 220,
            sortable: false,
            filter: false,
        };
    }

    static get currencyCode(): Readonly<ColDef> {
        return {
            headerName: "Waehrung",
            headerTooltip: "Waehrung",
            field: "currencyCode",
            width: 100,
            sortable: false,
            filter: false,
        };
    }

    static get bookingText(): Readonly<ColDef> {
        return {
            headerName: "Buchungstext",
            headerTooltip: "Buchungstext",
            field: "bookingText",
            width: 220,
            sortable: false,
            filter: false,
        };
    }

    static get purpose(): Readonly<ColDef> {
        return {
            headerName: "Verwendungszweck",
            headerTooltip: "Verwendungszweck",
            field: "purpose",
            width: 300,
            sortable: false,
            filter: false,
        };
    }

    static get counterpartyName(): Readonly<ColDef> {
        return {
            headerName: "Beguenstigter/Zahlungspflichtiger",
            headerTooltip: "Beguenstigter/Zahlungspflichtiger",
            field: "counterpartyName",
            width: 500,
            sortable: false,
            filter: false,
        };
    }

    static get counterpartyIbanOrAccountNum(): Readonly<ColDef> {
        return {
            headerName: "Kontonummer/IBAN",
            headerTooltip: "Kontonummer/IBAN",
            field: "counterpartyIbanOrAccountNum",
            width: 200,
            sortable: false,
            filter: false,
        };
    }

    static get counterpartyBicOrBlz(): Readonly<ColDef> {
        return {
            headerName: "Counterparty BIC/BLZ",
            headerTooltip: "Counterparty BIC/BLZ",
            field: "counterpartyBicOrBlz",
            width: 150,
            sortable: false,
            filter: false,
        };
    }
}
