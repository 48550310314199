import { Button, Dropdown } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import styles from "./actionList.module.scss";
import React, { FC, useState } from "react";
import { useDropdownAction } from "@dms/components/ActionList/useDropdownAction";
import { IDocumentEnriched, ITableDocument } from "@dms/types";
import { IRowNode } from "ag-grid-community";

type TProps = {
    document: IDocumentEnriched;
    mode: "table" | "card";
    node: IRowNode<ITableDocument>;
};

export const DropdownActions: FC<TProps> = ({ document, mode, node }) => {
    const { dropdownItems } = useDropdownAction({
        document,
        mode,
        node,
    });

    return (
        <Dropdown
            menu={{ items: dropdownItems }}
            trigger={["click"]}
            destroyPopupOnHide
            // open={open}
            // onOpenChange={setOpen}
        >
            <Button
                icon={<MoreOutlined rotate={90} style={{ padding: 0 }} />}
                className={styles.actionsItem}
                type="link"
            />
        </Dropdown>
    );
};
