import { Form, Typography } from "antd";
import React, { type FC, useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { FormWrapperContext } from "@dms/modules/DocumentFormModule/context/FormWrapperContext";
import { GroupFormContext } from "@dms/modules/GroupFormModules/context/GroupFormContext";

import { DateInput } from "../DocumentInputs/DateInput";
import { FieldLabel } from "@app/components/shared/form/baseComponents/FieldLabel";
import { FormItemCheckbox } from "../DocumentInputs/FormItemCheckbox";

const { Text } = Typography;

export const SingleDiscountDateField = () => {
    const { formatMessage } = useIntl();

    return (
        <FieldLabel
            className={"DMSForm__Item"}
            label={
                <Text strong ellipsis>
                    <FormattedMessage id="app.fields.discountDate" />
                </Text>
            }
            name={"discountDate"}
        >
            <DateInput placeholder={formatMessage({ id: "app.fields.discountDate" })} />
        </FieldLabel>
    );
};

const GroupDiscountDateField: FC = () => {
    const [isDisabled, setIsDisabled] = useState<boolean>(true);

    const { notEqualField, isCancel } = useContext(GroupFormContext);

    useEffect(() => {
        if (isCancel) {
            setIsDisabled(true);
        }
    }, [isCancel]);

    const placeholder = notEqualField.includes("discountDate") ? "Diverse" : undefined;

    return (
        <div className={"BinaleForm__Item DMSForm__Item"}>
            <div style={{ position: "relative" }}>
                <Form.Item initialValue={false} name={"discountDateCheckbox"} valuePropName={"checked"} noStyle={true}>
                    <FormItemCheckbox parentFieldName={"discountDate"} setIsDisabled={arg => setIsDisabled(arg)} />
                </Form.Item>
            </div>
            <FieldLabel
                label={
                    <Text strong ellipsis>
                        {<FormattedMessage id="app.fields.discountDate" />}
                    </Text>
                }
                className={"DMSForm__Item"}
                name={"discountDate"}
            >
                <DateInput placeholder={placeholder} disabled={isDisabled} />
            </FieldLabel>
        </div>
    );
};

export const DocumentDiscountDateField: FC = () => {
    const { isGroupEditor } = useContext(FormWrapperContext);

    return <>{isGroupEditor ? <GroupDiscountDateField /> : <SingleDiscountDateField />}</>;
};
