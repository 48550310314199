import React, { FC } from "react";
import { useIntl } from "react-intl";
import { Button, Flex, Spin } from "antd";
import { CheckCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { InvoiceFormInputTranslate } from "@inv/types";

interface IProps {
    success: boolean;
    isLoading: boolean;
    handleCancel: () => void;
}

export const ControlBlock: FC<IProps> = ({ success, isLoading, handleCancel }) => {
    const intl = useIntl();

    return (
        <Flex justify={"end"} gap={10} style={{ width: "100%", padding: 10 }}>
            {success && !isLoading && <CheckCircleOutlined style={{ fontSize: 24, color: "#52c41a" }} />}
            {isLoading && <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />}
            <Button onClick={handleCancel}>{intl.formatMessage({ id: InvoiceFormInputTranslate.CANCEL })}</Button>
            <Button>{intl.formatMessage({ id: InvoiceFormInputTranslate.CACHING })}</Button>
            <Button type="primary" htmlType="submit">
                {intl.formatMessage({ id: InvoiceFormInputTranslate.GO_PREVIEW })}
            </Button>
        </Flex>
    );
};
