import { useCallback, useContext } from "react";
import { Category, GenericRecord } from "../../../../../scripts/models";
import { ContactAccountingConverter } from "../../../../../scripts/models/converters/ContactAccountingConverter";
import { RecordFormPropsContext } from "@app/components/recordform/context/RecordFormPropsContext";
import { useFormRecordStateUpdater } from "@app/components/recordform/hooks/useFormRecordStateUpdater";
import { useFormHandlerItemTag } from "./useFormHandlerItemTag";
import { useFormHandlerItemText } from "./useFormHandlerItemText";
import { useFormHandlerItemBu } from "./useFormHandlerItemBu";
import { useFormHandlerItemAccount } from "./useFormHandlerItemAccount";
import { useFormHandlerRecordAccount } from "./useFormHandlerRecordAccount";
import { useFormHandlerRecordBF1 } from "@app/components/recordform/hooks/handlers/useFormHandlerRecordBF1";
import { useFormHandlerItemText2 } from "@app/components/recordform/hooks/handlers/useFormHandlerItemText2";
import { useFormHandlerItemBF1 } from "@app/components/recordform/hooks/handlers/useFormHandlerItemBF1";
import { useFormHandlerItemBF2 } from "@app/components/recordform/hooks/handlers/useFormHandlerItemBF2";

export const useFormHandlerRecordTemplate = () => {
    const { refsData } = useContext(RecordFormPropsContext);
    const { updateEditableRecord } = useFormRecordStateUpdater();
    const onTagChange = useFormHandlerItemTag();
    const onTextChange = useFormHandlerItemText();
    const onText2Change = useFormHandlerItemText2();
    const { onUstChange } = useFormHandlerItemBu();
    const onItemAccountChange = useFormHandlerItemAccount();
    const onRecordAccountChange = useFormHandlerRecordAccount();
    const onRecordBelegfeld1Change = useFormHandlerRecordBF1();
    const onItemBF1Change = useFormHandlerItemBF1();
    const onItemBF2Change = useFormHandlerItemBF2();

    return useCallback(
        (template: GenericRecord) => {
            if (template) {
                const formState = refsData.formStateRef.current;
                const updates = ContactAccountingConverter.applyContactTemplateToRecord(template, formState);
                if (updates.editableRecord.recordNum) {
                    onRecordBelegfeld1Change(updates.editableRecord.recordNum);
                }
                if (updates.editableRecord.recordCategoryCreditor) {
                    onRecordAccountChange(updates.editableRecord.recordCategoryCreditor);
                }
                if (updates.editableRecordItem.itemCategoryCreditor) {
                    const account = updates.editableRecordItem.itemCategoryCreditor as Category;
                    onItemAccountChange(account);
                    if (!account.isAutoBu()) {
                        if (updates.editableRecordItem.itemBu) {
                            onUstChange(updates.editableRecordItem.itemBu, updates.editableRecordItem.itemUSt13b);
                        }
                    }
                }
                if (updates.editableRecordItem.itemTag) {
                    onTagChange(updates.editableRecordItem.itemTag);
                }
                if (updates.editableRecordItem.itemText) {
                    onTextChange(updates.editableRecordItem.itemText);
                }
                if (updates.editableRecordItem.itemText2) {
                    onText2Change(updates.editableRecordItem.itemText2);
                }
                if (updates.editableRecordItem.itemBelegfeld1) {
                    onItemBF1Change(updates.editableRecordItem.itemBelegfeld1);
                }
                if (updates.editableRecordItem.itemBelegfeld2) {
                    onItemBF2Change(updates.editableRecordItem.itemBelegfeld2);
                }
            }
        },
        [
            refsData.formStateRef,
            onRecordBelegfeld1Change,
            onRecordAccountChange,
            onItemAccountChange,
            onUstChange,
            onTagChange,
            onTextChange,
            onText2Change,
            onItemBF1Change,
            onItemBF2Change,
        ]
    );
};
