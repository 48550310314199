import React, { FC } from "react";
import { InvoiceTableContextProvider } from "@inv/modules/InvoiceTableModule/context/InvoiceTableContext";
import { InvoiceTableBlock } from "@inv/components/InvoiceTableBlock";

export const InvoiceTableModule: FC = () => {
    return (
        <InvoiceTableContextProvider>
            <InvoiceTableBlock />
        </InvoiceTableContextProvider>
    );
};
