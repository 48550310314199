import React, { FC, PropsWithChildren } from "react";

import { CompanyContext } from "scripts/context/CompanyContext";
import { RecordsContext } from "scripts/context/recordsContext/RecordsCtx";
import { RecordsControlContext } from "scripts/context/recordsContext/RecordsControlCtx";
import {
    TableViewContext,
    TableViewContextProvider,
    useDefaultYearPeriodFilters,
} from "../../../../scripts/context/tableViewContext/tableViewContext";
import { RecordLogRepository } from "../../../../scripts/infrastructure/repository/grRepositories";
import { GQL } from "@binale-tech/shared";
import { ProductER } from "../../../../scripts/core/Product";

export const ERProvider: FC<PropsWithChildren> = ({ children }) => {
    const { yearConfig, companyGQL } = React.useContext(CompanyContext);
    const allRecords = React.useContext(RecordsContext);
    const allActions = React.useContext(RecordsControlContext);
    const defaultFilters = useDefaultYearPeriodFilters();
    const tableCtx = React.useMemo<React.ContextType<typeof TableViewContext>>(
        () => ({
            view: "erErfassung",
            product: new ProductER(yearConfig.skr, companyGQL),
            productKey: GQL.IProductKey.Er,
            moduleRecords: allRecords.recordsER.list || [],
            moduleActions: allActions.actionsER,
            moduleLogLister: key => RecordLogRepository.ER.list({ path: [companyGQL?.id, key] }),
        }),
        [allActions.actionsER, allRecords.recordsER, companyGQL, yearConfig]
    );
    return (
        <TableViewContextProvider tableCtx={tableCtx} defaultFilters={defaultFilters}>
            {children}
        </TableViewContextProvider>
    );
};
