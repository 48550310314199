import { Base } from "@binale-tech/shared";
import { CurrencyValue } from "@binale-tech/ui-components";
import { Form, Typography } from "antd";
import cn from "classnames";
import React, { type FC, useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { FormWrapperContext } from "@dms/modules/DocumentFormModule/context/FormWrapperContext";
import { GroupFormContext } from "@dms/modules/GroupFormModules/context/GroupFormContext";
import { SingleFormContext } from "@dms/modules/SingleFormModule/context/SingleFormContext";

import styles from "../../../components/DocumentForm/DocumentFormFields/DocumentFormField.module.scss";
import { CurrencyGroupInput } from "../DocumentInputs/CurrencyGroupInput";
import { CurrencyInput } from "../DocumentInputs/CurrencyInput";
import { FormItemCheckbox } from "../DocumentInputs/FormItemCheckbox";
import { AttachmentCheckbox } from "@dms/components/DocumentForm/DocumentInputs/AttachmentCheckbox";
import { DmsType, MovementType } from "@dms/types";
import { MovementTypeToggle } from "@dms/components/DocumentForm/DocumentInputs/MovementTypeToggle";
import { DmsUtils } from "@dms/scripts/utils/DmsUtils";
import { DmsDataContext } from "@dms/types/ContextTypes";

const { Text } = Typography;

interface IProps {
    aiResult?: boolean;
}

export const SingleInvoiceCurrencyField: FC<IProps> = ({ aiResult }) => {
    const [isAttached, setIsAttached] = useState<boolean>(false);

    const { documentTypes } = useContext(DmsDataContext);
    const { necessaryFields, changedDocumentType, movementType, setMovementType } = useContext(SingleFormContext);
    const { type } = DmsUtils.getTypeAndSubType(changedDocumentType, documentTypes);

    return (
        <div
            className={cn({
                [styles.relative]: true,
                [styles.aiResult]: aiResult,
            })}
        >
            <Form.Item
                label={
                    <Text
                        strong
                        ellipsis
                        type={
                            isAttached
                                ? "secondary"
                                : necessaryFields?.includes("documentAmount")
                                  ? "warning"
                                  : undefined
                        }
                    >
                        {<FormattedMessage id="app.fields.betrag" />}
                    </Text>
                }
                name={"currencyData"}
                style={{ marginBottom: 0 }}
            >
                <CurrencyInput
                    allowConfigEdit={!isAttached}
                    movementType={movementType}
                    documentType={type as DmsType}
                    setMovementType={(arg: MovementType) => setMovementType(arg)}
                />
            </Form.Item>
            <div className={styles.attachment}>
                <MovementTypeToggle
                    documentType={type as DmsType}
                    movementType={movementType}
                    setMovementType={arg => setMovementType(arg)}
                    disabled={isAttached || movementType === MovementType.ZERO}
                />
                <Form.Item name={"isAttachment"} valuePropName={"checked"} style={{ marginBottom: 0 }}>
                    <AttachmentCheckbox setIsAttached={setIsAttached} />
                </Form.Item>
            </div>
        </div>
    );
};

const GroupInvoiceCurrencyField: FC = () => {
    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const [isAttachDisabled, setIsAttachDisabled] = useState(false);
    const [isAttached, setIsAttached] = useState<boolean>(false);

    useEffect(() => {
        if (isAttached) {
            setIsDisabled(true);
        }
    }, [isAttached]);

    const { notEqualField, isCancel, necessaryGroupFields } = useContext(GroupFormContext);

    useEffect(() => {
        setIsAttachDisabled(notEqualField.includes("isAttachment"));
    }, [notEqualField]);

    useEffect(() => {
        if (isCancel) {
            setIsDisabled(true);
        }
    }, [isCancel]);

    const placeholders:
        | Record<keyof Partial<Omit<CurrencyValue, "currency"> & Partial<Base.CurrencyConfig>>, string | undefined>
        | undefined = {
        amount: notEqualField.includes("documentAmount") ? "Diverse" : undefined,
        originalAmount: notEqualField.includes("originalAmount") ? "Diverse" : undefined,
        code: notEqualField.includes("currency") ? "Diverse" : undefined,
        rate: notEqualField.includes("currencyRate") ? "Diverse" : undefined,
    };

    return (
        <>
            <div style={{ position: "relative" }}>
                <Form.Item initialValue={false} name={"currencyDataCheckbox"} valuePropName={"checked"} noStyle={true}>
                    <FormItemCheckbox
                        disabled={isAttached}
                        parentFieldName={"currencyData"}
                        setIsDisabled={arg => setIsDisabled(arg)}
                    />
                </Form.Item>
            </div>
            <Form.Item
                label={
                    <Text
                        strong
                        ellipsis
                        type={
                            isAttached
                                ? "secondary"
                                : necessaryGroupFields?.includes("documentAmount")
                                  ? "warning"
                                  : undefined
                        }
                    >
                        {<FormattedMessage id="app.fields.betrag" />}
                    </Text>
                }
                name={"currencyData"}
                style={{ marginBottom: 0 }}
                rules={[
                    {
                        validator: (_rule, value) => {
                            if (isDisabled) {
                                return Promise.resolve();
                            }

                            if (!value.currency.code || !value.currency.rate) {
                                return Promise.reject(new Error());
                            }
                            return Promise.resolve();
                        },
                    },
                ]}
            >
                <CurrencyGroupInput placeholders={placeholders} allowConfigEdit={!isDisabled} />
            </Form.Item>
            <Form.Item name={"isAttachment"} valuePropName={"checked"} className={styles.attachment}>
                <AttachmentCheckbox setIsAttached={setIsAttached} disabled={isAttachDisabled} />
            </Form.Item>
        </>
    );
};

export const DocumentInvoiceCurrencyField: FC<IProps> = ({ aiResult }) => {
    const { isGroupEditor } = useContext(FormWrapperContext);

    return <>{isGroupEditor ? <GroupInvoiceCurrencyField /> : <SingleInvoiceCurrencyField aiResult={aiResult} />}</>;
};
