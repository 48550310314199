import React, { FC } from "react";
import { FieldLabel } from "@app/components/shared/form/baseComponents/FieldLabel";
import { Form, Typography } from "antd";

import styles from "./FormFields.module.scss";
import { DigitInput } from "@app/components/shared/form/baseComponents/DigitInput/DigitInput";
import { isValidAccountNumberBLZ } from "ibantools-germany";

const { Text } = Typography;

type TProps = {
    isDisabled?: boolean;
};

export const AccountNumberField: FC<TProps> = ({ isDisabled }) => {
    const form = Form.useFormInstance();
    return (
        <FieldLabel
            className={styles.bankFormField}
            label={
                <Text strong ellipsis>
                    Account number
                </Text>
            }
            name={"accountNumber"}
            rules={[
                { required: true, message: "Required field" },
                {
                    validator: (rule, value, callback) => {
                        if (!value) {
                            return Promise.resolve();
                        }
                        if (isValidAccountNumberBLZ(value, form.getFieldValue("bankId"))) {
                            return Promise.resolve();
                        }

                        return Promise.reject("Account is not valid");
                    },
                },
            ]}
            required
        >
            <DigitInput disabled={isDisabled} maxLength={20} showCount></DigitInput>
        </FieldLabel>
    );
};
