import React, { FC } from "react";
import { FieldLabel } from "@app/components/shared/form/baseComponents/FieldLabel";
import { Input, Typography } from "antd";

import styles from "./FormFields.module.scss";

const { Text } = Typography;

type TProps = {
    isDisabled?: boolean;
};

export const BankIdField: FC<TProps> = ({ isDisabled }) => {
    return (
        <FieldLabel
            className={styles.bankFormField}
            label={
                <Text strong ellipsis>
                    BLZ (БИК)
                </Text>
            }
            name={"bankId"}
            rules={[{ required: true, message: "" }]}
            required
        >
            <Input disabled={isDisabled} maxLength={9} showCount></Input>
        </FieldLabel>
    );
};
