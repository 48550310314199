import { deleteObject, getDownloadURL, getMetadata, ref, uploadBytesResumable, UploadTask } from "firebase/storage";
import { logger } from "./logger";
import { storage } from "../api/firebase/firebase";

const NS = "images";

export interface AssetData {
    name: string;
    downloadUrl: string;
    file: File;
}

class DocumentsCache {
    protected static readonly cache = new Map<string, AssetData>();
    protected static cacheList: string[] = [];
    static cacheAdd = (k: string, v: AssetData) => {
        this.cache.set(k, v);
        this.cacheList.push(k);
        if (this.cacheList.length > 100) {
            const drop = this.cacheList[0];
            this.cacheList = this.cacheList.slice(1);
            this.cache.delete(drop);
        }
    };
    static has = (k: string) => this.cache.has(k);
    static get = (k: string) => this.cache.get(k);
}

export class Assets {
    static getRefs = (refs: string[]) => Promise.all(refs.map(v => ref(storage, v)));
    static getNameFromDownloadUrl = (v: string) => {
        let ext = v.split(".").pop();
        ext = ext.slice(0, ext.indexOf("?"));
        return "file." + ext;
    };
    static getFilesCached = (refs: string[]): Promise<AssetData[]> => {
        if (!refs || refs.length === 0) {
            return Promise.resolve([]);
        }
        const cached = refs.filter(v => DocumentsCache.has(v)).map(v => DocumentsCache.get(v));
        const unCached = refs.filter(v => !DocumentsCache.has(v));
        return Assets.getFiles(unCached).then(data => {
            data.forEach((v, i) => DocumentsCache.cacheAdd(unCached[i], v));
            return [...cached, ...data];
        });
    };
    static getFiles = (refsStr: string[]): Promise<AssetData[]> =>
        Assets.getRefs(refsStr).then(async refs => {
            if (refs.length === 0) {
                return [];
            }
            const meta = await Promise.all(refs.map(v => getMetadata(v)));
            const download = await Promise.all(refs.map(v => getDownloadURL(v)));
            const blobs: Blob[] = await Promise.all(
                download.map(url => {
                    return new Promise<Blob>((resolve, reject) => {
                        const xhr = new XMLHttpRequest();
                        xhr.responseType = "blob";
                        xhr.onload = function () {
                            const blob: Blob = xhr.response;
                            resolve(blob);
                        };
                        xhr.onerror = function () {
                            reject(new Error("error"));
                        };
                        xhr.open("GET", url);
                        xhr.send();
                    });
                })
            );
            const assetsDetails: AssetData[] = meta.map((v, i) => {
                const name =
                    v.customMetadata && v.customMetadata.originalName
                        ? v.customMetadata.originalName
                        : i + "." + Assets.getNameFromDownloadUrl(download[i]);
                return {
                    name,
                    downloadUrl: download[i],
                    file: new File([blobs[i]], name, { type: blobs[i].type }),
                };
            });
            return assetsDetails;
        });

    static uploadFiles = (companyId: string, fs: File[], progressCallback?: (progress: number) => void) => {
        const totalLoadings = new Map<File, number>();
        return Promise.all(
            fs.map(
                file =>
                    new Promise<string>(resolve => {
                        const name = Date.now() + "_" + crypto.randomUUID() + "." + file.name.split(".").pop();
                        const uploader = new DocumentsUploader(companyId, name, file);
                        const loadingPhotoTask = uploader.upload();
                        loadingPhotoTask.on(
                            "state_changed",
                            snapshot => {
                                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                                totalLoadings.set(file, progress);
                                progressCallback &&
                                    progressCallback(
                                        Array.from(totalLoadings.values()).reduce((caret, v) => v + caret, 0) /
                                            fs.length
                                    );
                            },
                            error => {
                                // this.loadingPercent = null;
                                // Handle unsuccessful uploads
                                logger.error(error);
                                resolve(null);
                            },
                            () => {
                                getDownloadURL(loadingPhotoTask.snapshot.ref).then(downloadURL => {
                                    resolve(downloadURL);
                                });
                            }
                        );
                    })
            )
        );
    };
}

export class DocumentsUploader {
    protected _ref: ReturnType<typeof ref>;

    constructor(
        path: string,
        protected name: string,
        protected file: File
    ) {
        this._ref = ref(storage, [NS, path, name].join("/"));
    }

    get ref() {
        return this._ref;
    }

    upload(): UploadTask {
        return uploadBytesResumable(this._ref, this.file, { customMetadata: { originalName: this.file.name } });
    }

    remove() {
        return deleteObject(this._ref)
            .then(() => logger.log("delete asset", this._ref.fullPath))
            .catch(r => logger.log("deletion failed", r));
    }
}
