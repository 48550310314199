import { useCallback, useContext } from "react";
import { DmsAccountingConverter } from "../../../../scripts/models/converters/DmsAccountingConverter";
import { DmsDataContext } from "@dms/types/ContextTypes";
import { GQL } from "@binale-tech/shared";
import { RecordFormPropsContext } from "../context/RecordFormPropsContext";
import { TableViewContext } from "../../../../scripts/context/tableViewContext/tableViewContext";
import { useFormIsRefDisabled } from "../hooks/useFormIsRefDisabled";
import { useFormHandlerDate } from "../hooks/handlers/useFormHandlerDate";
import { useFormProperties } from "./useFormProperties";
import { useFormHandlerBruttoTotal } from "../hooks/handlers/useFormHandlerBruttoTotal";
import { useFormHandlerItemBF1 } from "../hooks/handlers/useFormHandlerItemBF1";
import { useFormHandlerItemText } from "../hooks/handlers/useFormHandlerItemText";
import { useFormHandlerItemBF2 } from "../hooks/handlers/useFormHandlerItemBF2";
import { useFormHandlerRecordBF1 } from "../hooks/handlers/useFormHandlerRecordBF1";
import { useFormHandlerRecordContact } from "../hooks/handlers/useFormHandlerRecordContact";

export const useApplyDocumentProperties = () => {
    const { product } = useContext(TableViewContext);
    const { isUpdating, periodBound, yearBound, refsHtml, refsData } = useContext(RecordFormPropsContext);
    const { documentsKV } = useContext(DmsDataContext);

    const isDisabled = useFormIsRefDisabled();
    const { isContactFieldDisabled } = useFormProperties();
    const onDateChange = useFormHandlerDate();
    const onBruttoTotalChange = useFormHandlerBruttoTotal();
    const onItemBelegfeld1Change = useFormHandlerItemBF1();
    const onTextChange = useFormHandlerItemText();
    const onBF2Change = useFormHandlerItemBF2();
    const onRecordBelegfeld1Change = useFormHandlerRecordBF1();
    const onRecordContactChange = useFormHandlerRecordContact();

    return useCallback(
        (fileIds: string[]) => {
            const docs = fileIds.map(id => documentsKV[id]).filter(Boolean);
            const { editableRecordItem, editableRecord } = refsData.formStateRef.current;
            const suggestions = DmsAccountingConverter.getRecordDocumentsSuggestion(
                product.productKey() as GQL.IProductKey,
                docs,
                { isUpdating, periodBound, yearBound, recordFormData: { editableRecordItem, editableRecord } }
            );

            if (suggestions.editableRecordItem.itemText && !isDisabled(refsHtml.REF_iTEXT)) {
                onTextChange(suggestions.editableRecordItem.itemText);
            }
            if (suggestions.editableRecordItem.itemBelegfeld2 && !isDisabled(refsHtml.REF_iBELEGFELD2)) {
                onBF2Change(suggestions.editableRecordItem.itemBelegfeld2);
            }
            if (suggestions.editableRecord.recordContact && !isContactFieldDisabled) {
                onRecordContactChange(suggestions.editableRecord.recordContact);
            }
            if (suggestions.editableRecord.recordNum && !isDisabled(refsHtml.REF_rBELEGFELD1)) {
                onRecordBelegfeld1Change(suggestions.editableRecord.recordNum);
            }
            if (suggestions.editableRecordItem.itemBelegfeld1 && !isDisabled(refsHtml.REF_iBELEGFELD1)) {
                onItemBelegfeld1Change(suggestions.editableRecordItem.itemBelegfeld1);
            }
            if (suggestions.editableRecord.recordBrutto && !isDisabled(refsHtml.REF_rBRUTTO)) {
                onBruttoTotalChange({
                    amount: suggestions.editableRecord.recordBrutto,
                    originalAmount: suggestions.editableRecord.recordOriginalAmount,
                    currency: suggestions.editableRecord.recordCurrency,
                });
            }
            if (suggestions.recordDate && !isDisabled(refsHtml.REF_rDATE) && !refsData.dateTouchedRef.current) {
                onDateChange(suggestions.recordDate.date);
            }
        },
        [
            product,
            isUpdating,
            periodBound,
            yearBound,
            isDisabled,
            refsHtml,
            refsData,
            isContactFieldDisabled,
            documentsKV,
            onTextChange,
            onBF2Change,
            onRecordContactChange,
            onRecordBelegfeld1Change,
            onItemBelegfeld1Change,
            onBruttoTotalChange,
            onDateChange,
        ]
    );
};
