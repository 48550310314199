import React from "react";
import { DatabaseOutlined } from "@ant-design/icons";
import { FormattedMessage, IntlShape } from "react-intl";
import { Subject } from "rxjs";
import { message, notification } from "antd";

import BulkRecordEditModal from "appearance/components/shared/modals/BulkRecordEdit";
import TableUtils, { GenericRecordTableItemCalculator } from "scripts/core/TableUtils";
import { ActionButtons } from "./actions/ActionButtons";
import { Base, GQL } from "@binale-tech/shared";
import { BindingButton } from "../../components/recordform/components";
import { BuTimeframe } from "scripts/context/BuContext";
import { Category, Creditor, Debitor, GenericRecord, Payment, Tag } from "scripts/models";
import { ColumnOverrides, GenericRecordColumns } from "../../columns/ColumnConfig";
import { ConfirmationDialog } from "../../components/shared/ConfirmationDialog";
import { Container, PageFullScreen, PageHeader } from "../../components/shared/appearance/page";
import { FUViewer } from "../../components/recordform/FileUploader/FileUploader";
import { FlexFillBlock } from "../../components/shared/appearance/page/Scaffold";
import { GenericRecordTableItem, TableColumn, TableItem, TableStateless } from "../../components/shared/Table/Table";
import { GenericRecordUtils, ManipulationPermissions } from "scripts/models/utils/GenericRecordUtils";
import { IDocumentActions } from "../../../scripts/context/hooks/useDocumentActions";
import { IViewsKeys } from "scripts/models/User";
import { GenericRecordForm } from "../../components/recordform/GenericRecordForm";
import { LeftBlockWrapper } from "../../components/shared/LeftTable";
import { ModalLog } from "./ModalLog/ModalLog";
import { Msg } from "../../components/shared/Msg";
import { PaymentExecutionPlanCreator } from "scripts/core/PaymentRecordCreator";
import { PaymentPrototype } from "../../../scripts/models/Payment";
import { YearPeriodContext } from "scripts/context/CompanyContext";
import { PaymentsContext } from "scripts/context/PaymentsProvider";
import { Product, TableProperties } from "scripts/core/Product";
import { ProductAccessUtils } from "scripts/models/utils/ProductAccessUtils";
import { ProductKey } from "scripts/models/Product";
import type { RecordActions, RecordsController } from "scripts/context/recordsContext/RecordsControlCtx";
import { RecordsCtxData } from "scripts/context/recordsContext/RecordsCtx";
import { RecordsTableBlock } from "./RecordsTableBlock";
import { ReviewDropdown } from "./actions/Review/ReviewDropdown";
import { TableFiltersContext, TableFiltersControlContext } from "scripts/context/tableViewContext/tableViewContext";
import { ZahlungenModal } from "./ZahlungenModal/ZahlungenModal";
import { logger } from "scripts/infrastructure/logger";
import { ProductFactory } from "../../../scripts/core/ProductFactory";
import { FormExternalRef } from "../../components/recordform/types/ref";

export interface GenericTableViewStateProps extends React.ContextType<typeof YearPeriodContext> {
    records?: GenericRecord[];
    aggregatedRecordsData?: RecordsCtxData;
    defaultCategories?: Map<number, Category>;

    user?: Base.UserInterface;
    payments?: React.ContextType<typeof PaymentsContext>;
    filters?: React.ContextType<typeof TableFiltersContext>["filters"];
    setFilter: React.ContextType<typeof TableFiltersControlContext>["setFilter"];
    replaceFilters: React.ContextType<typeof TableFiltersControlContext>["replaceFilters"];
    canWrite?: boolean;
    companyGQL?: GQL.ICompany;
    yearConfig?: GQL.ICompanyAccountingYear;
    accountingYears: number[];
    view: keyof IViewsKeys;
    buTimeframes: BuTimeframe[];
    intl?: IntlShape;
    product: Product;
    productKey: ProductKey;
}

export interface GenericTableViewActions {
    onPaymentGql?: (v: Omit<GQL.IPaymentsSaveInput, "companyId">) => Promise<GQL.ISaveSummary>;
    recordActions: RecordsController;
    documentActions: IDocumentActions;
    moduleActions: RecordActions;
}

export interface GenericTableViewState {
    activeRecord: GenericRecord;
    showCancelled?: boolean;
    activePaymentRecord?: GenericRecord;
    confirmationModal: "delete" | "cancel";
    confirmationEditPaymentConfirmed?: boolean;
    confirmationEditPaymentJournaled?: boolean;
    confirmationFestschreibenEdit?: boolean;
    modalLogRecordKey?: string;
    onConfirm?: (selected?: boolean) => void;
    bulkEditRecords: GenericRecord[];

    tableItems: TableItem<GenericRecord>[];
    sortColumn?: TableColumn<GenericRecord>;
    focusIndex?: number;
    saldoMap?: Map<number, Map<number, number>>;

    documentModalRecord?: GenericRecord;
}

const EmptyArray: string[] = [];

export interface GenericTableViewProps extends GenericTableViewStateProps, GenericTableViewActions {}

export abstract class GenericTableView<S extends GenericTableViewState = GenericTableViewState> extends React.Component<
    GenericTableViewProps,
    S
> {
    protected selectedKeys: string[] = [];
    protected selectCategoryEvent = new Subject<Category>();
    protected selectCreditorEvent = new Subject<Creditor>();
    protected selectDebitorEvent = new Subject<Debitor>();
    protected selectTagEvent = new Subject<Tag>();
    protected tableColumns: GenericRecordColumns;

    protected tableRef = React.createRef<TableStateless<GenericRecord>>();
    protected scrollToBottomAfterUpdate = false;

    protected inlineFormRef = React.createRef<FormExternalRef>();

    constructor(props: GenericTableViewProps) {
        super(props);
        this.tableColumns = this.getTableColumns(props.product, props.yearConfig);
        const state = this.getInitialState() as S;
        const sortColKey = this.getDefaultSortColumn();
        const sortCol = this.tableColumns.getMappedColumns().get(sortColKey);
        if (sortCol) {
            sortCol.sortDirection = "asc";
            state.sortColumn = sortCol;
        }
        this.state = state;
    }

    protected getTableColumns(p: Product, yearConfig: GQL.ICompanyAccountingYear): GenericRecordColumns {
        return new GenericRecordColumns(p, yearConfig, r => this.onTableDocumentsClick(r), {
            getters: this.tableConfigGetters.call(this),
        });
    }
    protected tableConfigGetters(): ColumnOverrides<GenericRecord>["getters"] {
        return {};
    }

    protected abstract getHeader(): React.ReactNode;

    protected abstract getToolbarBlock(): React.ReactNode;

    protected abstract getBlockTableView(h: number): React.ReactNode;

    protected abstract getInlineForm(): React.ReactNode;

    componentDidMount() {
        const { payments, records, filters } = this.props;
        const { sortColumn } = this.state;
        this.calculateAndSetTableItems({
            records,
            payments,
            filters: this.mapCtxFilters(filters),
            sortColumn,
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps: GenericTableViewProps) {
        if (this.props.product !== nextProps.product) {
            this.tableColumns = this.getTableColumns(this.props.product, nextProps.yearConfig);
        }
    }

    componentDidUpdate(prevProps: Readonly<GenericTableViewProps>, prevState: Readonly<S>) {
        if (
            this.props.records !== prevProps.records ||
            this.props.payments !== prevProps.payments ||
            this.props.period !== prevProps.period ||
            this.props.filters !== prevProps.filters ||
            prevProps.buTimeframes !== this.props.buTimeframes
        ) {
            const { payments, records, filters } = this.props;
            const { sortColumn } = this.state;
            this.calculateAndSetTableItems({
                records,
                payments,
                filters: this.mapCtxFilters(filters),
                sortColumn,
            });
        }
    }

    protected getInitialState(): GenericTableViewState {
        return {
            tableItems: [],
            activeRecord: null,
            activePaymentRecord: null,
            confirmationModal: null,
            onConfirm: () => {},
            bulkEditRecords: [],
        };
    }

    protected onTableDocumentsClick(v: GenericRecord) {
        this.setState({ documentModalRecord: v });
    }

    protected getDefaultSortColumn() {
        return TableProperties.ComputedNr;
    }

    protected getControlLogButton(tableItem: TableItem<GenericRecord>) {
        if (tableItem.key !== null) {
            return (
                <div className="LogButtonCell">
                    <DatabaseOutlined
                        style={{ cursor: "pointer" }}
                        onClick={() => this.setState({ modalLogRecordKey: tableItem.item.key })}
                    />
                </div>
            );
        }
        return null;
    }

    protected getActionButtons(tableItem: TableItem<GenericRecord>) {
        return (
            <ActionButtons
                tableItem={tableItem}
                onEdit={() => this.handleEditItem(tableItem)}
                onConfirm={() => this.handleConfirm(tableItem)}
                onDelete={() => this.handleDeleteItems([tableItem])}
                selectedPeriodEditBound={this.getSelectedPeriodEditBound()}
            />
        );
    }

    protected getRecordReview(tableItem: TableItem<GenericRecord>, onClick: (review: GenericRecord["review"]) => void) {
        const pk = this.props.productKey;
        const canReviewRecord = ProductAccessUtils.canReviewRecord(pk, this.props.companyGQL, this.props.user);

        return <ReviewDropdown tableItem={tableItem} onClick={onClick} canReviewRecord={canReviewRecord} />;
    }

    protected rowClassFunc = (tableItem: GenericRecordTableItem) => {
        return TableUtils.getTableRowClassName(tableItem, this.state.focusIndex);
    };

    // The most important function here: calculateAndSetTableItems
    protected calculateAndSetTableItems(opts: {
        records: GenericRecord[];
        filters: Map<string, (v: GenericRecord) => boolean>;
        sortColumn: TableColumn<GenericRecord>;
        payments: React.ContextType<typeof PaymentsContext>;
    }) {
        const calculator = new GenericRecordTableItemCalculator(opts.records, records =>
            this.getTableItems(records, opts.payments)
        );
        calculator.applyFilters(opts.filters);
        calculator.mapItems(vs => this.postProcessTableItems(vs));
        calculator.sort(opts.sortColumn);
        calculator.mapItems(vs => this.postSortTableItems(vs));
        const saldoMap = calculator.getSaldoMap(this.props.accountingYears);

        const tableItems = calculator.getItems();
        if (Number.isFinite(this.state.focusIndex) && this.state.focusIndex >= tableItems.length) {
            this.setState({ focusIndex: null });
        }
        this.setState(
            {
                saldoMap,
                tableItems,
            },
            () => {
                if (this.scrollToBottomAfterUpdate) {
                    this.scrollToBottomAfterUpdate = false;
                    this.tableRef.current.scrollToBottom();
                }
            }
        );
    }

    protected postProcessTableItems(vs: GenericRecordTableItem[]) {
        return vs;
    }

    protected postSortTableItems(vs: GenericRecordTableItem[]) {
        return vs;
    }

    protected mapCtxFilters = (filters: GenericTableViewStateProps["filters"]) => {
        const f: Map<string, (record: GenericRecord) => boolean> = new Map();
        filters.forEach(({ predicate }, key) => {
            f.set(key, predicate);
        });
        return f;
    };
    protected getTableItems(
        records: GenericRecord[],
        payments: React.ContextType<typeof PaymentsContext>
    ): GenericRecordTableItem[] {
        return TableUtils.getTableItems(
            records,
            payments,
            this.props.buTimeframes,
            this.props.yearConfig,
            this.state.showCancelled,
            this.selectedKeys
        );
    }

    protected handleModalHide = () => {
        this.setState(
            {
                confirmationModal: null,
                confirmationEditPaymentConfirmed: false,
                confirmationEditPaymentJournaled: false,
                activeRecord: null,
                activePaymentRecord: null,
                modalLogRecordKey: null,
                documentModalRecord: null,
            },
            () => {
                setTimeout(() => this.inlineFormRef?.current?.focusFirstField(), 200);
            }
        );
    };
    protected onHideFestschreibenEdit = () => {
        this.setState({ confirmationFestschreibenEdit: false });
    };

    protected getBulkEditModal(): React.ReactNode {
        if (!this.state.bulkEditRecords.length) {
            return null;
        }
        return (
            <BulkRecordEditModal
                itemsToEdit={this.state.bulkEditRecords}
                onCancel={() => {
                    this.setState({ bulkEditRecords: [] });
                }}
            />
        );
    }

    protected getInlineFormProps(): React.ComponentProps<typeof GenericRecordForm> {
        return {
            ref: this.inlineFormRef,
            record: this.state.activeRecord,
            onSave: this.handleSave,
            onClear: this.handleOnClear,
        };
    }

    protected getRecordPermissions<T extends keyof ManipulationPermissions>(
        record: GenericRecord,
        permissions: T[]
    ): Pick<ManipulationPermissions, T> {
        const all = GenericRecordUtils.getManipulationPermissions(
            record,
            this.props.canWrite,
            this.props.product,
            this.props.payments.recordRelation,
            { selectedPeriod: this.getSelectedPeriodEditBound(), globalYear: this.props.year },
            this.props.yearConfig,
            this.props.companyGQL,
            this.props.user
        );
        const res = {} as Pick<ManipulationPermissions, T>;
        permissions.forEach(key => {
            res[key] = all[key];
        });
        return res;
    }

    protected getModalsBlock() {
        const { documentModalRecord, activePaymentRecord } = this.state;
        const payments = activePaymentRecord ? this.props.payments.recordRelation.get(activePaymentRecord.key) : null;

        let paymentDisabledSave = true;
        let paymentDisabledDelete = true;
        if (activePaymentRecord) {
            const { paymentSave, paymentDelete } = this.getRecordPermissions(activePaymentRecord, [
                "paymentSave",
                "paymentDelete",
            ]);
            paymentDisabledSave = paymentSave.disabled;
            paymentDisabledDelete = paymentDelete.disabled;
        }
        let recordFileUploadDisabled = true;
        if (documentModalRecord) {
            recordFileUploadDisabled = documentModalRecord.journaled || Boolean(documentModalRecord.cancellation);
        }

        return (
            <React.Fragment>
                <ConfirmationDialog
                    show={Boolean(this.state.confirmationModal)}
                    title={
                        this.state.confirmationModal === "delete" ? (
                            <FormattedMessage id="app.confirmation.header.record_delete" />
                        ) : (
                            <FormattedMessage id="app.confirmation.header.record_cancel" />
                        )
                    }
                    confirmText={
                        this.state.confirmationModal === "delete" ? (
                            <FormattedMessage id="app.button.delete" />
                        ) : (
                            <FormattedMessage id="app.button.storno" />
                        )
                    }
                    onHide={this.handleModalHide}
                    onConfirm={v => this.state.onConfirm(v)}
                    content={
                        this.state.confirmationModal === "delete" ? (
                            <FormattedMessage id="app.confirmation.record_remove.body" />
                        ) : (
                            <FormattedMessage id="app.confirmation.record_cancel.body" />
                        )
                    }
                />
                <ConfirmationDialog
                    show={this.state.confirmationFestschreibenEdit}
                    title={<FormattedMessage id="app.confirmation.header.festschreiben_edit" />}
                    confirmText={<FormattedMessage id="app.button.edit" />}
                    onHide={this.onHideFestschreibenEdit}
                    onConfirm={v => this.state.onConfirm(v)}
                    content={<FormattedMessage id="app.confirmation.festschreiben_edit.body" />}
                />
                <ConfirmationDialog
                    show={this.state.confirmationEditPaymentConfirmed}
                    confirmText={<FormattedMessage id="app.button.edit" />}
                    checkboxTitle={<FormattedMessage id="app.confirmation.header.confirmed_edit" />}
                    onHide={this.handleModalHide}
                    onConfirm={v => this.state.onConfirm(v)}
                    content={
                        <span>
                            By this payments change some related <strong>CONFIRMED</strong> entities also updated and/or
                            removed
                        </span>
                    }
                />
                <ConfirmationDialog
                    show={this.state.confirmationEditPaymentJournaled}
                    confirmText={<FormattedMessage id="app.button.edit" />}
                    onHide={this.handleModalHide}
                    checkboxTitle={<FormattedMessage id="app.confirmation.header.festschreiben_edit" />}
                    onConfirm={v => this.state.onConfirm(v)}
                    content={
                        <span>
                            By this payments change some related <strong>JOURNALED</strong> entities will be storniren
                        </span>
                    }
                />
                {this.getBulkEditModal()}
                <ZahlungenModal
                    record={activePaymentRecord}
                    payments={payments}
                    disabledSave={paymentDisabledSave}
                    disabledDelete={paymentDisabledDelete}
                    show={!!activePaymentRecord}
                    onHide={this.handleModalHide}
                    onPaymentsSave={this.handlePaymentUpdatesSoftCheck}
                />
                <ModalLog recordKey={this.state.modalLogRecordKey} onHide={this.handleModalHide} />
                <FUViewer
                    addedDocumentIds={documentModalRecord ? documentModalRecord.documents.map(v => v.id) : EmptyArray}
                    visible={Boolean(documentModalRecord)}
                    onHide={this.handleModalHide}
                    allowEdit={!recordFileUploadDisabled}
                    onSave={this.onAssetsViewerChange(documentModalRecord)}
                    productKey={documentModalRecord?.getProductKey()}
                />
            </React.Fragment>
        );
    }

    protected onAssetsViewerChange = (r: GenericRecord) => async (vs: GQL.IRecordDocumentInput[]) => {
        this.handleModalHide();
        if (!r) {
            return Promise.resolve();
        }

        const record = r.clone();
        record.documents = vs;
        return this.getRecordProductProps(r)
            .actions.saveDocuments(r.key, vs)
            .then(res => {
                return this.onRecordSaveHook(record, res);
            })
            .then(() => {
                return this.props.documentActions.updateRecordFromDocuments(record);
            });
    };

    private onRecordSaveHook = async (record: GenericRecord, res: GQL.ISaveSummary) => {
        return this.recordDocumentInputsFill(record, res).then(this.showSaveSummary);
    };

    protected recordDocumentInputsFill = async (record: GenericRecord, res: GQL.ISaveSummary) => {
        if (!res?.status) {
            return res;
        }
        const { product } = this.getRecordProductProps(record);
        await this.props.documentActions.updateDocumentsFromRecord(record.documents, record, product);
        return res;
    };

    protected showSaveSummary = (res: GQL.ISaveSummary) => {
        if (!res.summary.length) {
            return;
        }

        const msg = Msg.init(this.props.intl);
        notification.info({
            description: (
                <div style={{ textAlign: "left" }}>
                    {res.summary.map(item => (
                        <div key={item.id}>{msg.getMsg(item.productId, item.action)}</div>
                    ))}
                </div>
            ),
            message: "Record updates",
            placement: "topRight",
        });
    };

    private handlePaymentUpdatesSoftCheck = (payments: Payment[], defCatMap: Map<number, Category>) => {
        const record = this.state.activePaymentRecord;
        if (!record) {
            return;
        }
        const currentPayments = this.props.payments.recordRelation.get(record.key) || [];
        const gqlInput: GQL.IPaymentProtoInput = {
            sourceRecordKey: record.key,
            strategy: GQL.IPaymentProtoStrategy.Source,
            strategySource: payments.map(payment => ({
                discountAmount: payment.skontoBetrag,
                existingPaymentKey: payment.key,
                date: {
                    year: payment.date.getFullYear(),
                    period: payment.date.getMonth() + 1,
                    day: payment.date.getDate(),
                },
                konto: payment.type.konto,
                type: payment.type.value,
                paymentAmount: payment.zahlungsBetrag,
                paymentSource:
                    payment.paymentSources && Object.keys(payment.paymentSources).length > 0
                        ? {
                              recordKey: Object.keys(payment.paymentSources)[0],
                              amount: Object.values(payment.paymentSources)[0],
                          }
                        : null,
            })),
        };

        const executionPlanCreator = new PaymentExecutionPlanCreator(
            this.props.product,
            this.props.companyGQL,
            defCatMap,
            this.props.aggregatedRecordsData
        );
        const plan = executionPlanCreator.createExecutionPlan(record, currentPayments, payments);

        const dr1 = plan.getNonDraftDeletions();
        const dr2 = plan.getNonDraftUpdates();

        const jo1 = plan.getJournaledDeletions();
        const jo2 = plan.getJournaledUpdates();
        logger.log({ dr1, dr2, jo1, jo2 });

        if (jo1.length + jo2.length > 0) {
            // previous modal have to close first
            setTimeout(() => {
                this.setState({
                    confirmationEditPaymentJournaled: true,
                    onConfirm: (selected?: boolean) => {
                        this.props
                            .onPaymentGql({
                                payment: gqlInput,
                                confirmedRecordsChangeApproved: Boolean(selected),
                                journaledRecordsChangeApproved: Boolean(selected),
                            })
                            .then(this.showSaveSummary);
                        this.handleModalHide();
                    },
                });
            }, 150);
            return;
        } else if (dr1.length + dr2.length > 0) {
            // previous modal have to close first
            setTimeout(() => {
                this.setState({
                    confirmationEditPaymentConfirmed: true,
                    onConfirm: (selected?: boolean) => {
                        this.props
                            .onPaymentGql({
                                payment: gqlInput,
                                confirmedRecordsChangeApproved: Boolean(selected),
                            })
                            .then(this.showSaveSummary);
                        this.handleModalHide();
                    },
                });
            }, 150);
            return;
        } else {
            this.props.onPaymentGql({ payment: gqlInput }).then(this.showSaveSummary);
        }
    };

    protected handleSave = (v: GenericRecord, payment?: PaymentPrototype) => {
        console.log("handleSave", { v, payment });
        if (v.journaled) {
            this.setState({
                confirmationFestschreibenEdit: true,
                onConfirm: () => {
                    this.setState({ activeRecord: null });
                    this.onHideFestschreibenEdit();
                    this.doRecordSave(v, payment).then(res => this.onRecordSaveHook(v, res));
                },
            });
        } else {
            this.setState({ activeRecord: null });
            this.doRecordSave(v, payment).then(res => this.onRecordSaveHook(v, res));
        }
    };

    protected async doRecordSave(v: GenericRecord, payment?: PaymentPrototype) {
        const isCreating = Boolean(!v.key);
        const ext = this.props.yearConfig.kontoExt;
        v.savePreProcess(ext);
        const res = await this.findSavePropsMethodAndSaveRecord(v, payment);
        if (isCreating) {
            this.scrollToBottomAfterUpdate = true;
        }
        return res;
    }

    protected getRecordProductProps(record: GenericRecord): {
        product: GQL.IProductKey;
        groupId: string;
        actions: RecordActions;
    } {
        const { groupsReverseIndex } = this.props.aggregatedRecordsData.allRecords;
        const groupId = groupsReverseIndex.get(record.key);
        // in case of editing record has product key
        if (record.getProductKey()) {
            const { actions } = ProductFactory.getAccountingProductConfig(
                record.getProductKey(),
                this.props.yearConfig,
                this.props.companyGQL,
                this.props.recordActions,
                groupId
            );
            return { groupId, actions, product: record.getProductKey() };
        }
        return {
            product: this.props.productKey in GQL.IProductKey ? (this.props.productKey as GQL.IProductKey) : null,
            actions: this.props.moduleActions,
            groupId: undefined, // groupId is used only for bulk editing, so it can be empty here
        };
    }

    protected findSavePropsMethodAndSaveRecord(record: GenericRecord, payment?: PaymentPrototype) {
        const methods = this.getRecordProductProps(record);
        return methods.actions.save(record, payment);
    }

    protected getPageWithoutLeftTable() {
        const header = this.getHeader();
        return (
            <PageFullScreen>
                {header && <PageHeader>{header}</PageHeader>}
                {this.getModalsBlock()}
                {this.getToolbarBlock()}
                <FlexFillBlock style={{ marginBottom: 5 }}>
                    <Container absolute>{(w, h) => this.getBlockTableView(h)}</Container>
                </FlexFillBlock>
            </PageFullScreen>
        );
    }

    protected getFormPageWithLeftTable() {
        const header = this.getHeader();
        return (
            <PageFullScreen>
                {header && <PageHeader>{header}</PageHeader>}
                {this.getModalsBlock()}
                {this.getToolbarBlock()}
                <LeftBlockWrapper
                    onSelectCategory={(v: Category) => this.selectCategoryEvent.next(v)}
                    onSelectCreditor={(v: Creditor) => this.selectCreditorEvent.next(v)}
                    onSelectDebitor={(v: Debitor) => this.selectDebitorEvent.next(v)}
                    onSelectTag={(v: Tag) => this.selectTagEvent.next(v)}
                >
                    <FlexFillBlock>
                        <Container absolute>{(w, h) => this.getBlockTableView(h)}</Container>
                    </FlexFillBlock>
                </LeftBlockWrapper>
                {this.getInlineForm()}
            </PageFullScreen>
        );
    }

    protected getFormPageWithoutLeftTable() {
        const header = this.getHeader();
        return (
            <PageFullScreen key="getFormPageWithoutLeftTable">
                {header && <PageHeader>{header}</PageHeader>}
                {this.getModalsBlock()}
                {this.getToolbarBlock()}
                <FlexFillBlock>
                    <Container absolute>{(w, h) => this.getBlockTableView(h)}</Container>
                </FlexFillBlock>
                {this.getInlineForm()}
            </PageFullScreen>
        );
    }

    protected onToggleHidden = () => {
        this.setState(
            ({ showCancelled }) => ({ showCancelled: !showCancelled }),
            () => {
                const { payments, records, filters } = this.props;
                const { sortColumn } = this.state;
                this.calculateAndSetTableItems({
                    records,
                    payments,
                    filters: this.mapCtxFilters(filters),
                    sortColumn,
                });
            }
        );
    };

    protected handleEditItem(tableItem: TableItem<GenericRecord>, e?: React.SyntheticEvent) {
        e && e.stopPropagation();
        const { edit } = this.getRecordPermissions(tableItem.item, ["edit"]);
        if (!edit.disabled) {
            this.setEditableRecord(tableItem.item.clone());
        }
    }

    protected handleBulkEditItem(tableItems: TableItem<GenericRecord>[], e?: React.SyntheticEvent) {
        e && e.stopPropagation();
        const records = tableItems.map(item => item.item);
        this.setState({ bulkEditRecords: records });
    }

    protected handleConfirm(tableItem: TableItem<GenericRecord>, e?: React.SyntheticEvent) {
        e && e.stopPropagation();
        this.onConfirm([tableItem.item.clone()]);
    }

    protected onConfirm(records: GenericRecord[]) {
        this.props.moduleActions.confirm(records.map(({ key }) => key));
    }

    protected handleCopyItem = (tableItem: TableItem<GenericRecord>, e?: React.SyntheticEvent) => {
        e && e.stopPropagation();
        const record = GenericRecordUtils.copyRecord(tableItem.item, this.props.year, this.props.period);

        const { copy } = this.getRecordPermissions(tableItem.item, ["copy"]);
        if (copy.disabled) {
            message.warning(copy.reason);
            return;
        }
        this.setEditableRecord(record);
    };

    protected setEditableRecord(record: GenericRecord) {
        if (!this.props.canWrite) {
            return;
        }
        this.setState({ activeRecord: record }, () => {
            this.inlineFormRef.current.flashForm();
        });
    }

    protected handleOnClear = () => {
        this.setState({ activeRecord: null });
    };

    protected handleZahlungTableItem = (tableItem: GenericRecordTableItem) => {
        this.setState({
            activePaymentRecord: tableItem.item,
        });
    };

    protected handleReviewItem =
        (tableItem: TableItem<GenericRecord>, e?: React.SyntheticEvent) => (review: GenericRecord["review"]) => {
            e && e.stopPropagation();
            this.getRecordProductProps(tableItem.item).actions.review([tableItem.item.key], review);
            this.setState({ focusIndex: tableItem.key });
        };

    protected getRecordsActionMap(records: GenericRecord[]): Map<string, { ids: string[]; actions: RecordActions }> {
        if (records.length === 0) {
            return new Map();
        }
        const actionMap = new Map<string, { ids: string[]; actions: RecordActions }>();
        records.forEach(record => {
            const { product, actions, groupId } = this.getRecordProductProps(record);
            const key = `${product}:${groupId}`;
            if (!actionMap.has(key)) {
                actionMap.set(key, { ids: [], actions });
            }
            actionMap.get(key).ids.push(record.key);
        });
        return actionMap;
    }
    protected handleAvisTableItems(tableItems: TableItem<GenericRecord>[], avis: boolean, e?: React.SyntheticEvent) {
        e && e.stopPropagation();
        const actionMap = this.getRecordsActionMap(
            tableItems.map(({ item }) => item).filter(v => !v.journaled && !v.cancellation)
        );
        actionMap.forEach(({ ids, actions }) => {
            actions.avis(ids, avis);
        });
    }

    protected handleColorTableItems(tableItems: TableItem<GenericRecord>[], color: string) {
        const actionMap = this.getRecordsActionMap(tableItems.map(({ item }) => item));
        actionMap.forEach(({ ids, actions }) => {
            actions.color(ids, color);
        });
    }

    protected handleDeleteItems(tableItems: TableItem<GenericRecord>[], e?: React.SyntheticEvent) {
        e && e.stopPropagation();
        const records = tableItems.map(tableItem => tableItem.item);
        this.setState({
            confirmationModal: "delete",
            onConfirm: () => {
                this.props.moduleActions.deleteByKeys(records.map(record => record.key)).then(this.showSaveSummary);
                this.handleModalHide();
            },
        });
    }

    protected handleCancelItems(tableItems: TableItem<GenericRecord>[], e?: React.SyntheticEvent) {
        e && e.stopPropagation();
        const records = tableItems.map(tableItem => tableItem.item);
        this.setState({
            confirmationModal: "cancel",
            onConfirm: () => {
                this.props.moduleActions.cancelByKeys(records.map(record => record.key)).then(this.showSaveSummary);
                this.handleModalHide();
            },
        });
    }

    protected handleSort = (column: TableColumn<GenericRecord>) => {
        const directions = [null, "asc", "desc"];
        const indexOf = directions.indexOf(column.sortDirection || null);
        const nextIdx = (indexOf + 1) % directions.length;
        column.sortDirection = directions[nextIdx] as any;
        this.setState({ sortColumn: column }, () => {
            const { payments, records, filters } = this.props;
            const { sortColumn } = this.state;
            this.calculateAndSetTableItems({
                records,
                payments,
                filters: this.mapCtxFilters(filters),
                sortColumn,
            });
        });
    };

    protected handleUpdateItems = (vs: TableItem<GenericRecord>[], e?: React.SyntheticEvent) => {
        e && e.preventDefault();
        this.setState({ tableItems: vs });
        this.selectedKeys = vs.filter(v => v.selected).map(v => v.item.key);
    };

    // Payment binding part, modules: Kasse, Bank, FE, Kontenauskunft
    protected getRecordBinding(tableItem: TableItem<GenericRecord>) {
        if (!Number.isFinite(tableItem.key)) {
            return null;
        }
        const { paymentSave, paymentDelete } = this.getRecordPermissions(tableItem.item, [
            "paymentSave",
            "paymentDelete",
        ]);

        // we don't allow to connect split representation records because it's a shitshow
        return (
            <BindingButton
                mode="table"
                size="small"
                paymentRepresentationRecord={tableItem.item}
                isButtonDisabled={false}
                isEditDisabled={paymentSave.disabled}
                isDeleteDisabled={paymentDelete.disabled}
                onSave={connection => {
                    const existingRelatedPayments = this.props.payments.recordRelation.get(tableItem.item.key) || [];
                    if (!connection) {
                        if (existingRelatedPayments.length) {
                            this.findSavePropsMethodAndSaveRecord(tableItem.item).then(this.showSaveSummary);
                        }
                    } else {
                        const payment = connection.payment;
                        this.props
                            .onPaymentGql({
                                payment: {
                                    sourceRecordKey: payment.sourceRecordKey,
                                    strategy: GQL.IPaymentProtoStrategy.Representation,
                                    strategyRepresentation: {
                                        discountAmount: payment.skontoBetrag,
                                        representationRecordKey: tableItem.item.key,
                                    },
                                },
                            })
                            .then(this.showSaveSummary);
                    }
                }}
            />
        );
    }

    protected getSelectedPeriodEditBound() {
        // if view has inline form (form enabled), then in modal we respect selected period.
        // otherwise (Auswertung, KA) periodBound is "Full year" (no period limit)
        const periodBound = this.props.product.getConfig().enableRecordForm ? this.props.period : null;
        return periodBound;
    }

    protected getTableLegacyCommonProps(): Pick<
        React.ComponentProps<typeof RecordsTableBlock>,
        | "onSort"
        | "sortColumn"
        | "canWrite"
        | "focusIndex"
        | "onSetFocus"
        | "product"
        | "view"
        | "tableRef"
        | "tableItems"
        | "selectedPeriodEditBound"
    > {
        const product = this.props.product;
        const { disableTableSorting } = product.getConfig();
        const sortColumn = disableTableSorting ? null : this.state.sortColumn;
        const onSort = disableTableSorting ? () => {} : this.handleSort;
        const { focusIndex, tableItems } = this.state;
        const { view } = this.props;
        return {
            product,
            view,
            tableItems,
            onSort,
            sortColumn,
            canWrite: this.props.canWrite,
            focusIndex,
            onSetFocus: v => this.setState({ focusIndex: v }),
            tableRef: this.tableRef,
            selectedPeriodEditBound: this.getSelectedPeriodEditBound(),
        };
    }
}
