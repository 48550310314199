import RecordFormState from "../types/RecordFormState";
import React, { createContext } from "react";
import { FieldError } from "react-hook-form";
import { PaymentPrototype } from "../../../../scripts/models/Payment";
import { IGenericItem } from "../../../../scripts/models/Interfaces";
import { Bu } from "@binale-tech/shared";

export const getDefaultItem = (): RecordFormState["editableRecordItem"] => ({
    itemBu: Bu.Bu.KU,
    itemUSt13b: undefined,
    itemBelegfeld1: undefined,
    itemBelegfeld2: undefined,
    itemCategoryCreditor: undefined,
    itemBrutto: undefined,
    itemOriginalAmount: undefined,
    itemTag: undefined,
    itemText: undefined,
    itemText2: undefined,
});
export const getDefaultRecord = (): RecordFormState["editableRecord"] => ({
    recordKey: null,
    recordNum: undefined,
    recordBrutto: undefined,
    recordOriginalAmount: undefined,
    recordCurrency: undefined,
    recordCategoryCreditor: undefined,
    recordDocuments: undefined,
    recordLastschrift: false,
    recordJournaled: undefined,
    recordDraft: false,
    recordPriority: undefined,
    recordFalligkeit: undefined,
    recordCreatedAt: undefined,
    recordColor: undefined,
    recordReview: undefined,
    recordContact: undefined,
});

const getDefaultState = (): RecordFormState => ({
    recordValidationStates: new Map(),
    itemValidationStates: new Map(),
    editableRecordItem: getDefaultItem(),
    editableRecord: getDefaultRecord(),
    recordTmpNetto: null,
    recordItems: [],
    recordDate: { date: undefined, period: undefined },
    isNettoMode: false,
    isModalOpen: false,
    isModalTemplateOpen: false,
});

export const RecordFormStateContext = createContext<RecordFormState>(getDefaultState());

type RecordFormStateControl = {
    setRecordValidationStates: React.Dispatch<React.SetStateAction<Map<React.RefObject<any>, FieldError>>>;
    setItemValidationStates: React.Dispatch<React.SetStateAction<Map<React.RefObject<any>, FieldError>>>;
    setIsNettoMode: (v: boolean) => void;
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setIsModalTemplateOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedPayment: React.Dispatch<React.SetStateAction<PaymentPrototype>>;
    setRecordVirtualNetto: (v: number) => void;
    setRecordDate: (v: { date: Date; period: number }) => void;
    setRecordItems: React.Dispatch<React.SetStateAction<IGenericItem[]>>;
    setEditableRecord: React.Dispatch<React.SetStateAction<RecordFormState["editableRecord"]>>;
    setEditableRecordItem: React.Dispatch<React.SetStateAction<RecordFormState["editableRecordItem"]>>;
};
const voidFn = () => {};
export const RecordFormStateControlContext = createContext<RecordFormStateControl>({
    setRecordValidationStates: voidFn,
    setItemValidationStates: voidFn,
    setIsNettoMode: voidFn,
    setIsModalOpen: voidFn,
    setIsModalTemplateOpen: voidFn,
    setSelectedPayment: voidFn,
    setRecordVirtualNetto: voidFn,
    setRecordDate: voidFn,
    setRecordItems: voidFn,
    setEditableRecord: voidFn,
    setEditableRecordItem: voidFn,
});
