import React, { useContext, useMemo, useRef } from "react";
import { Button, Divider, Form, Row, Space } from "antd";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { FieldLabel } from "@binale-tech/ui-components";
import { FormattedMessage } from "react-intl";
import { PlusOutlined } from "@ant-design/icons";

import {
    BaseInput,
    InputIBAN,
    InputIBANLabelAddon,
    useIBANValidation,
} from "appearance/components/shared/form/baseComponents";
import { Contacts } from "@binale-tech/shared";
import { ContactsContext } from "scripts/context/ContactsContext";
import { RemoveButton } from "../../components/RemoveButton";
import { removeEmpty } from "scripts/infrastructure/helpers";

interface Props {
    item: Contacts.Contact | null;
}

export const BankDetails = React.memo<Props>(function BankDetails({ item }) {
    const addBtnRef = useRef<HTMLButtonElement>();
    const { contacts } = useContext(ContactsContext);
    const { setFocus, watch, getValues } = useFormContext<Contacts.Contact>();
    const { append, remove, fields } = useFieldArray<Contacts.Contact, "banks">({ name: "banks" });
    const ibanValidator = useIBANValidation();

    const banks = watch("banks");

    const uniqueIBANs = useMemo(
        () =>
            contacts.reduce((acc, contact) => {
                const ibans = contact?.banks?.map(bank => bank.IBAN).filter(iban => !!iban);
                return contact.uuid === item?.uuid || !ibans ? acc : [...acc, ...ibans];
            }, []),
        [contacts, item]
    );

    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            {fields.map((bank, index) => (
                <div data-testid={`bank-card-${index}`} key={bank.id}>
                    <Divider orientation="left" plain style={{ borderTopColor: "#1890ff" }}>
                        <b>
                            <FormattedMessage id="app.global.bank" /> {index + 1}
                        </b>
                    </Divider>
                    <Row>
                        <Controller
                            name={`banks.${index}.accountHolder`}
                            render={({ field }) => (
                                <FieldLabel
                                    label={<FormattedMessage id="app.fields.accountOwner" />}
                                    style={{ minWidth: 500 }}
                                >
                                    <BaseInput data-testid="banks-accountHolder" {...field} maxLength={60} showCount />
                                </FieldLabel>
                            )}
                        />
                    </Row>
                    <Row>
                        <Space align="start">
                            <Controller
                                name={`banks.${index}.IBAN`}
                                rules={{
                                    validate: {
                                        allIBANS: (value: string) =>
                                            value && uniqueIBANs.includes(value) ? "not unique" : true,
                                        contactIBANs: (value: string) => {
                                            const ibans = banks
                                                .map((field, ci) => (ci === index ? null : field.IBAN))
                                                .filter(iban => !!iban);
                                            return value && ibans.includes(value) ? "not unique" : true;
                                        },
                                        ...ibanValidator,
                                    },
                                }}
                                render={({ field, fieldState }) => {
                                    return (
                                        <FieldLabel
                                            label="IBAN"
                                            labelAddon={<InputIBANLabelAddon value={field.value} />}
                                            style={{ minWidth: 300 }}
                                            fieldError={fieldState.error}
                                            validateStatus={fieldState.isValidating ? "validating" : undefined}
                                        >
                                            <InputIBAN data-testid="banks-IBAN" {...field} />
                                        </FieldLabel>
                                    );
                                }}
                            />
                            <Controller
                                name={`banks.${index}.BIC`}
                                render={({ field }) => (
                                    <FieldLabel label="BIC (SWIFT-Code)" style={{ minWidth: 200 }}>
                                        <BaseInput data-testid="banks-BIC" {...field} maxLength={11} showCount />
                                    </FieldLabel>
                                )}
                            />
                        </Space>
                    </Row>
                    <Row>
                        <Space>
                            <Controller
                                name={`banks.${index}.accountNumber`}
                                render={({ field }) => (
                                    <FieldLabel
                                        label={<FormattedMessage id="app.fields.bankAccountNumber" />}
                                        style={{ minWidth: 350 }}
                                    >
                                        <BaseInput
                                            data-testid="banks-accountNumber"
                                            {...field}
                                            maxLength={30}
                                            showCount
                                        />
                                    </FieldLabel>
                                )}
                            />
                            <Controller
                                name={`banks.${index}.BLZ`}
                                render={({ field }) => (
                                    <FieldLabel
                                        label={<FormattedMessage id="app.fields.bankCodeNumber" />}
                                        style={{ minWidth: 300 }}
                                    >
                                        <BaseInput data-testid="banks-BLZ" {...field} maxLength={30} showCount />
                                    </FieldLabel>
                                )}
                            />
                        </Space>
                    </Row>
                    <Row justify="space-between">
                        <Controller
                            name={`banks.${index}.bankName`}
                            render={({ field }) => (
                                <FieldLabel
                                    label={<FormattedMessage id="app.fields.bankName" />}
                                    style={{ minWidth: 350 }}
                                >
                                    <BaseInput data-testid="banks-bankName" {...field} maxLength={30} showCount />
                                </FieldLabel>
                            )}
                        />
                        <Form.Item label=" ">
                            <RemoveButton
                                isItemEmpty={() => {
                                    const slimmedBank = removeEmpty(getValues(`banks.${index}`) || {});
                                    return !slimmedBank;
                                }}
                                onRemove={async () => {
                                    remove(index);
                                    await new Promise(r => setTimeout(r, 100));
                                    if (fields.length > 1 && fields.length - 1 > index) {
                                        setFocus(`banks.${index}.accountHolder`);
                                    } else {
                                        addBtnRef.current?.focus();
                                    }
                                }}
                            />
                        </Form.Item>
                    </Row>
                </div>
            ))}

            <Row justify={fields.length === 0 ? "start" : "end"}>
                {fields.length > 0 && <Divider style={{ borderTopColor: "#1890ff", margin: "8px 0" }} />}
                <Form.Item noStyle>
                    <Button
                        type="primary"
                        ref={addBtnRef}
                        onClick={() => {
                            append(
                                {
                                    accountHolder: "",
                                    IBAN: "",
                                    BIC: "",
                                    accountNumber: "",
                                    bankName: "",
                                    BLZ: "",
                                },
                                { focusName: `banks.${fields.length}.accountHolder` }
                            );
                        }}
                        icon={<PlusOutlined />}
                        data-testid="button-add-bank"
                    >
                        <FormattedMessage id="app.global.bank" tagName="span" />
                    </Button>
                </Form.Item>
            </Row>
        </Space>
    );
});
