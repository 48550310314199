import React, { FC, useCallback, useContext, useMemo, useRef, useState } from "react";
import { TransactionsTable } from "@banks/components";
import { Flex } from "antd";
import { ParseDataViewToolbar } from "@banks/modules/ParseDataModule/components/ParseDataViewToolbar";
import { IRowNode, RowClassRules } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { TParseTransaction } from "@banks/types";
import dayjs from "dayjs";

import { gridOptions } from "@banks/modules/ParseDataModule/gridOptions";
import { ParseTransactionTableColumns } from "@banks/modules/ParseDataModule/parseTransactionTableColumns";

import styles from "@banks/modules/ParseDataModule/ParseData.module.scss";
import { useDebounce } from "../../../../scripts/infrastructure/hooks/useDebounce";
import { ParseDataContext, ParseDataControlContext } from "@banks/modules/ParseDataModule/context/ParseDataContext";

const PARSE_TABLE_CONTAINER_HEIGHT = "calc(100vh - 200px)";

export const ParseDataTable: FC = () => {
    const [checkOnlySelected, setCheckOnlySelected] = useState<boolean>(false);
    const [hideIsAlreadyExist, setHideIsAlreadyExist] = useState(false);

    const { filteredRows, selectedRows, transactionBank } = useContext(ParseDataContext);
    const { selectRows } = useContext(ParseDataControlContext);

    const tableRef = useRef<AgGridReact>(null);

    const bankTransactionAndDate = useMemo(() => {
        return {
            alreadyExistTransaction: Object.values(transactionBank?.transactions || {}),
            openingBalanceDate: transactionBank?.openingBalanceDate
                ? dayjs(transactionBank.openingBalanceDate)
                : undefined,
        };
    }, [transactionBank]);

    const { alreadyExistTransaction, openingBalanceDate } = useDebounce(bankTransactionAndDate, 1000);

    const rowClassRules = useMemo<RowClassRules<TParseTransaction>>(() => {
        return {
            [styles.secondaryCell]: params => {
                if (!params?.data || !alreadyExistTransaction?.length) {
                    return false;
                }
                return !!alreadyExistTransaction.find(el => el.csvLine === params.data.csvLine);
            },
            [styles.beyondDatesCell]: params => {
                if (!params?.data || !openingBalanceDate) {
                    return false;
                }

                const bookingDate = dayjs(params.data.bookingDate);
                return dayjs(bookingDate).isBefore(openingBalanceDate, "day");
            },
        };
    }, [alreadyExistTransaction, openingBalanceDate]);

    const handleSelectionChange = (rows: TParseTransaction[]) => {
        selectRows(rows);
    };

    const isExternalFilterPresent = useCallback((): boolean => {
        return checkOnlySelected || hideIsAlreadyExist;
    }, [checkOnlySelected, hideIsAlreadyExist]);

    const doesExternalFilterPass = useCallback(
        (node: IRowNode<TParseTransaction>): boolean => {
            if (node.data) {
                if (
                    hideIsAlreadyExist &&
                    !!Object.values(alreadyExistTransaction).find(el => el.csvLine === node?.data?.csvLine)
                ) {
                    return false;
                }
                if (!checkOnlySelected) {
                    return true;
                }
                return !!selectedRows.find(el => node?.data?.csvLine === el.csvLine);
            }

            return false;
        },
        [selectedRows, checkOnlySelected, hideIsAlreadyExist, alreadyExistTransaction]
    );

    const isRowSelectable = useCallback(
        (rowNode: IRowNode) => {
            const bookingDate = dayjs(rowNode?.data?.bookingDate);
            const isDateBefore = openingBalanceDate ? dayjs(bookingDate).isBefore(openingBalanceDate, "day") : false;
            const isSelectable =
                !Object.values(alreadyExistTransaction).find(el => el.csvLine === rowNode?.data?.csvLine) &&
                !isDateBefore;
            return rowNode.data ? isSelectable : false;
        },
        [alreadyExistTransaction, openingBalanceDate]
    );

    const handleSelectChecked = useCallback((checked: boolean) => {
        setCheckOnlySelected(checked);
        tableRef?.current?.api.onFilterChanged();
    }, []);

    const handleHideIsAlreadyExist = useCallback((checked: boolean) => {
        setHideIsAlreadyExist(checked);
        tableRef?.current?.api.onFilterChanged();
    }, []);

    return (
        <Flex vertical gap={20}>
            <ParseDataViewToolbar
                handleSelectChecked={handleSelectChecked}
                handleHideIsAlreadyExist={handleHideIsAlreadyExist}
            />
            <TransactionsTable
                ref={tableRef}
                tableKey={"parse-bank-data"}
                rowData={filteredRows}
                gridOptions={gridOptions}
                isRowSelectable={isRowSelectable}
                rowClassRules={rowClassRules}
                isExternalFilterPresent={isExternalFilterPresent}
                doesExternalFilterPass={doesExternalFilterPass}
                handleSelectionChange={handleSelectionChange}
                tableColumns={ParseTransactionTableColumns.tableColumnConfig}
                viewHeight={PARSE_TABLE_CONTAINER_HEIGHT}
            />
        </Flex>
    );
};
