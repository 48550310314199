import React, { CSSProperties, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";

interface ContainerProps {
    children: (width: number, height: number) => React.ReactNode;
    absolute?: boolean;
    style?: CSSProperties;
}

const Container = React.forwardRef<HTMLDivElement, ContainerProps>(function Container(
    { children, absolute, style, ...props },
    ref
) {
    const [width, setWidth] = useState<number>(0);
    const [height, setHeight] = useState<number>(0);

    const containerRef = useRef<HTMLDivElement>();

    useImperativeHandle(ref, () => containerRef.current, [containerRef]);

    const updateSize = useCallback(() => {
        setWidth(containerRef.current.clientWidth);
        setHeight(containerRef.current.clientHeight);
    }, [setWidth, setHeight]);

    useEffect(() => {
        updateSize();
        window.addEventListener("resize", updateSize);
        return () => {
            window.removeEventListener("resize", updateSize);
        };
    }, [updateSize]);

    const containerStyle: CSSProperties = absolute ? { width: "100%", height: "100%", position: "absolute" } : style;

    return (
        <div {...props} style={containerStyle} ref={containerRef}>
            {children(width, height)}
        </div>
    );
});

export default Container;
