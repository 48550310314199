import { GridOptions } from "ag-grid-community";
import { baseOptions } from "@app/components/shared/AgGridTable/options/baseOptions";

export const gridOptions: GridOptions = {
    ...baseOptions,

    defaultColDef: {
        enableCellChangeFlash: true,
        suppressMovable: true,
    },
    suppressRowClickSelection: false,
    rowMultiSelectWithClick: false,
    suppressCellFocus: true,
};
