import React, { FC, useContext } from "react";
import { Button, Flex } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { BanksAppControlContext } from "@banks/scripts/context";

export const BankListHeader: FC = () => {
    const { setAddBankData, openTransactionUploader } = useContext(BanksAppControlContext);

    const handleAddBank = () => {
        setAddBankData({ isModalOpen: true });
    };

    const handleUpdateBank = () => {
        openTransactionUploader();
    };

    return (
        <Flex gap={15}>
            <Button type={"primary"} icon={<PlusOutlined />} onClick={handleAddBank}>
                New Bank
            </Button>
            <Button onClick={handleUpdateBank}>Upload bank file</Button>
        </Flex>
    );
};
