import React from "react";
import { Bu, GQL, KontoNumUtils, Utils } from "@binale-tech/shared";
import { FormattedMessage } from "react-intl";

import Category from "../models/Category";
import { CategoryUtils } from "../models/utils/CategoryUtils";
import { IExtraData } from "../models/Interfaces";
import { ProductKey, ProductKeys } from "../models/Product";
import { logger } from "../infrastructure/logger";
import { BinaleError } from "../errors/errors";

export class TableProperties {
    static readonly ComputedNr = "Nr.";
    static readonly ControlLog = "Log";
    static readonly ControlAction = "Aktion";
    static readonly ComputedModul = "Modul";

    static permanentColumns() {
        return [this.ComputedNr, this.ControlAction];
    }
}

export class GenericRecordProperties {
    // Must be unique names!!!
    static readonly RecordDatum = "Datum";
    static readonly RecordPeriod = "Period";
    static readonly RecordCategoryCreditorNum = "Konto";
    static readonly RecordCategoryCreditorName = "Konto Bezeichnung";
    static readonly RecordBelegfeld1 = "Rechnung Nr.";
    static readonly RecordBrutto = "Brutto";
    static readonly RecordCurrencyOriginalAmount = "Eingabebetrag";
    static readonly RecordCurrencyRate = "Kurs";
    static readonly RecordCurrencyCode = "WKZ";
    static readonly RecordPicture = "Bild";
    static readonly RecordFalligkeit = "Fälligkeit";
    static readonly RecordReview = "Kontrolle";
    static readonly RecordContact = "Contact";

    static readonly ItemCategoryCreditorNum = "G.Konto";
    static readonly ItemCategoryCreditorName = "G.Konto Bezeichnung";
    static readonly ItemBelegfeld2 = "Belegfeld 2";
    static readonly ItemBuchungstext = "Buchungstext";
    static readonly ItemBuchungstext2 = "Notizen";
    static readonly ItemUStPerc = "USt, %";
    static readonly ItemKS = "KS";
    static readonly ItemUSt13b = "13b";

    static readonly ComputedNetto = "Netto";
    static readonly ComputedUSt = "USt";
    static readonly ComputedOffen = "offen";
    static readonly ComputedSaldo = "Saldo";
    static readonly ComputedKASaldoBrutto = "Saldo (Brutto)";
    static readonly ComputedKABruttoSoll = "Soll (Brutto)";
    static readonly ComputedKABruttoHaben = "Haben (Brutto)";
    static readonly ComputedKASoll = "Soll";
    static readonly ComputedKAHaben = "Haben";
    static readonly ComputedZA = "ZA";
    static readonly ComputedStatus = "Status";
    static readonly ComputedZahlung = "Zahlungen";
    static readonly ComputedSkonto = "Skonto";
    static readonly ComputedBu = "BU";

    static readonly ComputedSHInfo = "Info";
    static readonly ControlBinding = "Binding";
}

export interface FormLabelsOverride {
    recordCategoryCreditor?: React.ReactNode;
}

export type CategoryCreditorMode = "category" | "creditor" | "combined" | "debitor" | "resolver" | "modeRecordKontoFE";

export class CategoryCreditorModes {
    static readonly CAT: CategoryCreditorMode = "category";
    static readonly CRED: CategoryCreditorMode = "creditor";
    static readonly DEB: CategoryCreditorMode = "debitor";
    static readonly CCD: CategoryCreditorMode = "combined";
    static readonly RESOLVER: CategoryCreditorMode = "resolver";
    static readonly RecordKontoFE: CategoryCreditorMode = "modeRecordKontoFE";
}

export interface ProductConfig {
    enableRecordForm: boolean; // enabled in the main modules, disabled in the overviews
    recordAccountMode: CategoryCreditorMode; // ER - creditor; KB, Bank - resolver; FE - modeRecordKontoFE
    recordAccountResolver?: (year: number, categories: Map<number, Category>, selectedRecordGroup: string) => Category; // KB, Bank;
    itemAccountMode: CategoryCreditorMode; // KB, Bank, FE - category, ER - category, ER_A - resolver
    itemAccountResolver?: (year: number, categories: Map<number, Category>) => Category; // KB, Bank - null;
    putRecordCategoryCreditorAfterUst?: boolean; // FE
    useLastschrift?: boolean; // ER
    useFalligkeit?: boolean; // ER
    useItemBelegfeld1?: boolean; // Bank, KB, FE, Pos -> Utils.ModuleUtils.useBelegfeld1Split
    useContact?: "afterDate" | "beforeSplit" | "afterBu"; // ER, Deb - afterDate | Bank, KB, LA - beforeSplit, FE/POS - afterBu
    isRecordRechnungRequired?: boolean; // ER, Deb, LA
    labels?: FormLabelsOverride;
    modalDefaultFocusCCField?: boolean; // FE
    useSplit?: boolean; // ER->Anzahlungen, LA
    usePaymentBinding?: "beforeBu" | "afterBu"; // Bank, KB, FE. Correlates with isRecordPaymentRepresentation
    useItemAccount?: boolean; // ER->Anzahlungen
    itemBuResolver?: (formBu: Bu.Bu) => Bu.Bu; // ER->Anzahlungen / LA
    disableBu?: boolean; // LA
    itemExtraDataResolver?: (formBu: Bu.Bu) => IExtraData; // ER->Anzahlungen
    useSollHabenHints?: boolean; // FE
    disableTableSorting?: boolean; // true for Bank, KB
    usePriorityOrder?: boolean; // KB
    disableNegativeJournalSaldo?: boolean; // KB
    bulkEditFields?: string[];
}

export interface TableColumnNameOverride {
    label?: string;
    header?: string | React.ReactNode;
}

export interface ProductColumnDataProvider {
    productKey(): ProductKey;

    tableColumnOverrides(): Map<string, TableColumnNameOverride>;

    nonEditableTableColumns(): Set<string>;

    tableColumns(): string[];

    useAbsAmounts(): boolean;
}

const emptySet = new Set<string>();
const emptyMap = new Map();

export abstract class Product implements ProductColumnDataProvider {
    constructor(
        protected skr: number,
        protected company: GQL.ICompany
    ) {}
    abstract tableColumns(): string[];

    abstract getConfig(): ProductConfig;

    abstract productKey(): ProductKey;

    tableColumnOverrides(): Map<string, TableColumnNameOverride> {
        return emptyMap;
    }

    nonEditableTableColumns(): Set<string> {
        return emptySet;
    }

    useAbsAmounts(): boolean {
        return false;
    }
}

const BulkEditRecordSourceFields = Object.freeze([
    GenericRecordProperties.RecordContact,
    GenericRecordProperties.ItemBelegfeld2,
    GenericRecordProperties.RecordBelegfeld1,
    GenericRecordProperties.ItemKS,
    GenericRecordProperties.RecordCategoryCreditorNum,
    GenericRecordProperties.ItemCategoryCreditorNum,
    GenericRecordProperties.ItemUStPerc,
    GenericRecordProperties.ItemUSt13b,
    GenericRecordProperties.ItemBuchungstext,
    GenericRecordProperties.ItemBuchungstext2,
]);
const BulkEditRecordRepresentationFields = Object.freeze([
    GenericRecordProperties.RecordContact,
    GenericRecordProperties.ItemKS,
    GenericRecordProperties.ItemCategoryCreditorNum,
    GenericRecordProperties.ItemUStPerc,
    GenericRecordProperties.ItemUSt13b,
    GenericRecordProperties.ItemBuchungstext,
]);

export class ProductER extends Product {
    productKey() {
        return ProductKeys.ER;
    }

    getConfig(): ProductConfig {
        return {
            enableRecordForm: true,
            recordAccountMode: CategoryCreditorModes.CRED,
            itemAccountMode: CategoryCreditorModes.CAT,
            labels: { recordCategoryCreditor: <FormattedMessage id="app.fields.creditor" /> },
            useLastschrift: true,
            useFalligkeit: true,
            useContact: "afterDate",
            isRecordRechnungRequired: true,
            useSplit: true,
            useItemAccount: true,
            bulkEditFields: [...BulkEditRecordSourceFields],
        };
    }

    tableColumnOverrides() {
        const m = new Map();
        m.set(GenericRecordProperties.RecordCategoryCreditorNum, {
            label: "Kreditor",
            header: <FormattedMessage id="app.fields.creditor" />,
        });
        return m;
    }

    tableColumns() {
        return [
            TableProperties.ComputedNr,
            GenericRecordProperties.RecordPeriod,
            GenericRecordProperties.RecordDatum,
            GenericRecordProperties.RecordContact,
            GenericRecordProperties.RecordCategoryCreditorNum,
            GenericRecordProperties.RecordCategoryCreditorName,
            GenericRecordProperties.RecordBelegfeld1,
            GenericRecordProperties.ItemBelegfeld2,
            GenericRecordProperties.ItemCategoryCreditorNum,
            GenericRecordProperties.ItemCategoryCreditorName,
            GenericRecordProperties.RecordBrutto,
            GenericRecordProperties.RecordCurrencyCode,
            GenericRecordProperties.RecordCurrencyOriginalAmount,
            GenericRecordProperties.RecordCurrencyRate,
            GenericRecordProperties.ItemUStPerc,
            GenericRecordProperties.ComputedBu,
            GenericRecordProperties.ItemUSt13b,
            GenericRecordProperties.ComputedUSt,
            GenericRecordProperties.ComputedNetto,
            GenericRecordProperties.ComputedOffen,
            GenericRecordProperties.ComputedZA,
            GenericRecordProperties.ComputedStatus,
            GenericRecordProperties.ItemKS,
            GenericRecordProperties.RecordFalligkeit,
            GenericRecordProperties.ItemBuchungstext,
            GenericRecordProperties.ItemBuchungstext2,
            GenericRecordProperties.RecordPicture,
            TableProperties.ControlAction,
            GenericRecordProperties.RecordReview,
            TableProperties.ControlLog,
            GenericRecordProperties.ComputedSHInfo,
        ];
    }
}

export class ProductERAnzahlungen extends ProductER {
    productKey() {
        return ProductKeys.ER_A;
    }

    getConfig(): ProductConfig {
        const skr = this.skr;
        return {
            ...super.getConfig(),
            useSplit: false,
            useItemAccount: false,
            itemAccountMode: CategoryCreditorModes.RESOLVER,
            itemAccountResolver: (year: number, categories: Map<number, Category>) => {
                switch (skr) {
                    case 3:
                        return categories.get(1793);
                    case 4:
                        return categories.get(3695);
                }
                return null;
            },
            itemBuResolver: () => Bu.Bu.KU,
            itemExtraDataResolver: (bu: Bu.Bu) => ({
                bu,
            }),
        };
    }
}

abstract class ProductAuswertungFalligkeit extends ProductER {
    getConfig(): ProductConfig {
        return {
            ...super.getConfig(),
            enableRecordForm: false,
        };
    }
    tableColumns() {
        return [
            TableProperties.ComputedNr,
            GenericRecordProperties.RecordFalligkeit,
            GenericRecordProperties.RecordPeriod,
            GenericRecordProperties.RecordDatum,
            GenericRecordProperties.RecordCategoryCreditorNum,
            GenericRecordProperties.RecordCategoryCreditorName,
            GenericRecordProperties.RecordBelegfeld1,
            GenericRecordProperties.ItemBelegfeld2,
            GenericRecordProperties.ItemCategoryCreditorNum,
            GenericRecordProperties.ItemCategoryCreditorName,
            GenericRecordProperties.RecordBrutto,
            GenericRecordProperties.RecordCurrencyCode,
            GenericRecordProperties.RecordCurrencyOriginalAmount,
            GenericRecordProperties.RecordCurrencyRate,
            GenericRecordProperties.ItemUStPerc,
            GenericRecordProperties.ComputedUSt,
            GenericRecordProperties.ItemUSt13b,
            GenericRecordProperties.ComputedNetto,
            GenericRecordProperties.ComputedOffen,
            GenericRecordProperties.ComputedZA,
            GenericRecordProperties.ComputedStatus,
            GenericRecordProperties.ItemKS,
            GenericRecordProperties.ItemBuchungstext,
            GenericRecordProperties.ItemBuchungstext2,
            GenericRecordProperties.RecordPicture,
            TableProperties.ControlAction,
            TableProperties.ControlLog,
        ];
    }
}
export class ProductAuswertungFalligkeitER extends ProductAuswertungFalligkeit {
    productKey() {
        return ProductKeys.ER;
    }
}
export class ProductAuswertungFalligkeitERAnz extends ProductAuswertungFalligkeit {
    productKey() {
        return ProductKeys.ER_A;
    }
}
const OposAvisColumns = [
    TableProperties.ComputedNr,
    GenericRecordProperties.RecordPeriod,
    GenericRecordProperties.RecordDatum,
    GenericRecordProperties.RecordCategoryCreditorNum,
    GenericRecordProperties.RecordCategoryCreditorName,
    GenericRecordProperties.RecordBelegfeld1,
    GenericRecordProperties.ItemBelegfeld2,
    GenericRecordProperties.ItemCategoryCreditorNum,
    GenericRecordProperties.ItemCategoryCreditorName,
    GenericRecordProperties.RecordBrutto,
    GenericRecordProperties.RecordCurrencyCode,
    GenericRecordProperties.RecordCurrencyOriginalAmount,
    GenericRecordProperties.RecordCurrencyRate,
    GenericRecordProperties.ItemUStPerc,
    GenericRecordProperties.ComputedBu,
    GenericRecordProperties.ItemUSt13b,
    GenericRecordProperties.ComputedUSt,
    GenericRecordProperties.ComputedNetto,
    GenericRecordProperties.ComputedOffen,
    GenericRecordProperties.ComputedZA,
    GenericRecordProperties.ComputedStatus,
    GenericRecordProperties.ItemKS,
    GenericRecordProperties.RecordFalligkeit,
    GenericRecordProperties.ItemBuchungstext,
    GenericRecordProperties.ItemBuchungstext2,
    GenericRecordProperties.RecordPicture,
    GenericRecordProperties.RecordReview,
    TableProperties.ControlLog,
    GenericRecordProperties.ComputedSHInfo,
];

class ProductAuswertungOposAvis extends ProductER {
    getConfig(): ProductConfig {
        return {
            ...super.getConfig(),
            enableRecordForm: false,
        };
    }
    tableColumns() {
        return OposAvisColumns;
    }
}
export class ProductAuswertungOposAvisER extends ProductAuswertungOposAvis {
    productKey() {
        return ProductKeys.ER;
    }
}
export class ProductAuswertungOposAvisERAnz extends ProductAuswertungOposAvis {
    productKey() {
        return ProductKeys.ER_A;
    }
}

export class ProductDeb extends Product {
    productKey() {
        return ProductKeys.Deb;
    }

    getConfig(): ProductConfig {
        return {
            enableRecordForm: true,
            recordAccountMode: CategoryCreditorModes.DEB,
            itemAccountMode: CategoryCreditorModes.CAT,
            useLastschrift: true,
            useFalligkeit: true,
            isRecordRechnungRequired: true,
            labels: { recordCategoryCreditor: <FormattedMessage id="app.fields.debitor" /> },
            useSplit: true,
            useContact: "afterDate",
            useItemAccount: true,
            bulkEditFields: [...BulkEditRecordSourceFields],
        };
    }

    tableColumnOverrides() {
        const m = new Map();
        m.set(GenericRecordProperties.RecordCategoryCreditorNum, {
            label: "Debitor",
            header: <FormattedMessage id="app.fields.debitor" />,
        });
        m.set(GenericRecordProperties.RecordCategoryCreditorName, { label: "Debitor Bezeichnung" });
        return m;
    }

    tableColumns() {
        return [
            TableProperties.ComputedNr,
            GenericRecordProperties.RecordPeriod,
            GenericRecordProperties.RecordDatum,
            GenericRecordProperties.RecordContact,
            GenericRecordProperties.RecordCategoryCreditorNum,
            GenericRecordProperties.RecordCategoryCreditorName,
            GenericRecordProperties.RecordBelegfeld1,
            GenericRecordProperties.ItemBelegfeld2,
            GenericRecordProperties.ItemCategoryCreditorNum,
            GenericRecordProperties.ItemCategoryCreditorName,
            GenericRecordProperties.RecordBrutto,
            GenericRecordProperties.RecordCurrencyCode,
            GenericRecordProperties.RecordCurrencyOriginalAmount,
            GenericRecordProperties.RecordCurrencyRate,
            GenericRecordProperties.ItemUStPerc,
            GenericRecordProperties.ComputedBu,
            GenericRecordProperties.ItemUSt13b,
            GenericRecordProperties.ComputedUSt,
            GenericRecordProperties.ComputedNetto,
            GenericRecordProperties.ComputedOffen,
            GenericRecordProperties.ComputedZA,
            GenericRecordProperties.ComputedStatus,
            GenericRecordProperties.ItemKS,
            GenericRecordProperties.RecordFalligkeit,
            GenericRecordProperties.ItemBuchungstext,
            GenericRecordProperties.ItemBuchungstext2,
            GenericRecordProperties.RecordPicture,
            TableProperties.ControlAction,
            GenericRecordProperties.RecordReview,
            TableProperties.ControlLog,
            GenericRecordProperties.ComputedSHInfo,
        ];
    }
}

export class ProductAuswertungOposAvisDeb extends ProductDeb {
    getConfig(): ProductConfig {
        return {
            ...super.getConfig(),
            enableRecordForm: false,
        };
    }
    tableColumns() {
        return OposAvisColumns;
    }
}

export class ProductFE extends Product {
    productKey() {
        return GQL.IProductKey.Fe;
    }
    static getSHHint(brutto: number) {
        if (brutto > 0) {
            return {
                konto: <code>S</code>,
                gkonto: <code>H</code>,
            };
        }
        if (brutto < 0) {
            return {
                konto: <code>H</code>,
                gkonto: <code>S</code>,
            };
        }
        return {
            konto: null,
            gkonto: null,
        };
    }

    useAbsAmounts(): boolean {
        return true;
    }

    getConfig(): ProductConfig {
        return {
            enableRecordForm: true,
            recordAccountMode: CategoryCreditorModes.RecordKontoFE,
            itemAccountMode: CategoryCreditorModes.CAT,
            putRecordCategoryCreditorAfterUst: true,
            modalDefaultFocusCCField: true,
            useSplit: true,
            useContact: "afterBu",
            useItemAccount: true,
            useSollHabenHints: true,
            usePaymentBinding: "afterBu",
            useItemBelegfeld1: Utils.ModuleUtils.useBelegfeld1Split(this.productKey()),
            bulkEditFields: [...BulkEditRecordRepresentationFields],
        };
    }

    tableColumnOverrides() {
        const m = new Map();

        return m;
    }

    tableColumns() {
        return [
            TableProperties.ComputedNr,
            GenericRecordProperties.RecordPeriod,
            GenericRecordProperties.RecordDatum,
            GenericRecordProperties.RecordBrutto,
            GenericRecordProperties.RecordCurrencyCode,
            GenericRecordProperties.RecordCurrencyOriginalAmount,
            GenericRecordProperties.RecordCurrencyRate,
            GenericRecordProperties.ItemUStPerc,
            GenericRecordProperties.ComputedBu,
            GenericRecordProperties.ItemUSt13b,
            GenericRecordProperties.ComputedUSt,
            GenericRecordProperties.ComputedNetto,
            GenericRecordProperties.ItemCategoryCreditorNum,
            GenericRecordProperties.ItemCategoryCreditorName,
            GenericRecordProperties.RecordBelegfeld1,
            GenericRecordProperties.ItemBelegfeld2,
            GenericRecordProperties.RecordContact,
            GenericRecordProperties.RecordCategoryCreditorNum,
            GenericRecordProperties.RecordCategoryCreditorName,
            GenericRecordProperties.ItemKS,
            GenericRecordProperties.ItemBuchungstext,
            GenericRecordProperties.ItemBuchungstext2,
            GenericRecordProperties.RecordPicture,
            GenericRecordProperties.ControlBinding,
            TableProperties.ControlAction,
            GenericRecordProperties.RecordReview,
            TableProperties.ControlLog,
            GenericRecordProperties.ComputedSHInfo,
        ];
    }
}

export class ProductPOS extends ProductFE {
    productKey() {
        return GQL.IProductKey.Pos;
    }

    getConfig(): ProductConfig {
        const config = super.getConfig();
        return {
            ...config,
            usePaymentBinding: null,
        };
    }
}

export class ProductLA extends Product {
    productKey() {
        return ProductKeys.LA;
    }

    getConfig(): ProductConfig {
        const skr = this.skr;
        return {
            enableRecordForm: true,
            putRecordCategoryCreditorAfterUst: true,
            itemAccountMode: CategoryCreditorModes.CCD,
            recordAccountMode: CategoryCreditorModes.RESOLVER,
            useContact: "beforeSplit",
            recordAccountResolver: (year: number, categories: Map<number, Category>) => {
                switch (skr) {
                    case 3:
                        return categories.get(KontoNumUtils.LA_3);
                    case 4:
                        return categories.get(KontoNumUtils.LA_4);
                }
                return null;
            },
            useSplit: false,
            itemBuResolver: () => Bu.Bu.KU,
            // disableBu: true,
            useItemAccount: true,
            isRecordRechnungRequired: true,
            useSollHabenHints: true,
        };
    }

    tableColumnOverrides() {
        const m = new Map();
        return m;
    }

    tableColumns() {
        return [
            TableProperties.ComputedNr,
            GenericRecordProperties.RecordPeriod,
            GenericRecordProperties.RecordDatum,
            GenericRecordProperties.ItemCategoryCreditorNum,
            GenericRecordProperties.ItemCategoryCreditorName,
            GenericRecordProperties.RecordBelegfeld1,
            GenericRecordProperties.ItemBelegfeld2,
            GenericRecordProperties.RecordCategoryCreditorNum,
            GenericRecordProperties.RecordCategoryCreditorName,
            GenericRecordProperties.RecordBrutto,
            GenericRecordProperties.RecordCurrencyCode,
            GenericRecordProperties.RecordCurrencyOriginalAmount,
            GenericRecordProperties.RecordCurrencyRate,
            GenericRecordProperties.ItemUStPerc,
            GenericRecordProperties.ComputedUSt,
            GenericRecordProperties.ComputedNetto,
            GenericRecordProperties.ItemKS,
            GenericRecordProperties.ItemBuchungstext,
            GenericRecordProperties.ItemBuchungstext2,
            GenericRecordProperties.RecordPicture,
            TableProperties.ControlAction,
            GenericRecordProperties.RecordReview,
            TableProperties.ControlLog,
            GenericRecordProperties.ComputedSHInfo,
        ];
    }
}

export class ProductKB extends Product {
    productKey() {
        return GQL.IProductKey.Kb;
    }

    getConfig(): ProductConfig {
        const kbs = this.company.kasseList;
        return {
            enableRecordForm: true,
            useItemBelegfeld1: Utils.ModuleUtils.useBelegfeld1Split(this.productKey()),
            itemAccountMode: CategoryCreditorModes.CCD,
            recordAccountMode: CategoryCreditorModes.RESOLVER,
            recordAccountResolver: (year: number, categories: Map<number, Category>, selectedRecordGroup: string) => {
                const kb = kbs.find(v => v.id === selectedRecordGroup && v.year === year);
                if (!kb) {
                    throw new BinaleError(
                        "no data for selected kb: " + JSON.stringify([selectedRecordGroup, kbs]),
                        "app.error.message.kb_not_available"
                    );
                }
                if (!kb.accountNum) {
                    logger.error("kassenbush should be updated");
                    return null;
                }
                return CategoryUtils.enrichCategory({ num: String(kb.accountNum), name: kb.name }, categories);
            },
            useSplit: true,
            useContact: "beforeSplit",
            usePaymentBinding: "beforeBu",
            useItemAccount: true,
            disableTableSorting: true,
            usePriorityOrder: true,
            disableNegativeJournalSaldo: true,
            bulkEditFields: [...BulkEditRecordRepresentationFields],
        };
    }

    tableColumnOverrides() {
        const m = new Map();
        return m;
    }

    tableColumns() {
        return [
            TableProperties.ComputedNr,
            GenericRecordProperties.RecordPeriod,
            GenericRecordProperties.RecordDatum,
            GenericRecordProperties.RecordBrutto,
            GenericRecordProperties.RecordCurrencyCode,
            GenericRecordProperties.RecordCurrencyOriginalAmount,
            GenericRecordProperties.RecordCurrencyRate,
            GenericRecordProperties.ItemUStPerc,
            GenericRecordProperties.ComputedBu,
            GenericRecordProperties.ItemUSt13b,
            GenericRecordProperties.ComputedUSt,
            GenericRecordProperties.ComputedNetto,
            GenericRecordProperties.ComputedSaldo,
            GenericRecordProperties.RecordBelegfeld1,
            GenericRecordProperties.ItemBelegfeld2,
            GenericRecordProperties.RecordContact,
            GenericRecordProperties.ItemCategoryCreditorNum,
            GenericRecordProperties.ItemCategoryCreditorName,
            GenericRecordProperties.ItemKS,
            GenericRecordProperties.ItemBuchungstext,
            GenericRecordProperties.ItemBuchungstext2,
            GenericRecordProperties.RecordPicture,
            GenericRecordProperties.ControlBinding,
            TableProperties.ControlAction,
            GenericRecordProperties.RecordReview,
            TableProperties.ControlLog,
            GenericRecordProperties.ComputedSHInfo,
        ];
    }
}

export class ProductBank extends Product {
    productKey() {
        return GQL.IProductKey.Bank;
    }

    getConfig(): ProductConfig {
        const banks = this.company.bankList;
        return {
            enableRecordForm: true,
            useItemBelegfeld1: Utils.ModuleUtils.useBelegfeld1Split(this.productKey()),
            itemAccountMode: CategoryCreditorModes.CCD,
            recordAccountMode: CategoryCreditorModes.RESOLVER,
            recordAccountResolver: (year: number, categories: Map<number, Category>, selectedRecordGroup: string) => {
                const bank = banks.find(v => v.id === selectedRecordGroup && v.year === year);
                if (!bank) {
                    throw new BinaleError(
                        "no data for selected bank: " + JSON.stringify([selectedRecordGroup, banks]),
                        "app.error.message.bank_not_available"
                    );
                }
                if (!bank.accountNum) {
                    logger.error("bank should be updated");
                    return null;
                }
                return CategoryUtils.enrichCategory({ num: String(bank.accountNum), name: bank.name }, categories);
            },
            useSplit: true,
            useContact: "beforeSplit",
            usePaymentBinding: "beforeBu",
            useItemAccount: true,
            disableTableSorting: true,
            bulkEditFields: [...BulkEditRecordRepresentationFields],
        };
    }

    tableColumnOverrides() {
        const m = new Map();
        return m;
    }

    tableColumns() {
        return [
            TableProperties.ComputedNr,
            GenericRecordProperties.RecordPeriod,
            GenericRecordProperties.RecordDatum,
            GenericRecordProperties.RecordBrutto,
            GenericRecordProperties.RecordCurrencyCode,
            GenericRecordProperties.RecordCurrencyOriginalAmount,
            GenericRecordProperties.RecordCurrencyRate,
            GenericRecordProperties.ItemUStPerc,
            GenericRecordProperties.ComputedBu,
            GenericRecordProperties.ItemUSt13b,
            GenericRecordProperties.ComputedUSt,
            GenericRecordProperties.ComputedNetto,
            GenericRecordProperties.ComputedSaldo,
            GenericRecordProperties.RecordBelegfeld1,
            GenericRecordProperties.ItemBelegfeld2,
            GenericRecordProperties.RecordContact,
            GenericRecordProperties.ItemCategoryCreditorNum,
            GenericRecordProperties.ItemCategoryCreditorName,
            GenericRecordProperties.ItemKS,
            GenericRecordProperties.ItemBuchungstext,
            GenericRecordProperties.ItemBuchungstext2,
            GenericRecordProperties.RecordPicture,
            GenericRecordProperties.ControlBinding,
            TableProperties.ControlAction,
            GenericRecordProperties.RecordReview,
            TableProperties.ControlLog,
            GenericRecordProperties.ComputedSHInfo,
        ];
    }
}

export abstract class ProductCommonAnsicht extends Product {
    productKey() {
        return ProductKeys.AccountingCommon;
    }

    getConfig(): ProductConfig {
        return {
            enableRecordForm: false,
            recordAccountMode: CategoryCreditorModes.CCD,
            itemAccountMode: CategoryCreditorModes.CCD,
            useSplit: true,
            useItemAccount: true,
        };
    }

    tableColumnOverrides() {
        const m = new Map();
        return m;
    }
}

export class ProductCommonKontenAnsicht extends ProductCommonAnsicht {
    tableColumns() {
        return [
            TableProperties.ComputedNr,
            GenericRecordProperties.RecordPeriod,
            GenericRecordProperties.RecordDatum,
            GenericRecordProperties.RecordContact,
            GenericRecordProperties.RecordCategoryCreditorNum,
            GenericRecordProperties.RecordCategoryCreditorName,

            GenericRecordProperties.ItemUStPerc,
            GenericRecordProperties.ComputedBu,
            GenericRecordProperties.ItemUSt13b,
            GenericRecordProperties.ComputedUSt,

            GenericRecordProperties.RecordBelegfeld1,
            GenericRecordProperties.ItemBelegfeld2,

            GenericRecordProperties.ComputedKABruttoSoll,
            GenericRecordProperties.ComputedKABruttoHaben,
            GenericRecordProperties.ComputedKASaldoBrutto,
            GenericRecordProperties.ComputedNetto,

            GenericRecordProperties.ComputedKASoll,
            GenericRecordProperties.ComputedKAHaben,
            GenericRecordProperties.ComputedSaldo,

            GenericRecordProperties.RecordCurrencyCode,
            GenericRecordProperties.RecordCurrencyOriginalAmount,
            GenericRecordProperties.RecordCurrencyRate,

            GenericRecordProperties.ComputedZA,
            GenericRecordProperties.ComputedStatus,
            GenericRecordProperties.ItemKS,
            GenericRecordProperties.ItemBuchungstext,
            GenericRecordProperties.ItemBuchungstext2,
            GenericRecordProperties.RecordReview,
            GenericRecordProperties.RecordPicture,
            GenericRecordProperties.ControlBinding,
            TableProperties.ComputedModul,
            GenericRecordProperties.ComputedSHInfo,
        ];
    }

    nonEditableTableColumns() {
        return new Set([
            GenericRecordProperties.ComputedKABruttoSoll,
            GenericRecordProperties.ComputedKABruttoHaben,
            GenericRecordProperties.ComputedKASaldoBrutto,

            GenericRecordProperties.ComputedNetto,
            GenericRecordProperties.ComputedKASoll,
            GenericRecordProperties.ComputedKAHaben,
            GenericRecordProperties.ComputedSaldo,

            GenericRecordProperties.ItemUStPerc,
            GenericRecordProperties.ItemUSt13b,
            GenericRecordProperties.ComputedUSt,
            GenericRecordProperties.ComputedSHInfo,
        ]);
    }
}

export class ProductCommonAuswertung extends ProductCommonAnsicht {
    tableColumns() {
        return [
            TableProperties.ComputedNr,
            GenericRecordProperties.RecordPeriod,
            GenericRecordProperties.RecordDatum,
            GenericRecordProperties.RecordContact,
            GenericRecordProperties.RecordCategoryCreditorNum,
            GenericRecordProperties.RecordCategoryCreditorName,
            GenericRecordProperties.RecordBelegfeld1,
            GenericRecordProperties.ItemBelegfeld2,
            GenericRecordProperties.ItemCategoryCreditorNum,
            GenericRecordProperties.ItemCategoryCreditorName,
            GenericRecordProperties.RecordBrutto,
            GenericRecordProperties.RecordCurrencyCode,
            GenericRecordProperties.RecordCurrencyOriginalAmount,
            GenericRecordProperties.RecordCurrencyRate,
            GenericRecordProperties.ItemUStPerc,
            GenericRecordProperties.ItemUSt13b,
            GenericRecordProperties.ItemKS,
            GenericRecordProperties.RecordFalligkeit,
            GenericRecordProperties.ItemBuchungstext,
            GenericRecordProperties.ItemBuchungstext2,
            GenericRecordProperties.RecordPicture,
            // TableProperties.ControlLog,
            TableProperties.ComputedModul,
            GenericRecordProperties.ComputedSHInfo,
        ];
    }
}
