import React, { FC } from "react";
import InvoicesApp from "@inv/InvoicesApp";
import { InvoicesContextProvider } from "@inv/scripts/context";

export const Invoices: FC = () => {
    return (
        <InvoicesContextProvider>
            <InvoicesApp />;
        </InvoicesContextProvider>
    );
};
