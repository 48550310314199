import React, { useMemo } from "react";
import { GQL, Utils } from "@binale-tech/shared";
import { GenericRecord } from "../../models/GenericRecord";
import { GenericRecordUtils } from "../../models/utils/GenericRecordUtils";
import { PaymentPrototype } from "../../models/Payment";
import { UserContext } from "../UserProvider";
import {
    genericRecordDocuments,
    genericRecordSave,
    genericRecordsAvis,
    genericRecordsCancel,
    genericRecordsColor,
    genericRecordsConfirm,
    genericRecordsDelete,
    genericRecordsImport,
    genericRecordsJournal,
    genericRecordsReview,
    genericRecordsSetPriorities,
} from "../mutations/genericRecordMutations.graphql";
import { logger } from "../../infrastructure/logger";
import { useGqlMutator } from "../../graphql/useGqlMutator";

export const useRecordsSaver = () => {
    const { selectedCompany: companyId } = React.useContext(UserContext);
    const mutator = useGqlMutator();

    return useMemo(() => {
        const saveRecord = async (
            record: GenericRecord,
            payment: PaymentPrototype,
            product: GQL.IProductKey,
            opts?: { groupId?: string; isTemplate?: boolean }
        ) => {
            logger.log("save record: ", { record, payment, companyId, product });
            const getPayment = (): GQL.IPaymentProtoInput => {
                if (!payment) {
                    return null;
                }
                if (!Utils.PaymentUtils.isProductPaymentRepresentation(product)) {
                    throw new Error(
                        `only payment representation record save must pass 'payment' on save, received it in ${product}`
                    );
                }
                return {
                    sourceRecordKey: payment.sourceRecordKey,
                    strategy: GQL.IPaymentProtoStrategy.Representation,
                    strategyRepresentation: {
                        discountAmount: payment.skontoBetrag,
                        representationRecordKey: record.key,
                    },
                };
            };
            return mutator
                .mutate<"genericRecordSave", GQL.IGenericRecordSaveInput>({
                    mutation: genericRecordSave,
                    input: {
                        companyId,
                        product,
                        record: GenericRecordUtils.mapToGQL(record),
                        payment: getPayment(),
                        groupId: opts?.groupId,
                        isTemplate: opts?.isTemplate,
                    },
                })
                .then(res => res.genericRecordSave);
        };

        return {
            save: saveRecord,
            saveDocuments: async (
                id: string,
                product: GQL.IProductKey,
                documents: GQL.IRecordDocumentInput[],
                groupId?: string
            ) => {
                return mutator
                    .mutate<"genericRecordDocuments", GQL.IGenericRecordDocumentsInput>({
                        mutation: genericRecordDocuments,
                        input: {
                            companyId,
                            product,
                            id,
                            documents,
                        },
                    })
                    .then(res => res.genericRecordDocuments);
            },
            deleteByKeys: async (
                ids: string[],
                product: GQL.IProductKey,
                opts?: { groupId?: string; isTemplate?: boolean }
            ) => {
                logger.log("delete record", { ids, companyId, product });
                return mutator
                    .mutate<"genericRecordsDelete", GQL.IGenericRecordsIdInput>({
                        mutation: genericRecordsDelete,
                        input: {
                            companyId,
                            product,
                            ids,
                            groupId: opts?.groupId,
                            isTemplate: opts?.isTemplate,
                        },
                    })
                    .then(res => res.genericRecordsDelete);
            },
            cancelByKeys: async (ids: string[], product: GQL.IProductKey, groupId?: string) => {
                logger.log("cancel record", { ids, companyId, product });
                return mutator
                    .mutate<"genericRecordsCancel", GQL.IGenericRecordsIdInput>({
                        mutation: genericRecordsCancel,
                        input: {
                            companyId,
                            product,
                            ids,
                            groupId,
                        },
                    })
                    .then(res => res.genericRecordsCancel);
            },
            confirm: async (ids: string[], product: GQL.IProductKey, groupId?: string) => {
                logger.log("confirm record", { ids, companyId, product });
                await mutator.mutate<"genericRecordsConfirm", GQL.IGenericRecordsIdInput>({
                    mutation: genericRecordsConfirm,
                    input: {
                        companyId,
                        product,
                        ids,
                        groupId,
                    },
                });
            },
            journal: async (ids: string[], product: GQL.IProductKey, groupId?: string) => {
                logger.log("journal record", { ids, companyId, product });
                await mutator.mutate<"genericRecordsJournal", GQL.IGenericRecordsIdInput>({
                    mutation: genericRecordsJournal,
                    input: {
                        companyId,
                        product,
                        ids,
                        groupId,
                    },
                });
            },
            avis: async (ids: string[], product: GQL.IProductKey, avis: boolean, groupId?: string) => {
                logger.log("avis record", { ids, companyId, product });
                await mutator.mutate<"genericRecordsAvis", GQL.IGenericRecordsAvisInput>({
                    mutation: genericRecordsAvis,
                    input: {
                        avis,
                        companyId,
                        product,
                        ids,
                        groupId,
                    },
                });
            },
            color: async (ids: string[], product: GQL.IProductKey, color: string | null, groupId?: string) => {
                logger.log("color record", { ids, companyId, product, color });
                await mutator.mutate<"genericRecordsColor", GQL.IGenericRecordsColorInput>({
                    mutation: genericRecordsColor,
                    input: {
                        color,
                        companyId,
                        product,
                        ids,
                        groupId,
                    },
                });
            },
            review: async (ids: string[], product: GQL.IProductKey, review: string | null, groupId?: string) => {
                logger.log("review record", { ids, companyId, product, review });
                await mutator.mutate<"genericRecordsReview", GQL.IGenericRecordsReviewInput>({
                    mutation: genericRecordsReview,
                    input: {
                        review,
                        companyId,
                        product,
                        ids,
                        groupId,
                    },
                });
            },
            setPriorities: async (priorities: Record<string, number>, product: GQL.IProductKey, groupId: string) => {
                logger.log("setPriorities", { priorities, companyId, product, groupId });
                await mutator.mutate<"genericRecordsSetPriorities", GQL.IGenericRecordsSetPrioritiesInput>({
                    mutation: genericRecordsSetPriorities,
                    input: {
                        companyId,
                        product,
                        groupId,
                        ids: Object.keys(priorities),
                        priorities: Object.values(priorities),
                    },
                });
            },
            importMany: async (
                records: GenericRecord[],
                product: GQL.IProductKey,
                draft: boolean,
                groupId?: string
            ) => {
                records = records.filter(Boolean);
                if (records.length === 0) {
                    return;
                }
                await mutator.mutate<"genericRecordsImport", GQL.IGenericRecordsImportInput>({
                    mutation: genericRecordsImport,
                    input: {
                        companyId,
                        product,
                        groupId,
                        draft,
                        records: records.map(GenericRecordUtils.mapToGQL),
                    },
                });
            },
        };
    }, [companyId, mutator]);
};
