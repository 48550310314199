import React, { type CSSProperties, type FC, useContext } from "react";
import { Badge, Popover } from "antd";
import { DocumentsApi } from "@dms/scripts/DocumentsApi/DocumentsApi";
import { LinkOutlined } from "@ant-design/icons";
import { RecordDataTable } from "./RecordDataTable";
import { blue, green } from "@ant-design/colors";
import { DmsDataContext } from "@dms/types/ContextTypes";

type TProps = {
    url: string;
    position: "table" | "grid";
};

export const RecordDataPopover: FC<TProps> = ({ url, position }) => {
    const { dmsRecordsData } = useContext(DmsDataContext);
    const formattedUrl = DocumentsApi.urlFormatter(url);
    if (!dmsRecordsData.recordsAssetsSet.has(DocumentsApi.urlFormatter(url))) {
        return null;
    }
    const data = dmsRecordsData.recordSnapshots[formattedUrl] ?? [];

    const style: Record<TProps["position"], CSSProperties> = {
        table: {
            color: green[6],
            fontSize: "1.15rem",
        },
        grid: {
            marginLeft: "10px",
            color: green[6],
            fontWeight: 600,
        },
    };

    const switchElement = (position: TProps["position"]) => {
        switch (position) {
            case "table":
                return (
                    <Badge
                        title={""}
                        count={data?.length}
                        size={"small"}
                        overflowCount={99}
                        offset={[12, -5]}
                        color={blue.primary}
                    >
                        <LinkOutlined style={{ cursor: "pointer" }} />
                    </Badge>
                );
            default:
                return <LinkOutlined style={{ cursor: "pointer" }} />;
        }
    };

    return (
        <Popover
            placement="topLeft"
            arrow={{ pointAtCenter: true }}
            content={<RecordDataTable url={formattedUrl} />}
            style={{ width: 400 }}
        >
            <div style={style[position]}>{switchElement(position)}</div>
        </Popover>
    );
};
