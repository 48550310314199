import React from "react";
import cn from "classnames";
import { Base } from "@binale-tech/shared";
import { FieldLabel, FieldLabelProps } from "@binale-tech/ui-components";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";

import { AppRoutes } from "scripts/routing/routeConstants";
import { Category, CCDCombinedType, Creditor, Debitor, Tag } from "scripts/models";
import {
    CategoryCombobox,
    CombinedCombobox,
    CreditorCombobox,
    DebitorCombobox,
    ExtNumComboboxProps,
    TagCombobox,
} from "appearance/components/shared/form/baseComponents";
import "./ComboboxBlocks.css";

type FormComboboxType<T extends Base.IExtNum> = ExtNumComboboxProps<T> & {
    labelProps?: FieldLabelProps;
    showHint?: boolean;
};

const isValue = (v: CCDCombinedType) => !!v && v.num && v.num.length > 0;
const Hint = React.memo<{ value?: CCDCombinedType }>(function Hint({ value }) {
    return isValue(value) ? <span>{value.name}</span> : <FormattedMessage id="app.components.not_selected" />;
});

export const TagComboboxBlock: React.FC<FormComboboxType<Tag>> = ({ labelProps, showHint, ...props }) => (
    <FieldLabel
        {...labelProps}
        label={labelProps.label === undefined ? <FormattedMessage id="app.titles.tag" /> : labelProps.label}
        className={cn("TagComboboxBlock NumNameComboboxBlock", labelProps?.className)}
        style={{ width: 150, ...labelProps?.style }}
        labelAddon={
            <Link to={AppRoutes.manageAccountTag} tabIndex={-1} target="_blank">
                <PlusOutlined />
            </Link>
        }
        extra={showHint ? <Hint value={props.value} /> : null}
    >
        <TagCombobox dropdownStyle={{ maxWidth: 325 }} {...props} />
    </FieldLabel>
);

export const CreditorComboboxBlock: React.FC<FormComboboxType<Creditor>> = ({ labelProps, showHint, ...props }) => {
    return (
        <FieldLabel
            {...labelProps}
            label={labelProps.label === undefined ? <FormattedMessage id="app.fields.creditor" /> : labelProps.label}
            className={cn("CreditorComboboxBlock NumNameComboboxBlock", labelProps?.className)}
            labelAddon={
                <Link to={AppRoutes.manageAccountCreditors} tabIndex={-1} target="_blank">
                    <PlusOutlined />
                </Link>
            }
            extra={showHint ? <Hint value={props.value} /> : null}
        >
            <CreditorCombobox dropdownStyle={{ maxWidth: 325 }} {...props} />
        </FieldLabel>
    );
};

export const DebitorComboboxBlock: React.FC<FormComboboxType<Debitor>> = ({ labelProps, showHint, ...props }) => {
    return (
        <FieldLabel
            {...labelProps}
            label={labelProps.label === undefined ? <FormattedMessage id="app.fields.debitor" /> : labelProps.label}
            className={cn("CreditorComboboxBlock NumNameComboboxBlock", labelProps?.className)}
            labelAddon={
                <Link to={AppRoutes.manageAccountDebitor} tabIndex={-1} target="_blank">
                    <PlusOutlined />
                </Link>
            }
            extra={showHint ? <Hint value={props.value} /> : null}
        >
            <DebitorCombobox dropdownStyle={{ maxWidth: 325 }} {...props} />
        </FieldLabel>
    );
};

export const CategoryComboboxBlock: React.FC<FormComboboxType<Category> & { disableAutoCategories?: boolean }> = ({
    labelProps,
    showHint,
    ...props
}) => {
    return (
        <FieldLabel
            {...labelProps}
            label={labelProps.label === undefined ? <FormattedMessage id="app.titles.category" /> : labelProps.label}
            labelAddon={
                <Link to={AppRoutes.manageAccountCategories} tabIndex={-1} target="_blank">
                    <PlusOutlined type="plus" />
                </Link>
            }
            className={cn("NumNameComboboxBlock", labelProps?.className)}
            extra={showHint ? <Hint value={props.value} /> : null}
        >
            <CategoryCombobox dropdownStyle={{ maxWidth: 325 }} {...props} />
        </FieldLabel>
    );
};

type CombinedComboboxType = React.FC<
    React.ComponentProps<typeof CombinedCombobox> & {
        labelProps?: FieldLabelProps;
        showHint?: boolean;
    }
>;

export const CombinedComboboxBlock: CombinedComboboxType = ({ labelProps, showHint, ...props }) => {
    return (
        <FieldLabel
            label={<FormattedMessage id="app.fields.konto" />}
            {...labelProps}
            className={cn("NumNameComboboxBlock", labelProps?.className)}
            extra={showHint ? <Hint value={props.value} /> : null}
        >
            <CombinedCombobox dropdownStyle={{ maxWidth: 325 }} {...props} />
        </FieldLabel>
    );
};
