import "pdfjs-dist/build/pdf.worker.entry";

import { GQL } from "@binale-tech/shared";
import { UploadChangeParam } from "antd/es/upload";
import { saveAs } from "file-saver";
import { deleteObject, getDownloadURL, getMetadata, ref as refStorage, uploadBytesResumable } from "firebase/storage";
import JSZip from "jszip";
import * as pdfjs from "pdfjs-dist";
import { Dispatch, SetStateAction } from "react";
import { useGqlMutator } from "../../../scripts/graphql/useGqlMutator";
import { storage } from "../../../scripts/api/firebase/firebase";
import {
    documentCreate,
    documentsDelete,
    documentsTypeUpdate,
    documentsUpdate,
} from "../../../scripts/context/mutations/documentMutations.graphql";
import { logger } from "../../../scripts/infrastructure/logger";
import { PdfUtils } from "@dms/scripts/utils/PdfUtils";
import { DmsDefaultSubType, DmsType, IDocumentEnriched, IRemoverFile, IUploaderDocument } from "@dms/types";
import { convertDocumentToInput } from "@dms/scripts/helpers/convertDocumentToInput";
import { ALL_DOCUMENTS } from "@dms/configs/constants";

export class DocumentsApi {
    private static readonly maxFileSize = 20971520;
    private static readonly NS = "files";
    private static readonly fileFolderName = "pdf";
    private static readonly previewFolderName = "preview";

    static createDocument = async (arg: {
        fileData: (UploadChangeParam<any> | { file: File }) & { hash: string; isUploaded: boolean };
        companyData: GQL.ICompany;
        mutator: ReturnType<typeof useGqlMutator>;
        setFileStatus: (
            arg: (prev: {
                progress: number;
                isUploaded: boolean;
                error: false | string;
            }) => { progress: number; isUploaded: boolean; error: false | string }
        ) => void;
        documentType: { type: DmsType | typeof ALL_DOCUMENTS; subType?: string };
    }) => {
        if (typeof window !== "undefined" && "Worker" in window) {
            if (!pdfjs.GlobalWorkerOptions.workerSrc) {
                pdfjs.GlobalWorkerOptions.workerSrc = (window as any).pdfjsWorker;
            }
        }

        const { fileData, companyData, mutator, setFileStatus, documentType } = arg;

        const uploadedFile: any = await this.uploadFile({
            fileData,
            companyData,
            mutator,
            setFileStatus,
        }).catch(err => {
            setFileStatus(prev => {
                return {
                    ...prev,
                    error: err.message,
                    isUploaded: false,
                };
            });
            return Promise.reject(err);
        });

        if (uploadedFile == null) {
            return null;
        }

        const { type, subType } = documentType;

        uploadedFile.type = type;
        uploadedFile.subType = subType;

        await mutator.mutate({
            mutation: documentCreate,
            input: uploadedFile,
            hideMessages: true,
        });
        setFileStatus(prev => {
            return {
                ...prev,
                error: false,
                isUploaded: true,
            };
        });
        return { fileRes: { ...fileData, isUploaded: true }, url: uploadedFile.fileUrl };
    };

    static uploadFile = async (arg: {
        fileData: (UploadChangeParam<any> | { file: File }) & { hash: string; isUploaded: boolean };
        companyData: GQL.ICompany;
        mutator: ReturnType<typeof useGqlMutator>;
        setFileStatus: (
            arg: (prev: {
                progress: number;
                isUploaded: boolean;
                error: false | string;
            }) => { progress: number; isUploaded: boolean; error: false | string }
        ) => void;
    }) => {
        const { fileData, companyData, setFileStatus } = arg;
        return new Promise((resolve, reject) => {
            const { file, hash } = fileData;

            if (file.size > this.maxFileSize) {
                setFileStatus(prev => {
                    return {
                        ...prev,
                        error: `Size limit ${this.maxFileSize} bytes`,
                    };
                });
                reject(new Error(`Size limit ${this.maxFileSize} bytes`));
                return;
            }

            const metaData = {
                contentType: "application/pdf",
                customMetadata: {
                    name: file.name,
                },
            };

            const name = hash + ".pdf";
            const companyId = companyData.id;

            const fileRef = refStorage(storage, [this.NS, companyId, this.fileFolderName, name].join("/"));
            const uploadTask = uploadBytesResumable(fileRef, file, metaData);
            uploadTask.on(
                "state_changed",
                (snapshot: { bytesTransferred: number; totalBytes: number }) => {
                    const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    setFileStatus(prev => {
                        return {
                            ...prev,
                            progress,
                            error: false,
                        };
                    });
                },
                (error: { message: string }) => {
                    setFileStatus(prev => {
                        return {
                            ...prev,
                            error: error.message,
                        };
                    });
                    reject(error);
                },
                async () => {
                    try {
                        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                        const [previewDownloadURL, numPages]: any = await this.uploadPreview({
                            companyData,
                            file,
                            NS: this.NS,
                            name,
                        });

                        const dataWithFile: IUploaderDocument = {
                            key: hash,
                            fileName: file.name,
                            companyId: companyData.id,
                            numPages,
                            fileUrl: "",
                            previewUrl: "",
                        };

                        console.debug("file uploaded ", downloadURL);

                        resolve({ ...dataWithFile, fileUrl: downloadURL, previewUrl: previewDownloadURL });
                    } catch (err) {
                        reject(err);
                    }
                }
            );
        });
    };

    static uploadPreview = (arg: { companyData: GQL.ICompany; file: File; NS: string; name: string }) => {
        const { companyData, file, NS, name } = arg;
        return new Promise<(string | number)[]>((resolve, reject) => {
            const previewFilename = name.replace(".pdf", "-preview.png");
            let numPages: number;

            PdfUtils.getPdfDocument(file)
                .then(pdf => {
                    if (!pdf) {
                        reject(new Error("The file extension is not pdf"));
                        return;
                    }

                    numPages = pdf.numPages;
                    return PdfUtils.generatePreview(pdf);
                })
                .then(previewBlob => {
                    if (!previewBlob || !(previewBlob instanceof Blob)) {
                        reject(new Error("Failed to get blob"));
                        return;
                    }
                    const previewFile = new File([previewBlob], previewFilename, { type: "image/png" });
                    const companyId = companyData.id;

                    const previewFileRef = refStorage(
                        storage,
                        [NS, companyId, this.previewFolderName, previewFilename].join("/")
                    );
                    const uploadTask = uploadBytesResumable(previewFileRef, previewFile);
                    uploadTask.on(
                        "state_changed",
                        (snapshot: { bytesTransferred: number; totalBytes: number }) => {},
                        (error: { message: any }) => {
                            reject(`File upload error: ${error.message}`);
                        },
                        () => {
                            getDownloadURL(uploadTask.snapshot.ref)
                                .then((downloadURL: string) => {
                                    console.debug("file uploaded ", downloadURL);
                                    resolve([downloadURL, numPages]);
                                })
                                .catch(error => {
                                    reject(error);
                                });
                        }
                    );
                });
        });
    };

    static updateDocuments = async (arg: {
        documents: IDocumentEnriched[];
        mutator: ReturnType<typeof useGqlMutator>;
    }): Promise<void> => {
        const { documents, mutator } = arg;
        const documentInputs = documents.map(convertDocumentToInput);

        await mutator.mutate({
            mutation: documentsUpdate,
            input: documentInputs,
            hideMessages: true,
        });
    };

    static changeDocumentsType = async (documents: GQL.IDocument[], mutator: ReturnType<typeof useGqlMutator>) => {
        const checkSubtype = (subType: string) => {
            if (!subType) {
                return false;
            }

            const disallowedSubTypes: string[] = [DmsDefaultSubType.no_subTypes, DmsDefaultSubType.all_subTypes];

            const hasSubstring =
                subType.includes(DmsDefaultSubType.no_subTypes) || subType.includes(DmsDefaultSubType.all_subTypes);

            return !disallowedSubTypes.includes(subType) && !hasSubstring;
        };

        const documentTypeInputs = documents.map(doc => {
            const subType = checkSubtype(doc.subType) ? doc.subType : null;
            return {
                id: doc.key,
                type: doc.type,
                subType,
                companyId: doc.companyId,
            };
        });

        await mutator.mutate({
            mutation: documentsTypeUpdate,
            input: documentTypeInputs,
            hideMessages: true,
        });
    };

    static deleteDocuments = async (arg: {
        documents: IDocumentEnriched[];
        recordsAssetsSet: Set<string>;
        mutator: ReturnType<typeof useGqlMutator>;
    }) => {
        const { documents, recordsAssetsSet, mutator } = arg;
        const toTrash: IDocumentEnriched[] = [];
        const toRemove: IDocumentEnriched[] = [];

        documents.forEach(document => {
            if (document.type === "new_documents" && !recordsAssetsSet.has(this.urlFormatter(document.fileUrl))) {
                return toRemove.push(document);
            }

            return toTrash.push(document);
        });

        await this.sendToTrash(toTrash, mutator);
        await this.removeDocuments(toRemove, mutator);
    };

    static sendToTrash = async (
        documents: IDocumentEnriched[],
        mutator: ReturnType<typeof useGqlMutator>
    ): Promise<void> => {
        if (documents.length === 0) {
            return;
        }

        const toTrashDocument = documents.map(document => {
            return {
                ...document,
                type: GQL.IDocumentStatus.Trash,
                subType: undefined,
            };
        });
        await this.changeDocumentsType(toTrashDocument, mutator);
    };

    static async removeDocuments(
        documents: IDocumentEnriched[],
        mutator: ReturnType<typeof useGqlMutator>
    ): Promise<boolean> {
        const notInStorage: (IRemoverFile & { fileName: string })[] = [];
        const removedFiles: IRemoverFile[] = [];

        const removeArr = documents.map(async file => {
            return this.removeFile(file)
                .then((file: IRemoverFile) => {
                    removedFiles.push(file);
                })
                .catch(notInStorageFile => {
                    return notInStorage.push(notInStorageFile);
                });
        });

        await Promise.all(removeArr);

        if (notInStorage.length > 0) {
            for (const file of notInStorage) {
                console.debug(
                    `Document file ${file.fileName}  not exist in Storage but we try to delete document in Database`
                );
                const removeFiles: IRemoverFile[] = [{ key: file.key, companyId: file.companyId }];
                await mutator.mutate({
                    mutation: documentsDelete,
                    input: removeFiles,
                    hideMessages: true,
                });
                console.debug(`Document ${file.fileName} has been deleted from Database`);
            }
        }

        if (removedFiles.length > 0) {
            await mutator.mutate({
                mutation: documentsDelete,
                input: removedFiles,
                hideMessages: true,
            });
            console.debug("Documents has been deleted from Storage and Database");
        }
        return true;
    }

    static async removeFile(info: IDocumentEnriched): Promise<IRemoverFile> {
        return new Promise(
            (resolve: (arg: IRemoverFile) => void, reject: (arg: IRemoverFile & { fileName: string }) => void) => {
                const desertRef = refStorage(storage, info.fileUrl);

                deleteObject(desertRef)
                    .then(() => {
                        resolve({ key: info.key, companyId: info.companyId });
                    })
                    .catch(() => {
                        reject({ key: info.key, companyId: info.companyId, fileName: info.fileName });
                    });
            }
        );
    }

    static downloadFile = (url: string, fileName?: string) => {
        const storageRef = refStorage(storage, url);
        getMetadata(storageRef).then(value => {
            fetch(url)
                .then(response => response.blob())
                .then(blob => {
                    saveAs(blob, fileName || value?.customMetadata?.name);
                })
                .catch(error => {
                    console.log(error);
                });
        });
    };

    static bulkDownloadFiles = async (files: IDocumentEnriched[], setIsDownload: Dispatch<SetStateAction<boolean>>) => {
        setIsDownload(true);
        const zip = new JSZip();
        const usedFileNames = new Set<string>();

        try {
            for (const [index, file] of files.entries()) {
                const name = usedFileNames.has(file.fileName) ? `copy-${index}_${file.fileName}` : file.fileName;
                const response = await fetch(file.fileUrl);
                const blob = await response.blob();
                zip.file(name, blob, { binary: true });
                usedFileNames.add(name);
            }
            const content = await zip.generateAsync({ type: "blob", compression: "DEFLATE" });
            saveAs(content, "dms-documents");
            setIsDownload(false);
        } catch (error) {
            logger.crit(error);
        }
    };

    static urlFormatter = (url: string): string => {
        const indexQueryStart = url.indexOf("?");
        return url.slice(0, indexQueryStart);
    };

    static checkPDF = (file: File): Promise<boolean> => {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.onloadend = async () => {
                try {
                    const data = reader.result as ArrayBuffer;
                    const loadingTask = pdfjs.getDocument({ data, isEvalSupported: false });
                    const pdf = await loadingTask.promise;
                    resolve(Boolean(pdf.numPages));
                } catch (error) {
                    resolve(false);
                }
            };
            reader.readAsArrayBuffer(file);
        });
    };
}
