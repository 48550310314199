import React from "react";
import { getElementHeight } from "scripts/infrastructure/helpers/element-size";

interface LazyRenderProps {
    height: number;
    width?: number;
    className?: string;
    itemPadding?: number;
    useTbody?: boolean;
    children?: React.ReactNode;
    scrollTop?: number;
    // scrollToBottom?: boolean;
    onScroll?: (e: HTMLElement) => void;
    onAcquireItemHeight?: (v: number) => void;
    onAcquireMaximalScrollTop?: (v: number) => void;
}

interface ComponentCache {
    childHeight?: number;
    childArray?: React.ReactNode[];
}

export default class LazyRender extends React.Component<LazyRenderProps, any> {
    static defaultProps: LazyRenderProps = {
        height: 0,
        itemPadding: 0,
        scrollTop: 0,
        onScroll: () => {},
        onAcquireItemHeight: () => {},
        onAcquireMaximalScrollTop: () => {},
        // scrollToBottom: false
    };
    protected cache: ComponentCache = {};
    protected containerRef = React.createRef<any>();
    protected rowRef = React.createRef<any>();

    protected scrollHeight?: number;

    constructor(props: LazyRenderProps) {
        super(props);
        this.state = {
            childrenTop: 0,
            childrenToRender: 10,
            scrollTop: 0,
            height: props.height,
            generatorData: null,
            generatorFunction: null,
        };
        this._calculatePropsCache(props);
    }

    componentDidMount() {
        const childHeight = this.getChildHeight();
        const childrenLength = this.getChildrenLength(this.props);

        const height = this.getHeight(childrenLength, childHeight, this.props.height);

        let numberOfItems = Math.ceil(height / childHeight);

        if (height === this.props.height) {
            numberOfItems += this.props.itemPadding;
        }
        numberOfItems = Math.max(10, numberOfItems);

        const container = this.container;

        container.scrollTop = this.props.scrollTop;

        this.setState({
            childHeight,
            childrenToRender: numberOfItems,
            childrenTop: 0,
            childrenBottom: childrenLength - numberOfItems,
            height,
        });
    }

    componentWillUnmount() {
        this.cache = {};
    }

    componentDidUpdate() {
        // important to update the child height in the case that the children change(example: ajax call for data)
        const height = this.getChildHeight();
        if (this.state.childHeight !== height) {
            this.setState({ childHeight: height });
        }
        if (this.scrollHeight !== this.container.scrollHeight) {
            this.scrollHeight = this.container.scrollHeight;
            this.props.onAcquireMaximalScrollTop(this.scrollHeight - this.props.height);
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps: any) {
        this._calculatePropsCache(nextProps);

        let childHeight = this.state.childHeight || 1;
        const childrenLength = this.getChildrenLength(nextProps);

        const _ch = this.getChildHeight();
        if (!this.state.childHeight && _ch) {
            childHeight = _ch;
        }

        const height = this.getHeight(childrenLength, childHeight, nextProps.height);

        let numberOfItems = Math.ceil(height / childHeight);

        if (height === this.props.height) {
            numberOfItems += this.props.itemPadding;
        }

        let childrenTop = Math.floor(this.state.scrollTop / childHeight);

        // if children top is larger than the max item count, set it to the bottom
        childrenTop = Math.min(childrenTop, childrenLength - numberOfItems);
        childrenTop = Math.max(childrenTop, 0);

        let childrenBottom = childrenLength - childrenTop - this.state.childrenToRender;

        childrenBottom = Math.max(childrenBottom, 0);

        this.setState({
            childHeight,
            childrenTop,
            childrenBottom,
            childrenToRender: numberOfItems,
            height,
        });
        if (nextProps.scrollTop !== this.props.scrollTop) {
            this.container.scrollTop = nextProps.scrollTop;
            // this.onScroll(); // todo check if it's causing issues and why
        }
    }

    scrollToBottom() {
        const container = this.container;
        container.scrollTop = container.scrollHeight;
        this.props.onScroll(container);
    }

    protected get container() {
        return this.containerRef.current as HTMLElement;
    }

    onScroll = () => {
        this.props.onScroll(this.container);
        const scrollTop = this.container.scrollTop;
        const childrenLength = this.getChildrenLength(this.props);

        const oldChildTop = this.state.childrenTop;
        const calcTop = scrollTop / this.state.childHeight;
        const tolerance = 0.1;

        let childrenTop = oldChildTop;

        if (Math.abs(oldChildTop - calcTop) > tolerance) {
            childrenTop = Math.floor(calcTop);
        }

        let childrenBottom = childrenLength - childrenTop - this.state.childrenToRender;

        if (childrenBottom < 0) {
            childrenBottom = 0;
        }

        this.setState({
            childrenTop,
            childrenBottom,
            scrollTop,
            // scrollToBottom: false
        });
    };

    getHeight(numChildren: number, childHeight: number, maxHeight: number) {
        return maxHeight;
        // Return the height as a stop gap fix for now because the calculation below
        // is incorrect because the getElementHeight function is slightly innacurate for unknown reasons

        // let fullHeight = numChildren * childHeight;
        // if (fullHeight < height) {
        //     return fullHeight;
        // } else {
        //     return height;
        // }
    }

    getChildrenLength(props: LazyRenderProps) {
        // For some reason React.Children.count returns twice more than real count
        return this.cache.childArray.length;
    }

    getChildHeight() {
        if (!this.cache.childHeight) {
            this.cache.childHeight = getElementHeight(this.rowRef.current as Element);
            this.props.onAcquireItemHeight(this.cache.childHeight);
        }

        return this.cache.childHeight;
    }

    protected _calculatePropsCache(props: LazyRenderProps) {
        this.cache.childArray = React.Children.toArray(props.children);
    }

    getCounters = () => {
        const { childrenToRender, childrenTop } = this.state;
        const totalLength = this.cache.childArray.length;
        const start = childrenTop;
        const end = childrenTop + childrenToRender;
        const padding = this.props.itemPadding ?? 0;
        return { totalLength, start, end, startVisible: start + padding, endVisible: end - padding };
    };

    protected cloneChildren() {
        const { start, end } = this.getCounters();

        const childSlice = this.cache.childArray.slice(start, end);

        return childSlice.map((child: any, index: number) => {
            if (index === 0) {
                return React.cloneElement(child, { ref: this.rowRef });
            }
            return child;
        });
    }

    protected getChildren() {
        return this.cloneChildren();
    }

    render() {
        const children = this.getChildren();
        const childHeight = this.state.childHeight || 0;
        const childrenBottom = this.state.childrenBottom || 0;
        const childrenTop = this.state.childrenTop || 0;
        let unshiftElem = null;
        let pushElem = null;
        let container = null;
        if (this.props.useTbody) {
            unshiftElem = <tr style={{ height: childrenTop * childHeight }} key="top" />;
            pushElem = <tr style={{ height: childrenBottom * childHeight }} key="bottom" />;
        } else {
            unshiftElem = <div style={{ height: childrenTop * childHeight }} key="top" />;
            pushElem = <div style={{ height: childrenBottom * childHeight }} key="bottom" />;
        }

        children.unshift(unshiftElem);
        children.push(pushElem);

        if (this.props.useTbody) {
            container = (
                <tbody
                    style={{
                        height: this.state.height,
                        overflowY: "auto",
                        display: "block",
                        width: this.props.width || "auto",
                    }}
                    className={this.props.className}
                    ref={this.containerRef}
                    onScroll={this.onScroll}
                >
                    {children}
                </tbody>
            );
        } else {
            container = (
                <div
                    style={{ height: this.state.height, overflowY: "auto", display: "block" }}
                    className={this.props.className}
                    ref={this.containerRef}
                    onScroll={this.onScroll}
                >
                    {children}
                </div>
            );
        }
        return container;
    }
}
