import BinaleForm from "../shared/form/BinaleForm";
import React, { useCallback, useContext, useMemo } from "react";
import { CompanyContext, YearPeriodContext } from "../../../scripts/context/CompanyContext";
import { ConfirmProps, EyeButtonProps, JournalProps, PrioritySectionProps } from "./components/buttons";
import { GenericToolbarFilters, searchPredicate } from "../../../scripts/context/tableViewContext/tableViewFilters";
import { KAProps } from "./components/comboboxes";
import { Layout } from "../shared/Layout";
import { TableColumns } from "../../columns/ColumnConfig";
import {
    TableFiltersContext,
    TableFiltersControlContext,
    TableViewContext,
} from "../../../scripts/context/tableViewContext/tableViewContext";
import { ToolbarComponents } from "./components";
import "./GenericToolbar.css";
import { GQL } from "@binale-tech/shared";
import { GenericRecord } from "../../../scripts/models";

interface GenericToolbarProps
    extends Omit<KAProps, "years" | "creditor" | "onCreditorChange" | "onFormFollowChange" | "isFormFollowEnabled">,
        JournalProps,
        ConfirmProps,
        Omit<PrioritySectionProps, "onMoveUpClick" | "onMoveDownClick">,
        EyeButtonProps {
    tableColumns?: TableColumns<any>;
    withJournal?: boolean;
    withConfirm?: boolean;
    withKontenAnsicht?: boolean;
}

export const GenericToolbar: React.FC<GenericToolbarProps> = props => {
    const [searchFilter, setSearchFilter] = React.useState("");
    const { year, period, onChangeYear, onChangePeriod } = useContext(YearPeriodContext);
    const { yearConfig, companyGQL } = useContext(CompanyContext);
    const { view, productKey, selectedRecordGroup, product, moduleActions } = useContext(TableViewContext);
    const skr = yearConfig?.skr;
    const accountingYears = useMemo(() => {
        if (productKey === GQL.IProductKey.Kb || productKey === GQL.IProductKey.Bank) {
            return [...companyGQL.bankList, ...companyGQL.kasseList]
                .filter(v => v.id === selectedRecordGroup)
                .map(v => v.year);
        }
        return companyGQL?.accountingYears || [];
    }, [companyGQL, selectedRecordGroup, productKey]);

    const { setFilter } = useContext(TableFiltersControlContext);
    const { e2eFilterEntity, e2eFilterEntityFormFollow } = useContext(TableFiltersContext);
    const { showRemoved, onToggleHidden } = props;

    const handleSearch = (value: string) => {
        setFilter(GenericToolbarFilters.TextSearch, {
            value,
            predicate: record => searchPredicate(record, value, skr),
        });
    };
    const onMoveUpClick = useCallback((vs: GenericRecord[], prev: GenericRecord) => {
        moduleActions.sinkFirstDown([prev, ...vs]);
    }, []);
    const onMoveDownClick = useCallback((vs: GenericRecord[], next: GenericRecord) => {
        moduleActions.swimLastUp([...vs, next]);
    }, []);

    const isActiveFollowBlock = Boolean(e2eFilterEntity) || e2eFilterEntityFormFollow;

    return (
        <BinaleForm className="GenericToolbar" disableEnterNavigation>
            <Layout direction="row" style={{ alignItems: "flex-end" }}>
                <ToolbarComponents.DateSection
                    period={period}
                    year={year}
                    years={accountingYears}
                    withAll
                    withArrows
                    onPeriodChange={onChangePeriod}
                    onYearChange={onChangeYear}
                    disableYear={isActiveFollowBlock}
                />
                {props.withKontenAnsicht && (
                    <ToolbarComponents.KASection
                        className="KASectionContainer"
                        categoryCreditorMode={props.categoryCreditorMode}
                        years={accountingYears}
                    />
                )}
                <ToolbarComponents.SearchInput
                    style={{ marginLeft: "auto" }}
                    onSearch={handleSearch}
                    onChange={setSearchFilter}
                    value={searchFilter}
                />
                {product.getConfig().usePriorityOrder && (
                    <ToolbarComponents.PrioritySection
                        // toSort={props.toSort}
                        // onSortClicked={props.onSortClicked}
                        toMoveItems={props.toMoveItems}
                        toMovePrev={props.toMovePrev}
                        onMoveUpClick={onMoveUpClick}
                        toMoveNext={props.toMoveNext}
                        onMoveDownClick={onMoveDownClick}
                        onMoveOkClick={props.onMoveOkClick}
                    />
                )}
                {props.withConfirm && (
                    <ToolbarComponents.ConfirmButton
                        onConfirmClicked={props.onConfirmClicked}
                        toConfirm={props.toConfirm}
                    />
                )}
                {props.withJournal && (
                    <ToolbarComponents.JournalButton
                        onJournalClicked={props.onJournalClicked}
                        toJournal={props.toJournal}
                    />
                )}
                <ToolbarComponents.EyeButton showRemoved={showRemoved} onToggleHidden={onToggleHidden} />
                <ToolbarComponents.AnsichtBtn tableColumns={props.tableColumns} view={view} />
            </Layout>
        </BinaleForm>
    );
};
