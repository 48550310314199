import React, { FC, useContext } from "react";
import { Layout } from "../../shared/Layout";
import { AntButtonBlock } from "../../shared/form/FormBlocks";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { Space } from "antd";
import { useFormInfoBlock } from "../hooks/useFormInfoBlock";
import { RecordFormPropsContext } from "../context/RecordFormPropsContext";
import { useFormConfig } from "../hooks/useFormConfig";
import { FormItemBrutto } from "../inputs/FormItemBrutto";
import { FormItemBelegfeld1 } from "../inputs/FormItemBelegfeld1";
import { FormItemAccount } from "../inputs/FormItemAccount";
import { FormItemBu } from "../inputs/FormItemBu";
import { FormItemBelegfeld2 } from "../inputs/FormItemBelegfeld2";
import { FormItemTag } from "../inputs/FormItemTag";
import { FormItemText } from "../inputs/FormItemText";
import { FormItemText2 } from "../inputs/FormItemText2";

type Props = {
    onClearItem: () => void;
};
export const ModalFormItemAdd: FC<Props> = ({ onClearItem }) => {
    const { infoBlock } = useFormInfoBlock();
    const formConfig = useFormConfig();
    const { disabled, refsHtml } = useContext(RecordFormPropsContext);
    return (
        <Space direction="vertical" size={0}>
            <Layout spaceBetween={10}>
                <FormItemBrutto />
                {formConfig.useItemBelegfeld1 ? <FormItemBelegfeld1 isModalMode /> : null}
                <FormItemAccount isModalMode />
                <FormItemBu isModalMode />
                <FormItemBelegfeld2 isModalMode />
                <FormItemTag isModalMode />
                <FormItemText isModalMode />
                <FormItemText2 isModalMode />
                <AntButtonBlock
                    ref={refsHtml.REF_cADD}
                    disabled={disabled}
                    type="primary"
                    htmlType="submit"
                    icon={<PlusOutlined />}
                />
                <AntButtonBlock
                    icon={<CloseOutlined />}
                    disabled={disabled}
                    type="default"
                    tabIndex={-1}
                    tooltip={<FormattedMessage id="app.components.form.clear" />}
                    onClick={onClearItem}
                />
            </Layout>
            {infoBlock}
        </Space>
    );
};
