import React from "react";
import ReactDOMServer from "react-dom/server";
import classNames from "classnames";
import dayjs from "dayjs";
import { GQL, Utils } from "@binale-tech/shared";
import { Button, Tooltip } from "antd";
import { FormattedMessage } from "react-intl";
import { InfoCircleOutlined, LinkOutlined, PaperClipOutlined, PlusOutlined } from "@ant-design/icons";

import TableUtils from "scripts/core/TableUtils";
import { CATEGORY_DIV } from "scripts/models/Category";
import { ConfigurableColumn } from "./ConfigurableColumn";
import { GenericRecord } from "scripts/models/GenericRecord";
import { GenericRecordProperties, ProductColumnDataProvider, TableProperties } from "scripts/core/Product";
import { GenericRecordUtils } from "scripts/models/utils/GenericRecordUtils";
import { ScrollbarDetection } from "scripts/infrastructure/helpers/browser";
import { TableCellAlignment } from "../components/shared/Table/TableCell";
import { TableColumn, TableItem, TableItemExtra } from "../components/shared/Table/Table";
import { logger } from "scripts/infrastructure/logger";
import "./styles.css";

interface ColumnNameOverride {
    label?: string;
    header?: string | React.ReactNode;
}

const coloredMoneyCell = (v: number, extraClass?: string): JSX.Element => {
    if (!Number.isFinite(v)) {
        return null;
    }
    const className = "number ".concat(...[v < 0 ? "number--negative" : null, " ", extraClass].filter(Boolean));
    return <span className={className}>{Utils.CurrencyUtils.currencyFormat(v)}</span>;
};
const getDisplayAmount = (product: ProductColumnDataProvider, amount: number): number => {
    return product.useAbsAmounts() ? Math.abs(amount) : amount;
};

const retrieveNum = (value: string | React.ReactElement) => {
    if (!value) {
        return "";
    }
    if (typeof value === "string") {
        return value.replaceAll(" ", "");
    }
    return ReactDOMServer.renderToString(value).replaceAll(/[^0-9]+/g, "");
};

export type ColumnOverrides<T> = {
    getters?: Record<string, (tableItem: TableItem<T>, kontoExt: number) => React.ReactNode>;
};

export abstract class TableColumns<T> {
    protected _nameOverrides = new Map<string, ColumnNameOverride>();
    protected _columnConfig: TableColumn<T>[];

    constructor(
        protected readonly product: ProductColumnDataProvider,
        protected readonly yearConfig: GQL.ICompanyAccountingYear,
        protected readonly onDocumentsClick?: (v: T) => any,
        protected readonly columnOverrides?: ColumnOverrides<T>
    ) {
        this._nameOverrides = product.tableColumnOverrides();
        const columnConfig: TableColumn<T>[] = [];
        const mapping = this.getColumnsMapping();
        product.tableColumns().forEach(k => {
            columnConfig.push(mapping.get(k));
        });
        this._columnConfig = columnConfig;
    }

    getColumnConfig = () => {
        return [...this._columnConfig];
    };

    getMappedColumns = () => {
        const productColumns = new Map(this.getColumnConfig().map(v => [v.key, v]));
        const allColumns = new Map(productColumns);
        this.getColumnsMapping().forEach((column, key) => {
            if (!allColumns.has(key)) {
                allColumns.set(key, column);
            }
        });
        return allColumns;
    };

    getViewableColumnNames = (excludeNonEditable?: boolean): Map<string, React.ReactNode> => {
        const permanent = new Set(TableProperties.permanentColumns());

        const columns = this.getColumnConfig()
            .filter(column => !permanent.has(column.key))
            .filter(column => !excludeNonEditable || !this.product.nonEditableTableColumns().has(column.key));

        const m = new Map();
        columns.forEach(column => m.set(column.key, column.header));
        return m;
    };

    protected getColumnsMapping(): Map<string, TableColumn<T>> {
        const m = new Map();
        this.getConfigurableColumns().forEach(configurableColumn => {
            const key = configurableColumn.getColumnKey();
            configurableColumn.setOpts(this.yearConfig);
            const getterOverride = this.columnOverrides?.getters?.[key];
            if (getterOverride) {
                configurableColumn.setGetterOverride(ti => getterOverride(ti, this.yearConfig.kontoExt));
            }
            const column = configurableColumn.composeConfig();
            const nameOverride = this._nameOverrides.get(key);
            if (nameOverride && nameOverride.header) {
                column.header = nameOverride.header;
            }

            m.set(key, column);
        });
        return m;
    }
    protected abstract getConfigurableColumns(): ConfigurableColumn<T>[];

    protected colNr(): ConfigurableColumn<T> {
        return new ConfigurableColumn<T>(
            {
                key: TableProperties.ComputedNr,
                width: 37,
                header: <FormattedMessage id="app.fields.nr" />,
                alignment: TableCellAlignment.right,
                footer: {
                    mainFooter: "", // TODO
                    selectedFooter: "", // TODO
                },
            },
            (tableItem: TableItemExtra<T>) => {
                return tableItem.key === null ? null : tableItem.key + 1;
            }
        )
            .withSorting((getter, a, b) => {
                const av = +getter(a);
                const bv = +getter(b);
                return av - bv;
            })
            .withResizing({
                min: 37,
                max: 50,
            });
    }

    protected colActions(): ConfigurableColumn<T> {
        return new ConfigurableColumn<T>({
            key: TableProperties.ControlAction,
            width: 80,
            header: <FormattedMessage id="app.fields.actions" />,
        });
    }

    protected colRecordBinding(): ConfigurableColumn<T> {
        return new ConfigurableColumn<T>({
            key: GenericRecordProperties.ControlBinding,
            width: 40,
            header: <LinkOutlined />,
        });
    }
}

const isEmptyItem = (tableItem: TableItemExtra<Pick<GenericRecord, "key">>) => {
    if (!tableItem) {
        return true;
    }
    if (tableItem.key === null || tableItem.key === undefined) {
        return true;
    }
    if (!tableItem.item.key) {
        return true;
    }
    return false;
};

export class GenericRecordColumns<T extends GenericRecord = GenericRecord> extends TableColumns<T> {
    protected readonly NUM_PREPEND = "000000000000";

    static MONEY_COLUMN = {
        isMoney: true,
        width: 70,
        alignment: TableCellAlignment.right,
    };
    static MONEY_COLUMN_SIZE = {
        min: 55,
        max: 150,
    };

    static MONEY_COLUMN_SH = {
        isMoney: true,
        width: 95,
        alignment: TableCellAlignment.right,
    };

    static MONEY_COLUMN_SH_SIZE = {
        min: 70,
        max: 115,
    };

    protected getConfigurableColumns(): ConfigurableColumn<T>[] {
        return [
            this.colNr(),
            this.colPeriod(),
            this.colDatum(),
            this.colFalligkeit(),
            this.colContact(),
            this.colRecordCCNum(),
            this.colRecordCCName(),
            this.colItemCCNum(),
            this.colItemCCName(),
            this.colBrutto(),
            this.colOriginalAmount(),
            this.colCurrencyCode(),
            this.colCurrencyRate(),
            this.colBF1Record(),
            this.colBF2Item(),
            this.colItemBu(),
            this.colUstPerc(),
            this.colKS(),
            this.colText1(),
            this.colText2(),
            this.colPicture(),
            this.colRecordBinding(),

            this.colUst(),
            this.colUSt13b(),
            this.colNetto(),
            this.colComputedOffen(),
            this.colComputedZA(),
            this.colComputedSaldo(),
            this.colComputedStatus(),
            this.colComputedZahlung(),
            this.colComputedSkonto(),

            this.colActions(),
            this.colLog(),
            this.colModul(),

            this.colComputedBruttoSoll(),
            this.colComputedBruttoHaben(),
            this.colComputedSaldoBrutto(),
            this.colComputedKASoll(),
            this.colComputedKAHaben(),
            this.colComputedSHInfo(),

            this.colRecordReview(),
        ];
    }

    protected colRecordReview(): ConfigurableColumn<T> {
        return new ConfigurableColumn<T>({
            key: GenericRecordProperties.RecordReview,
            width: 80,
            header: <FormattedMessage id="app.fields.recordReview" />,
        });
    }
    protected colDatum(): ConfigurableColumn<T> {
        return new ConfigurableColumn<T>(
            {
                key: GenericRecordProperties.RecordDatum,
                width: 82,
                header: <FormattedMessage id="app.fields.date" />,
                alignment: TableCellAlignment.left,
            },
            (tableItem: TableItemExtra<T>) => {
                if (!tableItem.item.date || isNaN(tableItem.item.date.getTime())) {
                    return "";
                }
                return dayjs(tableItem.item.date).format("DD.MM.YYYY");
            }
        ).withSorting((_getter, a, b) => {
            if (a.item.year !== b.item.year) {
                return a.item.year - b.item.year;
            }
            if (a.item.period !== b.item.period) {
                return a.item.period - b.item.period;
            }
            return (a.item.date || FakeDateInstance).getTime() - (b.item.date || FakeDateInstance).getTime();
        });
    }
    protected colPeriod(): ConfigurableColumn<T> {
        return new ConfigurableColumn<T>(
            {
                key: GenericRecordProperties.RecordPeriod,
                width: 30,
                header: <abbr title={"Periode"}>P</abbr>,
                alignment: TableCellAlignment.left,
            },
            (tableItem: TableItemExtra<T>) => {
                return tableItem.item.period;
            }
        ).withSorting((_getter, a, b) => {
            return a.item.period - b.item.period;
        });
    }

    protected colFalligkeit(): ConfigurableColumn<T> {
        return new ConfigurableColumn<T>(
            {
                key: GenericRecordProperties.RecordFalligkeit,
                width: 80,
                header: <FormattedMessage id="app.fields.falligkeit" />,
                alignment: TableCellAlignment.left,
            },
            (tableItem: TableItemExtra<T>) => {
                return tableItem.item.falligkeit ? dayjs(tableItem.item.falligkeit).format("DD.MM.YYYY") : "";
            }
        ).withSorting((_getter, a, b) => {
            return (
                (a.item.falligkeit || FakeDateInstance).getTime() - (b.item.falligkeit || FakeDateInstance).getTime()
            );
        });
    }

    protected colBrutto(): ConfigurableColumn<T> {
        const getter = (tableItem: TableItem<T>) => getDisplayAmount(this.product, tableItem.item.getBrutto());
        return new ConfigurableColumn<T>(
            {
                key: GenericRecordProperties.RecordBrutto,
                header: <FormattedMessage id="app.fields.brutto" />,
                footer: {
                    mainFooter: "", // TODO
                    selectedFooter: "", // TODO
                },
                ...GenericRecordColumns.MONEY_COLUMN,
            },
            (tableItem: TableItem<T>) => {
                return coloredMoneyCell(getter(tableItem));
            }
        )
            .withSorting((_getter, a, b) => {
                return getter(a) - getter(b);
            })
            .withResizing(GenericRecordColumns.MONEY_COLUMN_SIZE);
    }

    protected colOriginalAmount(): ConfigurableColumn<T> {
        return new ConfigurableColumn<T>(
            {
                key: GenericRecordProperties.RecordCurrencyOriginalAmount,
                header: <FormattedMessage id="app.fields.originalAmount" />,
                ...GenericRecordColumns.MONEY_COLUMN,
            },
            (tableItem: TableItemExtra<T>) => {
                const amount = tableItem.item.getOriginalAmount();
                if (amount === 0) {
                    return null;
                }
                return coloredMoneyCell(getDisplayAmount(this.product, amount));
            }
        )
            .withSorting()
            .withResizing(GenericRecordColumns.MONEY_COLUMN_SIZE);
    }
    protected colCurrencyCode(): ConfigurableColumn<T> {
        return new ConfigurableColumn<T>(
            {
                key: GenericRecordProperties.RecordCurrencyCode,
                width: 50,
                header: "FW",
                alignment: TableCellAlignment.center,
                getter: tableItem => tableItem.item.currency?.code || "",
            },
            tableItem => tableItem.item.currency?.code || ""
        ).withSorting();
    }
    protected colCurrencyRate(): ConfigurableColumn<T> {
        return new ConfigurableColumn<T>(
            {
                key: GenericRecordProperties.RecordCurrencyRate,
                width: 60,
                alignment: TableCellAlignment.right,
                header: <FormattedMessage id="app.fields.currency.rate" />,
            },
            tableItem =>
                tableItem.item.currency?.rate
                    ? Utils.CurrencyUtils.currencyFormat(tableItem.item.currency.rate * 100, 4)
                    : ""
        )
            .withResizing({
                min: 60,
                max: 120,
            })
            .withSorting();
    }

    protected colBF1Record(): ConfigurableColumn<T> {
        const getter = (tableItem: TableItemExtra<T>) => {
            return GenericRecordUtils.getInvoiceNumber(tableItem.item, tableItem.item.getProductKey(), true);
        };

        return new ConfigurableColumn<T>(
            {
                key: GenericRecordProperties.RecordBelegfeld1,
                width: 180,
                header: <FormattedMessage id="app.fields.rechnung_num" />,
            },
            ti => <span className={"Column--monospace"}>{getter(ti)}</span>
        )
            .withSorting((_getter, a, b) => {
                const av = getter(a) || "";
                const bv = getter(b) || "";
                return (this.NUM_PREPEND.substring(av.length) + av).localeCompare(
                    this.NUM_PREPEND.substring(bv.length) + bv
                );
            })
            .withResizing({
                min: 25,
                max: 220,
            });
    }

    protected colContact(): ConfigurableColumn<T> {
        return new ConfigurableColumn<T>(
            {
                key: GenericRecordProperties.RecordContact,
                width: 180,
                header: <FormattedMessage id="app.fields.contact" />,
            },
            ti => ti.item.partner?.name
        )
            .withSorting()
            .withResizing({
                min: 25,
                max: 220,
            });
    }

    protected colBF2Item(): ConfigurableColumn<T> {
        return new ConfigurableColumn<T>(
            {
                key: GenericRecordProperties.ItemBelegfeld2,
                width: 180,
                header: <FormattedMessage id="app.fields.internal_num" tagName="span" />,
            },
            (tableItem: TableItemExtra<T>) => {
                return tableItem.item.getBelegfeld2();
            }
        )
            .withSorting((getter, a, b) => {
                const av = getter(a) || "";
                const bv = getter(b) || "";
                return (this.NUM_PREPEND.substring(av.length) + av).localeCompare(
                    this.NUM_PREPEND.substring(bv.length) + bv
                );
            })
            .withResizing({
                min: 25,
                max: 180,
            });
    }

    protected colUstPerc(): ConfigurableColumn<T> {
        return new ConfigurableColumn<T>(
            {
                key: GenericRecordProperties.ItemUStPerc,
                width: 100,
                header: <FormattedMessage id="app.fields.vat%" />,
                alignment: TableCellAlignment.right,
            },
            (tableItem: TableItemExtra<T>, { skr }) => {
                return tableItem.item.getTableBuText(skr || 0);
            }
        )
            .withSorting()
            .withResizing({
                min: 25,
                max: 100,
            });
    }

    protected colItemBu(): ConfigurableColumn<T> {
        return new ConfigurableColumn<T>(
            {
                key: GenericRecordProperties.ComputedBu,
                width: 45,
                header: "BU",
                alignment: TableCellAlignment.right,
            },
            (tableItem: TableItemExtra<T>, { skr }) => {
                return tableItem.item.getTableBu(skr);
            }
        ).withSorting();
    }

    protected colUst(): ConfigurableColumn<T> {
        const getter = (tableItem: TableItem<T>, skr: number) =>
            getDisplayAmount(this.product, tableItem.item.getVatEuro(skr));
        return new ConfigurableColumn<T>(
            {
                key: GenericRecordProperties.ComputedUSt,
                header: <FormattedMessage id="app.fields.vat" />,
                footer: {
                    mainFooter: "", // TODO
                    selectedFooter: "", // TODO
                },
                ...GenericRecordColumns.MONEY_COLUMN,
            },
            (tableItem, { skr }) => {
                return coloredMoneyCell(
                    getter(tableItem, skr),
                    tableItem.item.getBrutto() < 0 ? "number--negative" : null
                );
            }
        )
            .withSorting((_getter, a, b, { skr }) => {
                return getter(a, skr) - getter(b, skr);
            })
            .withResizing(GenericRecordColumns.MONEY_COLUMN_SIZE);
    }

    protected colNetto(): ConfigurableColumn<T> {
        const getter = (tableItem: TableItem<T>, skr: number) =>
            getDisplayAmount(this.product, tableItem.item.getNetto(skr));
        return new ConfigurableColumn<T>(
            {
                key: GenericRecordProperties.ComputedNetto,
                header: <FormattedMessage id="app.fields.netto" />,
                footer: {
                    mainFooter: "", // TODO
                    selectedFooter: "", // TODO
                },
                ...GenericRecordColumns.MONEY_COLUMN_SH,
            },
            (tableItem, { skr }) => {
                return coloredMoneyCell(getter(tableItem, skr));
            }
        )
            .withSorting((_getter, a, b, { skr }) => {
                return getter(a, skr) - getter(b, skr);
            })
            .withResizing(GenericRecordColumns.MONEY_COLUMN_SH_SIZE);
    }

    protected colKS(): ConfigurableColumn<T> {
        return new ConfigurableColumn<T>(
            {
                key: GenericRecordProperties.ItemKS,
                width: 40,
                header: "KS",
                alignment: TableCellAlignment.right,
            },
            (tableItem: TableItemExtra<T>) => tableItem.item.getTag().num
        ).withSorting();
    }

    protected colText1(): ConfigurableColumn<T> {
        return new ConfigurableColumn<T>(
            {
                key: GenericRecordProperties.ItemBuchungstext,
                width: 250,
                header: <FormattedMessage id="app.fields.buchtext" />,
            },
            (tableItem: TableItemExtra<T>) => tableItem.item.getText()
        )
            .withSorting()
            .withResizing({
                min: 25,
                max: 865,
            });
    }

    protected colText2(): ConfigurableColumn<T> {
        return new ConfigurableColumn<T>(
            {
                key: GenericRecordProperties.ItemBuchungstext2,
                width: 250,
                header: <FormattedMessage id="app.fields.buchtext2" />,
            },
            (tableItem: TableItemExtra<T>) => tableItem.item.getText2()
        )
            .withSorting()
            .withResizing({
                min: 25,
                max: 865,
            });
    }

    protected colUSt13b(): ConfigurableColumn<T> {
        return new ConfigurableColumn<T>(
            {
                key: GenericRecordProperties.ItemUSt13b,
                width: 45,
                header: "13b",
                alignment: TableCellAlignment.right,
            },
            (tableItem: TableItemExtra<T>) => tableItem.item.getUSt13b()
        ).withSorting();
    }

    protected colPicture(): ConfigurableColumn<T> {
        const getter = (tableItem: TableItemExtra<T>) => {
            if (tableItem.extra.isVirtualRecord) {
                return null;
            }
            if (isEmptyItem(tableItem)) {
                return null;
            }
            const len = Number(tableItem.item.documents?.length || 0);
            const isEditDisabledSoft = GenericRecordUtils.isAssetsAndColorDisabled(tableItem);
            return (
                <Button
                    type="link"
                    size="small"
                    style={{ padding: 0, display: "flex", alignItems: "center" }}
                    disabled={isEditDisabledSoft && len === 0}
                    onClick={() => {
                        this.onDocumentsClick && this.onDocumentsClick(tableItem.item);
                    }}
                >
                    {len > 0 ? (
                        <span>
                            <PaperClipOutlined />
                            <sup>{len}</sup>
                        </span>
                    ) : (
                        <PlusOutlined />
                    )}
                </Button>
            );
        };
        return new ConfigurableColumn<T>(
            {
                key: GenericRecordProperties.RecordPicture,
                width: 30,
                header: <PaperClipOutlined />,
            },
            ti => getter(ti)
        );
    }

    protected colModul(): ConfigurableColumn<T> {
        return new ConfigurableColumn<T>(
            {
                key: TableProperties.ComputedModul,
                width: 150,
                header: <FormattedMessage id={"app.fields.module"} />,
            },
            (tableItem: TableItemExtra<T>) => {
                const converted = TableUtils.getTableItemSHContainer(tableItem);
                return <FormattedMessage id={"app.titles." + converted.product} />;
            }
        ).withSorting();
    }

    protected colLog(): ConfigurableColumn<T> {
        return new ConfigurableColumn<T>({
            key: TableProperties.ControlLog,
            width: 35,
            header: <span>Log</span>,
        });
    }

    protected colComputedSHInfo(): ConfigurableColumn<T> {
        const getter = (tableItem: TableItemExtra<T>, ext: number) => {
            const converted = TableUtils.getTableItemSHContainer(tableItem);
            const content = (
                <span>
                    Modul:{" "}
                    <code>
                        <FormattedMessage id={"app.titles." + converted.product} />
                    </code>
                    <div style={{ display: "table" }}>
                        {converted.items.map((v, i) => {
                            if (!v.konto) {
                                console.log(converted);
                            }
                            return (
                                <div key={i} style={{ display: "table-row" }}>
                                    <div style={{ display: "table-cell" }}>{v.konto?.getExtNumPrint(ext)}</div>
                                    <div style={{ display: "table-cell", padding: "0 5px" }}>
                                        <code>{v.isHaben ? "H" : "S"}</code>
                                    </div>
                                    <div style={{ display: "table-cell", padding: "0 5px" }}>
                                        {Number.isFinite(v.bu) ? <code>BU{v.bu}</code> : ""}
                                    </div>
                                    <div style={{ display: "table-cell", textAlign: "right" }}>
                                        {Utils.CurrencyUtils.currencyFormat(v.amount)}
                                    </div>
                                </div>
                            );
                        })}
                        <div style={{ display: "table-row" }}>
                            <div style={{ display: "table-cell" }}>Summe:</div>
                            <div style={{ display: "table-cell" }} />
                            <div style={{ display: "table-cell" }} />
                            <div style={{ display: "table-cell", textAlign: "right" }}>
                                {Utils.CurrencyUtils.currencyFormat(
                                    converted.items.reduce((caret, v) => caret + (v.isHaben ? -v.amount : v.amount), 0)
                                )}
                            </div>
                        </div>
                    </div>
                </span>
            );
            return (
                <Tooltip
                    placement="left"
                    title={content}
                    onOpenChange={() => {
                        logger.log({ tableItem, converted });
                    }}
                >
                    <InfoCircleOutlined />
                </Tooltip>
            );
        };
        return new ConfigurableColumn<T>(
            {
                key: GenericRecordProperties.ComputedSHInfo,
                width: 25 + ScrollbarDetection.getWidth(),
                header: <span>i</span>,
            },
            (ti, { kontoExt }) => getter(ti, kontoExt)
        );
    }

    protected colItemCCNum(): ConfigurableColumn<T> {
        return new ConfigurableColumn<T>(
            {
                key: GenericRecordProperties.ItemCategoryCreditorNum,
                width: 85,
                header: <FormattedMessage id="app.fields.gkonto" />,
                alignment: TableCellAlignment.right,
            },
            (tableItem: TableItemExtra<T>, { kontoExt }) => {
                return tableItem.item.getItemCategoryCreditor().getExtNumPrint(kontoExt);
            }
        ).withSorting((getter, a, b) => {
            let as = retrieveNum(getter(a));
            let bs = retrieveNum(getter(b));

            if (as === CATEGORY_DIV.num) {
                as = "0";
            }
            if (bs === CATEGORY_DIV.num) {
                bs = "0";
            }
            return +as - +bs;
        });
    }

    protected colItemCCName(): ConfigurableColumn<T> {
        return new ConfigurableColumn<T>(
            {
                key: GenericRecordProperties.ItemCategoryCreditorName,
                width: 250,
                header: <FormattedMessage id="app.fields.bezeichnung" />,
            },
            (tableItem: TableItemExtra<T>) => {
                return tableItem.item.getItemCategoryCreditor().name;
            }
        ).withResizing({
            min: 25,
            max: 390,
        });
    }

    protected colRecordCCNum(): ConfigurableColumn<T> {
        return new ConfigurableColumn<T>(
            {
                key: GenericRecordProperties.RecordCategoryCreditorNum,
                width: 85,
                header: <FormattedMessage id="app.fields.konto" />,
                alignment: TableCellAlignment.right,
            },
            (tableItem: TableItemExtra<T>, { kontoExt }) => {
                return tableItem.item.getRecordCategoryCreditor().getExtNumPrint(kontoExt);
            }
        ).withSorting((getter, a, b) => {
            let as = retrieveNum(getter(a));
            let bs = retrieveNum(getter(b));
            if (as === CATEGORY_DIV.num) {
                as = "0";
            }
            if (bs === CATEGORY_DIV.num) {
                bs = "0";
            }
            return +as - +bs;
        });
    }

    protected colRecordCCName(): ConfigurableColumn<T> {
        return new ConfigurableColumn<T>(
            {
                key: GenericRecordProperties.RecordCategoryCreditorName,
                width: 250,
                header: <FormattedMessage id="app.fields.bezeichnung" />,
            },
            (tableItem: TableItemExtra<T>) => {
                return tableItem.item.getRecordCategoryCreditor().name;
            }
        )
            .withSorting()
            .withResizing({
                min: 25,
                max: 390,
            });
    }

    protected colComputedOffen(): ConfigurableColumn<T> {
        return new ConfigurableColumn<T>(
            {
                key: GenericRecordProperties.ComputedOffen,
                header: <FormattedMessage id="app.fields.open" />,
                footer: {
                    mainFooter: "", // TODO
                    selectedFooter: "", // TODO
                },
                ...GenericRecordColumns.MONEY_COLUMN,
            },
            (tableItem: TableItemExtra<T>) => {
                if (tableItem.key === null) {
                    return null;
                }
                return Utils.CurrencyUtils.currencyFormat(
                    tableItem.item.getOpenBrutto(tableItem.extra.payments.get(tableItem.item) || [])
                );
            }
        )
            .withSorting()
            .withResizing(GenericRecordColumns.MONEY_COLUMN_SIZE);
    }

    protected colComputedZA(): ConfigurableColumn<T> {
        return new ConfigurableColumn<T>(
            {
                key: GenericRecordProperties.ComputedZA,
                width: 30,
                header: "ZA",
                alignment: TableCellAlignment.center,
            },
            tableItem => {
                const o = TableGetters.computedZA(tableItem);
                return o ? <abbr title={o.title}>{o.abbr}</abbr> : "";
            }
        );
    }

    protected colComputedSaldo(): ConfigurableColumn<T> {
        return new ConfigurableColumn<T>(
            {
                key: GenericRecordProperties.ComputedSaldo,
                header: <span>Saldo</span>,
                ...GenericRecordColumns.MONEY_COLUMN_SH,
            },
            (tableItem: TableItemExtra<T>) => {
                return coloredMoneyCell(tableItem.extra.saldo, "KassenbuchView__saldo");
            }
        ).withResizing(GenericRecordColumns.MONEY_COLUMN_SH_SIZE);
    }

    protected colComputedBruttoSoll(): ConfigurableColumn<T> {
        const key = GenericRecordProperties.ComputedKABruttoSoll;
        return new ConfigurableColumn<T>({
            key,
            header: <>{key}</>,
            ...GenericRecordColumns.MONEY_COLUMN_SH,
        })
            .withSorting()
            .withResizing(GenericRecordColumns.MONEY_COLUMN_SH_SIZE);
    }

    protected colComputedBruttoHaben(): ConfigurableColumn<T> {
        const key = GenericRecordProperties.ComputedKABruttoHaben;
        return new ConfigurableColumn<T>({
            key,
            header: <>{key}</>,
            ...GenericRecordColumns.MONEY_COLUMN_SH,
        })
            .withSorting()
            .withResizing(GenericRecordColumns.MONEY_COLUMN_SH_SIZE);
    }

    protected colComputedKASoll(): ConfigurableColumn<T> {
        const key = GenericRecordProperties.ComputedKASoll;
        return new ConfigurableColumn<T>({
            key,
            header: <>{key}</>,
            ...GenericRecordColumns.MONEY_COLUMN_SH,
        }).withResizing(GenericRecordColumns.MONEY_COLUMN_SH_SIZE);
    }

    protected colComputedKAHaben(): ConfigurableColumn<T> {
        const key = GenericRecordProperties.ComputedKAHaben;
        return new ConfigurableColumn<T>({
            key,
            header: <span>{key}</span>,
            ...GenericRecordColumns.MONEY_COLUMN_SH,
        }).withResizing(GenericRecordColumns.MONEY_COLUMN_SH_SIZE);
    }

    protected colComputedSaldoBrutto(): ConfigurableColumn<T> {
        const key = GenericRecordProperties.ComputedKASaldoBrutto;
        return new ConfigurableColumn<T>({
            key,
            header: <span>{key}</span>,
            ...GenericRecordColumns.MONEY_COLUMN_SH,
        }).withResizing(GenericRecordColumns.MONEY_COLUMN_SH_SIZE);
    }

    protected colComputedStatus(): ConfigurableColumn<T> {
        const key = GenericRecordProperties.ComputedStatus;
        return new ConfigurableColumn<T>(
            {
                key,
                width: 70,
                header: <>{key}</>,
                alignment: TableCellAlignment.center,
            },
            tableItem => {
                const o = TableGetters.computedStatus(tableItem);
                return !o ? (
                    ""
                ) : (
                    <p
                        className={classNames("status", {
                            "status-offenColor": o.abbr === "offen",
                            "status-bezahltColor": o.abbr === "bezahlt",
                            "status-avisColor": o.abbr === "AVIS",
                        })}
                    >
                        {o.abbr}
                    </p>
                );
            }
        );
    }

    protected colComputedZahlung(): ConfigurableColumn<T> {
        return new ConfigurableColumn<T>(
            {
                key: GenericRecordProperties.ComputedZahlung,
                header: <FormattedMessage id="app.fields.payment" />,
                ...GenericRecordColumns.MONEY_COLUMN,
            },
            (tableItem: TableItemExtra<T>) => {
                const payments = tableItem.extra.payments.get(tableItem.item) || [];
                return Utils.CurrencyUtils.currencyFormat(payments.reduce((s, p) => s + p.zahlungsBetrag || 0, 0));
            }
        )
            .withSorting()
            .withResizing(GenericRecordColumns.MONEY_COLUMN_SIZE);
    }

    protected colComputedSkonto(): ConfigurableColumn<T> {
        return new ConfigurableColumn<T>(
            {
                key: GenericRecordProperties.ComputedSkonto,
                header: <FormattedMessage id="app.fields.skonto" />,
                ...GenericRecordColumns.MONEY_COLUMN,
            },
            (tableItem: TableItemExtra<T>) => {
                const payments = tableItem.extra.payments.get(tableItem.item) || [];
                const paymentSkonto = payments.reduce((s, p) => s + p.skontoBetrag || 0, 0);

                return Utils.CurrencyUtils.currencyFormat(paymentSkonto);
            }
        ).withResizing(GenericRecordColumns.MONEY_COLUMN_SIZE);
    }
}

export class TableGetters {
    static computedStatus<T extends GenericRecord>(tableItem: TableItemExtra<T>): { title: string; abbr: string } {
        if (isEmptyItem(tableItem)) {
            return null;
        }
        if (!Utils.PaymentUtils.isRecordPaymentSource(tableItem.item.getProductKey())) {
            return null;
        }
        let title = "Offener Posten";
        let abbr = "offen";
        if (tableItem.item.getOpenBrutto(tableItem.extra.payments.get(tableItem.item) || []) === 0) {
            title = "bezahlt";
            abbr = "bezahlt";
        } else if (tableItem.item.avis) {
            title = "Zahlungavis";
            abbr = "AVIS";
        }
        return { title, abbr };
    }

    static computedZA<T extends GenericRecord>(tableItem: TableItemExtra<T>): { title: string; abbr: string } {
        if (isEmptyItem(tableItem)) {
            return null;
        }
        if (tableItem.item.lastschrift) {
            return { title: "Lastschrift", abbr: "LS" };
        }
        if (tableItem.extra && GenericRecordUtils.isRecordPayed(tableItem.item, tableItem.extra.payments)) {
            return null;
        }
        if (tableItem.item.avis) {
            return { title: "Avis", abbr: "A" };
        }
        return null;
    }
}

class FakeDate {
    getTime() {
        return 0;
    }
}

const FakeDateInstance = new FakeDate();
