import React, { FC, memo, useCallback, useContext, useEffect, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import { GridReadyEvent, ModelUpdatedEvent, RowSelectedEvent } from "ag-grid-community";
import { AgGridTable } from "@app/components/shared/AgGridTable";

import { ITableDocument, tableModeEnum } from "@dms/types";
import { DmsAppContext, DmsAppControlContext } from "@dms/types/ContextTypes";
import { useDocumentTableConfig } from "@dms/modules/DocumentTableModule/hooks/useDocumentColumnConfig";
import { useDocumentTableOptions } from "@dms/modules/DocumentTableModule/hooks/useDocumentTableOptions";

type TProps = {
    dataSource: ITableDocument[];
    activeType: string[];
};

export const DocumentTableModule: FC<TProps> = memo(({ dataSource, activeType }) => {
    const gridApiRef = useRef<AgGridReact>();
    const { selectedRowKeys } = useContext(DmsAppContext);
    const { selectRow, unselectRow } = useContext(DmsAppControlContext);
    const gridOptions = useDocumentTableOptions();
    const { columns } = useDocumentTableConfig(tableModeEnum.modal, [activeType[1]]);

    const onGridReady = useCallback(
        ({ api }: GridReadyEvent<ITableDocument>) => api.updateGridOptions({ rowData: dataSource }),
        [dataSource]
    );

    const onModelUpdated = useCallback(
        ({ api }: ModelUpdatedEvent<ITableDocument>) => {
            api.forEachNode(node => node.setSelected(selectedRowKeys.includes(node.data.key)));
        },
        [selectedRowKeys]
    );

    const onRowSelected = useCallback(({ source, data, node }: RowSelectedEvent<ITableDocument>) => {
        const isSelectedNode = node.isSelected();
        if (source === "checkboxSelected" || source === "uiSelectAllCurrentPage" || source === "spaceKey") {
            isSelectedNode ? selectRow(data.key) : unselectRow(data.key);
        }
    }, []);

    useEffect(() => {
        if (gridApiRef.current?.api) {
            requestAnimationFrame(() => {
                gridApiRef.current.api.updateGridOptions({ rowData: dataSource });
            });
        }
    }, [dataSource]);

    return (
        <AgGridTable
            ref={gridApiRef}
            gridOptions={gridOptions}
            tableKey={activeType.join(",")}
            columnDefs={columns}
            onRowSelected={onRowSelected}
            onGridReady={onGridReady}
            onModelUpdated={onModelUpdated}
        />
    );
});
