import { type UploadChangeParam } from "antd/es/upload";

import { TReducedFileInfo } from "@dms/types";

export const fileDataReducer = (allFilesInfo: (UploadChangeParam<any> & { hash: string })[]): TReducedFileInfo => {
    return allFilesInfo.reduce((acc: TReducedFileInfo, file: UploadChangeParam<any> & { hash: string }) => {
        if (acc[file.hash]) {
            return {
                ...acc,
                [file.hash]: [...acc[file.hash], { ...file }],
            };
        }

        return {
            ...acc,
            [file.hash]: [{ ...file }],
        };
    }, {});
};
