import React, { forwardRef, useEffect, useState } from "react";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import { PickerRef } from "rc-picker";

interface IProps {
    onChange?: (v?: string) => void;
    value?: string;
    isMonthPicker?: boolean;
}

const { RangePicker } = DatePicker;
const dateFormat = "DD.MM.YYYY";
const monthFormat = "MM.YYYY";

export const InvoiceRangePicker = forwardRef<PickerRef, IProps>(
    ({ value, onChange, isMonthPicker, ...restProps }, ref) => {
        const [pickerValue, setPickerValue] = useState<string[]>();
        const format = isMonthPicker ? monthFormat : dateFormat;

        const handleOnChange = (dates?: dayjs.Dayjs[]): void => {
            if (!dates) {
                return;
            }

            const formattedDates = [
                dates[0] ? dates[0].format(format) : undefined,
                dates[1] ? dates[1].format(format) : undefined,
            ];
            const resultValue = JSON.stringify(formattedDates);

            onChange?.(resultValue);
        };

        const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
            if (e.key === "Enter" || (e.shiftKey && e.key === "Enter")) {
                e.preventDefault();
            }
        };

        useEffect(() => {
            if (!value) {
                return;
            }
            const arrayValue: string[] = JSON.parse(value);
            setPickerValue(arrayValue);
        }, [value]);

        return (
            <RangePicker
                {...restProps}
                picker={isMonthPicker ? "month" : "date"}
                format={format}
                style={{ width: "100%" }}
                onChange={handleOnChange}
                onKeyDown={handleKeyDown}
                value={pickerValue ? [dayjs(pickerValue[0], format), dayjs(pickerValue[1], format)] : null}
                ref={ref}
            />
        );
    }
);
