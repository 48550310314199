import React, { type FC, useEffect, useState } from "react";
import { PdfViewer } from "@binale-tech/ui-components";
import { getAppCheckToken } from "../../../../scripts/api/firebase/firebase";

interface IProps {
    url?: string;
    filename?: string;
    type?: string;
}

export const DocumentPdfViewer: FC<IProps> = ({ url, filename, type }) => {
    const [appCheckToken, setAppCheckToken] = useState<string>();
    const [viewerUrl, setViewerUrl] = useState<string>();
    useEffect(() => {
        getAppCheckToken().then(token => {
            setAppCheckToken(token);
            setViewerUrl(url);
        });
    }, [url]);
    return (
        <div style={{ height: "calc(100vh - 122px)", width: "100%" }}>
            {viewerUrl && <PdfViewer url={viewerUrl} fileName={filename} type={type} appCheckToken={appCheckToken} />}
        </div>
    );
};
