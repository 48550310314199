import React, { FC, useCallback, useContext, useMemo } from "react";
import { AgGridTable } from "@app/components/shared/AgGridTable";
import { gridOptions } from "@inv/modules/InvocesListModule/config/gridOptions";
import { InvoicesContext } from "@inv/scripts/context";
import { CellValueChangedEvent, GridReadyEvent, RowClassRules } from "ag-grid-community";
import { invoicesListTotal } from "@inv/scripts/utils/utils";
import { ITableDocument } from "@dms/types";
import { useColumnConfig } from "@inv/modules/InvocesListModule/hooks/useColumnConfig";
import { ActionList } from "@inv/components/ActionList";
import { SettingsColumns } from "./components/SettingsColumns";
import { RateComponent } from "@inv/components/InvoicesList/components/RateComponent";

import styles from "./InvoicesList.module.scss";

const INVOICES_LIST_TABLE_CONTAINER_HEIGHT = "calc(100vh - 300px)";

export const InvoicesList: FC = () => {
    const { invoicesList } = useContext(InvoicesContext);
    const columns = useColumnConfig();

    const components = {
        rateComponent: RateComponent,
        settingsColumns: SettingsColumns,
        actionList: ActionList,
    };

    const onGridReady = useCallback(({ api }: GridReadyEvent) => {
        const { amountNetto, vat, amountBrutto } = invoicesListTotal(api);
        api.updateGridOptions({
            pinnedBottomRowData: [{ amountNetto, vat, amountBrutto }],
        });
    }, []);

    const onCellValueChanged = useCallback(({ api }: CellValueChangedEvent) => {
        const { amountNetto, vat, amountBrutto } = invoicesListTotal(api);
        api.updateGridOptions({
            pinnedBottomRowData: [{ amountNetto, vat, amountBrutto }],
        });
    }, []);

    const rowClassRules = useMemo<RowClassRules<ITableDocument>>(
        () => ({
            [styles.agTotalRow]: params => Boolean(params.node.rowPinned),
        }),
        []
    );

    return (
        <AgGridTable
            columnDefs={columns}
            gridOptions={gridOptions}
            rowData={invoicesList}
            onGridReady={onGridReady}
            onCellValueChanged={onCellValueChanged}
            rowClassRules={rowClassRules}
            components={components}
            wrapperStyles={{ height: INVOICES_LIST_TABLE_CONTAINER_HEIGHT }}
        />
    );
};
