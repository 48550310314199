import React, { createContext, FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { child, DataSnapshot } from "firebase/database";
import { refInvoices } from "../../../scripts/api/firebase/firebaseRootRefs";
import { useCollectionListener } from "../../../scripts/context/hooks/useCollectionListener";
import { CompanyContext, CompanyProvider } from "../../../scripts/context/CompanyContext";
import {
    IInvoiceFormLineItem,
    TInvoicesValues,
    TInvoicesListColumnsConfig,
    TInvoicesListData,
    TInvoicesGet,
} from "@inv/types";
import { transformServicePeriod } from "@inv/scripts/utils/utils";
import { InvoicesListTableColumns } from "@inv/modules/InvocesListModule/config/tableColumns";
import { useGqlMutator } from "../../../scripts/graphql/useGqlMutator";
import { BanksApi } from "@banks/scripts/api";
import { InvoicesApi } from "@inv/scripts/api";

type TValue = {
    invoicesList?: Partial<TInvoicesListData>[];
    invoiceListColumnsConfig: TInvoicesListColumnsConfig;
    isOpenSettingsColumns: boolean;
};

type TActionValue = {
    setColumnConfig: (newValue: TInvoicesListColumnsConfig) => void;
    setViewConfig: (newValue: boolean) => void;
};

const initialValue = {
    invoiceListColumnsConfig: InvoicesListTableColumns.invoicesListInitColumnsConfig,
    isOpenSettingsColumns: false,
};

const initialActionValue = {
    setColumnConfig: () => {},
    setViewConfig: () => {},
};

export const InvoicesContext = createContext<TValue>(initialValue);
export const InvoicesControlContext = createContext<TActionValue>(initialActionValue);

type TProps = {
    children?: ReactNode;
};

export const InvoicesContextProvider: FC<TProps> = ({ children }) => {
    const { companyGQL } = useContext(CompanyContext);
    const companyId = companyGQL?.id;
    const mutator = useGqlMutator();

    useEffect(() => {
        InvoicesApi.mutator = mutator;
        InvoicesApi.companyId = companyId;
    }, [companyId, mutator]);

    const [invoiceListColumnsConfig, setInvoiceListColumnsConfig] = useState<TInvoicesListColumnsConfig>(
        initialValue.invoiceListColumnsConfig
    );
    const [isOpenSettingsColumns, setIsOpenSettingsColumns] = useState<boolean>(initialValue.isOpenSettingsColumns);

    const shouldSkipLoad = useMemo(() => !companyId, [companyId]);
    const ref = useMemo(() => child(refInvoices, String(companyId)), [companyId]);
    const initializer = useCallback((snap: DataSnapshot) => snap.val(), []);

    const invoicesMap = useCollectionListener(ref, initializer, shouldSkipLoad);

    const transformInvoicesData = useCallback(
        (invoices: TInvoicesGet[]): TInvoicesListData[] =>
            invoices.map(invoice => {
                const lineItems = invoice.lineItems as IInvoiceFormLineItem[];
                const lineItemsTotal = lineItems.reduce(
                    (totals, item, index) => {
                        const originalAmount = item.quantity * item.discount;
                        const tax = item.tax / 100;

                        totals.originalAmount += originalAmount;
                        totals.generalTax += originalAmount * tax;

                        if (index === 0) {
                            totals.generalTaxRate = item.tax.toString();
                        } else if (totals.generalTaxRate !== item.tax.toString()) {
                            totals.generalTaxRate = "Diverse";
                        }

                        return totals;
                    },
                    {
                        originalAmount: 0,
                        generalTax: 0,
                        generalTaxRate: "",
                    }
                );

                const servicePeriodDaysValue = invoice?.servicePeriodDays
                    ? transformServicePeriod(invoice?.servicePeriodDays)
                    : null;
                const servicePeriodMonthsValue = invoice?.servicePeriodMonths
                    ? transformServicePeriod(invoice?.servicePeriodMonths)
                    : null;

                return {
                    id: invoice.id,
                    invoiceDate: invoice?.date,
                    invoiceNumber: "DRAFT",
                    serviceDescription: "N/A",
                    contact: invoice?.customer?.name,
                    currency: invoice?.currencyCode,
                    project: "N/A",
                    serviceDate:
                        invoice?.serviceDate ??
                        invoice?.deliveryDate ??
                        servicePeriodDaysValue ??
                        servicePeriodMonthsValue,
                    rate: 1,
                    lineItemsList: lineItems,
                    ...lineItemsTotal,
                };
            }),
        [invoicesMap]
    );

    const invoicesList = transformInvoicesData(Array.from(invoicesMap.values()));

    const value = { invoicesList, invoiceListColumnsConfig, isOpenSettingsColumns };
    const actions = useMemo(
        () => ({
            setColumnConfig: (newValue: TInvoicesListColumnsConfig) => setInvoiceListColumnsConfig(newValue),
            setViewConfig: (newValue: boolean) => setIsOpenSettingsColumns(newValue),
        }),
        []
    );

    return (
        <InvoicesContext.Provider value={value}>
            <InvoicesControlContext.Provider value={actions}>{children}</InvoicesControlContext.Provider>
        </InvoicesContext.Provider>
    );
};
