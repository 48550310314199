import React, { useCallback, useContext, useMemo } from "react";
import RecordFormUtils from "../utils/RecordFormUtils";
import { RecordFormStateContext } from "../context/RecordFormState";
import { BuContext } from "../../../../scripts/context/BuContext";
import { CompanyContext } from "../../../../scripts/context/CompanyContext";
import { TableViewContext } from "../../../../scripts/context/tableViewContext/tableViewContext";
import { useFormConfig } from "./useFormConfig";

export const useFormRecordComposer = () => {
    const { yearConfig } = useContext(CompanyContext);
    const { companyBuTimeframes } = useContext(BuContext);
    const { selectedRecordGroup } = useContext(TableViewContext);
    const formState = useContext(RecordFormStateContext);
    const productFormConfig = useFormConfig();

    const composeFormItem = useCallback(() => {
        const { editableRecordItem, isNettoMode, recordDate } = formState;
        const requiredState = { editableRecordItem, isNettoMode, recordDate };
        return RecordFormUtils.constructRecordItem(
            requiredState,
            productFormConfig,
            yearConfig.skr,
            companyBuTimeframes,
            selectedRecordGroup
        );
    }, [formState, productFormConfig, yearConfig, companyBuTimeframes, selectedRecordGroup]);

    const composeFormRecord = useCallback(() => {
        const { editableRecord, recordDate, recordItems } = formState;
        const requiredState = { editableRecord, recordDate };
        const record = RecordFormUtils.constructRecord(
            requiredState,
            productFormConfig,
            yearConfig.skr,
            companyBuTimeframes,
            selectedRecordGroup
        );
        if (recordItems?.length > 0) {
            record.items = [...recordItems];
        } else {
            record.items = [composeFormItem()];
        }
        if (productFormConfig.useItemBelegfeld1) {
            record.num = undefined; // cleanup just in case
        }
        record.calculateBrutto();
        return record;
    }, [formState, productFormConfig, composeFormItem, yearConfig, companyBuTimeframes, selectedRecordGroup]);

    return useMemo(() => ({ composeFormRecord, composeFormItem }), [composeFormItem, composeFormRecord]);
};
