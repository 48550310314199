import React, { type FC } from "react";
import { NotValidFileItem } from "./NotValidFileItem";
import { Typography } from "antd";
import { type UploadChangeParam } from "antd/es/upload";

interface IProps {
    notValidFiles: UploadChangeParam[];
}

export const NotValidFiles: FC<IProps> = ({ notValidFiles }) => {
    const notValidFilesArr = notValidFiles.map((el, i) => {
        return (
            <div key={i}>
                <NotValidFileItem fileName={el.file.name} />
            </div>
        );
    });

    return (
        <div style={{ marginBottom: "20px" }}>
            <Typography.Text type={"secondary"}>{notValidFilesArr.length} files is not valid</Typography.Text>
            {notValidFilesArr}
        </div>
    );
};
