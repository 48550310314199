import React, { FC } from "react";
import { Button, ConfigProvider, Layout } from "antd";
import { InvoicesListModule } from "@inv/modules";
import { PageHeader } from "@inv/components/PageHeader/PageHeader";
import { themeConfig } from "@inv/theme";
import { Footer } from "antd/es/layout/layout";
import { AppRoutes } from "../../../scripts/routing/routeConstants";
import { useNavigate } from "react-router-dom";
import { InvoicePagesTitle } from "@inv/types";

const { Header, Content } = Layout;

export const InvoicesListLayout: FC = () => {
    const navigate = useNavigate();
    const handleMoveToCreateInvoice = () => {
        navigate(AppRoutes.invoices + "/create-invoice-form");
    };
    return (
        <ConfigProvider
            theme={{
                components: {
                    Layout: {
                        headerBg: themeConfig.colorLight,
                        headerHeight: "auto",
                        headerPadding: 0,
                        footerBg: themeConfig.colorLight,
                    },
                },
            }}
        >
            <Layout
                style={{
                    margin: "0 auto",
                    backgroundColor: themeConfig.colorLight,
                }}
            >
                <Header>
                    <PageHeader titleId={InvoicePagesTitle.INVOICES_LIST} />
                </Header>
                <Content>
                    <InvoicesListModule />
                </Content>
                <Footer>
                    <Button onClick={handleMoveToCreateInvoice}>Create invoice</Button>
                </Footer>
            </Layout>
        </ConfigProvider>
    );
};
