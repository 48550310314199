import { Form, Typography } from "antd";
import React, { type FC, useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { FormWrapperContext } from "@dms/modules/DocumentFormModule/context/FormWrapperContext";
import { GroupFormContext } from "@dms/modules/GroupFormModules/context/GroupFormContext";
import { SingleFormContext } from "@dms/modules/SingleFormModule/context/SingleFormContext";

import { DocumentFileNameInput } from "../DocumentInputs/DocumentFileNameInput";
import { FieldLabel } from "@app/components/shared/form/baseComponents/FieldLabel";
import { FormItemCheckbox } from "../DocumentInputs/FormItemCheckbox";

const { Text } = Typography;

const SingleFileNameField: FC = () => {
    const { fileNameSuffix } = useContext(SingleFormContext);
    return (
        <FieldLabel
            className={"DMSForm__Item"}
            label={
                <Text strong ellipsis>
                    {<FormattedMessage id="app.dms.fileName" />}
                </Text>
            }
            rules={[{ required: true, message: "" }]}
            name={"fileName"}
        >
            <DocumentFileNameInput suffix={fileNameSuffix} />
        </FieldLabel>
    );
};

const GroupFileNameField = () => {
    const [isDisabled, setIsDisabled] = useState<boolean>(true);

    const { notEqualField, isCancel, requiredFields, fileNameSuffix } = useContext(GroupFormContext);

    useEffect(() => {
        if (isCancel) {
            setIsDisabled(true);
        }
    }, [isCancel]);

    const placeholder = notEqualField.includes("fileName") ? "Diverse" : undefined;

    return (
        <>
            <div style={{ position: "relative" }}>
                <Form.Item initialValue={false} name={"fileNameCheckbox"} valuePropName={"checked"} noStyle={true}>
                    <FormItemCheckbox parentFieldName={"fileName"} setIsDisabled={arg => setIsDisabled(arg)} />
                </Form.Item>
            </div>
            <FieldLabel
                className={"DMSForm__Item"}
                label={
                    <Text strong ellipsis>
                        {<FormattedMessage id="app.dms.fileName" />}
                    </Text>
                }
                rules={[{ required: requiredFields.includes("fileName"), message: "" }]}
                name={"fileName"}
            >
                <DocumentFileNameInput placeholder={placeholder} disabled={isDisabled} suffix={fileNameSuffix} />
            </FieldLabel>
        </>
    );
};

export const DocumentFileNameField = () => {
    const { isGroupEditor } = useContext(FormWrapperContext);

    return <>{isGroupEditor ? <GroupFileNameField /> : <SingleFileNameField />}</>;
};
