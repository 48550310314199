import { Checkbox, Empty } from "antd";
import React, { type FC, useContext, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeGrid } from "react-window";

import { DmsAppContext, DmsAppControlContext, DmsUserSettingsContext } from "@dms/types/ContextTypes";
import { DmsUtils } from "@dms/scripts/utils/DmsUtils";

import { DocumentCard } from "../DocumentCard/DocumentCard";
import { GroupButtons } from "@dms/components/GroupButtons/GroupButtons";
import styles from "./documentGrid.module.scss";
import { IDocumentEnriched } from "@dms/types";

interface IProps {
    dataSource: IDocumentEnriched[];
}

export const DocumentGrid: FC<IProps> = ({ dataSource }) => {
    const [checkedAll, setCheckedAll] = useState(false);
    const [indeterminate, setIndeterminate] = useState(false);

    const { userConfig } = useContext(DmsUserSettingsContext);
    const { selectedRowKeys, activeType } = useContext(DmsAppContext);
    const { resetSelectedRows, selectRows } = useContext(DmsAppControlContext);
    const selectedFilter = Boolean(DmsUtils.getFilterConfig(userConfig.filterConfig, activeType)?.selectedFilter);
    const sortedDocumentData = useMemo(() => {
        const data = selectedFilter ? dataSource.filter(item => selectedRowKeys.includes(item.key)) : dataSource;
        return data.sort((doc1, doc2) => {
            return DmsUtils.documentSorter(doc2, doc1, "documentDate", "date");
        });
    }, [dataSource, selectedFilter, selectedRowKeys]);

    useEffect(() => {
        if (selectedRowKeys.length === 0) {
            setCheckedAll(false);
            setIndeterminate(false);
            return;
        }

        if (selectedRowKeys.length === sortedDocumentData.length) {
            setCheckedAll(true);
            setIndeterminate(false);
            return;
        }

        if (selectedRowKeys.length < sortedDocumentData.length) {
            setCheckedAll(false);
            setIndeterminate(true);
        }
    }, [sortedDocumentData, selectedRowKeys]);

    const handleSelectAll = (checked: boolean): void => {
        if (indeterminate) {
            setCheckedAll(false);
            resetSelectedRows();
            return;
        }

        setCheckedAll(checked);

        if (checked) {
            const allKeys = sortedDocumentData.map(doc => doc.key);
            selectRows(allKeys);
            return;
        }
        resetSelectedRows();
    };

    const columnWidth = 290;
    const rowHeight = 440;

    return (
        <>
            <div className={styles.header}>
                {selectedRowKeys.length > 1 ? (
                    <div className={styles.header__buttons}>
                        <GroupButtons documentData={sortedDocumentData} />
                    </div>
                ) : null}
                <span className={styles.header__total}>
                    <FormattedMessage id="app.dms.total" />{" "}
                    <FormattedMessage id="app.dms.totalDocuments" values={{ count: sortedDocumentData.length }} />
                </span>
            </div>
            <div className={styles["header-bottom"]}>
                <Checkbox
                    checked={checkedAll}
                    indeterminate={indeterminate}
                    style={{ marginRight: "10px" }}
                    onChange={e => {
                        handleSelectAll(e.target.checked);
                    }}
                />
                <FormattedMessage id="app.dms.selectAll" />
            </div>
            <div className={styles.body}>
                {sortedDocumentData.length > 0 ? (
                    <AutoSizer>
                        {({ height, width }: { height: number; width: number }) => {
                            const columnCount = Math.floor(width / columnWidth);
                            return (
                                <FixedSizeGrid
                                    itemData={sortedDocumentData}
                                    columnCount={columnCount}
                                    columnWidth={columnWidth}
                                    height={height}
                                    rowCount={Math.ceil(sortedDocumentData.length / columnCount)}
                                    rowHeight={rowHeight}
                                    width={width}
                                >
                                    {({ columnIndex, rowIndex, style, data }) => {
                                        const index = rowIndex * columnCount + columnIndex;
                                        const item = data[index];

                                        return (
                                            <>
                                                {item ? (
                                                    <div style={{ ...style, height: null }}>
                                                        {item && (
                                                            <DocumentCard
                                                                document={item}
                                                                index={index}
                                                                key={item.key}
                                                            />
                                                        )}
                                                    </div>
                                                ) : null}
                                            </>
                                        );
                                    }}
                                </FixedSizeGrid>
                            );
                        }}
                    </AutoSizer>
                ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
            </div>
        </>
    );
};
