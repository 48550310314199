import { DmsModal, UploaderModal } from "@dms/index";
import Preview from "./Preview";
import React, {
    DragEventHandler,
    FC,
    forwardRef,
    PropsWithChildren,
    useContext,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from "react";
import { AddDmsIdInput } from "./AddDmsIdInput";
import { Badge, Button, Card, Col, Flex, Modal, Row } from "antd";
import {
    CloseOutlined,
    CloudOutlined,
    CloudUploadOutlined,
    FileAddOutlined,
    PaperClipOutlined,
} from "@ant-design/icons";
import { DmsDataContext, DmsAppControlContext } from "@dms/types/ContextTypes";
import { DocumentThumbnail } from "./DocumentThumbnail";
import { FormattedMessage } from "react-intl";
import { GQL } from "@binale-tech/shared";
import { TableViewContext } from "../../../../scripts/context/tableViewContext/tableViewContext";
import { useCanRead, useIsMounted } from "../../../../scripts/infrastructure/hooks";

import "./FileUploader.css";
import { RecordFormStateContext } from "@app/components/recordform/context/RecordFormState";
import { DocumentsCardMinWidth, FormCardStyle, SplitCardWidth } from "@app/components/recordform/types/constants";

interface IButtonProps {
    count?: number;
    disabled?: boolean;
    onClick: (e: React.MouseEvent<HTMLElement>) => void;
}

// if there are no files, then open uploader popup
// if there are files, fire onClick
export const FUButton = React.forwardRef<HTMLButtonElement, IButtonProps>(function FUButton(
    { onClick, count, disabled },
    btnRef
) {
    return (
        <Badge className="FileUploader FileUploader__Badge" count={count} styles={{ indicator: { zIndex: 99 } }}>
            <Button ref={btnRef} disabled={disabled} onClick={onClick} role="button" icon={<PaperClipOutlined />} />
        </Badge>
    );
});

interface ViewerProps {
    addedDocumentIds: string[];
    visible: boolean;
    onHide: (e: React.MouseEvent) => void;
    onSave?: (vs: GQL.IRecordDocumentInput[]) => void;
    useFullScreenMode?: boolean;
    allowEdit?: boolean;
    productKey?: GQL.IProductKey;
    onFileListChanged?: (ids: string[]) => void;
}

const supportedProductKeys = [
    GQL.IProductKey.Er,
    GQL.IProductKey.Deb,
    GQL.IProductKey.Pos,
    GQL.IProductKey.La,
    GQL.IProductKey.Fe,
    GQL.IProductKey.Bank,
    GQL.IProductKey.Kb,
];

type CardWrapperProps = Pick<ViewerProps, "visible" | "onHide"> & {
    onDragEnter: DragEventHandler;
    footer: React.ReactNode;
};

type CardRef = {
    makeSpace: () => void;
    resetSpace: () => void;
};

const makeSpace = (visible: boolean, div: HTMLDivElement, skipAnimation?: boolean) => {
    if (visible && div) {
        const availableSpace = window.innerWidth - 10;
        const suggestedOffsetLeft = availableSpace - SplitCardWidth - div.getBoundingClientRect().width - 5;
        if (suggestedOffsetLeft < 0) {
            div.animate([{ transform: "translateX(0px)" }, { transform: `translateX(${suggestedOffsetLeft}px)` }], {
                duration: skipAnimation ? 0 : 250,
                iterations: 1,
                easing: "ease-in-out",
            });

            div.style.transform = `translateX(${suggestedOffsetLeft}px)`;
        }
    }
};
const resetSpace = (visible: boolean, div: HTMLDivElement) => {
    if (visible && div) {
        div.animate([{ transform: div.style.transform }, { transform: `translateX(0px)` }], {
            duration: 250,
            iterations: 1,
            easing: "ease-in-out",
        });
        div.style.transform = `translateX(0px)`;
    }
};

const CardWrapper = forwardRef<CardRef, PropsWithChildren<CardWrapperProps>>(function CardWrapper(
    { children, visible, onHide, onDragEnter, footer },
    ref
) {
    const { isModalOpen } = useContext(RecordFormStateContext);
    const isMounted = useIsMounted();
    const cardRef = useRef<HTMLDivElement>();

    useImperativeHandle(ref, () => ({
        makeSpace: () => makeSpace(visible, cardRef.current),
        resetSpace: () => resetSpace(visible, cardRef.current),
    }));
    useEffect(() => {
        if (isMounted() && isModalOpen) {
            makeSpace(visible, cardRef.current);
        }
        if (isMounted() && !isModalOpen) {
            resetSpace(visible, cardRef.current);
        }
    }, [visible, isModalOpen, isMounted]);
    if (!visible) {
        return null;
    }
    return (
        <Card
            ref={cardRef}
            size={"small"}
            title={<FormattedMessage id="app.dms.add_documents" />}
            extra={<Button size={"small"} shape={"circle"} icon={<CloseOutlined />} onClick={onHide} />}
            style={{
                ...FormCardStyle,
                minWidth: DocumentsCardMinWidth,
                width: "calc(48vw)",
                left: 5,
            }}
            styles={{
                body: { overflowY: "auto", height: "calc(100% - 35px)", position: "relative", padding: 5 },
            }}
            onDragEnter={onDragEnter}
        >
            <Flex vertical style={{ position: "relative", height: "100%" }}>
                <Row style={{ position: "relative", height: "calc(100% - 30px)", overflowY: "scroll" }}>{children}</Row>
                <div style={{ marginTop: 5 }}>{footer}</div>
            </Flex>
        </Card>
    );
});

const ModalWrapper: FC<
    PropsWithChildren<
        Pick<ViewerProps, "visible" | "onHide"> & {
            onDragEnter: DragEventHandler;
            footer: React.ReactNode;
        }
    >
> = ({ children, visible, onHide, onDragEnter, footer }) => {
    return (
        <Modal
            className="FileUploader"
            styles={{
                body: {
                    minWidth: "70vw",
                    height: `calc(100vh - 125px)`,
                },
            }}
            style={{ top: 10, display: "flex", alignItems: "center", justifyContent: "center" }}
            width={1000}
            open={visible}
            onCancel={onHide}
            destroyOnClose
            wrapProps={{ onDragEnter }}
            footer={[footer]}
        >
            <Row style={{ height: "100%" }}>{children}</Row>
        </Modal>
    );
};

export type FileUploaderRefProps = CardRef & { getFiles: () => null | { uploadedFiles: GQL.IRecordDocumentInput[] } };

export const FUViewer = React.forwardRef<FileUploaderRefProps, ViewerProps>(function FUViewer(props, ref) {
    const cardRef = useRef<CardRef>();
    const { documentsKV } = useContext(DmsDataContext);
    const { resetAppContext } = useContext(DmsAppControlContext);
    const hasDMSAccess = useCanRead(GQL.IProductKey.Dms);
    const [documentIds, setDocumentIds] = useState<string[]>([]);
    const [selectedDocumentIds, setSelectedDocumentIds] = useState<number>(0);
    const [previewDocumentId, setPreviewDocumentId] = useState<string | null>(null);
    const [dmsDocumentIds, setDmsDocumentIds] = useState<string[]>([]);
    const [uploadedFileIds, setUploadedFileIds] = useState<Set<string>>(new Set());
    const [searchFileId, setSearchFileId] = useState<string[]>([]);
    const [recordFiles, setRecordFiles] = useState<GQL.IRecordDocumentInput[]>([]);

    const { selectedRecordGroup } = useContext(TableViewContext);

    const [showDmsModal, setShowDmsModal] = useState(false);
    const [showUploaderModal, setShowUploaderModal] = useState(false);

    const productKey = supportedProductKeys.includes(props.productKey) ? props.productKey : undefined;

    const combinedDocumentIds = useMemo(() => {
        return [...new Set([...documentIds, ...uploadedFileIds, ...dmsDocumentIds, ...searchFileId])];
    }, [documentIds, uploadedFileIds, dmsDocumentIds, searchFileId]);

    React.useImperativeHandle(ref, () => {
        return {
            getFiles: () => ({ uploadedFiles: [...recordFiles] }),
            makeSpace: () => cardRef.current?.makeSpace(),
            resetSpace: () => cardRef.current?.resetSpace(),
        };
    }, [recordFiles]);

    useEffect(() => {
        setDocumentIds(props.addedDocumentIds);

        if (props.addedDocumentIds?.length) {
            setPreviewDocumentId(props.addedDocumentIds[0]);
        }
    }, [props.addedDocumentIds]);

    useEffect(() => {
        setPreviewDocumentId(null);
        setSelectedDocumentIds(0);
        setDmsDocumentIds([]);
        setUploadedFileIds(new Set());
        setSearchFileId([]);
    }, [props.visible]);

    useEffect(() => {
        setSelectedDocumentIds(0);
        setPreviewDocumentId(combinedDocumentIds[0]);
        if (props.visible && props.onFileListChanged) {
            props.onFileListChanged(combinedDocumentIds);
        }
    }, [combinedDocumentIds]);

    useEffect(() => {
        const allAvailableFiles = combinedDocumentIds.filter(id => Boolean(documentsKV[id]));
        const recordFilesArr: GQL.IRecordDocumentInput[] = allAvailableFiles.map(el => {
            return {
                id: el,
                url: documentsKV[el].fileUrl,
            };
        });
        setRecordFiles(recordFilesArr);
    }, [combinedDocumentIds, documentsKV]);

    const hasChanges = () => {
        const setA = props.addedDocumentIds ? new Set(props.addedDocumentIds) : new Set<string>();
        const setB = new Set(combinedDocumentIds);

        if (setA.size !== setB.size) {
            return true;
        }
        const aDiffB = new Set([...setA].filter(x => !setB.has(x)));
        const bDiffA = new Set([...setB].filter(x => !setA.has(x)));
        return aDiffB.size > 0 || bDiffA.size > 0;
    };

    const handleDelete = (key: string) => {
        if (!props.allowEdit) {
            return;
        }

        setDmsDocumentIds(prev => prev.filter(v => v !== key));
        setDocumentIds(prev => prev.filter(v => v !== key));
        setUploadedFileIds(prev => {
            prev.delete(key);
            return new Set([...prev]);
        });
        setSearchFileId(prev => prev.filter(v => v !== key));
    };

    const handleDmsCancel = () => {
        setShowDmsModal(false);
    };

    const handleUploadFile = (fileKeys: string[]) => {
        setUploadedFileIds(prevUploadedFiles => {
            fileKeys.forEach(key => {
                prevUploadedFiles.add(key);
            });
            return new Set([...prevUploadedFiles]);
        });
    };

    const handleSave = () => {
        props.onSave(recordFiles);
    };

    const onDragEnter: DragEventHandler = e => {
        e.preventDefault();
        if (!props.allowEdit || showDmsModal) {
            return;
        }
        if (!showUploaderModal) {
            setShowUploaderModal(true);
        }
    };

    useEffect(() => {
        if (props.visible) {
            resetAppContext();
        }
    }, [resetAppContext, props.visible]);

    const footer = props.allowEdit ? (
        <Row gutter={10} key="row" justify={"end"}>
            <Col span={10}>
                <AddDmsIdInput handleAddSearchedId={id => setSearchFileId([id])} />
            </Col>
            <Col span={4}>
                <Button
                    onClick={() => setShowUploaderModal(true)}
                    icon={<CloudUploadOutlined />}
                    className="FileUploader__Button"
                >
                    <strong className="button-text button-media">
                        <FormattedMessage id="app.button.upload" />
                    </strong>
                </Button>
                {showUploaderModal && (
                    <UploaderModal
                        isOpen={showUploaderModal}
                        onCancel={() => setShowUploaderModal(false)}
                        onFilesAdd={handleUploadFile}
                        productData={{ productKey, selectedRecordGroup }}
                    />
                )}
            </Col>
            <Col span={6}>
                {hasDMSAccess && (
                    <>
                        <Button
                            onClick={() => setShowDmsModal(true)}
                            icon={<CloudOutlined />}
                            className="FileUploader__Button"
                        >
                            <span className="button-text button-media">
                                <FormattedMessage id="app.dms.selectInDms" />
                            </span>
                        </Button>

                        <DmsModal
                            productKey={productKey}
                            visible={showDmsModal}
                            selectedFiles={combinedDocumentIds}
                            onCancel={handleDmsCancel}
                            onSave={fileKeys => {
                                setDmsDocumentIds(fileKeys);
                                setShowDmsModal(false);
                            }}
                        />
                    </>
                )}
            </Col>
            <Col span={4}>
                <Button
                    className="FileUploader__Button"
                    type="primary"
                    onClick={handleSave}
                    disabled={!hasChanges()}
                    key="save"
                >
                    <span className="button-text">
                        <FormattedMessage id="app.button.save" />
                    </span>
                </Button>
            </Col>
        </Row>
    ) : null;

    const body = (
        <>
            <Col span={6} style={{ height: "100%" }}>
                <div
                    style={{
                        overflowY: "auto",
                        marginRight: 2,
                        height: "100%",
                    }}
                >
                    {combinedDocumentIds.length ? (
                        combinedDocumentIds.map((key, idx) => {
                            return (
                                <DocumentThumbnail
                                    fileId={key}
                                    key={"tn-" + idx}
                                    onDelete={props.allowEdit ? () => handleDelete(key) : undefined}
                                    onClick={() => {
                                        setPreviewDocumentId(combinedDocumentIds[idx]);
                                        setSelectedDocumentIds(idx);
                                    }}
                                    selected={selectedDocumentIds === idx}
                                />
                            );
                        })
                    ) : (
                        <div className={"FileUploader__empty"}>
                            <FileAddOutlined />
                        </div>
                    )}
                </div>
            </Col>
            <Col span={18} style={{ height: "100%", paddingLeft: 25 }}>
                <Preview fileId={previewDocumentId} />
            </Col>
        </>
    );
    return props.useFullScreenMode ? (
        <CardWrapper
            ref={cardRef}
            onDragEnter={onDragEnter}
            footer={footer}
            visible={props.visible}
            onHide={props.onHide}
        >
            {body}
        </CardWrapper>
    ) : (
        <ModalWrapper onDragEnter={onDragEnter} footer={footer} visible={props.visible} onHide={props.onHide}>
            {body}
        </ModalWrapper>
    );
});
