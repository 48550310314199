import React, { FC } from "react";
import { Alert, Button, Card, Result, Space, Steps } from "antd";
import { AppstoreAddOutlined, AuditOutlined, SmileOutlined, UserOutlined } from "@ant-design/icons";
import { AuthLayout } from "./AuthLayout";
import { CompanyFormModal } from "../../components/company/CompanyFormModal";
import { CompanyInvoiceData } from "../../components/company/CompanyInvoiceData";
import { FormattedMessage } from "react-intl";
import { GQL } from "@binale-tech/shared";
import { StepsProps } from "antd/es/steps";
import { UserContext } from "scripts/context/UserProvider";

const steps: StepsProps["items"] = [
    {
        title: "Confirmation",
        icon: <UserOutlined />,
    },
    {
        title: "Company data",
        icon: <AppstoreAddOutlined />,
    },
    {
        title: "Invoice information",
        icon: <AuditOutlined />,
    },
    {
        title: "Done",
        icon: <SmileOutlined />,
    },
];

type Step1Props = {
    onComplete: () => void;
};

const Step1: React.FC<Step1Props> = ({ onComplete }) => {
    const { fireUser } = React.useContext(UserContext);
    return (
        <div>
            <Card title={"You have completed registration!"}>
                <p>
                    Click{" "}
                    <strong>
                        <FormattedMessage id={"app.button.next"} />
                    </strong>{" "}
                    to create a company
                </p>
                <p>
                    <Alert
                        type="info"
                        message={
                            <div>
                                If you are a member of the existing company, please ask your administrator to invite you
                                to the company using your email <strong>{fireUser.email}</strong>
                            </div>
                        }
                    />
                </p>
            </Card>
            <Space style={{ marginTop: 16 }}>
                <Button type="primary" autoFocus onClick={onComplete}>
                    <FormattedMessage id={"app.button.next"} />
                </Button>
            </Space>
        </div>
    );
};
type Step2Props = {
    onHide: () => void;
    onComplete: (v: GQL.ICompany) => void;
};
const Step2: React.FC<Step2Props> = ({ onComplete, onHide }) => {
    return (
        <div>
            <CompanyFormModal onHide={onHide} companyId={""} onComplete={onComplete} />
        </div>
    );
};

const Step4: React.FC = () => {
    return (
        <Result
            status="success"
            title="Sie haben erfolgreich eine Firma gegründet!"
            subTitle="Alle notwendigen Einstellungen wurden vorgenommen, nun können Sie die App nutzen"
            extra={[
                <Button type="primary" key={"refresh"} onClick={() => window.location.reload()}>
                    zur Binale App
                </Button>,
            ]}
        />
    );
};
type Props = {
    stepInvoice?: boolean;
    companyGQL?: GQL.ICompany;
};
export const CompanyStepper: FC<Props> = ({ stepInvoice, companyGQL }) => {
    const [current, setCurrent] = React.useState(stepInvoice ? 2 : 0);
    const [company, setCompany] = React.useState<GQL.ICompany>(companyGQL);

    return (
        <AuthLayout title={"Firma anlegen"} width={1000}>
            <Steps current={current} items={steps} />
            {current === 0 && (
                <Step1
                    onComplete={() => {
                        setCurrent(1);
                    }}
                />
            )}
            {current === 1 && (
                <Card>
                    <Step2
                        onHide={() => setCurrent(0)}
                        onComplete={v => {
                            setCompany(v);
                            setCurrent(2);
                        }}
                    />
                </Card>
            )}
            {current === 2 && (
                <CompanyInvoiceData
                    company={company}
                    onComplete={v => {
                        setCompany(v);
                        setCurrent(3);
                    }}
                />
            )}
            {current === 3 && <Step4 />}
        </AuthLayout>
    );
};
