import { useCallback, useContext, useMemo } from "react";
import { IGenericItem, IGenericRecord } from "../../../../scripts/models/Interfaces";
import {
    getDefaultItem,
    getDefaultRecord,
    RecordFormStateContext,
    RecordFormStateControlContext,
} from "../context/RecordFormState";
import RecordFormState from "../types/RecordFormState";
import { TableViewContext } from "../../../../scripts/context/tableViewContext/tableViewContext";
import { GenericRecordUtils } from "../../../../scripts/models/utils/GenericRecordUtils";
import { focusFirstFormField } from "../../../../scripts/infrastructure/helpers/focus";
import { RecordFormPropsContext } from "../context/RecordFormPropsContext";
import { logger } from "../../../../scripts/infrastructure/logger";
import { useFormRecordComposer } from "../hooks/useFormRecordComposer";
import { useFormValidators } from "../hooks/useFormValidators";

export const useFormRecordLifecycle = () => {
    const { composeFormRecord } = useFormRecordComposer();
    const { validateInlineForm } = useFormValidators();

    const { product } = useContext(TableViewContext);
    const { refsHtml, isTemplate } = useContext(RecordFormPropsContext);
    const formState = useContext(RecordFormStateContext);
    const {
        setSelectedPayment,
        setEditableRecordItem,
        setRecordVirtualNetto,
        setRecordItems,
        setEditableRecord,
        setRecordDate,
        setRecordValidationStates,
        setItemValidationStates,
        setIsModalOpen,
        setIsModalTemplateOpen,
    } = useContext(RecordFormStateControlContext);
    const { onClear: onClearExternal, onSave: onSaveExternal } = useContext(RecordFormPropsContext);

    const resetRecord = useCallback(() => {
        setIsModalTemplateOpen(false);
        setRecordVirtualNetto(null);
        setSelectedPayment(null);
        setRecordItems([]);
        setEditableRecord(prevState => ({
            ...getDefaultRecord(),
            recordDraft: isTemplate ? true : (prevState?.recordDraft ?? false),
        }));
        refsHtml.REF_cASSET.current?.resetState();
        refsHtml.modalFormRef.current?.resetState();
    }, [
        setIsModalTemplateOpen,
        setRecordVirtualNetto,
        setSelectedPayment,
        setRecordItems,
        setEditableRecord,
        refsHtml.REF_cASSET,
        refsHtml.modalFormRef,
        isTemplate,
    ]);

    const resetItem = useCallback(
        (overrides: Partial<RecordFormState["editableRecordItem"]> = {}) => {
            setEditableRecordItem({ ...getDefaultItem(), ...overrides });
        },
        [setEditableRecordItem]
    );

    const receiveItem = useCallback(
        (i: IGenericItem, nettoConfig?: { netto: number }) => {
            setSelectedPayment(null);
            const editableItem = GenericRecordUtils.convertRecordItemToForm(i, product);
            if (nettoConfig) {
                editableItem.itemBrutto = nettoConfig.netto;
            }
            setEditableRecordItem(editableItem);
        },
        [product, setEditableRecordItem, setSelectedPayment]
    );

    // zero deps effectively (only product from setItem)
    const receiveRecord = useCallback(
        (r: IGenericRecord) => {
            setRecordValidationStates(new Map());
            setItemValidationStates(new Map());

            resetRecord();
            resetItem();
            setRecordItems([]);

            if (!r) {
                return;
            }
            setRecordVirtualNetto(null);
            setRecordDate({ date: r.date, period: r.period });
            setEditableRecord(GenericRecordUtils.convertRecordToForm(r));

            if (r.items?.length > 0) {
                if (r.items.length === 1) {
                    receiveItem(r.items[0]);
                    setRecordItems([]);
                } else {
                    resetItem();
                    setRecordItems(r.items);
                }
            }
        },
        [
            setRecordValidationStates,
            setItemValidationStates,
            setRecordVirtualNetto,
            setRecordDate,
            setEditableRecord,
            resetRecord,
            resetItem,
            setRecordItems,
            receiveItem,
        ]
    );

    const onClearForm = useCallback(() => {
        resetRecord();
        resetItem();

        setRecordValidationStates(new Map());
        setItemValidationStates(new Map());

        if (onClearExternal) {
            onClearExternal();
        }
        setIsModalOpen(false);
        setTimeout(focusFirstFormField, 100);
    }, [onClearExternal, resetItem, resetRecord, setIsModalOpen, setItemValidationStates, setRecordValidationStates]);

    const validateAndSave = useCallback(() => {
        // not needed (most likely) as we now apply asset changes on fly
        // const assetsData = refs.REF_cASSET.current.getFiles();
        // if (assetsData) {
        //     setAssetsToState(assetsData.uploadedFiles);
        // }

        if (!validateInlineForm()) {
            return;
        }
        const composedRecord = composeFormRecord();

        if (composedRecord.items?.length === 0) {
            logger.error(composedRecord);
            throw new Error("attempt to save a record without items");
        }

        onSaveExternal(composedRecord, formState.selectedPayment);

        resetItem();
        resetRecord();
        setIsModalOpen(false);
        setTimeout(focusFirstFormField, 100);
    }, [
        validateInlineForm,
        composeFormRecord,
        onSaveExternal,
        formState.selectedPayment,
        resetItem,
        resetRecord,
        setIsModalOpen,
    ]);

    return useMemo(
        () => ({ receiveItem, resetRecord, resetItem, receiveRecord, onClearForm, validateAndSave }),
        [receiveItem, resetRecord, resetItem, receiveRecord, onClearForm, validateAndSave]
    );
};
