import { Currency, GQL, Utils } from "@binale-tech/shared";
import { CurrencyValue } from "@binale-tech/ui-components";
import { Button, Form, Input, Select, Space } from "antd";
import React, { forwardRef, useEffect, useRef, useState } from "react";

import { floatInputBlocker, formatDefault, getMovementType, parseNumber } from "@dms/scripts/helpers";
import { DmsType, MovementType } from "@dms/types";
import { useApolloClient } from "@apollo/client";
import { DownloadOutlined } from "@ant-design/icons";
import {
    composeCurrencyValue,
    getEuroAmount,
    handleKeyDown,
    isRetrieveCurrencyRate,
} from "@dms/components/DocumentForm/utils";
import { retrieveCurrencyRate } from "../../../../scripts/infrastructure/queries/retrieveCurrencyRate";

type CurrencyInputBlockProps = {
    value?: Partial<CurrencyValue>;
    allowConfigEdit?: boolean;
    documentType?: DmsType;
    movementType?: MovementType;
    setMovementType?: (arg: MovementType) => void;
    label?: React.ReactNode;
    onChange?: (value: Partial<CurrencyValue>) => void;
};

export const CurrencyInput = forwardRef<any, CurrencyInputBlockProps>(function CurrencyInputBlock(props, ref) {
    const { allowConfigEdit = true, value: currencyValue, movementType, documentType, setMovementType } = props;
    const client = useApolloClient();
    const form = Form.useFormInstance();
    const documentDate = form.getFieldValue("documentDate") ?? null;
    const isRetrieve = isRetrieveCurrencyRate(documentDate);

    const [type, setType] = useState(movementType);
    const [isRateLoading, setIsRateLoading] = useState(false);

    const ccCode = currencyValue?.currency?.code || GQL.ICurrencyCode.Eur;
    const ccRate = currencyValue?.currency?.rate ?? 1;

    const [originalAmountStr, setOriginalAmountStr] = useState(formatDefault(currencyValue?.originalAmount ?? 0));
    const [rateStr, setRateStr] = useState(Utils.CurrencyUtils.numberFormat(ccRate, 4));
    const [euroValueRes, setEuroValueRes] = useState(formatDefault(currencyValue?.amount ?? 0));

    const [openOptions, setOpenOptions] = useState(false);
    const prevCcCode = useRef(ccCode);

    useEffect(() => {
        const originalAmount = currencyValue?.originalAmount ?? currencyValue?.amount ?? 0;
        const euroAmount = currencyValue?.amount ?? 0;

        setOriginalAmountStr(formatDefault(originalAmount));
        setRateStr(Utils.CurrencyUtils.numberFormat(currencyValue?.currency?.rate ?? 1, 4));
        setEuroValueRes(formatDefault(euroAmount ?? 0));
    }, [currencyValue, type]);

    useEffect(() => {
        if (ccCode !== GQL.ICurrencyCode.Eur) {
            const originalAmountNum = Math.round(parseNumber(originalAmountStr) * 100);
            const euroAmount = Utils.CurrencyUtils.getEuroFromCurrency(originalAmountNum, parseNumber(rateStr) ?? 1);
            setEuroValueRes(formatDefault(euroAmount));
        }
    }, [rateStr, originalAmountStr]);

    const sendBack = (euroAmount: number, originalAmount: number, code: GQL.ICurrencyCode) => {
        const value: Partial<CurrencyValue> = {
            amount: euroAmount,
            currency: {
                rate: 1,
                code: GQL.ICurrencyCode.Eur,
            },
        };

        if (code !== GQL.ICurrencyCode.Eur) {
            value.currency = {
                rate: parseNumber(rateStr),
                code,
            };

            value.originalAmount = originalAmount;
        }

        prevCcCode.current = code;
        if (props.onChange) {
            props.onChange(value);
        }
    };

    const handleChange = (code?: GQL.ICurrencyCode) => {
        const codeNow = code || ccCode;

        let isNegative: boolean;
        let originalAmount = Math.round(parseNumber(originalAmountStr) * 100);
        let euroAmount = Math.round(parseNumber(euroValueRes) * 100);

        if (movementType === MovementType.RECEIPT) {
            isNegative = documentType === DmsType.ER;
        } else {
            isNegative = documentType !== DmsType.ER;
        }

        if (!isNegative) {
            originalAmount = Math.abs(originalAmount);
            euroAmount = Math.abs(euroAmount);
        } else {
            originalAmount = Math.abs(originalAmount) * -1;
            euroAmount = Math.abs(euroAmount) * -1;
        }

        sendBack(euroAmount, originalAmount, codeNow);
    };

    const handleBlur = (code?: GQL.ICurrencyCode) => {
        const codeNow = code || ccCode;

        const originalAmount = Math.round(parseNumber(originalAmountStr) * 100);
        const euroAmount = Math.round(parseNumber(euroValueRes) * 100);

        sendBack(euroAmount, originalAmount, codeNow);

        setMovementType && setMovementType(getMovementType(euroAmount, documentType));
    };

    const handleCurrencyClick = () => {
        setIsRateLoading(true);
        console.log({ documentDate });
        retrieveCurrencyRate({ client, documentDate, currencyCode: ccCode })
            .then(rate => {
                const stringRate = rate.toFixed(4).replace(".", ",");
                setRateStr(stringRate);

                const euroAmount = getEuroAmount(originalAmountStr, stringRate);
                const value = composeCurrencyValue(euroAmount, ccCode, originalAmountStr, stringRate);
                props?.onChange(value);
            })
            .finally(() => setIsRateLoading(false));
    };

    useEffect(() => {
        if (movementType === type) {
            return;
        }

        handleChange();
        setType(movementType);
    }, [movementType]);

    useEffect(() => {
        handleBlur();
    }, [documentType]);

    return (
        <Space.Compact block>
            <Select
                value={ccCode}
                onChange={handleBlur}
                style={{ width: "20%" }}
                disabled={!allowConfigEdit}
                data-testid="select-currency"
                onBlur={() => setOpenOptions(false)}
                onDropdownVisibleChange={setOpenOptions}
                open={openOptions}
                id="currencySelectID"
                onKeyDown={event => handleKeyDown(event, { ccCode, prevCcCode, openOptions })}
                options={Currency.CommonCurrencyList.map(code => ({
                    label: code,
                    value: code,
                }))}
            />

            <Input
                ref={ref}
                style={{ width: "30%", textAlign: "center" }}
                value={originalAmountStr}
                placeholder="Amount"
                disabled={ccCode === GQL.ICurrencyCode.Eur || !allowConfigEdit}
                onChange={e => setOriginalAmountStr(e.target.value)}
                onBlur={() => handleBlur()}
                onFocus={e => e.target.select()}
                onKeyDown={floatInputBlocker}
                data-testid="amount"
                id="currencyAmountID"
            />
            <div style={{ position: "relative", width: "20%" }}>
                <Input
                    style={{ textAlign: "center" }}
                    placeholder="Rate"
                    disabled={ccCode === GQL.ICurrencyCode.Eur || !allowConfigEdit}
                    value={rateStr}
                    onChange={e => {
                        setRateStr(e.target.value);
                    }}
                    onBlur={() => {
                        handleBlur();
                    }}
                    onFocus={e => e.target.select()}
                    onKeyDown={floatInputBlocker}
                    data-testid="rate"
                    id="currencyRateID"
                />

                {ccCode !== GQL.ICurrencyCode.Eur && isRetrieve && (
                    <div style={{ position: "absolute", top: -5, right: -5, zIndex: 100, borderRadius: "50%" }}>
                        <Button
                            tabIndex={-1}
                            size="small"
                            icon={<DownloadOutlined />}
                            style={{ borderRadius: "50%" }}
                            onClick={handleCurrencyClick}
                            loading={isRateLoading}
                        />
                    </div>
                )}
            </div>
            <Input
                style={{
                    width: "30%",
                    textAlign: "center",
                }}
                value={euroValueRes}
                onChange={e => {
                    setEuroValueRes(e.target.value);
                }}
                onBlur={() => {
                    handleBlur();
                }}
                onFocus={e => e.target.select()}
                disabled={ccCode !== GQL.ICurrencyCode.Eur || !allowConfigEdit}
                onKeyDown={floatInputBlocker}
                suffix="EUR"
                data-testid="final-amount"
                id="currencyFinalAmountID"
            />
        </Space.Compact>
    );
});
