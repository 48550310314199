import React, { useCallback, useEffect, useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { FileUploaderRefProps, FUButton, FUViewer } from "../../../recordform/FileUploader/FileUploader";
import { Form, Tooltip } from "antd";
import { FormattedMessage } from "react-intl";
import { GQL } from "@binale-tech/shared";
import "./AssetsBlock.css";

interface Props {
    onSave: (v: GQL.IRecordDocumentInput[]) => void;
    onFileListChanged: (ids: string[]) => void;
    value?: GQL.IRecordDocumentInput[];
    disabled?: boolean;
    productKey?: GQL.IProductKey;
}

const defaultDocumentIds: string[] = [];

export type AssetsBlockRef = Pick<HTMLButtonElement, "focus"> & FileUploaderRefProps & { resetState: () => void };

export const AssetsBlock = React.forwardRef<AssetsBlockRef, Props>(function AssetsBlock(
    { onSave, onFileListChanged, value, disabled, productKey },
    ref
) {
    const [showViewer, setShowViewer] = useState(false);
    const [addedDocumentIds, setAddedDocumentIds] = useState<string[]>(defaultDocumentIds);

    const buttonRef = React.useRef<HTMLButtonElement>();
    const viewerRef = React.useRef<FileUploaderRefProps>();

    const resetState = useCallback(() => {
        setShowViewer(false);
    }, []);
    React.useImperativeHandle(ref, () => {
        return {
            focus: (options?) => buttonRef.current.focus(options),
            resetState,
            getFiles: () => {
                if (showViewer) {
                    const { uploadedFiles } = viewerRef.current.getFiles();
                    resetState();
                    return { uploadedFiles };
                }
                return null;
            },
            makeSpace: viewerRef.current.makeSpace,
            resetSpace: viewerRef.current.resetSpace,
        };
    }, [showViewer, resetState]);

    useEffect(() => {
        setAddedDocumentIds(value ? value.map(el => el.id) : defaultDocumentIds);
    }, [value]);

    const onRemove = useCallback(
        (e: React.MouseEvent) => {
            e.stopPropagation();
            e.preventDefault();
            onSave([]);
            resetState();
        },
        [onSave, resetState]
    );

    const handleSave = useCallback(
        (v: GQL.IRecordDocumentInput[]) => {
            onSave(v);
            resetState();
        },
        [onSave]
    );

    return (
        <Form.Item
            label={
                addedDocumentIds.length ? <DeleteOutlined className="DropzoneBlock__delete" onClick={onRemove} /> : " "
            }
        >
            <Tooltip placement="top" title={<FormattedMessage id="app.components.form.attachment" />}>
                <FUButton
                    ref={buttonRef}
                    count={addedDocumentIds.length}
                    onClick={() => setShowViewer(prev => !prev)}
                    disabled={disabled}
                />
            </Tooltip>
            <FUViewer
                ref={viewerRef}
                visible={showViewer}
                onHide={resetState}
                onSave={handleSave}
                allowEdit={!disabled}
                useFullScreenMode
                addedDocumentIds={addedDocumentIds}
                productKey={productKey}
                onFileListChanged={onFileListChanged}
            />
        </Form.Item>
    );
});
