import React, { forwardRef, memo } from "react";
import { AgGridReact } from "ag-grid-react";
import { ColDef, GridOptions, IRowNode, RowClassRules } from "ag-grid-community";
import { TParseTransaction } from "@banks/types";
import { AgGridTable } from "@app/components/shared/AgGridTable";

type TProps = {
    tableKey?: string;
    rowData?: Record<string, string>[];
    gridOptions?: GridOptions<TParseTransaction>;
    rowClassRules?: RowClassRules<TParseTransaction>;
    isExternalFilterPresent?: () => boolean;
    doesExternalFilterPass?: (node: IRowNode<TParseTransaction>) => boolean;
    handleSelectionChange?: (arg: TParseTransaction[]) => void;
    isRowSelectable?: (rowNode: IRowNode) => boolean;
    tableColumns?: Readonly<ColDef>[];
    components?: Record<string, any>;
    viewHeight: string | number;
};

export const TransactionsTable = memo(
    forwardRef<AgGridReact, TProps>(
        (
            {
                rowData,
                tableKey,
                gridOptions,
                isExternalFilterPresent,
                doesExternalFilterPass,
                rowClassRules,
                handleSelectionChange,
                isRowSelectable,
                tableColumns,
                components,
                viewHeight,
            },
            ref
        ) => {
            return (
                <AgGridTable
                    ref={ref}
                    wrapperStyles={{ height: viewHeight ?? "100%" }}
                    tableKey={tableKey}
                    gridOptions={gridOptions}
                    rowClassRules={rowClassRules}
                    rowData={rowData}
                    columnDefs={tableColumns}
                    components={components}
                    isExternalFilterPresent={isExternalFilterPresent}
                    doesExternalFilterPass={doesExternalFilterPass}
                    isRowSelectable={isRowSelectable}
                    onSelectionChanged={e => handleSelectionChange(e.api.getSelectedRows())}
                />
            );
        }
    )
);
