import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { AgGridTable } from "@app/components/shared/AgGridTable";

import {
    CellKeyDownEvent,
    ColumnMovedEvent,
    FilterChangedEvent,
    GridApi,
    GridPreDestroyedEvent,
    GridReadyEvent,
    ModelUpdatedEvent,
    PaginationChangedEvent,
    RowClickedEvent,
    RowSelectedEvent,
    SortChangedEvent,
} from "ag-grid-community";
import { Flex } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { ITableDocument, KeysEnum, tableModeEnum, TDmsTableCols, TPaginationPageSize, TTableCols } from "@dms/types";
import { DmsAppContext, DmsAppControlContext, DmsUserSettingsContext } from "@dms/types/ContextTypes";
import { useDocumentTableConfig } from "@dms/modules/DocumentTableModule/hooks/useDocumentColumnConfig";
import { useDocumentTableOptions } from "@dms/modules/DocumentTableModule/hooks/useDocumentTableOptions";
import { SettingsColumns, TableLoader } from "@dms/modules/DocumentTableModule/components";
import { reorderingCols } from "@dms/modules/DocumentTableModule/helpers";
import { DmsTableCols } from "@dms/modules/DocumentTableModule/consts";
import { GroupButtons } from "@dms/components/GroupButtons/GroupButtons";
import "ag-grid-community/styles/ag-theme-balham.css";
import { debounce } from "@dms/scripts/helpers";

import styles from "@dms/modules/DocumentTableModule/DocumentTable.module.scss";
import { DmsUtils } from "@dms/scripts/utils/DmsUtils";

interface IProps {
    dataSource: ITableDocument[];
}

export const DocumentTableModule: FC<IProps> = ({ dataSource }) => {
    const { userConfig, setFilterConfig, setColumnsTableConfig } = useContext(DmsUserSettingsContext);
    const { activeType, selectedRowKeys, focusedRows, loadDataSource } = useContext(DmsAppContext);
    const { selectRow, unselectRow, setLoadDataSource, addFocusedRows } = useContext(DmsAppControlContext);
    const gridApiRef = useRef<AgGridReact>(null);
    const [gridApi, setGridApi] = useState<GridApi>(null);

    const [openSearch, setOpenSearch] = useState(false);

    const { columnsConfig, columns } = useDocumentTableConfig(tableModeEnum.app, activeType);
    const gridOptions = useDocumentTableOptions();

    const { sortState, filterState, paginationState } = useMemo(() => {
        if (!activeType) {
            return {
                sortState: null,
                filterState: null,
                paginationState: {
                    pageSize: 50,
                    currentPage: 0,
                },
            };
        }

        return DmsUtils.getFilterConfig(userConfig.filterConfig, activeType);
    }, [activeType, userConfig.filterConfig]);

    const onCellKeyDown = useCallback(
        debounce(({ api, node, event }: CellKeyDownEvent<ITableDocument>) => {
            const keyboardEvent = event as KeyboardEvent;
            const currentRowIndex = node.rowIndex;
            const displayedFirstRowIndex = api.getFirstDisplayedRowIndex();
            const displayedLastRowIndex = api.getLastDisplayedRowIndex();

            if (keyboardEvent.key === KeysEnum.ARROW_UP) {
                if (currentRowIndex === displayedFirstRowIndex) {
                    return;
                }

                const prevRowIndex = currentRowIndex - 1;
                if (prevRowIndex >= displayedFirstRowIndex) {
                    const prevNode = api.getDisplayedRowAtIndex(prevRowIndex);
                    if (prevNode) {
                        addFocusedRows([prevNode.data.key]);
                    }
                }
            }

            if (keyboardEvent.key === KeysEnum.ARROW_DOWN) {
                if (currentRowIndex === displayedLastRowIndex) {
                    return;
                }

                const nextRowIndex = currentRowIndex + 1;
                if (nextRowIndex <= displayedLastRowIndex) {
                    const nextNode = api.getDisplayedRowAtIndex(nextRowIndex);
                    if (nextNode) {
                        addFocusedRows([nextNode.data.key]);
                    }
                }
            }
        }, 100),
        [focusedRows]
    );

    const onRowSelected = useCallback(({ source, data, node }: RowSelectedEvent<ITableDocument>) => {
        const isSelectedNode = node.isSelected();
        if (source === "checkboxSelected" || source === "uiSelectAllCurrentPage" || source === "spaceKey") {
            isSelectedNode ? selectRow(data.key) : unselectRow(data.key);
        }
        return;
    }, []);

    const toggleFloatingFilter = useCallback(() => {
        const { api } = gridApiRef.current;
        const allColumns = api.getColumnDefs();

        if (openSearch) {
            api.setFilterModel({});
        }

        const newColModel = allColumns.map(column => {
            return {
                ...column,
                floatingFilter: !openSearch,
            };
        });
        api.setGridOption("columnDefs", newColModel);
        setOpenSearch(prev => !prev);
    }, [openSearch]);

    const onColumnMoved = useCallback(
        async ({ api, finished }: ColumnMovedEvent) => {
            if (!finished) {
                return;
            }

            const newColOrderingArr = api.getColumnState().map(el => {
                const key = el.colId as keyof TTableCols;
                return {
                    [key]: true,
                };
            }) as TTableCols[];

            const returnArray = reorderingCols(columnsConfig, newColOrderingArr);

            await setColumnsTableConfig(activeType, returnArray);
        },
        [columnsConfig]
    );

    const onGridReady = useCallback(
        ({ api }: GridReadyEvent<ITableDocument>) => {
            setLoadDataSource(true);
            if (dataSource.length === 0) {
                setLoadDataSource(false);
            }
            setGridApi(api);
            api.paginationGoToPage(paginationState?.currentPage ?? 0);

            if (filterState) {
                api.setFilterModel(filterState);
            }

            api.applyColumnState({
                state: sortState ?? [{ colId: DmsTableCols.DOCUMENT_DATE, sort: "desc" }],
                defaultState: { sort: null },
            });

            api.forEachNode(row => row.setSelected(selectedRowKeys.includes(row.data.key)));
        },
        [dataSource, selectedRowKeys, focusedRows, filterState, sortState, paginationState]
    );

    const onModelUpdated = useCallback(
        ({ api }: ModelUpdatedEvent<ITableDocument>) => {
            api.forEachNode(node => {
                if (focusedRows.includes(node.data.key)) {
                    node.setData({ ...node.data, isFocused: true });
                    setTimeout(() => {
                        api.setFocusedCell(node.rowIndex, DmsTableCols.DRAG);
                        api.ensureIndexVisible(node.rowIndex, "middle");
                    });
                }
                if (selectedRowKeys.length > 0) {
                    node.setSelected(selectedRowKeys.includes(node.data.key));
                }
            });

            setLoadDataSource(false);
        },
        [dataSource, selectedRowKeys]
    );

    const onSortChanged = useCallback(
        ({ api }: SortChangedEvent<ITableDocument>) => {
            setLoadDataSource(true);

            api.refreshCells({
                force: true,
                suppressFlash: true,
                columns: [DmsTableCols.ROW_NUMBER],
            });

            const sortState = api
                .getColumnState()
                .filter(col => col.sort)
                .map(col => ({
                    colId: col.colId as TDmsTableCols,
                    sort: col.sort,
                }));

            setFilterConfig(activeType, {
                sortState,
            });
        },
        [activeType]
    );

    const onFilterChanged = useCallback(
        (params: FilterChangedEvent<ITableDocument>) => {
            setLoadDataSource(true);
            setFilterConfig(activeType, {
                filterState: params.api.getFilterModel(),
            });
        },
        [activeType]
    );

    const onPaginationChanged = useCallback(
        ({ api }: PaginationChangedEvent<ITableDocument>) => {
            const pageSize = api.paginationGetPageSize() as TPaginationPageSize;
            const currentPage = api.paginationGetCurrentPage();

            setFilterConfig(activeType, {
                paginationState: {
                    pageSize,
                    currentPage,
                },
            });
        },
        [dataSource, activeType]
    );

    const onRowClicked = useCallback(
        ({ api, node, data }: RowClickedEvent<ITableDocument>) => {
            api.forEachNode(row => {
                if (focusedRows.includes(row.data.key) || row.data.isFocused) {
                    row.setData({ ...row.data, isFocused: false });
                }
            });

            addFocusedRows([data.key]);
            node.setData({ ...data, isFocused: true });
        },
        [focusedRows]
    );

    const onGridPreDestroyed = useCallback((params: GridPreDestroyedEvent) => {
        setGridApi(null);
    }, []);

    useEffect(() => {
        if (!gridApi) {
            return;
        }

        if (selectedRowKeys.length === 0) {
            gridApi.deselectAll();

            setFilterConfig(activeType, {
                selectedFilter: false,
            });
        }
    }, [activeType, gridApi, selectedRowKeys]);

    useEffect(() => {
        if (!gridApi) {
            return;
        }

        gridApi.forEachNode(row => {
            const isFocused = focusedRows.includes(row.data.key);
            row.setData({ ...row.data, isFocused });
            if (isFocused) {
                setTimeout(() => {
                    gridApi.setFocusedCell(row.rowIndex, DmsTableCols.DRAG);
                });
            }
        });
    }, [activeType, gridApi, focusedRows]);

    useEffect(() => {
        if (!gridApi) {
            return;
        }

        gridApi.updateGridOptions({
            rowData: dataSource,
        });
    }, [gridApi, dataSource]);

    useEffect(() => {
        setGridApi(null);
    }, [activeType]);

    return (
        <div style={{ position: "relative" }}>
            <Flex align="center" gap="10" className={styles.columnActions}>
                <SearchOutlined style={{ zIndex: 10 }} onClick={toggleFloatingFilter} />
                {columnsConfig ? (
                    <SettingsColumns
                        activeType={activeType}
                        configCols={columnsConfig}
                        className={styles.columnSetting}
                    />
                ) : null}
            </Flex>

            {selectedRowKeys.length > 1 ? (
                <div className={styles.select}>
                    <GroupButtons documentData={dataSource} />
                </div>
            ) : null}

            <AgGridTable
                ref={gridApiRef}
                wrapperStyles={{ height: "calc(100vh - 110px)", paddingTop: 60 }}
                gridOptions={gridOptions}
                columnDefs={columns}
                tableKey={activeType.join(",")}
                onGridPreDestroyed={onGridPreDestroyed}
                onColumnMoved={onColumnMoved}
                onModelUpdated={onModelUpdated}
                onCellKeyDown={onCellKeyDown}
                onRowSelected={onRowSelected}
                onGridReady={onGridReady}
                onSortChanged={onSortChanged}
                onRowClicked={onRowClicked}
                onFilterChanged={onFilterChanged}
                onPaginationChanged={onPaginationChanged}
                loading={loadDataSource}
                loadingOverlayComponent={TableLoader}
            />
        </div>
    );
};
