import React, { FC, useContext, useEffect, useState } from "react";
import { Button, Flex } from "antd";
import {
    PreviewParseDataContext,
    PreviewParseDataControlContext,
} from "@banks/modules/PreviewParseDataModule/context/PreviewParseDataContext";
import { PlusOutlined } from "@ant-design/icons";
import { BanksAppContext, BanksAppControlContext } from "@banks/scripts/context";
import { OptionsValues } from "@banks/types/enums";
import { SelectBankTitle } from "@banks/modules/PreviewParseDataModule/components/SelectBankTitle";
import { BankSelect } from "@banks/modules/PreviewParseDataModule/components/BankSelect";
import { MatchKeyList } from "@banks/modules/PreviewParseDataModule/components/MatchKeyList";
import { requiredFields, transactionFieldsDict } from "@banks/configs/constants/transactionFieldsDict";
import { BanksContext } from "../../../../scripts/context/BanksContext";

export const PreviewParseDataToolbar: FC = () => {
    const [bankSelectValue, setBankSelectValue] = useState<string>();

    const { banksData } = useContext(BanksContext);
    const { addBankData } = useContext(BanksAppContext);
    const { setAddBankData } = useContext(BanksAppControlContext);
    const { missingBankData, parseBankData, unmappedDataKeys } = useContext(PreviewParseDataContext);
    const { intoParseTransaction, setBankData } = useContext(PreviewParseDataControlContext);

    const handleAddBank = () => {
        setAddBankData({
            isModalOpen: true,
            bankData: missingBankData?.iban ? { iban: missingBankData.iban } : null,
            isNeedSave: true,
        });
    };

    const handleSelect = (arg: string) => {
        if (arg === OptionsValues.ADD_NEW_BANK) {
            setBankSelectValue(null);
            return handleAddBank();
        }
        setBankSelectValue(arg);
    };

    useEffect(() => {
        if (!parseBankData) {
            return setBankSelectValue(null);
        }

        setBankSelectValue(parseBankData.id);
    }, [parseBankData]);

    useEffect(() => {
        if (!addBankData.bankData) {
            return;
        }

        const { accountNumber, bankId } = addBankData.bankData;
        const foundData = Object.values(banksData).find(
            el => el.bankId === bankId && el.accountNumber === accountNumber
        );
        if (!foundData) {
            return setBankSelectValue(null);
        }

        setBankSelectValue(foundData.id);
    }, [addBankData.bankData, banksData]);

    useEffect(() => {
        if (!bankSelectValue) {
            return;
        }

        const foundBank = Object.values(banksData).find(el => el.id === bankSelectValue);
        if (!foundBank) {
            return;
        }

        setBankData(foundBank);
    }, [bankSelectValue, banksData]);

    useEffect(() => {
        if (missingBankData) {
            setAddBankData({
                isModalOpen: false,
                bankData: missingBankData?.iban ? { iban: missingBankData.iban } : null,
                isNeedSave: true,
            });
        }
    }, [missingBankData]);

    const checkRequiredFields = () => {
        return !unmappedDataKeys.find(key => requiredFields.includes(key as keyof typeof transactionFieldsDict));
    };

    const isNextBtnDisabled = !!missingBankData || (!missingBankData && !bankSelectValue) || !checkRequiredFields();

    return (
        <Flex vertical>
            <Flex gap={15} align={"center"} justify={"space-between"} style={{ minHeight: 32, marginBottom: 10 }}>
                <SelectBankTitle />

                <Flex gap={10}>
                    {missingBankData ? (
                        <Button type={"primary"} icon={<PlusOutlined />} onClick={handleAddBank}>
                            New Bank
                        </Button>
                    ) : (
                        <BankSelect value={bankSelectValue} selectBank={handleSelect} />
                    )}
                    <Button
                        style={{ alignSelf: "end" }}
                        type={"primary"}
                        onClick={intoParseTransaction}
                        disabled={isNextBtnDisabled}
                    >
                        Next
                    </Button>
                </Flex>
            </Flex>

            <MatchKeyList />
        </Flex>
    );
};
