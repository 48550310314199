import React, { FC } from "react";
import {
  BorderOutlined,
  CheckCircleOutlined,
  CheckSquareOutlined,
  CloseCircleOutlined,
  CopyOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  PlusOutlined,
  RedoOutlined,
  ScissorOutlined
} from "@ant-design/icons";
import { Button, Progress, Tooltip } from "antd";
import { FormattedMessage } from "react-intl";

interface IProps {
    checkAll: () => void;
    deleteEmptyPages: () => void;
    hidingProgress: number;
    showDeletedPages: boolean;
    toggleDeletedPages: (arg: boolean) => void;
    deleteCheckedPages: () => void;
    restoreCheckedPages: () => void;
    checkedIds: string[];
    splitCheckedDocs: () => void;
    rotateCheckedPages: () => void;
    unifyCheckedDocs: () => void;
    uncheckAll: () => void;
    middleHeight?: boolean;
}

export const ActionButtonsGrid: FC<IProps> = ({
    checkAll,
    deleteEmptyPages,
    hidingProgress,
    showDeletedPages,
    toggleDeletedPages,
    deleteCheckedPages,
    restoreCheckedPages,
    checkedIds,
    splitCheckedDocs,
    rotateCheckedPages,
    unifyCheckedDocs,
    uncheckAll,
}) => {
    return (
        <div className="FlexColumn" style={{ minHeight: "40%" }}>
            <FormattedMessage id="app.ocr.allPages" tagName="h3" />
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 15,
                }}
            >
                <Tooltip placement="topLeft" title={<FormattedMessage id="app.ocr.button.checkAll" />}>
                    <Button
                        size={"large"}
                        style={{ marginRight: 15, marginBottom: 10 }}
                        onClick={checkAll}
                        icon={<CheckSquareOutlined />}
                    ></Button>
                </Tooltip>

                <Tooltip placement="topLeft" title={<FormattedMessage id="app.ocr.button.deleteEmptyPages" />}>
                    <Button
                        size={"large"}
                        style={{ marginRight: 15, marginBottom: 10 }}
                        onClick={deleteEmptyPages}
                        icon={<CopyOutlined />}
                    >
                        {hidingProgress > 0 && <Progress percent={hidingProgress || 0} type="circle" width={20} />}
                    </Button>
                </Tooltip>

                {showDeletedPages && (
                    <Tooltip placement="topLeft" title={<FormattedMessage id="app.ocr.button.hideDeletedPages" />}>
                        <Button
                            size={"large"}
                            style={{ marginRight: 15, marginBottom: 10 }}
                            onClick={() => toggleDeletedPages(false)}
                            icon={<EyeInvisibleOutlined />}
                        ></Button>
                    </Tooltip>
                )}
                {!showDeletedPages && (
                    <Tooltip placement="topLeft" title={<FormattedMessage id="app.ocr.button.showDeletedPages" />}>
                        <Button
                            size={"large"}
                            style={{ marginRight: 15, marginBottom: 10 }}
                            onClick={() => toggleDeletedPages(true)}
                            icon={<EyeOutlined />}
                        ></Button>
                    </Tooltip>
                )}
            </div>

            <FormattedMessage id="app.ocr.selectedPages" tagName="h3" />
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 10,
                    flexWrap: "wrap",
                }}
            >
                <Tooltip placement="topLeft" title={<FormattedMessage id="app.ocr.button.deletePage" />}>
                    <Button
                        size={"large"}
                        style={{ marginRight: 15, marginBottom: 10 }}
                        onClick={deleteCheckedPages}
                        disabled={!checkedIds.length}
                        icon={<CloseCircleOutlined />}
                    ></Button>
                </Tooltip>

                <Tooltip placement="topLeft" title={<FormattedMessage id="app.ocr.button.restorePage" />}>
                    <Button
                        size={"large"}
                        onClick={restoreCheckedPages}
                        style={{ marginRight: 15, marginBottom: 10 }}
                        disabled={!checkedIds.length}
                        icon={<CheckCircleOutlined />}
                    ></Button>
                </Tooltip>

                <Tooltip placement="topLeft" title={<FormattedMessage id="app.ocr.button.rotatePage" />}>
                    <Button
                        size={"large"}
                        onClick={rotateCheckedPages}
                        style={{ marginRight: 15, marginBottom: 10 }}
                        disabled={!checkedIds.length}
                        icon={<RedoOutlined />}
                    ></Button>
                </Tooltip>

                <Tooltip placement="topLeft" title={<FormattedMessage id="app.ocr.button.splitDocs" />}>
                    <Button
                        size={"large"}
                        style={{ marginRight: 15, marginBottom: 10 }}
                        onClick={splitCheckedDocs}
                        disabled={!checkedIds.length}
                        icon={<ScissorOutlined />}
                    ></Button>
                </Tooltip>

                <Tooltip placement="topLeft" title={<FormattedMessage id="app.ocr.button.unifyDocs" />}>
                    <Button
                        size={"large"}
                        onClick={unifyCheckedDocs}
                        style={{ marginRight: 15, marginBottom: 10 }}
                        disabled={!checkedIds.length}
                        icon={<PlusOutlined />}
                    ></Button>
                </Tooltip>

                <Tooltip placement="topLeft" title={<FormattedMessage id="app.ocr.button.uncheckAll" />}>
                    <Button
                        size={"large"}
                        onClick={uncheckAll}
                        style={{ marginRight: 15, marginBottom: 10 }}
                        disabled={!checkedIds.length}
                        icon={<BorderOutlined />}
                    ></Button>
                </Tooltip>
            </div>
        </div>
    );
};
