import React, { type FC } from "react";
import { DocumentInfo } from "@dms/components/DocumentInfo/DocumentInfo";
import { GroupDocumentFormWrapper as GroupDocumentForm } from "../../components/DocumentForm/GroupDocumentForm";
import { GroupFormProvider } from "./context/GroupFormContext";

export const GroupFormModule: FC = () => {
    return (
        <>
            <DocumentInfo />
            <GroupFormProvider>
                <GroupDocumentForm />
            </GroupFormProvider>
        </>
    );
};
