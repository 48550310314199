import React, { FC } from "react";
import { ConfigProvider, Layout } from "antd";
import { PageHeader } from "@inv/components/PageHeader/PageHeader";
import { CreateInvoicingModule } from "@inv/modules";
import { themeConfig } from "@inv/theme";
import { InvoicePagesTitle } from "@inv/types";

const { Header, Content } = Layout;

export const CreateInvoicingLayout: FC = () => {
    return (
        <ConfigProvider
            theme={{
                components: {
                    Layout: {
                        headerBg: themeConfig.colorLight,
                        headerHeight: "auto",
                        headerPadding: 0,
                        footerBg: themeConfig.colorLight,
                    },
                },
            }}
        >
            <Layout
                style={{
                    margin: "0 auto",
                    maxWidth: 1366,
                    backgroundColor: themeConfig.colorLight,
                }}
            >
                <Header>
                    <PageHeader titleId={InvoicePagesTitle.CREATE_INVOICE} />
                </Header>
                <Content>
                    <CreateInvoicingModule />
                </Content>
            </Layout>
        </ConfigProvider>
    );
};
