import React, { type FC, useState } from "react";
import dayjs from "dayjs";
import { Collapse, Tooltip, Typography } from "antd";
import { DmsUtils } from "@dms/scripts/utils/DmsUtils";
import { FormattedMessage, useIntl } from "react-intl";
import { ITableDocument } from "@dms/types";
import { handleCopyField } from "../../../appearance/components/shared/functions";
import { CopyOutlined } from "@ant-design/icons";

interface IProps {
    document?: ITableDocument;
}

const { Text } = Typography;

const TextWrapper: FC<{ children: string | string[] }> = ({ children }) => {
    const intl = useIntl();
    return (
        <Text
            style={{ cursor: "pointer", paddingRight: "10px" }}
            ellipsis
            onDoubleClick={e => handleCopyField(e, intl.formatMessage({ id: "app.notification.copiedToClipboard" }))}
        >
            {children}
        </Text>
    );
};

export const DocumentInfo: FC<IProps> = ({ document }) => {
    const [tooltipTitleId, setTooltipTitleId] = useState(<FormattedMessage id="app.dms.copyId" />);

    const copyIdNumber = async (): Promise<void> => {
        if (document?.key) {
            try {
                await navigator.clipboard.writeText(document.key);
                setTooltipTitleId(<FormattedMessage id="app.dms.idCopied" />);
            } catch (err) {
                console.error("Failed to copy: ", err);
                setTooltipTitleId(<FormattedMessage id="app.dms.copyFailed" />);
            }
        }
    };

    const resetTooltipId = (open: boolean): void => {
        if (!open) {
            setTooltipTitleId(<FormattedMessage id="app.dms.copyId" />);
        }
    };

    const items = [
        {
            key: "1",
            label: <FormattedMessage id="app.dms.fileInformation" />,
            children: (
                <div>
                    <div style={{ display: "flex", flexDirection: "column", marginBottom: "10px" }}>
                        <Text type="secondary">ID:</Text>
                        <div style={{ display: "flex" }}>
                            <TextWrapper>{document?.key}</TextWrapper>
                            <Tooltip
                                title={tooltipTitleId}
                                onOpenChange={(open: boolean) => {
                                    resetTooltipId(open);
                                }}
                                placement="topLeft"
                            >
                                <CopyOutlined onClick={copyIdNumber} />
                            </Tooltip>
                        </div>
                    </div>

                    <div style={{ display: "flex", flexDirection: "column", marginBottom: "10px" }}>
                        <Text type="secondary">
                            <FormattedMessage id="app.dms.createdBy" />:
                        </Text>
                        <TextWrapper> {document?.createdBy?.email}</TextWrapper>
                    </div>

                    <div style={{ display: "flex", flexDirection: "column", marginBottom: "10px" }}>
                        <Text type="secondary">
                            <FormattedMessage id="app.dms.createdAt" />:
                        </Text>
                        <TextWrapper>
                            {document?.createdAt ? dayjs(document.createdAt).format("DD.MM.YYYY HH:mm") : null}
                        </TextWrapper>
                        {document?.updatedAt && (
                            <div style={{ display: "flex", flexDirection: "column", marginBottom: "10px" }}>
                                <Text type="secondary">
                                    <FormattedMessage id="app.dms.updatedAt" />:
                                </Text>
                                <TextWrapper>{dayjs(document?.updatedAt).format("DD.MM.YYYY HH:mm")}</TextWrapper>
                            </div>
                        )}
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", marginBottom: "10px" }}>
                        <Text type="secondary">
                            <FormattedMessage id="app.dms.status" />:
                        </Text>
                        <TextWrapper>{document ? DmsUtils.getDocumentStatus(document) : null}</TextWrapper>
                    </div>
                </div>
            ),
        },
    ];

    return (
        <Collapse
            bordered={false}
            size="small"
            style={{ marginBottom: "15px" }}
            items={items}
            collapsible={document ? "header" : "disabled"}
            destroyInactivePanel={true}
        />
    );
};
