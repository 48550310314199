import { Button, Card, Col, Flex, Row, Typography } from "antd";
import React, { FC, useContext, useEffect, useState } from "react";
import { SettingOutlined, StarFilled, StarOutlined, UploadOutlined } from "@ant-design/icons";
import { IBank } from "@banks/types";
import { BanksApi } from "@banks/scripts/api";
import { DropdownActions } from "@banks/components/DropdownActions";
import cn from "classnames";
import { gold } from "@ant-design/colors";

import styles from "./BankCard.module.scss";
import { cardBackground } from "@banks/components/BankCard/styles";
import { Utils } from "@binale-tech/shared";
import { AppRoutes } from "../../../scripts/routing/routeConstants";
import { useNavigate } from "react-router-dom";
import { BanksAppControlContext } from "@banks/scripts/context";

const { Text } = Typography;

type TProps = {
    bank: IBank;
    isActive: boolean;
    onClick: () => void;
};

export const BankCard: FC<TProps> = ({ bank, isActive, onClick }) => {
    const [isFavorite, setFavorite] = useState(bank.isFavorite);
    const [isLoadingFavoriteBtn, setIsLoadingFavoriteBtn] = useState(false);
    const [isActionLoading, setIsActionLoading] = useState(false);

    const { openTransactionUploader } = useContext(BanksAppControlContext);

    const navigate = useNavigate();

    useEffect(() => {
        setFavorite(bank.isFavorite);
    }, [bank.isFavorite]);

    const handleUpdateBank = () => {
        openTransactionUploader(bank);
    };

    const handleFavorite = async () => {
        setIsLoadingFavoriteBtn(true);
        setFavorite(prev => !prev);
        await BanksApi.markFavoriteBank({
            ...bank,
        });
        setIsLoadingFavoriteBtn(false);
    };

    const handleGoToBankTransactions = () => {
        return setTimeout(() => {
            navigate(AppRoutes.banks + `/bank-transactions/${bank.id}`);
        }, 300);
    };

    return (
        <Card
            data-parent={"banks"}
            title={<Text ellipsis>{bank.bankTitle ?? bank.bankName}</Text>}
            bordered={false}
            style={{ width: "100%", backgroundColor: cardBackground, overflow: "hidden" }}
            styles={{
                body: {
                    justifyContent: "space-between",
                },
                actions: { backgroundColor: bank?.color ?? cardBackground },
            }}
            actions={[""]}
            extra={
                <Button
                    type={"text"}
                    icon={
                        isFavorite ? (
                            <StarFilled style={{ color: gold[5] }} />
                        ) : (
                            <StarOutlined style={{ color: gold[5] }} />
                        )
                    }
                    onClick={handleFavorite}
                    loading={isLoadingFavoriteBtn}
                />
            }
            onClick={onClick}
            classNames={{
                header: cn(styles.cardInner, {
                    [styles.cardInnerActive]: isActive,
                }),
            }}
            hoverable
        >
            <Row onClick={handleGoToBankTransactions}>
                <Col span={20}>
                    <Flex vertical gap={5}>
                        {bank.iban ? (
                            <Flex gap={5}>
                                <Text strong ellipsis>
                                    IBAN:
                                </Text>
                                <Text ellipsis>{bank.iban}</Text>
                            </Flex>
                        ) : (
                            <Flex gap={5}>
                                <Text strong ellipsis>
                                    Acct. No.:
                                </Text>
                                <Text ellipsis>{bank.accountNumber}</Text>
                            </Flex>
                        )}
                        <Flex gap={5}>
                            <Text strong ellipsis>
                                Bank Name:
                            </Text>
                            <Text ellipsis>{bank.bankName}</Text>
                        </Flex>
                        <Text
                            ellipsis
                        >{`${bank.openingBalanceDate ?? "__.__.____"} : ${bank.openingBalance ? Utils.CurrencyUtils.currencyFormat(bank.openingBalance) : "0,00"} EUR`}</Text>
                    </Flex>
                </Col>
                <Col span={4}>
                    <Flex
                        vertical
                        gap={5}
                        justify={"flex-end"}
                        align={"flex-end"}
                        style={{ height: "100%" }}
                        onClick={event => {
                            event.stopPropagation();
                            onClick();
                        }}
                    >
                        <Button icon={<UploadOutlined />} onClick={handleUpdateBank}></Button>
                        <DropdownActions bank={bank} handleActionLoading={isLoading => setIsActionLoading(isLoading)}>
                            <Button icon={<SettingOutlined />} loading={isActionLoading}></Button>
                        </DropdownActions>
                    </Flex>
                </Col>
            </Row>
        </Card>
    );
};
