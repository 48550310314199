import React from "react";
import { CheckCircleOutlined, DeleteOutlined, EditOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { Popconfirm, Tooltip } from "antd";

import { CompanyContext, YearPeriodContext } from "scripts/context/CompanyContext";
import { BuContext } from "scripts/context/BuContext";
import { UserContext } from "scripts/context/UserProvider";
import { PaymentsContext } from "scripts/context/PaymentsProvider";
import { BuTaxesSKR } from "scripts/models/BuTaxUtils";
import { FlexRow } from "../../../components/shared/appearance/page/Scaffold";
import { GenericRecordTableItem } from "../../../components/shared/Table/Table";
import { GenericRecordUtils } from "scripts/models/utils/GenericRecordUtils";
import { TableViewContext } from "../../../../scripts/context/tableViewContext/tableViewContext";
import { useCanWrite } from "scripts/infrastructure/hooks";

type Props = {
    tableItem: GenericRecordTableItem;
    selectedPeriodEditBound: number;
    onDelete: () => void;
    onEdit: () => void;
    onConfirm: () => void;
};
export const ActionButtons: React.FC<Props> = ({ tableItem, onDelete, onEdit, onConfirm, selectedPeriodEditBound }) => {
    const { product } = React.useContext(TableViewContext);
    const canWrite = useCanWrite(tableItem?.item?.getProductKey() ?? product.productKey());
    const { recordRelation } = React.useContext(PaymentsContext);
    const { yearConfig, companyGQL } = React.useContext(CompanyContext);
    const userCtx = React.useContext(UserContext);
    const { year } = React.useContext(YearPeriodContext);
    const skr = yearConfig?.skr;
    const { companyBuTimeframes } = React.useContext(BuContext);
    const hasKey = Boolean(tableItem.key) || Number.isFinite(tableItem.key);
    if (!hasKey) {
        return null;
    }
    const buttons: React.ReactNode[] = [];
    // if removed, then to actions anymore
    // if draft than first it needs to be confirmed
    const manipulations = GenericRecordUtils.getManipulationPermissions(
        tableItem.item,
        canWrite,
        product,
        recordRelation,
        { selectedPeriod: selectedPeriodEditBound, globalYear: year },
        yearConfig,
        companyGQL,
        userCtx
    );

    if (manipulations.edit.disabled) {
        buttons.push(
            <Tooltip key="edit" placement="left" title={manipulations.edit.reason}>
                <EditOutlined className="ActionButtons__Item ActionButtons__Item--disabled" />
            </Tooltip>
        );
    } else {
        const icon = <EditOutlined key="edit" className="ActionButtons__Item" onClick={onEdit} />;
        buttons.push(icon);
    }

    if (manipulations.remove.disabled) {
        buttons.push(
            <Tooltip key="delete" placement="left" title={manipulations.remove.reason}>
                <DeleteOutlined className="ActionButtons__Item ActionButtons__Item--disabled" />
            </Tooltip>
        );
    } else {
        const icon = (
            <Popconfirm
                key="delete"
                onConfirm={onDelete}
                placement="left"
                title={<FormattedMessage id="app.confirmation.header" />}
                okText={<FormattedMessage id="app.button.confirm" />}
                cancelText={<FormattedMessage id="app.button.cancel" />}
            >
                <DeleteOutlined className="ActionButtons__Item" />
            </Popconfirm>
        );
        buttons.push(icon);
    }

    const shouldAddConfirmation = () => {
        if (!tableItem.item.draft) {
            return false;
        }
        if (manipulations.confirm.disabled) {
            return false;
        }
        if (product.getConfig().isRecordRechnungRequired && !tableItem.item.num) {
            return false;
        }
        if (!tableItem.item.getRecordCategoryCreditor().num) {
            return false;
        }
        if (!tableItem.item.getItemCategoryCreditor().num) {
            return false;
        }
        return true;
    };

    if (shouldAddConfirmation()) {
        buttons.push(
            <CheckCircleOutlined
                key="confirm"
                className="ActionButtons__Item ActionButtons__Item--green"
                onClick={onConfirm}
            />
        );
    }

    const extraBu = tableItem.item?.items?.[0]?.extra?.bu;
    if (Number.isFinite(extraBu)) {
        const buTax = BuTaxesSKR.getBuTaxYearPeriod(
            extraBu,
            skr,
            tableItem.item.year,
            tableItem.item.period,
            companyBuTimeframes
        );
        buttons.push(
            <Tooltip key="info" title={buTax?.text} placement="left">
                <InfoCircleOutlined />
            </Tooltip>
        );
    }
    return <FlexRow className="ActionButtons">{buttons}</FlexRow>;
};
