import "./App.css";

import { Empty, Layout } from "antd";
import React, { DragEvent, type FC, type PropsWithChildren, useContext } from "react";
import { Route, Routes } from "react-router-dom";

import { ItemsDragLayer } from "./components/DragAndDrop/ItemsDragLayer";
import { PATH } from "./configs/constants";
import { DocumentsMenuModule } from "./modules/DocumentsMenuModule/DocumentsMenuModule";
import { DocumentListPage } from "./pages/DocumentListPage";
import { DocumentPage } from "./pages/DocumentPage";
import { EditingPage } from "./pages/EditingPage";
import { DmsAppContext, DmsAppControlContext } from "@dms/types/ContextTypes";

const App: FC = () => {
    return (
        <AppLayout>
            <Routes>
                <Route index element={<DocumentListPage />} />
                <Route path={`${PATH.EDITING}/:${PATH.DOCUMENT_ID}`} element={<DocumentPage />} />
                <Route path={`${PATH.EDITING_GROUP}`} element={<EditingPage />} />
                <Route path="*" element={<Empty />} />
            </Routes>
        </AppLayout>
    );
};

const AppLayout: FC<PropsWithChildren> = ({ children }) => {
    const { isTypeChangeMode, isDraggingRightNow, downloadDocumentsModalOpen } = useContext(DmsAppContext);
    const { toggleDownloadDocumentsModal } = useContext(DmsAppControlContext);

    const onDragEnter = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        if (e.dataTransfer.types.includes("Files")) {
            if (!downloadDocumentsModalOpen && !isDraggingRightNow) {
                toggleDownloadDocumentsModal(true);
            }
        }
    };

    const onDrop = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
    };

    const onDragOver = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
    };

    return (
        <Layout
            style={{ position: "relative", height: "100%", flexDirection: "row" }}
            onDragEnter={onDragEnter}
            onDrop={onDrop}
            onDragOver={onDragOver}
        >
            <DocumentsMenuModule />
            <Layout.Content
                style={{
                    padding: "25px 0",
                    paddingLeft: "25px",
                    position: "relative",
                }}
            >
                {children}
                {(isTypeChangeMode || isDraggingRightNow) && (
                    <div
                        style={{
                            position: "absolute",
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            backgroundColor: "#00000099",
                            zIndex: 10,
                        }}
                    ></div>
                )}
                <ItemsDragLayer />
            </Layout.Content>
        </Layout>
    );
};

export default App;
