import React, { memo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Flex, Popconfirm, Tooltip } from "antd";
import { DeleteOutlined, EditOutlined, ExportOutlined, EyeOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { IRowNode } from "ag-grid-community";
import { LineItemsModal } from "@inv/components/LineItemsModal";
import { InvoiceFormBlocksTranslate, TInvoicesListData } from "@inv/types";
import { InvoicesApi } from "@inv/scripts/api";

import styles from "./actionList.module.scss";

interface IProps {
    node: IRowNode<TInvoicesListData>;
}
export const ActionList = memo<IProps>(({ node }) => {
    const intl = useIntl();
    const [isOpenModal, setIsOpenModal] = useState(false);

    if (node.rowPinned) {
        return null;
    }

    const handleDeleteInvoice = async () => {
        await InvoicesApi.invoiceDelete({ id: node.data.id });
    };

    return (
        <Flex
            className={styles.action}
            align="center"
            justify="space-between"
            onClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
        >
            <Tooltip title={<FormattedMessage id="app.dms.view" />}>
                <Button icon={<EyeOutlined />} className={styles.actionsItem} type={"link"} disabled={true} />
            </Tooltip>
            <Tooltip
                title={`${intl.formatMessage({ id: "app.dms.view" })} ${intl.formatMessage({ id: InvoiceFormBlocksTranslate.LINE_ITEMS })}`}
            >
                <Button
                    icon={<UnorderedListOutlined />}
                    className={styles.actionsItem}
                    type={"link"}
                    onClick={() => setIsOpenModal(true)}
                />
            </Tooltip>
            <Tooltip title={<FormattedMessage id="app.button.send_to_dms" />}>
                <Button icon={<ExportOutlined />} className={styles.actionsItem} type="link" disabled={true} />
            </Tooltip>
            <Tooltip title={<FormattedMessage id="app.button.edit" />}>
                <Link to="#">
                    <Button className={styles.actionsItem} type="link" disabled={true} icon={<EditOutlined />} />
                </Link>
            </Tooltip>
            <Tooltip title={<FormattedMessage id="app.button.delete" />}>
                <Popconfirm
                    title={intl.formatMessage({ id: "app.confirmation.invoice_remove.body" })}
                    onConfirm={handleDeleteInvoice}
                    onCancel={null}
                    okText={intl.formatMessage({ id: "app.global.yes" })}
                    cancelText={intl.formatMessage({ id: "app.global.no" })}
                >
                    <Button icon={<DeleteOutlined />} className={styles.actionsItem} type="link" />
                </Popconfirm>
            </Tooltip>
            <LineItemsModal
                lineItemsList={node.data.lineItemsList}
                open={isOpenModal}
                onClose={() => setIsOpenModal(false)}
            />
        </Flex>
    );
});
