import "./BanksApp.css";
import { Empty, Layout } from "antd";
import React, { DragEvent, type FC, type PropsWithChildren, useContext, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { BankListPage, BankTransactionsPage, ParseDataPage, PreviewParseDataPage } from "./pages";
import { BankModalList } from "@banks/layouts/BankModalList/BankModalList";
import { BanksAppContextProvider, BanksAppControlContext, ParseContextProvider } from "@banks/scripts/context";
import { useLocation } from "react-router";
import { BanksContext } from "../scripts/context/BanksContext";

const AppLayout: FC<PropsWithChildren> = ({ children }) => {
    const onDragEnter = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
    };

    const onDrop = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
    };

    const onDragOver = (e: DragEvent<HTMLDivElement>) => {
        e.preventDefault();
    };

    return (
        <Layout
            style={{ position: "relative", height: "100%", width: "100vw", flexDirection: "row" }}
            onDragEnter={onDragEnter}
            onDrop={onDrop}
            onDragOver={onDragOver}
        >
            <Layout.Content
                style={{
                    padding: "15px",
                    position: "relative",
                }}
            >
                {children}
            </Layout.Content>
            <BankModalList />
        </Layout>
    );
};

const BanksApp: FC = () => {
    return (
        <BanksAppContextProvider>
            <AppLayout>
                <Routes>
                    <Route index element={<BankListPage />} />
                    <Route path={"preview-parse-transaction-data"} element={<PreviewParseDataPage />} />
                    <Route path={"parse-transaction-data"} element={<ParseDataPage />} />
                    <Route path={"bank-transactions/:id"} element={<BankTransactionsPage />} />
                    <Route path="*" element={<Empty />} />
                </Routes>
            </AppLayout>
        </BanksAppContextProvider>
    );
};

export default BanksApp;
