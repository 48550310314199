import { AgGridReact } from "ag-grid-react";
import { Button, Flex, Form, Typography } from "antd";
import React, { FC, useCallback, useContext, useRef } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { AgGridTable } from "@app/components/shared/AgGridTable";
import {
    CellValueChangedEvent,
    FirstDataRenderedEvent,
    GridApi,
    GridReadyEvent,
    RowDataUpdatedEvent,
    RowDragEndEvent,
} from "ag-grid-community";
import {
    InvoiceTableContext,
    InvoiceTableControlContext,
} from "@inv/modules/InvoiceTableModule/context/InvoiceTableContext";
import { IInvoiceFormLineItem, InvoiceColumns, InvoiceInputs, InvoiceFormInputTranslate } from "@inv/types";
import { useConfig } from "@inv/modules/InvoiceTableModule/config";
import { FormattedMessage } from "react-intl";
import { InvoiceFormContext } from "@inv/modules/CreateInvoicingModule/context/InvoiceFormContext";
import { useColumnConfig } from "@inv/modules/InvoiceTableModule/hooks/useColumnConfig";
import { themeConfig } from "@inv/theme";

import styles from "./InvoiceTableBlock.module.scss";
import { decimalFormatter } from "@dms/scripts/helpers";

const { Text } = Typography;

const initRow: IInvoiceFormLineItem = {
    productsServices: "Product / Service",
    quantity: 1,
    price: 1000,
    tax: 0,
    discount: 800,
    total: 0,
};

export const InvoiceTableBlock: FC = () => {
    const gridApi = useRef<AgGridReact>();
    const gridOptions = useConfig();
    const form = Form.useFormInstance();
    const { totalState } = useContext(InvoiceTableContext);
    const { invoiceTotalView } = useContext(InvoiceFormContext);
    const { setTotalState } = useContext(InvoiceTableControlContext);
    const { currencyCode } = form.getFieldsValue();

    const columns = useColumnConfig();

    const onAddRow = useCallback(() => {
        if (!gridApi.current) {
            return;
        }
        gridApi.current.api.applyTransaction({ add: [{ ...initRow }] });
    }, [gridApi.current]);

    const sendLineItems = (api: GridApi<IInvoiceFormLineItem>) => {
        const nodes = api.getRenderedNodes();
        const lineItems = nodes.map(node => {
            const { productsServices, unit, quantity, price, tax, discount } = node.data as IInvoiceFormLineItem;
            return { productsServices, unit, quantity, price, tax, discount };
        });
        form.setFieldValue(InvoiceInputs.LINE_ITEMS, lineItems);
    };

    const handleTotal = (data: IInvoiceFormLineItem[]) => {
        const { subtotal, tax0, tax7, tax19 } = data.reduce(
            (acc, row) => {
                const rowSubtotal = row.total / 100;

                acc.subtotal += rowSubtotal;
                const taxTotal = rowSubtotal * (row.tax / 100);

                switch (row.tax) {
                    case 7:
                        acc.tax7 += taxTotal;
                        break;
                    case 19:
                        acc.tax19 += taxTotal;
                        break;
                    default:
                        break;
                }
                return acc;
            },
            { subtotal: 0, tax0: 0, tax7: 0, tax19: 0 }
        );

        const total = subtotal + tax0 + tax7 + tax19;

        setTotalState({
            subtotal: decimalFormatter(subtotal),
            tax0: decimalFormatter(tax0),
            tax7: decimalFormatter(tax7),
            tax19: decimalFormatter(tax19),
            total: decimalFormatter(total),
        });
    };

    const onGridReady = useCallback(({ api }: GridReadyEvent<IInvoiceFormLineItem>) => {
        api.updateGridOptions({ rowData: [{ ...initRow }, { ...initRow }, { ...initRow }, { ...initRow }] });
    }, []);

    const onFirstDataRendered = useCallback(({ api }: FirstDataRenderedEvent<IInvoiceFormLineItem>) => {
        const renderRows = api.getRenderedNodes().map(row => row.data);
        handleTotal(renderRows);
        sendLineItems(api);
    }, []);

    const onCellValueChanged = useCallback(
        ({ data, node, colDef, api }: CellValueChangedEvent<IInvoiceFormLineItem>) => {
            const { discount, quantity } = data;

            if (colDef.field === "discount" || colDef.field === "price" || colDef.field === "quantity") {
                const total = quantity * discount;
                node.setDataValue(InvoiceColumns.TOTAL, total);
            }

            const updatedRows = api.getRenderedNodes().map(row => row.data);
            handleTotal(updatedRows);
            sendLineItems(api);
        },
        []
    );

    const onRowDataUpdated = useCallback((params: RowDataUpdatedEvent<IInvoiceFormLineItem>) => {
        const { api } = params;

        api.forEachNode(node => {
            const total = node.data.quantity * node.data.discount;
            node.setDataValue(InvoiceColumns.TOTAL, total);
        });

        const updatedRows = api.getRenderedNodes().map(row => row.data);
        handleTotal(updatedRows);

        api.refreshCells({
            force: true,
            suppressFlash: true,
            columns: ["position"],
        });

        sendLineItems(api);
    }, []);

    const onRowDragEnd = useCallback(({ api }: RowDragEndEvent<IInvoiceFormLineItem>) => {
        api.refreshCells({
            force: true,
            suppressFlash: true,
            columns: [InvoiceColumns.POSITION],
        });
    }, []);

    return (
        <Flex vertical={true} style={{ width: "100%", paddingBottom: 20 }}>
            <Form.Item name={InvoiceInputs.LINE_ITEMS}>
                <AgGridTable
                    ref={gridApi}
                    wrapperStyles={{ width: "100%", minHeight: "60px" }}
                    gridOptions={gridOptions}
                    columnDefs={columns}
                    onGridReady={onGridReady}
                    onFirstDataRendered={onFirstDataRendered}
                    onCellValueChanged={onCellValueChanged}
                    onRowDataUpdated={onRowDataUpdated}
                    onRowDragEnd={onRowDragEnd}
                />
            </Form.Item>
            <Flex align="start" justify="space-between" style={{ width: "100%" }}>
                <Button type="dashed" icon={<PlusOutlined />} onClick={onAddRow}>
                    <FormattedMessage id={InvoiceFormInputTranslate.NEW_LINE} />
                </Button>
                {totalState ? (
                    <Flex
                        vertical
                        align="start"
                        style={{
                            height: "100%",
                            maxWidth: 360,
                            width: "100%",
                            border: themeConfig.border,
                            borderRadius: 8,
                        }}
                    >
                        {invoiceTotalView === "Nett" ? (
                            <>
                                <Flex
                                    align="center"
                                    justify="space-between"
                                    className={styles.totalItem}
                                    style={{
                                        width: "100%",
                                        padding: "5px 10px",
                                        borderBottom: themeConfig.border,
                                    }}
                                >
                                    <Text>
                                        {" "}
                                        <FormattedMessage id={InvoiceFormInputTranslate.SUB_TOTAL} />
                                    </Text>
                                    <Text>
                                        {totalState.subtotal} {`(${currencyCode})`}
                                    </Text>
                                </Flex>
                                {totalState.tax0 ? (
                                    <Flex
                                        align="center"
                                        justify="space-between"
                                        className={styles.totalItem}
                                        style={{
                                            width: "100%",
                                            padding: "5px 10px",
                                            borderBottom: themeConfig.border,
                                        }}
                                    >
                                        <Text>
                                            <FormattedMessage id={InvoiceFormInputTranslate.VAT} /> 0%
                                        </Text>
                                        <Text>
                                            {totalState.tax0} {`(${currencyCode})`}
                                        </Text>
                                    </Flex>
                                ) : null}

                                {totalState.tax7 ? (
                                    <Flex
                                        align="center"
                                        justify="space-between"
                                        className={styles.totalItem}
                                        style={{
                                            width: "100%",
                                            padding: "5px 10px",
                                            borderBottom: themeConfig.border,
                                        }}
                                    >
                                        <Text>
                                            <FormattedMessage id={InvoiceFormInputTranslate.VAT} /> 7%
                                        </Text>
                                        <Text>
                                            {totalState.tax7} {`(${currencyCode})`}
                                        </Text>
                                    </Flex>
                                ) : null}

                                {totalState.tax19 ? (
                                    <Flex
                                        align="center"
                                        justify="space-between"
                                        className={styles.totalItem}
                                        style={{
                                            width: "100%",
                                            padding: "5px 10px",
                                            borderBottom: themeConfig.border,
                                        }}
                                    >
                                        <Text>
                                            <FormattedMessage id={InvoiceFormInputTranslate.VAT} /> 19%
                                        </Text>
                                        <Text>
                                            {totalState.tax19} {`(${currencyCode})`}
                                        </Text>
                                    </Flex>
                                ) : null}
                            </>
                        ) : null}

                        <Flex
                            align="center"
                            justify="space-between"
                            className={styles.totalItem}
                            style={{
                                backgroundColor: themeConfig.colorLight,
                                width: "100%",
                                fontWeight: 600,
                                borderRadius: 8,
                                padding: "5px 10px",
                            }}
                        >
                            <Text>
                                <FormattedMessage id={InvoiceFormInputTranslate.TOTAL_AMOUNT} />
                            </Text>
                            <Text>
                                {" "}
                                {totalState.total} {`(${currencyCode})`}
                            </Text>
                        </Flex>
                    </Flex>
                ) : null}
            </Flex>
        </Flex>
    );
};
