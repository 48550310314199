import React, { useContext } from "react";
import { FormClassName } from "../../../../scripts/infrastructure/helpers/focus";
import BinaleForm from "../../shared/form/BinaleForm";
import { FlexColumn } from "../../shared/appearance/page";
import { Layout } from "../../shared/Layout";
import { AntButtonBlock } from "../../shared/form/FormBlocks";
import { FormattedMessage } from "react-intl";
import { CloseOutlined } from "@ant-design/icons";
import { FlexRow } from "../../shared/appearance/page/Scaffold";
import { Space } from "antd";
import { FormRecordDate } from "../inputs/FormRecordDate";
import { useFormConfig } from "../hooks/useFormConfig";
import { FormRecordContact } from "../inputs/FormRecordContact";
import { FormRecordAccount } from "../inputs/FormRecordAccount";
import { RecordFormPropsContext } from "../context/RecordFormPropsContext";
import { RecordFormStateContext, RecordFormStateControlContext } from "../context/RecordFormState";
import { useFormInfoBlock } from "../hooks/useFormInfoBlock";
import { FormTotalBrutto } from "../inputs/FormTotalBrutto";
import { FormRecordBelegfeld1 } from "../inputs/FormRecordBelegfeld1";
import { FormItemBelegfeld1 } from "../inputs/FormItemBelegfeld1";
import { FormButtonSplit } from "../inputs/FormButtonSplit";
import { FormItemAccount } from "../inputs/FormItemAccount";
import { FormButtonPaymentConnection } from "../inputs/FormButtonPaymentConnection";
import { FormItemBu } from "../inputs/FormItemBu";
import { FormItemBelegfeld2 } from "../inputs/FormItemBelegfeld2";
import { FormItemTag } from "../inputs/FormItemTag";
import { FormItemText } from "../inputs/FormItemText";
import { FormItemText2 } from "../inputs/FormItemText2";
import { FormButtonDocuments } from "../inputs/FormButtonDocuments";
import { FormRecordLastschrift } from "../inputs/FormRecordLastschrift";
import { FormRecordDraft } from "../inputs/FormRecordDraft";
import { ModalForm } from "./ModalForm";
import { useFormRecordLifecycle } from "../hooks/useFormRecordLifecycle";
import { useFormKeyDown } from "../hooks/useFormKeyDown";

export const InlineForm: React.FC = () => {
    const formConfig = useFormConfig();
    const { infoBlock } = useFormInfoBlock();
    const onKeyDown = useFormKeyDown();
    const { validateAndSave } = useFormRecordLifecycle();
    const { disabled, refsHtml } = useContext(RecordFormPropsContext);
    const { setIsModalOpen } = useContext(RecordFormStateControlContext);
    const { isModalOpen } = useContext(RecordFormStateContext);
    const { onClearForm } = useFormRecordLifecycle();
    return (
        <div className={`ErfassungForm GenericRecordForm ${FormClassName}`} ref={refsHtml.inlineFormRef}>
            <BinaleForm onSubmit={validateAndSave} onKeyDown={onKeyDown}>
                <FlexColumn>
                    <Layout style={{ flexFlow: "row nowrap", alignItems: "flex-end" }}>
                        <FormRecordDate />
                        {formConfig.useContact === "afterDate" && <FormRecordContact />}
                        {!formConfig.putRecordCategoryCreditorAfterUst && <FormRecordAccount />}
                        {<FormTotalBrutto />}
                        {formConfig.useItemBelegfeld1 ? <FormItemBelegfeld1 /> : <FormRecordBelegfeld1 />}
                        {formConfig.useContact === "beforeSplit" && <FormRecordContact />}
                        {formConfig.useSplit && <FormButtonSplit />}
                        {formConfig.useItemAccount && <FormItemAccount />}
                        {formConfig.usePaymentBinding === "beforeBu" && <FormButtonPaymentConnection />}
                        {<FormItemBu />}
                        {formConfig.useContact === "afterBu" && <FormRecordContact />}
                        {formConfig.putRecordCategoryCreditorAfterUst && <FormRecordAccount />}
                        {formConfig.usePaymentBinding === "afterBu" && <FormButtonPaymentConnection />}
                        {<FormItemBelegfeld2 />}
                        {<FormItemTag />}
                        {<FormItemText />}
                        {<FormItemText2 />}
                        {<FormButtonDocuments />}
                        <AntButtonBlock
                            ref={refsHtml.REF_cBUCHEN}
                            className="RecordForm__Buchen"
                            disabled={disabled}
                            type="primary"
                            htmlType="submit"
                        >
                            <FormattedMessage id="app.button.buchen" />
                        </AntButtonBlock>
                        <AntButtonBlock
                            icon={<CloseOutlined />}
                            type="default"
                            tabIndex={-1}
                            disabled={disabled}
                            tooltipPlacement="topRight"
                            tooltip={
                                <>
                                    ESC <FormattedMessage id="app.components.form.clear" />
                                </>
                            }
                            className="ErfassungForm__Clear"
                            onClick={onClearForm}
                        />
                    </Layout>
                    <FlexRow style={{ flexFlow: "row nowrap", justifyContent: "space-between" }}>
                        {infoBlock}
                        <Space>
                            {<FormRecordLastschrift />}
                            {<FormRecordDraft />}
                        </Space>
                        {/* <div style={{ width: 135 }}>{this.inputFalligkeit}</div> */}
                    </FlexRow>
                </FlexColumn>
            </BinaleForm>
            <ModalForm ref={refsHtml.modalFormRef} show={isModalOpen} onHide={() => setIsModalOpen(false)} />
        </div>
    );
};
