import { AssetsBlock } from "../../shared/form/FormBlocks";
import React, { FC, useContext } from "react";

import { useFormIsRefDisabled } from "../hooks/useFormIsRefDisabled";
import { RecordFormStateContext } from "../context/RecordFormState";
import { TableViewContext } from "../../../../scripts/context/tableViewContext/tableViewContext";
import { GQL } from "@binale-tech/shared";
import { RecordFormPropsContext } from "../context/RecordFormPropsContext";
import { useApplyDocumentProperties } from "../hooks/useApplyDocumentProperties";
import { useFormHandlerDocument } from "../hooks/handlers/useFormHandlerDocument";

export const FormButtonDocuments: FC = () => {
    const isDisabled = useFormIsRefDisabled();
    const { refsHtml } = useContext(RecordFormPropsContext);
    const { editableRecord } = useContext(RecordFormStateContext);
    const onDocumentsChange = useFormHandlerDocument();
    const { product } = useContext(TableViewContext);
    const applyDocumentProperties = useApplyDocumentProperties();

    return (
        <AssetsBlock
            ref={refsHtml.REF_cASSET}
            disabled={isDisabled(refsHtml.REF_cASSET)}
            onSave={onDocumentsChange}
            onFileListChanged={applyDocumentProperties}
            value={editableRecord.recordDocuments}
            productKey={product.productKey() as GQL.IProductKey}
        />
    );
};
