import React, { createContext, FC, ReactNode } from "react";
import { BanksApi } from "@banks/scripts/api";
import { IBank, IBankUpdateInput } from "@banks/types";

type TValue = {};
type TActionValue = {
    updateBank: (arg: IBankUpdateInput) => Promise<void>;
};

const initialActionValue = {
    updateBank: () => Promise.resolve(),
};

export const EditBankContext = createContext<TValue>({});
export const EditBankControlContext = createContext<TActionValue>(initialActionValue);

type TProps = {
    children?: ReactNode;
};

export const EditBankContextProvider: FC<TProps> = ({ children }) => {
    const value = {};

    const actions = {
        updateBank: async (arg: IBank) => {
            const bankData = { ...arg };
            delete bankData.transactions;

            await BanksApi.updateBank(bankData);
        },
    };

    return (
        <EditBankContext.Provider value={value}>
            <EditBankControlContext.Provider value={actions}>{children}</EditBankControlContext.Provider>
        </EditBankContext.Provider>
    );
};
