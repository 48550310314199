import deDE from "antd/locale/de_DE";
import enGB from "antd/locale/en_GB";
import ruRU from "antd/locale/ru_RU";

import "dayjs/locale/de";
import "dayjs/locale/en-gb";
import "dayjs/locale/ru";

import de from "i18n-iso-countries/langs/de.json";
import en from "i18n-iso-countries/langs/en.json";
import ru from "i18n-iso-countries/langs/ru.json";
import * as countries from "i18n-iso-countries";

import React, {
  ContextType,
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import { ConfigProvider } from "antd";

import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";

import { getLocaleMessages, ILocaleMessages, Lang } from "../intl";
import { IntlProvider } from "react-intl";
import { auth } from "../api/firebase/firebase";

countries.registerLocale(en);
countries.registerLocale(de);
countries.registerLocale(ru);

dayjs.extend(localeData); // for getting month names from current locale
dayjs.extend(duration);
dayjs.extend(relativeTime);

const antLocale = (v: Lang) => {
    switch (v) {
        case Lang.En:
            return enGB;
        case Lang.Ru:
            return ruRU;
        default:
            return deDE;
    }
};

export enum AppError {
    IndexedDB = 1,
    FirebaseConnectionError,
    ReactRendering,
}

export const AppSettingsContext = createContext({
    intl: null as ILocaleMessages | null,
    changeLang: (v: Lang) => {},
    useClassicImage: false,
    changeImage: (old: boolean) => {},
    appError: undefined as AppError | undefined,
    setAppError: (error: AppError | undefined) => {},
});

const lsKey = "lang";
const imgKey = "useOldImage";

export const getLocalStorageLang = () => localStorage.getItem(lsKey) as Lang;

export const AppSettingsProvider: FC<PropsWithChildren> = props => {
    const [intlState, setIntlState] = useState<ILocaleMessages>(getLocaleMessages(getLocalStorageLang()));
    const [useClassicImage, setUseClassicImage] = useState<boolean>(Boolean(localStorage.getItem(imgKey)));
    const [appError, setAppError] = useState<AppError>(undefined);

    const changeLang = useCallback((v: Lang) => {
        const msg = getLocaleMessages(v);
        const { locale } = msg;
        dayjs.locale(locale);
        auth.languageCode = locale;
        setIntlState(msg);
        localStorage.setItem(lsKey, locale);
    }, []);

    const changeImage = useCallback((useOld: boolean) => {
        setUseClassicImage(useOld);
        if (useOld) {
            localStorage.setItem(imgKey, "1");
        } else {
            localStorage.removeItem(imgKey);
        }
    }, []);

    useEffect(() => {
        changeLang(getLocalStorageLang());
    }, [changeLang]);

    const setAppErrorWithLog = useCallback((v: AppError) => {
        setAppError(v);
    }, []);

    const settingsValue: ContextType<typeof AppSettingsContext> = useMemo(
        () => ({
            intl: intlState,
            changeLang,
            useClassicImage,
            changeImage,
            appError,
            setAppError: setAppErrorWithLog,
        }),
        [changeLang, intlState, useClassicImage, changeImage, appError]
    );

    return (
        <IntlProvider {...intlState}>
            <ConfigProvider locale={antLocale(intlState.locale)} popupMatchSelectWidth={false}>
                <AppSettingsContext.Provider value={settingsValue}>{props.children}</AppSettingsContext.Provider>
            </ConfigProvider>
        </IntlProvider>
    );
};
