import React from "react";
import {
    FacebookOutlined,
    GlobalOutlined,
    InstagramOutlined,
    MailOutlined,
    PhoneOutlined,
    SendOutlined,
    SkypeOutlined,
} from "@ant-design/icons";
import { FormattedMessage } from "react-intl";

import { Contacts, GQL } from "@binale-tech/shared";

export const CommunicationIcons = {
    [GQL.ICommunicationType.Email]: <MailOutlined />,
    [GQL.ICommunicationType.Facebook]: <FacebookOutlined />,
    [GQL.ICommunicationType.Instagram]: <InstagramOutlined />,
    [GQL.ICommunicationType.Phone]: <PhoneOutlined />,
    [GQL.ICommunicationType.Skype]: <SkypeOutlined />,
    [GQL.ICommunicationType.Telegram]: <SendOutlined />,
    [GQL.ICommunicationType.Website]: <GlobalOutlined />,
};

export const getTypeName = (type: GQL.ICommunicationType) => {
    const typeNames: Record<string, React.ReactNode> = {
        [GQL.ICommunicationType.Phone]: <FormattedMessage id="app.fields.phone" />,
        [GQL.ICommunicationType.Email]: "E-Mail",
        [GQL.ICommunicationType.Website]: "Web",
    };
    return typeNames[type] || type;
};
