import React, { FC, useContext } from "react";
import { AntButtonBlock } from "../../shared/form/FormBlocks";
import { ForkOutlined } from "@ant-design/icons";
import { useFormIsRefDisabled } from "../hooks/useFormIsRefDisabled";
import { useFormProperties } from "../hooks/useFormProperties";
import { RecordFormStateContext, RecordFormStateControlContext } from "../context/RecordFormState";
import { RecordFormPropsContext } from "../context/RecordFormPropsContext";

export const FormButtonSplit: FC = () => {
    const isDisabled = useFormIsRefDisabled();
    const { isSplitDisabled } = useFormProperties();
    const { refsHtml } = useContext(RecordFormPropsContext);
    const { setIsModalOpen } = useContext(RecordFormStateControlContext);
    const { recordItems } = useContext(RecordFormStateContext);
    const disabled = isSplitDisabled || isDisabled(refsHtml.REF_cSPLIT);
    return (
        <AntButtonBlock
            tooltip={disabled ? undefined : "Split"}
            ref={refsHtml.REF_cSPLIT}
            disabled={disabled}
            onClick={() => setIsModalOpen(prevState => !prevState)}
            icon={<ForkOutlined />}
            badge={recordItems?.length > 1 ? { color: "green", count: recordItems.length } : undefined}
        />
    );
};
