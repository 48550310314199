import React, { type FC, useEffect, useState } from "react";
import { Button, Radio, Space, Typography } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { TUploaderFileInfo } from "../../../../types";

interface IProps {
    hash: string;
    notUniqFiles: TUploaderFileInfo[];
    handleSelectNotUniqFiles: (
        hash: string,
        selected: TUploaderFileInfo | null,
        unSelected: TUploaderFileInfo[]
    ) => void;
}

export const NotUniqFile: FC<IProps> = ({ hash, notUniqFiles, handleSelectNotUniqFiles }) => {
    const [selectedFile, setSelectedFile] = useState<TUploaderFileInfo>(notUniqFiles[0]);
    const [unSelectedFiles, setUnSelectedFiles] = useState<TUploaderFileInfo[]>(notUniqFiles.slice(1));

    const handleSelect = (uid: string) => {
        const selected = notUniqFiles.filter(el => el.file.uid === uid)[0];
        const removed = notUniqFiles.filter(el => el.file.uid !== uid);

        setSelectedFile(selected);
        setUnSelectedFiles(removed);
    };

    useEffect(() => {
        handleSelectNotUniqFiles(hash, selectedFile, unSelectedFiles);
    }, [selectedFile, unSelectedFiles]);

    const handleRemove = () => {
        handleSelectNotUniqFiles(hash, null, notUniqFiles);
    };

    return (
        <div>
            <div
                style={{
                    position: "relative",
                    marginTop: "5px",
                    padding: "8px",
                    border: "1px solid #1677ff",
                    borderRadius: "5px",
                }}
            >
                <Button
                    size={"small"}
                    icon={<DeleteOutlined />}
                    style={{ position: "absolute", top: "7px", right: "7px" }}
                    danger
                    type={"text"}
                    onClick={handleRemove}
                ></Button>
                <Typography.Text type={"secondary"}>
                    <FormattedMessage id="app.dms.identicalFiles" />
                </Typography.Text>
                <div style={{ padding: "5px 8px 0" }}>
                    <Radio.Group
                        onChange={e => {
                            handleSelect(e.target.value);
                        }}
                        value={selectedFile?.file.uid}
                    >
                        <Space direction="vertical">
                            {notUniqFiles.map(el => {
                                const { file } = el;
                                return (
                                    <Radio value={file.uid} key={file.uid}>
                                        <div
                                            style={{
                                                marginLeft: "5px",
                                            }}
                                        >
                                            {file.name}
                                        </div>
                                    </Radio>
                                );
                            })}
                        </Space>
                    </Radio.Group>
                </div>
            </div>
        </div>
    );
};
