import React, { FC } from "react";
import { InvoicesListContextProvider } from "@inv/modules/InvocesListModule/context/InvoicesListContext";
import { InvoicesList } from "@inv/components/InvoicesList";

export const InvoicesListModule: FC = () => {
    return (
        <InvoicesListContextProvider>
            <InvoicesList />
        </InvoicesListContextProvider>
    );
};
