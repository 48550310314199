import { DeleteOutlined, DownloadOutlined, EditOutlined, LoadingOutlined, SendOutlined } from "@ant-design/icons";
import { gql, useApolloClient } from "@apollo/client";
import { GQL } from "@binale-tech/shared";
import { DmsType, IDocumentEnriched } from "@dms/types";
import { Button, message, Popconfirm, Space } from "antd";
import React, { type FC, useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import { AiButton } from "@dms/components/AiButton/AiButton";
import { PATH } from "@dms/configs/constants";
import { DmsAppContext, DmsAppControlContext, DmsDataContext } from "@dms/types/ContextTypes";
import { DocumentsApi } from "@dms/scripts/DocumentsApi/DocumentsApi";
import { DmsUtils } from "@dms/scripts/utils/DmsUtils";
import { useGqlMutator } from "../../../scripts/graphql/useGqlMutator";
import { CompanyContext } from "../../../scripts/context/CompanyContext";
import { FibuButton } from "@dms/components/GroupButtons/components/FibuButton";

interface IProps {
    documentData: IDocumentEnriched[];
}
const mutationBatchAi = gql`
    mutation documentsProcessAi($input: DocumentsProcessAiInput!) {
        documentsProcessAi(input: $input)
    }
`;
export const GroupButtons: FC<IProps> = ({ documentData }) => {
    const [isDisabledBtn, setIsDisabledBtn] = useState(false);
    const [isDownload, setIsDownload] = useState(false);
    const [isAILoading, setIsAILoading] = useState<boolean>(false);

    const { companyGQL } = useContext(CompanyContext);
    const { dmsRecordsData, documents } = useContext(DmsDataContext);
    const client = useApolloClient();

    const mutator = useGqlMutator();

    const [messageApi, contextHolder] = message.useMessage();

    const { selectedRowKeys, activeType } = useContext(DmsAppContext);
    const { deleteModeToggle, typeChangeModeToggle, resetFocusedRows } = useContext(DmsAppControlContext);
    const navigate = useNavigate();

    const selectedDocuments = documentData.filter(el => selectedRowKeys.includes(el.key));

    const { type } = DmsUtils.getActiveTypeAndSubType(activeType);

    useEffect(() => {
        setIsDisabledBtn(false);
    }, [selectedRowKeys]);

    const handleDeleteBtn = async (): Promise<void | boolean> => {
        resetFocusedRows();
        setIsDisabledBtn(true);
        if (type === DmsType.trash) {
            const foundDoc = selectedDocuments.some(el => {
                return dmsRecordsData.recordsAssetsSet.has(DocumentsApi.urlFormatter(el.fileUrl));
            });

            if (foundDoc) {
                return deleteModeToggle(true);
            }

            return DocumentsApi.removeDocuments(selectedDocuments, mutator);
        }

        await DocumentsApi.sendToTrash(selectedDocuments, mutator);

        setIsDisabledBtn(false);
    };

    const handleDownloadBtn = async (): Promise<void> => {
        resetFocusedRows();
        const files = documents.filter(el => selectedRowKeys.includes(el.key));
        await DocumentsApi.bulkDownloadFiles(files, setIsDownload);
    };
    const handleBatchBinaleAi = () => {
        resetFocusedRows();
        setIsAILoading(true);
        client
            .mutate<Pick<GQL.IMutation, "documentsProcessAi">, { input: GQL.IDocumentsProcessAiInput }>({
                mutation: mutationBatchAi,
                variables: {
                    input: {
                        companyId: companyGQL.id,
                        ids: selectedDocuments.map(({ key }) => key),
                    },
                },
            })
            .catch(e => {
                messageApi.open({
                    type: "error",
                    content: e.message,
                });
            })
            .finally(() => setIsAILoading(false));
    };

    return (
        <Space>
            {contextHolder}
            <Button
                disabled={isDisabledBtn}
                onClick={() => {
                    resetFocusedRows();
                    const params = new URLSearchParams();
                    selectedRowKeys.forEach(key => params.append("key", String(key)));
                    navigate(`/${PATH.DOCUMENTS}/${PATH.EDITING_GROUP}?${params.toString()}`);
                }}
                type={"primary"}
                icon={<EditOutlined />}
            >
                <FormattedMessage id="app.button.edit" />({selectedRowKeys.length})
            </Button>
            <Button
                onClick={() => {
                    typeChangeModeToggle(true);
                    resetFocusedRows();
                }}
                disabled={isDisabledBtn}
                type={"primary"}
                ghost
                icon={<SendOutlined />}
            >
                <FormattedMessage id="app.dms.moveTo" />({selectedRowKeys.length})
            </Button>
            <Popconfirm
                title={
                    <FormattedMessage
                        id={
                            type === DmsType.trash
                                ? "app.global.confirmRemoveDocument"
                                : "app.global.confirmSentToTrash"
                        }
                    />
                }
                destroyTooltipOnHide
                onConfirm={handleDeleteBtn}
                cancelButtonProps={{ autoFocus: true }}
                okText={<FormattedMessage id="app.button.confirm" />}
                cancelText={<FormattedMessage id="app.button.cancel" />}
            >
                <Button disabled={isDisabledBtn} danger icon={<DeleteOutlined />}>
                    <FormattedMessage id="app.button.delete" />({selectedRowKeys.length})
                </Button>
            </Popconfirm>
            <Button
                disabled={isDisabledBtn}
                onClick={handleDownloadBtn}
                type={"dashed"}
                icon={isDownload ? <LoadingOutlined /> : <DownloadOutlined />}
            >
                <FormattedMessage id="app.button.download" />({selectedRowKeys.length})
            </Button>

            <FibuButton documents={selectedDocuments} />

            {[DmsType.ER, DmsType.Deb].includes(type as DmsType) && (
                <AiButton onClick={handleBatchBinaleAi} disabled={isDisabledBtn} isLoading={isAILoading} />
            )}
        </Space>
    );
};
