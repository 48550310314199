import { InfoCircleOutlined } from "@ant-design/icons";
import { Form, Tooltip, Typography } from "antd";
import cn from "classnames";
import React, { type FC, useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { FormWrapperContext } from "@dms/modules/DocumentFormModule/context/FormWrapperContext";
import { GroupFormContext } from "@dms/modules/GroupFormModules/context/GroupFormContext";
import { SingleFormContext } from "@dms/modules/SingleFormModule/context/SingleFormContext";

import { DocumentNumberInput } from "../DocumentInputs/DocumentNumberInput";
import { FieldLabel } from "@app/components/shared/form/baseComponents/FieldLabel";
import { FormItemCheckbox } from "../DocumentInputs/FormItemCheckbox";

import styles from "./DocumentFormField.module.scss";

const { Text } = Typography;

interface IProps {
    aiResult?: boolean;
}

const INPUT_MAX_LENGTH = 36;
//TODO reorg component
export const NumberFieldLabel: FC<{ isLabel: boolean }> = ({ isLabel }) => {
    return (
        <>
            {isLabel ? <FormattedMessage id="app.dms.documentNumber" /> : null}
            <Tooltip
                placement="top"
                title={
                    <span>
                        <FormattedMessage id="app.dms.tooltipDocumentNumber" />: <br />
                        <code>
                            <FormattedMessage id="app.dms.letters" />: aA - zZ
                        </code>
                        <br />{" "}
                        <code>
                            {" "}
                            <FormattedMessage id="app.dms.number" />: 0 - 9
                        </code>
                        <br />{" "}
                        <code>
                            <FormattedMessage id="app.dms.specialSymbols" />: - / $ & % +
                        </code>
                    </span>
                }
            >
                <InfoCircleOutlined style={{ marginLeft: "10px" }} />
            </Tooltip>
        </>
    );
};

const SingleInvoiceNumberField: FC<IProps> = ({ aiResult }) => {
    const { necessaryFields } = useContext(SingleFormContext);
    return (
        <FieldLabel
            className={cn("DMSForm__Item", {
                [styles.aiResult]: aiResult,
            })}
            name={"documentNumber"}
            label={
                <Text strong ellipsis type={necessaryFields?.includes("documentNumber") ? "warning" : undefined}>
                    <NumberFieldLabel isLabel={true} />
                </Text>
            }
        >
            <DocumentNumberInput maxLength={INPUT_MAX_LENGTH} showCount />
        </FieldLabel>
    );
};

const GroupInvoiceNumberField: FC = () => {
    const [isDisabled, setIsDisabled] = useState<boolean>(true);

    const { notEqualField, isCancel, necessaryGroupFields } = useContext(GroupFormContext);

    useEffect(() => {
        if (isCancel) {
            setIsDisabled(true);
        }
    }, [isCancel]);

    const placeholder = notEqualField.includes("documentNumber") ? "Diverse" : undefined;

    return (
        <>
            <div style={{ position: "relative" }}>
                <Form.Item
                    initialValue={false}
                    name={"documentNumberCheckbox"}
                    valuePropName={"checked"}
                    noStyle={true}
                >
                    <FormItemCheckbox parentFieldName={"documentNumber"} setIsDisabled={arg => setIsDisabled(arg)} />
                </Form.Item>
            </div>
            <FieldLabel
                className={"DMSForm__Item"}
                name={"documentNumber"}
                label={
                    <Text
                        strong
                        ellipsis
                        type={necessaryGroupFields?.includes("documentNumber") ? "warning" : undefined}
                    >
                        <NumberFieldLabel isLabel={true} />
                    </Text>
                }
            >
                <DocumentNumberInput
                    placeholder={placeholder}
                    disabled={isDisabled}
                    maxLength={INPUT_MAX_LENGTH}
                    showCount
                />
            </FieldLabel>
        </>
    );
};

export const DocumentInvoiceNumberField: FC<IProps> = ({ aiResult }) => {
    const { isGroupEditor } = useContext(FormWrapperContext);

    return <>{isGroupEditor ? <GroupInvoiceNumberField /> : <SingleInvoiceNumberField aiResult={aiResult} />}</>;
};
