import { Base, GQL } from "@binale-tech/shared";

import Category from "../models/Category";
import Creditor, { Debitor } from "../models/Creditor";
import React, { useCallback, useMemo } from "react";
import Tag from "../models/Tag";
import { CompanyContext, YearPeriodContext } from "./CompanyContext";
import {
    categoryDelete,
    categorySave,
    creditorCreate,
    creditorDelete,
    creditorUpdate,
    debitorCreate,
    debitorDelete,
    debitorUpdate,
    tagCreate,
    tagDelete,
    tagUpdate,
} from "./mutations/kontoMutations.graphql";
import { refCategory, refCreditor, refDebitor, refTag } from "../api/firebase/firebaseRootRefs";
import { useGqlMutator } from "../graphql/useGqlMutator";
import { child, DataSnapshot } from "firebase/database";
import { useCollectionListener } from "./hooks/useCollectionListener";

export type AccountMaps = {
    categoryOverridesMap: Map<string, Base.INumName>;
    creditorsMap: Map<string, Creditor>;
    debitorsMap: Map<string, Debitor>;
    tagsMap: Map<string, Tag>;
};
type KontoCtxData = {
    categoryOverrides: Base.INumName[];
    creditors: Creditor[];
    debitors: Debitor[];
    tags: Tag[];
    accountMaps: AccountMaps;
};
export const defaultAccountsMap = {
    categoryOverridesMap: new Map(),
    creditorsMap: new Map(),
    debitorsMap: new Map(),
    tagsMap: new Map(),
};
const defaultValue: KontoCtxData = {
    categoryOverrides: [],
    creditors: [],
    debitors: [],
    tags: [],
    accountMaps: defaultAccountsMap,
    // areEntitiesLoaded: false,
};
export const KontoContext = React.createContext<KontoCtxData>(defaultValue);

type KontoController = {
    categoryOverrideSave: (v: Base.INumName) => Promise<void>;
    categoryOverrideDelete: (id: string, num: string) => Promise<void>;
    creditorSave: (v: Creditor) => Promise<void>;
    creditorDelete: (id: string, num: string) => Promise<void>;
    debSave: (v: Debitor) => Promise<void>;
    debDelete: (id: string, num: string) => Promise<void>;
    tagSave: (v: Tag) => Promise<void>;
    tagDelete: (id: string, num: string) => Promise<void>;
};
export const KontoControlContext = React.createContext<KontoController>({
    categoryOverrideSave: (v: Base.INumName) => Promise.resolve(),
    categoryOverrideDelete: (id: string, num: string) => Promise.resolve(),
    creditorSave: (v: Creditor) => Promise.resolve(),
    creditorDelete: (id: string, num: string) => Promise.resolve(),
    debSave: (v: Debitor) => Promise.resolve(),
    debDelete: (id: string, num: string) => Promise.resolve(),
    tagSave: (v: Tag) => Promise.resolve(),
    tagDelete: (id: string, num: string) => Promise.resolve(),
});

const toMap = <T extends Base.INumName>(vs: Map<string, T>): Map<string, T> => {
    const map = new Map<string, T>();
    vs.forEach(v => {
        map.set(v.num, v);
    });
    return map;
};

export const EntitiesProvider: React.FC<React.PropsWithChildren> = props => {
    const { companyGQL, yearConfig } = React.useContext(CompanyContext);
    const companyId = companyGQL?.id;
    const kontoExt = yearConfig?.kontoExt ?? 0;
    const { year } = React.useContext(YearPeriodContext);
    const mutator = useGqlMutator();

    const path = `${companyId}/${year}`;
    const shouldSkipLoad = useMemo(() => !companyId, [companyId]);

    const catRef = useMemo(() => child(refCategory, path), [path]);
    const catInitializer = useCallback((snap: DataSnapshot) => {
        const { num, name, marked } = snap.val();
        return Category.unserialize({ num, name, marked, key: snap.key });
    }, []);

    const credRef = useMemo(() => child(refCreditor, path), [path]);
    const credInitializer = useCallback(
        (snap: DataSnapshot) => Creditor.unserialize(snap.val()).fixNum(kontoExt) as Creditor,
        [kontoExt]
    );

    const debRef = useMemo(() => child(refDebitor, path), [path]);
    const debInitializer = useCallback(
        (snap: DataSnapshot) => Debitor.unserialize(snap.val()).fixNum(kontoExt) as Debitor,
        [kontoExt]
    );

    const tagsRef = useMemo(() => child(refTag, path), [path]);
    const tagsInitializer = useCallback((snap: DataSnapshot) => Tag.unserialize(snap.val()), []);

    const categoriesMap = useCollectionListener<Category>(catRef, catInitializer, shouldSkipLoad);
    const creditorsMap = useCollectionListener<Creditor>(credRef, credInitializer, shouldSkipLoad);
    const debitorsMap = useCollectionListener<Debitor>(debRef, debInitializer, shouldSkipLoad);
    const tagsMap = useCollectionListener<Tag>(tagsRef, tagsInitializer, shouldSkipLoad);

    const value: KontoCtxData = React.useMemo(
        () => ({
            categoryOverrides: Array.from(categoriesMap.values()).sort((a, b) => Number(a.num) - Number(b.num)),
            creditors: Array.from(creditorsMap.values()).sort((a, b) => Number(a.num) - Number(b.num)),
            debitors: Array.from(debitorsMap.values()).sort((a, b) => Number(a.num) - Number(b.num)),
            tags: Array.from(tagsMap.values()).sort((a, b) => Number(a.num) - Number(b.num)),
            accountMaps: {
                categoryOverridesMap: toMap(categoriesMap),
                creditorsMap: toMap(creditorsMap),
                debitorsMap: toMap(debitorsMap),
                tagsMap: toMap(tagsMap),
            },
        }),
        [categoriesMap, creditorsMap, debitorsMap, tagsMap]
    );

    const kontoControl: KontoController = React.useMemo(
        () => ({
            categoryOverrideSave: async category => {
                await mutator.mutate<"categorySave", GQL.ICategorySaveInput>({
                    mutation: categorySave,
                    input: {
                        companyId,
                        name: category.name,
                        accountNumString: category.num,
                        year,
                    },
                });
            },
            categoryOverrideDelete: async (id, num) => {
                await mutator.mutate<"categoryDelete", GQL.ICompanyNumEntityDeleteInput>({
                    mutation: categoryDelete,
                    input: {
                        companyId,
                        id,
                        year,
                        num,
                    },
                });
            },
            creditorSave: async creditor => {
                const { name, description } = creditor;
                const data = {
                    name,
                    description,
                    companyId,
                };
                if (creditor.key) {
                    await mutator.mutate<"creditorUpdate", GQL.ICredDebUpdateInput>({
                        mutation: creditorUpdate,
                        input: {
                            ...data,
                            id: creditor.key,
                            num: creditor.num,
                            year,
                        },
                    });
                } else {
                    await mutator.mutate<"creditorCreate", GQL.ICredDebCreateInput>({
                        mutation: creditorCreate,
                        input: {
                            ...data,
                            year,
                            accountNum: +creditor.num,
                        },
                    });
                }
            },
            creditorDelete: async (id, num) => {
                await mutator.mutate<"creditorDelete", GQL.ICompanyNumEntityDeleteInput>({
                    mutation: creditorDelete,
                    input: {
                        companyId,
                        id,
                        year,
                        num,
                    },
                });
            },
            debSave: async debitor => {
                const { name, description } = debitor;
                const data = {
                    name,
                    description,
                    companyId,
                };
                if (debitor.key) {
                    await mutator.mutate<"debitorUpdate", GQL.ICredDebUpdateInput>({
                        mutation: debitorUpdate,
                        input: {
                            ...data,
                            id: debitor.key,
                            num: debitor.num,
                            year,
                        },
                    });
                } else {
                    await mutator.mutate<"debitorCreate", GQL.ICredDebCreateInput>({
                        mutation: debitorCreate,
                        input: {
                            ...data,
                            accountNum: +debitor.num,
                            year,
                        },
                    });
                }
            },
            debDelete: async (id, num) => {
                await mutator.mutate<"debitorDelete", GQL.ICompanyNumEntityDeleteInput>({
                    mutation: debitorDelete,
                    input: {
                        companyId,
                        id,
                        year,
                        num,
                    },
                });
            },
            tagSave: async tag => {
                if (tag.key) {
                    await mutator.mutate<"tagUpdate", GQL.ITagUpdateInput>({
                        mutation: tagUpdate,
                        input: {
                            companyId,
                            id: tag.key,
                            num: tag.num,
                            name: tag.name,
                            year,
                        },
                    });
                } else {
                    await mutator.mutate<"tagCreate", GQL.ITagCreateInput>({
                        mutation: tagCreate,
                        input: {
                            companyId,
                            name: tag.name,
                            num: tag.num,
                            year,
                        },
                    });
                }
            },
            tagDelete: async (id, num) => {
                await mutator.mutate<"tagDelete", GQL.ICompanyNumEntityDeleteInput>({
                    mutation: tagDelete,
                    input: {
                        companyId,
                        id,
                        year,
                        num,
                    },
                });
            },
        }),
        [companyId, mutator, year]
    );
    return (
        <KontoContext.Provider value={value}>
            <KontoControlContext.Provider value={kontoControl}>{props.children}</KontoControlContext.Provider>
        </KontoContext.Provider>
    );
};
