import { GQL } from "@binale-tech/shared";
import { CurrencyValue } from "@binale-tech/ui-components";
import * as React from "react";
import { type FC, type PropsWithChildren, useContext, useEffect, useRef, useState } from "react";
import { DmsType, IDocumentEnriched, type TDocumentInputs } from "@dms/types";
import { necessaryFieldsForDocumentTypes } from "@dms/configs/constants";
import { DocumentFieldConfig } from "@dms/configs/documentConfig";
import { DocumentsApi } from "@dms/scripts/DocumentsApi/DocumentsApi";
import { compareObjectsByKey } from "@dms/scripts/helpers";
import { getFileNameAndSuffix } from "@dms/scripts/helpers/getFileNameAndSuffix";
import { FormWrapperContext } from "../../DocumentFormModule/context/FormWrapperContext";
import { useGqlMutator } from "../../../../scripts/graphql/useGqlMutator";
import { DmsDataContext } from "@dms/types/ContextTypes";

interface TValue {
    groupFormInitialValue: TDocumentInputs | undefined;
    disableInputs: (arg: boolean) => void;
    notEqualField: string[];
    isCancel: boolean;
    actionConfirm: (arg: TDocumentInputs) => Promise<void>;
    requiredFields: string[];
    setRequiredFields: (arg: string[]) => void;
    fileNameSuffix: string;
    necessaryGroupFields?: readonly (keyof Partial<IDocumentEnriched>)[];
    handleSetNecessaryGroupFields: (type: string) => void;
}

/**
 Context for Document Form
 */
export const GroupFormContext = React.createContext({} as TValue);

/**
 Context Provider for Document Form
 */

export const GroupFormProvider: FC<PropsWithChildren> = ({ children }) => {
    const [groupFormInitialValue, setGroupFormInitialValue] = useState<TDocumentInputs>();
    const [notEqualField, setNotEqualField] = useState<(keyof TDocumentInputs)[]>([]);
    const [isCancel, setIsCancel] = useState<boolean>(false);
    const [requiredFields, setRequiredFields] = useState<string[]>([]);
    const [necessaryGroupFields, setNecessaryGroupFields] = useState<readonly (keyof Partial<IDocumentEnriched>)[]>([]);
    const [isEditing, setIsEditing] = useState<boolean>(false);

    const [fileNameSuffix, setFileNameSuffix] = useState<string>(".pdf");

    const { checkboxCarousel } = useContext(FormWrapperContext);
    const { documents } = useContext(DmsDataContext);

    const mutator = useGqlMutator();

    const initialDocuments = useRef(null);

    useEffect(() => {
        if (isEditing) {
            return;
        }
        initialDocuments.current = documents;
    }, [documents, isEditing]);

    useEffect(() => {
        if (!initialDocuments.current) {
            return;
        }

        const selectedDocuments = initialDocuments.current.filter((document: { key: string }) =>
            checkboxCarousel.includes(document.key)
        );

        if (selectedDocuments?.length === 0) {
            return;
        }

        const notEqualFieldArr: (keyof TDocumentInputs)[] = [];

        const fields: Record<string, TDocumentInputs[keyof TDocumentInputs]> = Object.keys(
            DocumentFieldConfig.getDocumentField(selectedDocuments[0].type)
        ).reduce((acc, key) => {
            const parameter = key as keyof IDocumentEnriched;
            const isNotIdentity = compareObjectsByKey(selectedDocuments, parameter);

            if (isNotIdentity) {
                notEqualFieldArr.push(parameter);

                return {
                    ...acc,
                    [parameter]: undefined,
                };
            }

            return {
                ...acc,
                [parameter]: selectedDocuments[0][parameter],
            };
        }, {});

        const type = fields.subType ? fields.subType : fields.type;
        const { fileName, documentAmount, originalAmount, currency, currencyRate, ...restValue } = fields;

        let clippedFileName = fileName as string;
        if (fileName) {
            const { fileName: name, suffix } = getFileNameAndSuffix(fileName as string);
            clippedFileName = name;
            setFileNameSuffix(suffix || ".pdf");
        }

        const currencyData: CurrencyValue = {
            // TODO fix after FB migration
            amount: documentAmount as number,
            originalAmount: originalAmount as number,
            currency: { code: currency as GQL.ICurrencyCode, rate: (currencyRate as number) ?? 1 },
        };

        setGroupFormInitialValue({
            ...restValue,
            type: type as string,
            currencyData,
            fileName: clippedFileName,
        });
        setNotEqualField(notEqualFieldArr);
        setNecessaryGroupFields(necessaryFieldsForDocumentTypes[fields.type as DmsType] || []);
        setIsEditing(true);
    }, [isEditing, checkboxCarousel]);

    const disableInputs = (arg: boolean): void => {
        setIsCancel(arg);
    };

    const actionConfirm = async (data: TDocumentInputs): Promise<void> => {
        const documentsArr: IDocumentEnriched[] = documents
            .filter(el => checkboxCarousel.includes(el.key))
            .map(el => {
                return {
                    ...el,
                    ...data,
                };
            });

        await DocumentsApi.updateDocuments({ documents: documentsArr, mutator });
        setIsEditing(false);
    };

    const handleSetNecessaryGroupFields = (type: string) => {
        setNecessaryGroupFields(necessaryFieldsForDocumentTypes[type as DmsType] || []);
    };

    const value: TValue = {
        groupFormInitialValue,
        notEqualField,
        isCancel,
        requiredFields,
        disableInputs,
        actionConfirm,
        setRequiredFields,
        fileNameSuffix,
        necessaryGroupFields,
        handleSetNecessaryGroupFields,
    };

    return <GroupFormContext.Provider value={value}>{children}</GroupFormContext.Provider>;
};
