import React, { FC, useContext, useState } from "react";
import { Button, Col, Divider, Form, Row, Space, Spin } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined, LoadingOutlined, ReloadOutlined } from "@ant-design/icons";
import { FormattedMessage } from "react-intl";
import { IBankCreateInput } from "@banks/types";
import { BanksAppContext, BanksAppControlContext } from "@banks/scripts/context";
import {
    AccountNumberField,
    BankIdField,
    BankNameField,
    BankTitleField,
    IbanField,
    SwiftBicField,
} from "@banks/components/FormFields";

import styles from "./EditBankForm.module.scss";
import { EditBankControlContext } from "@banks/modules/EditBankModule/context";
import { ColorField } from "@banks/components/FormFields/ColorField";
import { OpeningBalanceField } from "@banks/components/FormFields/OpeningBalanceField";
import { OpeningBalanceDateField } from "@banks/components/FormFields/OpeningBalanceDateField";

export const EditBankForm: FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [isError, setIsError] = useState(false);

    const { setEditBankData } = useContext(BanksAppControlContext);
    const { editBankData } = useContext(BanksAppContext);
    const { updateBank } = useContext(EditBankControlContext);
    const [form] = Form.useForm();

    const { bank } = editBankData;

    const values: IBankCreateInput = Form.useWatch([], form);

    const handleFinish = async (inputValues: IBankCreateInput) => {
        setIsLoading(true);
        try {
            await updateBank({ ...bank, ...inputValues });
            setSuccess(true);
        } catch {
            setIsError(true);
        } finally {
            setIsLoading(false);

            setTimeout(() => {
                setEditBankData({ bank: null, isModalOpen: false });
            }, 500);
        }
    };

    return (
        <Form
            key={"editBankForm"}
            form={form}
            layout="vertical"
            name={"edit-bank-form"}
            autoComplete={"off"}
            onFinish={handleFinish}
            initialValues={bank}
            clearOnDestroy
        >
            <Row gutter={[10, 10]} className={styles.bankFormRow}>
                <Col span={14}>
                    <IbanField value={values?.iban} />
                </Col>

                <Col span={10}>
                    <SwiftBicField />
                </Col>
            </Row>

            <Row gutter={[10, 10]} className={styles.bankFormRow}>
                <Col span={8}>
                    <BankIdField />
                </Col>

                <Col span={16}>
                    <AccountNumberField />
                </Col>
            </Row>

            <Row gutter={[10, 10]} className={styles.bankFormRow}>
                <Col span={24}>
                    <BankNameField />
                </Col>
            </Row>

            <Row gutter={[10, 10]} className={styles.bankFormRow}>
                <Col span={24}>
                    <BankTitleField />
                </Col>
            </Row>

            <Row gutter={[10, 10]} className={styles.bankFormRow}>
                <Col span={12}>
                    <OpeningBalanceField />
                </Col>
                <Col span={12}>
                    <OpeningBalanceDateField />
                </Col>
            </Row>

            <Row gutter={[10, 10]} className={styles.bankFormRow}>
                <Col span={24}>
                    <ColorField />
                </Col>
            </Row>

            <Divider />
            <Space style={{ justifyContent: "flex-end", width: "100%" }}>
                {success && !isLoading && <CheckCircleOutlined style={{ fontSize: 24, color: "#52c41a" }} />}
                {isError && !isLoading && <CloseCircleOutlined style={{ fontSize: 24, color: "#ff4d4f" }} />}
                {isLoading && <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />}
                <Button id={"addBankConfirm"} type={"primary"} onClick={() => form.submit()}>
                    <FormattedMessage id="app.button.confirm" />
                </Button>
                <Button id={"addBankReset"} icon={<ReloadOutlined />} onClick={() => form.resetFields()}>
                    <FormattedMessage id="app.button.reset" />
                </Button>
                <Button onClick={() => setEditBankData({ bank: null, isModalOpen: false })}>
                    <FormattedMessage id="app.button.cancel" />
                </Button>
            </Space>
        </Form>
    );
};
