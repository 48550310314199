import { Form, Typography } from "antd";
import React, { type FC, useContext } from "react";
import { FormattedMessage } from "react-intl";
import { UStIdNrInput } from "@dms/components/DocumentForm/DocumentInputs/UStIdNrInput";
import { ICountriesOption } from "../../../../appearance/views/productContacts/components/CountriesCombobox";
import { GroupFormContext } from "@dms/modules/GroupFormModules/context/GroupFormContext";
import { FormWrapperContext } from "@dms/modules/DocumentFormModule/context/FormWrapperContext";

const { Text } = Typography;

interface IProps {
    options: ICountriesOption[];
    disabled?: boolean;
    isFocus?: boolean;
}
export const SingleUStIdNrField: FC<IProps> = ({ options }) => {
    return (
        <Form.Item
            label={
                <Text strong ellipsis>
                    {<FormattedMessage id="app.fields.vatNb" />}
                </Text>
            }
            name="UStIdNr"
            style={{ marginBottom: 0 }}
        >
            <UStIdNrInput options={options} />
        </Form.Item>
    );
};

export const GroupUStIdNrField: FC<IProps> = ({ options, disabled }) => {
    const { notEqualField, requiredFields } = useContext(GroupFormContext);

    const placeholder = notEqualField.includes("discountDays") ? "Diverse" : undefined;

    return (
        <Form.Item
            label={
                <Text strong ellipsis>
                    {<FormattedMessage id="app.fields.vatNb" />}
                </Text>
            }
            name="UStIdNr"
            rules={[{ required: requiredFields.includes("UStIdNr"), message: "" }]}
            style={{ marginBottom: 0 }}
        >
            <UStIdNrInput options={options} disabled={disabled} placeholder={placeholder} />
        </Form.Item>
    );
};

export const DocumentUStIdNrField: FC<IProps> = ({ options, disabled }) => {
    const { isGroupEditor } = useContext(FormWrapperContext);

    return isGroupEditor ? (
        <GroupUStIdNrField options={options} disabled={disabled} />
    ) : (
        <SingleUStIdNrField options={options} />
    );
};
