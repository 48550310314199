import React from "react";
import {
  AppstoreOutlined,
  AuditOutlined,
  ClockCircleOutlined,
  DeleteOutlined,
  OrderedListOutlined,
  PlusOutlined,
  UserOutlined
} from "@ant-design/icons";
import { Badge, Button, Col, Flex, Input, Popconfirm, Popover, Row, Space, Spin, Table, Tooltip } from "antd";
import { ColumnType } from "antd/es/table";
import { FormattedMessage, useIntl } from "react-intl";
import { GQL } from "@binale-tech/shared";
import { gql } from "@apollo/client";

import CompanyUpdateOwner from "../../components/company/CompanyUpdateOwner";
import { CompanyFormModal } from "../../components/company/CompanyFormModal";
import { CompanyInvoiceDataDrawer } from "../../components/company/CompanyInvoiceData";
import { CompanyModules } from "../../components/company/CompanyModules";
import { CompanyTableRecord, useCompanyDataFetch } from "../admin/useCompanyDataFetch";
import { CompanyUsersOverviewModal } from "../../components/company/CompanyUsersOverviewModal";
import { CompanyYearFormModal } from "../../components/company/CompanyYearFormModal";
import { FlexColumn, Page, PageHeader } from "../../components/shared/appearance/page";
import { ProductAccessUtils } from "scripts/models/utils/ProductAccessUtils";
import { UserCompaniesContext, UserContext } from "scripts/context/UserProvider";
import { decodeKey } from "scripts/api/firebase/firebase";
import { useGqlMutator } from "scripts/graphql/useGqlMutator";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./CompaniesView.css";
import { EditButton } from "./EditButton";
import { Switch } from "@nextui-org/switch";
import { DatevContext } from "../../../scripts/context/DatevContext";

const mutationDelete = gql`
    mutation companyDelete($input: CompanyDeleteInput!) {
        companyDelete(input: $input)
    }
`;
const query = gql`
    query getCompanyForOverview($id: ID!) {
        company(id: $id) {
            id
            name
            accountingYears
            legalForm
            legalName
            owner {
                id
                email
            }
            members {
                id
                emailKey
            }
            products {
                id
                productKey
            }
            invoiceEmail
            invoiceName
            invoiceReceiver
            datevNrCompany
            datevNrConsultant
            invoiceAddress {
                addressLine2
                city
                countryCode
                house
                postCode
                street
            }
            invoiceTax {
                stnr
                bundesland
            }
        }
    }
`;

export const CompaniesView: React.FC = () => {
    const intl = useIntl();
    const navigate = useNavigate();
    const companies = React.useContext(UserCompaniesContext);
    const user = React.useContext(UserContext);
    const { datevUserInfo } = React.useContext(DatevContext);
    const mutator = useGqlMutator();

    const [pageSize, setPageSize] = React.useState(10);
    const [page, setPage] = React.useState(1);
    const [searchParams, setSearchParams] = useSearchParams();

    const [showCompanyModal, setShowCompanyModal] = React.useState(false);
    const [companyModalId, setCompanyModalId] = React.useState<string | null>(null);
    const [companyIdModalAccountingYears, setCompanyIdModalAccountingYears] = React.useState<string | null>(null);
    const [subscriptionModalCompany, setSubscriptionModalCompany] = React.useState<GQL.ICompany | null>(null);
    const [usersModalCompanyId, setUsersModalCompanyId] = React.useState<string | null>(null);
    const [companyInvoiceDataModalId, setCompanyInvoiceDataModalId] = React.useState<string | null>(null);

    const { loading, dataSource, fetchAndSetCompaniesData } = useCompanyDataFetch(companies, query, page, pageSize);

    const handleCompanyShow = (v?: GQL.ICompany) => {
        setShowCompanyModal(true);
        setCompanyModalId(v?.id);
    };

    const handleShowUserModal = (v?: GQL.ICompany) => {
        setUsersModalCompanyId(v?.id);
    };

    const modalHide = () => {
        fetchAndSetCompaniesData(true);
        setShowCompanyModal(false);
        setCompanyModalId(null);
        setSubscriptionModalCompany(null);
        setUsersModalCompanyId(null);
        setCompanyIdModalAccountingYears(null);
        setCompanyInvoiceDataModalId(null);
    };

    const columns: ColumnType<CompanyTableRecord>[] = [
        {
            title: <FormattedMessage id="app.fields.lfdnr" />,
            dataIndex: "nr",
            key: "nr",
            width: 75,
        },
        {
            title: <FormattedMessage id="app.fields.internalDesignation" />,
            key: "name",
            render: (cell: string, record: CompanyTableRecord) => (
                <span data-uuid={record.company.id}>{record.company.name}</span>
            ),
        },
        {
            title: <FormattedMessage id="app.fields.companyName" />,
            key: "legalName",
            render: (cell: string, record: CompanyTableRecord) => (
                <span data-uuid={record.company.id}>{record.company.legalName}</span>
            ),
        },
        {
            title: <FormattedMessage id="app.fields.legalForm" />,
            key: "legalForm",
            render: (cell: string, record: CompanyTableRecord) => (
                <span data-uuid={record.company.id}>{record.company.legalForm}</span>
            ),
        },
        user.isAdmin
            ? {
                  title: <FormattedMessage id="app.companies.view.table.admin" />,
                  key: "admin",
                  render: (cell: string, record: CompanyTableRecord) => {
                      return (
                          <Row justify="space-between" align={"middle"}>
                              <Col>{record.company?.owner?.email}</Col>
                              <Col>
                                  <CompanyUpdateOwner
                                      companyId={record.company.id}
                                      currentEmail={record.company?.owner?.email}
                                      onComplete={() => fetchAndSetCompaniesData(true)}
                                  />
                              </Col>
                          </Row>
                      );
                  },
              }
            : null,
        user.isAdmin
            ? {
                  title: "Accounting Years",
                  key: "accountingYears",
                  align: "right" as ColumnType<unknown>["align"],
                  render: (cell: number, record: CompanyTableRecord) => {
                      if (!ProductAccessUtils.hasCompanyAccounting(record.company)) {
                          return null;
                      }
                      const years = record.company.accountingYears;
                      if (!years.length) {
                          return null;
                      }
                      const minMaxYears = new Set([Math.min(...years), Math.max(...years)]);
                      return <pre style={{ margin: 0 }}>{[...minMaxYears].join("-")}</pre>;
                  },
              }
            : null,
        user.isAdmin
            ? {
                  title: <FormattedMessage id="app.companies.view.table.user" />,
                  dataIndex: "users",
                  key: "users",
                  render: (cell: string[] = []) => {
                      if (!cell.length) {
                          return null;
                      }
                      const [first, ...rest] = cell;
                      return (
                          <Row justify={"space-between"} align={"middle"}>
                              <Col>{decodeKey(first)}</Col>
                              {Boolean(rest.length) && (
                                  <Popover
                                      destroyTooltipOnHide
                                      title="Users"
                                      trigger="click"
                                      placement={"left"}
                                      content={
                                          <div>
                                              {cell.map(v => (
                                                  <div key={v}>{decodeKey(v)}</div>
                                              ))}
                                          </div>
                                      }
                                  >
                                      <Button shape={"circle"}>+{rest.length}</Button>
                                  </Popover>
                              )}
                          </Row>
                      );
                  },
              }
            : null,
        {
            title: <FormattedMessage id="app.fields.actions" />,
            key: "actions",
            width: 210,
            render: (cell: unknown, record: CompanyTableRecord) => {
                const hasProducts = Boolean(record.company?.products?.length);
                const hasAccounting = ProductAccessUtils.hasCompanyAccounting(record.company);
                let membersButton = (
                    <Button
                        shape="circle"
                        icon={<UserOutlined />}
                        disabled={!user.isAdmin || !hasProducts}
                        onClick={() => handleShowUserModal(record.company)}
                    />
                );
                let deleteButton = <Button shape="circle" disabled={!record.canDrop} icon={<DeleteOutlined />} />;
                if (!hasProducts) {
                    membersButton = <Tooltip title="First enable products for this company">{membersButton}</Tooltip>;
                }
                if (!user.isAdmin) {
                    membersButton = <Tooltip title="Only admin can edit members">{membersButton}</Tooltip>;
                    deleteButton = <Tooltip title="Only admin can remove company">{deleteButton}</Tooltip>;
                }

                let yearsButton = (
                    <Button
                        shape="circle"
                        icon={<OrderedListOutlined />}
                        disabled={!hasAccounting}
                        onClick={() => setCompanyIdModalAccountingYears(record.company.id)}
                    />
                );
                if (!hasProducts) {
                    yearsButton = (
                        <Tooltip title="First enable accounting products for this company">{yearsButton}</Tooltip>
                    );
                }
                return (
                    <Space>
                        <EditButton
                            disabled={!record.canEdit}
                            onClick={() => handleCompanyShow(record.company)}
                            company={record.company}
                        />
                        <Button
                            shape="circle"
                            icon={<AppstoreOutlined />}
                            onClick={() => setSubscriptionModalCompany(record.company)}
                        />
                        <Badge
                            count={
                                ProductAccessUtils.hasCompanyInvoiceData(record.company) ? (
                                    0
                                ) : (
                                    <ClockCircleOutlined style={{ color: "#f5222d" }} />
                                )
                            }
                        >
                            <Button
                                shape="circle"
                                icon={<AuditOutlined />}
                                onClick={() => setCompanyInvoiceDataModalId(record.company.id)}
                            />
                        </Badge>
                        {membersButton}
                        {yearsButton}
                        <Popconfirm
                            onConfirm={() => handleDelete(record.company.id)}
                            placement="left"
                            title={<FormattedMessage id="app.confirmation.header" />}
                            okText={<FormattedMessage id="app.button.confirm" />}
                            cancelText={<FormattedMessage id="app.button.cancel" />}
                        >
                            {deleteButton}
                        </Popconfirm>
                    </Space>
                );
            },
        },
    ].filter(Boolean);

    const handleDelete = (id: string) =>
        mutator
            .mutate<"companyDelete", GQL.ICompanyDeleteInput>(
                {
                    mutation: mutationDelete,
                    input: { id },
                },
                {
                    success: intl.formatMessage({ id: "app.message.companyDelete" }),
                }
            )
            .then(() => navigate("/"));

    return (
        <Spin spinning={user.isLoading}>
            <Page className="CompaniesView">
                <>
                    {showCompanyModal && <CompanyFormModal onHide={modalHide} companyId={companyModalId} />}
                    <CompanyUsersOverviewModal onHide={modalHide} companyId={usersModalCompanyId} />
                    <CompanyYearFormModal companyId={companyIdModalAccountingYears} onHide={modalHide} />
                    <CompanyModules company={subscriptionModalCompany} onHide={modalHide} />
                    <CompanyInvoiceDataDrawer companyId={companyInvoiceDataModalId} onHide={modalHide} />
                </>
                <PageHeader>
                    <FormattedMessage id="app.titles.company.pl" />
                </PageHeader>
                <FlexColumn>
                    <Flex justify="space-between" style={{ margin: "8px 0" }}>
                        <Flex gap={32}>
                            <Input.Search
                                onSearch={search =>
                                    setSearchParams(prev => {
                                        if (search) {
                                            prev.set("search", search);
                                        } else {
                                            prev.delete("search");
                                        }
                                        return prev;
                                    })
                                }
                                allowClear
                                disabled={loading || companies.length === 0}
                            />
                            {datevUserInfo && (
                                <Space>
                                    <Switch
                                        isSelected={Boolean(searchParams.get("datev"))}
                                        onValueChange={v =>
                                            setSearchParams(prev => {
                                                if (v) {
                                                    prev.set("datev", "1");
                                                } else {
                                                    prev.delete("datev");
                                                }
                                                return prev;
                                            })
                                        }
                                    />
                                    DATEV
                                </Space>
                            )}
                        </Flex>
                        <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={() => handleCompanyShow(null)}
                            disabled={loading}
                        >
                            <FormattedMessage id="app.titles.company" tagName="span" />
                        </Button>
                    </Flex>
                    <Table
                        loading={loading}
                        dataSource={dataSource}
                        columns={columns}
                        size="middle"
                        bordered
                        pagination={{
                            current: page,
                            pageSize,
                            onShowSizeChange: (_, size) => setPageSize(size),
                            onChange: (page, pageSize) => {
                                setPage(page);
                                setPageSize(pageSize);
                            },
                            pageSizeOptions: [10, 25],
                        }}
                    />
                </FlexColumn>
            </Page>
        </Spin>
    );
};
