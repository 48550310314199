import React, { createContext, FC, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { IBank, ITransactionCreateInput, TParseTransaction } from "@banks/types";
import dayjs, { extend as dayjsExtend } from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { BanksApi } from "@banks/scripts/api";
import { parseNumber } from "@dms/scripts/helpers";
import { BanksContext } from "../../../../scripts/context/BanksContext";
import { getParsedDateRange } from "@banks/scripts/helpers";

type TValue = {
    filteredRows: TParseTransaction[];
    selectDateRange: { firstData: dayjs.Dayjs; lastData: dayjs.Dayjs };
    selectedRows: TParseTransaction[];
    transactionBank: IBank;
};
type TActionValue = {
    setSelectDateRange: (arg: { firstData: dayjs.Dayjs; lastData: dayjs.Dayjs }) => void;
    saveParseData: () => Promise<true | undefined>;
    selectRows: (arg: TParseTransaction[]) => void;
    saveSelectedTransactions: () => Promise<true | undefined>;
};

const initialActionValue = {
    setSelectDateRange: () => {},
    selectRows: () => {},
    saveSelectedTransactions: () => Promise.reject(),
    saveParseData: () => Promise.reject(),
};

export const ParseDataContext = createContext<TValue>({} as TValue);
export const ParseDataControlContext = createContext<TActionValue>(initialActionValue);

type TProps = {
    children?: ReactNode;
    transactionBankId: string;
    reducedParseResult: Record<string, string>[];
};

export const ParseDataContextProvider: FC<TProps> = ({ children, transactionBankId, reducedParseResult }) => {
    const [selectDateRange, setSelectDateRange] = useState<{ firstData: dayjs.Dayjs; lastData: dayjs.Dayjs }>();
    const [filteredRows, setFilteredRows] = useState<TParseTransaction[]>(reducedParseResult as TParseTransaction[]);
    const [selectedRows, setSelectedRows] = useState<TParseTransaction[]>([]);

    const { companyId, banksData } = useContext(BanksContext);
    const transactionBank = banksData[transactionBankId];

    useEffect(() => {
        const dateRange = getParsedDateRange(reducedParseResult as TParseTransaction[]);
        setSelectDateRange(dateRange);
    }, [reducedParseResult]);

    useEffect(() => {
        if (!reducedParseResult.length) {
            return setFilteredRows([]);
        }

        if (!selectDateRange) {
            return setFilteredRows(reducedParseResult as TParseTransaction[]);
        }

        dayjsExtend(isBetween);

        const rowArr = reducedParseResult.filter(el => {
            const date = dayjs(el.bookingDate, "DD.MM.YYYY");
            return dayjs(date).isBetween(selectDateRange.firstData, selectDateRange.lastData, "month", "[]");
        });

        setFilteredRows(rowArr as TParseTransaction[]);
    }, [reducedParseResult, selectDateRange]);

    const saveTransactions = useCallback(
        async (transactions: TParseTransaction[]) => {
            const filteredArr = transactions.filter(el => {
                return !Object.values(transactionBank?.transactions || {}).find(t => t.csvLine === el.csvLine);
            });
            const rowsData = filteredArr.map(el => {
                const result = { ...el };
                delete result.myBankAccount;
                delete result.myBankIban;

                const data: ITransactionCreateInput = {
                    ...result,
                    amount: result?.amount ? Math.round(parseNumber(result?.amount) * 100) : null,
                    bankId: transactionBank?.id,
                    transactionType: result?.transactionType ?? "",
                    companyId,
                };

                return data;
            });

            const res = await BanksApi.transactionsCreate(rowsData as never as ITransactionCreateInput);

            setSelectedRows([]);

            return res as unknown as true;
        },
        [companyId, transactionBank?.id, transactionBank?.transactions]
    );

    const value = useMemo(() => {
        return {
            filteredRows,
            selectDateRange,
            selectedRows,
            transactionBank,
        };
    }, [filteredRows, selectDateRange, selectedRows, transactionBank]);

    const actions = useMemo(() => {
        return {
            setSelectDateRange: (arg: { firstData: dayjs.Dayjs; lastData: dayjs.Dayjs }) => {
                setSelectDateRange(arg);
            },
            selectRows: (arg: TParseTransaction[]) => {
                setSelectedRows(arg);
            },
            saveSelectedTransactions: () => {
                if (!selectedRows.length) {
                    return;
                }
                return saveTransactions(selectedRows);
            },
            saveParseData: async () => {
                return saveTransactions(filteredRows);
            },
        };
    }, [selectedRows, saveTransactions, filteredRows]);

    return (
        <ParseDataContext.Provider value={value}>
            <ParseDataControlContext.Provider value={actions}>{children}</ParseDataControlContext.Provider>
        </ParseDataContext.Provider>
    );
};
