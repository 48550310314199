import { DmsDefaultSubType, DmsType } from "@dms/types";
import { Divider, RefSelectProps, Typography } from "antd";
import React, { forwardRef, useContext } from "react";
import { FormattedMessage } from "react-intl";

import { getSubTypeOptions } from "@dms/scripts/helpers";

import { FieldLabel } from "@app/components/shared/form/baseComponents/FieldLabel";
import { TypeInput } from "../DocumentInputs/TypeInput";
import { TOptions } from "../types";
import style from "./DocumentFormField.module.scss";
import { DmsTypeOptions } from "@dms/configs/constants";
import { DmsDataContext } from "@dms/types/ContextTypes";

const { Text } = Typography;

export const DocumentTypeSelectField = forwardRef<RefSelectProps>((props, ref) => {
    const { documentTypes } = useContext(DmsDataContext);

    const options: TOptions = DmsTypeOptions.map(({ value, isGroup, translationKey, isDivider, defaultSubtypes }) => {
        if (isDivider) {
            return {
                label: <Divider className={style.selectorDivider} />,
                disabled: true,
                className: `${style.selectorOption} ${style.selectorOptionDisabled}`,
            };
        }
        if (value && isGroup) {
            if (defaultSubtypes) {
                const defaultSubtypesArr = defaultSubtypes
                    .filter(el => el.value === DmsDefaultSubType.no_subTypes)
                    .map(defaultSubtype => {
                        return {
                            value,
                            label: defaultSubtype.translationKey ? (
                                <>
                                    <FormattedMessage id={defaultSubtype.translationKey} />
                                    {" / "}
                                    <FormattedMessage id={translationKey} />
                                </>
                            ) : (
                                `${value} / ${defaultSubtype.value}`
                            ),
                        };
                    });

                return {
                    label: <FormattedMessage id={translationKey} />,
                    options: [...defaultSubtypesArr, ...getSubTypeOptions(documentTypes, value)],
                };
            }

            return {
                label: <FormattedMessage id={translationKey} />,
                options: getSubTypeOptions(documentTypes, value),
            };
        }
        return {
            label: <FormattedMessage id={translationKey} />,
            value,
            disabled: value === DmsType.new_documents,
        };
    });

    return (
        <FieldLabel
            className={"DMSForm__Item"}
            label={
                <Text strong ellipsis>
                    <FormattedMessage id="app.dms.typeOfDocuments" />
                </Text>
            }
            key={"type"}
            name={"type"}
        >
            <TypeInput ref={ref} options={options} />
        </FieldLabel>
    );
});
