import { ColDef } from "ag-grid-community";
import { Utils } from "@binale-tech/shared";
import { parseNumber } from "@dms/scripts/helpers";
import { TransactionTableColumns } from "@banks/components/TransactionsTable/transactionTableColumns";

export class ParseTransactionTableColumns extends TransactionTableColumns {
    static get amount(): Readonly<ColDef> {
        return {
            headerName: "Betrag",
            headerTooltip: "Betrag",
            field: "amount",
            width: 100,
            sortable: true,
            filter: false,
            valueGetter: params => {
                return params?.data?.amount
                    ? Utils.CurrencyUtils.currencyFormat(Math.round(parseNumber(params?.data?.amount) * 100))
                    : null;
            },

            cellStyle: params => {
                const cellStyles = { textAlign: "right", fontWeight: "400" };
                const amount = params?.data?.amount ? Math.round(parseNumber(params?.data?.amount) * 100) : null;
                if (amount !== null && amount < 0) {
                    return {
                        ...cellStyles,
                        color: "#f5222d",
                    };
                }
                return {
                    ...cellStyles,
                    color: "#52c41a",
                };
            },
        };
    }

    static get tableColumnConfig(): Readonly<ColDef>[] {
        return [
            this.selectRowColumn,
            this.transactionRowNumberColumn,
            this.bookingDate,
            this.transactionType,
            this.amount,
            this.currencyCode,
            this.bookingText,
            this.purpose,
            this.counterpartyName,
            this.counterpartyIbanOrAccountNum,
            this.counterpartyBicOrBlz,
        ];
    }
}
