import { GridOptions } from "ag-grid-community";
import { RequiredTableCols } from "@app/components/shared/AgGridTable/constants/tableCols";

export const baseOptions: GridOptions<any> = {
    defaultColDef: {
        filter: "agTextColumnFilter",
        resizable: true,
        sortable: true,
        minWidth: 50,
        enableCellChangeFlash: true,
    },
    pagination: true,
    paginationPageSize: 50,
    paginationPageSizeSelector: [50, 100, 1000],
    headerHeight: 50,
    autoSizePadding: 20,
    rowHeight: 40,
    rowGroupPanelShow: "onlyWhenGrouping",
    sortingOrder: ["asc", "desc", null],
    rowSelection: "multiple",
    suppressRowClickSelection: true,
    suppressAggFuncInHeader: true,
    alwaysShowHorizontalScroll: true,
    alwaysShowVerticalScroll: true,
    rowMultiSelectWithClick: true,
    debounceVerticalScrollbar: true,
    rowClassRules: {},
    scrollbarWidth: 8,
    onSortChanged: event => {
        event.api.refreshCells({
            force: true,
            suppressFlash: true,
            columns: [RequiredTableCols.ROW_NUMBER],
        });
    },
    onFilterChanged: event =>
        event.api.refreshCells({
            force: true,
            suppressFlash: true,
            columns: [RequiredTableCols.ROW_NUMBER],
        }),
};
