import React, { FC, useContext } from "react";
import { BankModal } from "banks/components/BankModal";
import { BanksAppContext } from "@banks/scripts/context";
import { EditBankContextProvider } from "@banks/modules/EditBankModule/context";
import { EditBankForm } from "@banks/components/EditBankForm";

type TProps = {};

export const EditBankModule: FC<TProps> = () => {
    const { editBankData } = useContext(BanksAppContext);

    return (
        <EditBankContextProvider>
            <BankModal
                title={"Edit Bank"}
                open={editBankData.isModalOpen}
                footer={false}
                closable={false}
                style={{ maxWidth: "95vw" }}
                width={"800px"}
                destroyOnClose
            >
                <EditBankForm />
            </BankModal>
        </EditBankContextProvider>
    );
};
