import React, { useMemo } from "react";
import { DmsUtils } from "@dms/scripts/utils/DmsUtils";
import { GridOptions, ICellRendererParams, RowClassRules } from "ag-grid-community";
import { ITableDocument } from "@dms/types";
import { GQL } from "@binale-tech/shared";
import { handleCopyField } from "@app/components/shared/functions";
import { useIntl } from "react-intl";
import {
    AmountType,
    DragComponent,
    HeaderActions,
    HeaderAttached,
    HeaderWithTooltip,
} from "@dms/modules/DocumentTableModule/components";
import { AttachedFilter, ColorFilter } from "@dms/modules/DocumentTableModule/filters";
import { TableActionList } from "@dms/components/ActionList";

import styles from "@dms/modules/DocumentTableModule/DocumentTable.module.scss";

interface ICellProps extends ICellRendererParams<ITableDocument> {
    style?: React.CSSProperties;
}
export const useDocumentTableOptions = () => {
    const intl = useIntl();

    const components = useMemo(
        () => ({
            headerAttached: HeaderAttached,
            headerActions: HeaderActions,
            filterAttached: AttachedFilter,
            filterColor: ColorFilter,
            amountTypeTag: AmountType,
            headerTooltip: HeaderWithTooltip,
            actionList: TableActionList,
            dragComponent: DragComponent,
        }),
        []
    );

    const rowClassRules = useMemo<RowClassRules<ITableDocument>>(() => {
        return {
            [styles.agDraft]: params => {
                if (!params.data) {
                    return false;
                }
                return DmsUtils.getDocumentStatus(params.data) === GQL.IDocumentStatus.Draft;
            },
            [styles.agHighlightGreen]: params => {
                if (!params.data) {
                    return false;
                }

                return params.data.color === GQL.IDocumentColors.Green;
            },
            [styles.agHighlightRed]: params => {
                if (!params.data) {
                    return false;
                }

                return params.data.color === GQL.IDocumentColors.Red;
            },
            [styles.agHighlightBlue]: params => {
                if (!params.data) {
                    return false;
                }

                return params.data.color === GQL.IDocumentColors.Blue;
            },
            [styles.agHighlightPurple]: params => {
                if (!params.data) {
                    return false;
                }

                return params.data.color === GQL.IDocumentColors.Purple;
            },
            [styles.agHighlightYellow]: params => {
                if (!params.data) {
                    return false;
                }

                return params.data.color === GQL.IDocumentColors.Yellow;
            },
            [styles.agAiResult]: params => {
                if (!params.data) {
                    return false;
                }

                return Boolean(params.data.aiBackgroundProcessingResult);
            },
            [styles.agHighlightFocus]: params => {
                if (!params.data) {
                    return false;
                }

                return Boolean(params.data.isFocused);
            },
        };
    }, []);

    return useMemo(
        () => ({
            defaultColDef: {
                filter: "agTextColumnFilter",
                resizable: true,
                sortable: true,
                minWidth: 50,
                enableCellChangeFlash: true,
                suppressHeaderFilterButton: true,
                cellClass: styles.agBinaleCell,
                cellRenderer: ({ value, style }: ICellProps) => {
                    return (
                        <div
                            onDoubleClick={e =>
                                handleCopyField(e, intl.formatMessage({ id: "app.notification.copiedToClipboard" }))
                            }
                            style={style ?? null}
                        >
                            {value ?? ""}
                        </div>
                    );
                },
            },
            pagination: true,
            paginationPageSize: 50,
            paginationPageSizeSelector: [50, 100, 1000],
            headerHeight: 50,
            autoSizePadding: 20,
            rowHeight: 40,
            rowGroupPanelShow: "onlyWhenGrouping",
            sortingOrder: ["asc", "desc", null],
            rowSelection: "multiple",
            suppressRowClickSelection: true,
            suppressAggFuncInHeader: true,
            suppressHeaderFocus: true,
            rowClassRules,
            components,
            alwaysShowHorizontalScroll: true,
            alwaysShowVerticalScroll: true,
            rowMultiSelectWithClick: true,
            debounceVerticalScrollbar: true,
            suppressMultiSort: true,
            rowBuffer: 50,
        }),
        [components, intl, rowClassRules]
    ) as GridOptions<ITableDocument>;
};
