import { useCallback, useContext } from "react";
import { focusRef } from "../../../../../scripts/infrastructure/helpers/focus";
import { GQL } from "@binale-tech/shared";
import { useApplyDocumentProperties } from "../useApplyDocumentProperties";
import { useFormRecordStateUpdater } from "../useFormRecordStateUpdater";
import { RecordFormPropsContext } from "../../context/RecordFormPropsContext";

export const useFormHandlerDocument = () => {
    const applyDocumentProperties = useApplyDocumentProperties();
    const { updateEditableRecord } = useFormRecordStateUpdater();
    const { refsHtml } = useContext(RecordFormPropsContext);

    return useCallback(
        (files: GQL.IRecordDocumentInput[]) => {
            updateEditableRecord({
                recordDocuments: files,
            });
            applyDocumentProperties(files.map(({ id }) => id));
            if (document.activeElement === document.body) {
                focusRef(refsHtml.REF_cASSET);
            }
        },
        [applyDocumentProperties, refsHtml, updateEditableRecord]
    );
};
