import React, { FC } from "react";
import { useLocation } from "react-router";
import { ParseDataTable } from "@banks/modules/ParseDataModule/components/ParseDataTable";
import { ParseDataContextProvider } from "./context/ParseDataContext";

export const ParseDataModule: FC = () => {
    const location = useLocation();
    const { transactionBank, reducedParseResult } = location.state;

    return (
        <ParseDataContextProvider transactionBankId={transactionBank.id} reducedParseResult={reducedParseResult}>
            <ParseDataTable />
        </ParseDataContextProvider>
    );
};
