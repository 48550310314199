import React from "react";
import { GQL } from "@binale-tech/shared";
import { gql } from "@apollo/client";

import {
    Alert,
    Button,
    DatePicker,
    Flex,
    Form,
    Modal,
    Popconfirm,
    Select,
    Space,
    Spin,
    Table,
    TableColumnType,
} from "antd";
import { BuContext } from "../../../scripts/context/BuContext";
import { CompanyControlContext } from "../../../scripts/context/CompanyContext";
import { BuTaxesSKR } from "../../../scripts/models/BuTaxUtils";
import { FormattedMessage, useIntl } from "react-intl";
import { LoadingOutlined } from "@ant-design/icons";
import { ProductAccessUtils } from "scripts/models/utils/ProductAccessUtils";
import { useGQLRetriever } from "scripts/graphql/gqlRetriever";
import { useGqlMutator } from "scripts/graphql/useGqlMutator";
import { validation } from "scripts/infrastructure/helpers/validation";
import "./CompanyFormModal.css";
import dayjs from "dayjs";

const fragment = gql`
    fragment CompanyForAccountingYearCreation on Company {
        id
        name
        accountingYears
        legalName
        legalForm
        products {
            id
            productKey
        }
        accountingConfigs {
            id
            year
            kontoExt
            taxation
            skr
            fiscalYearStart
        }
    }
`;
const query = gql`
    ${fragment}
    query getCompanyForEdit($id: ID!) {
        company(id: $id) {
            ...CompanyForAccountingYearCreation
        }
    }
`;

const mutationCreate = gql`
    ${fragment}
    mutation companyCreateAccountingYear($input: CompanyCreateAccountingYearInput!) {
        companyCreateAccountingYear(input: $input) {
            ...CompanyForAccountingYearCreation
        }
    }
`;
const mutationCopyAccounts = gql`
    ${fragment}
    mutation companyCopyNextYearAccounts($input: CompanyCopyNextYearAccountsInput!) {
        companyCopyNextYearAccounts(input: $input) {
            ...CompanyForAccountingYearCreation
        }
    }
`;

type Props = {
    onHide?: () => void;
    companyId: string;
};
const dateFormat = "YYYY-MM-DD";
export const CompanyYearFormModal: React.FC<Props> = ({ companyId, onHide }) => {
    const intl = useIntl();
    const [form] = Form.useForm<GQL.ICompanyCreateAccountingYearInput>();
    const Mutator = useGqlMutator();
    const retriever = useGQLRetriever<"company">();
    const { triggerDataRefetch } = React.useContext(CompanyControlContext);

    const { allBuTimeframes } = React.useContext(BuContext);
    const [loading, setLoading] = React.useState(false);
    const [company, setCompany] = React.useState<GQL.ICompany | null>();
    const year = Form.useWatch("year", form);
    const hasSKR3 = BuTaxesSKR.hasBuTimeframeCategoriesByYear(3, year, allBuTimeframes);
    const hasSKR4 = BuTaxesSKR.hasBuTimeframeCategoriesByYear(4, year, allBuTimeframes);
    const hasSKR = hasSKR3 && hasSKR4;

    const hasAccounting = Boolean(company?.id) && ProductAccessUtils.hasCompanyAccounting(company);
    const accountingYears = company?.accountingYears || [];
    const [existingYearMin, existingYearMax] = [Math.min(...accountingYears), Math.max(...accountingYears)];
    const accountingYearsNew = accountingYears.length
        ? Array.from(new Set([existingYearMin - 1, existingYearMax + 1]))
        : [...Array(new Date().getFullYear() + 1 - 2015 + 1).keys()].map(v => v + 2015);

    const getCompanyData = React.useCallback(() => {
        setLoading(true);
        retriever
            .query({ id: companyId, query })
            .then(data => setCompany(data?.company))
            .finally(() => setLoading(false));
    }, [companyId, retriever]);

    React.useEffect(() => {
        if (companyId) {
            getCompanyData();
        } else {
            setCompany(null);
        }
    }, [getCompanyData, companyId]);

    React.useEffect(() => {
        form.resetFields();
    }, [form, company]);
    React.useEffect(() => {
        if (year) {
            form.setFieldValue("fiscalYearStart", dayjs(year + "-01-01", dateFormat));
        }
    }, [form, year]);

    const handleSubmit = (values: GQL.ICompanyCreateAccountingYearInput) => {
        setLoading(true);
        Mutator.mutate<"companyCreateAccountingYear", GQL.ICompanyCreateAccountingYearInput>({
            mutation: mutationCreate,
            input: { ...values, companyId, fiscalYearStart: dayjs(values.fiscalYearStart).format(dateFormat) },
            throwOnError: true,
        })
            .then(() => {
                triggerDataRefetch();
                form.resetFields();
                onHide();
            })
            .finally(() => setLoading(false));
    };
    const handleCopyAccounts = (year: number) => {
        setLoading(true);
        Mutator.mutate<"companyCopyNextYearAccounts", GQL.ICompanyCopyNextYearAccountsInput>({
            mutation: mutationCopyAccounts,
            input: { year, companyId },
            throwOnError: true,
        })
            .then(() => triggerDataRefetch())
            .finally(() => setLoading(false));
    };

    const copyDataFromYearInfo =
        accountingYears.length === 0 || !year ? undefined : year > existingYearMax ? existingYearMax : existingYearMin;

    const getLenMinMax = (companyGql: GQL.ICompany, selectedYear: number): [number, number] => {
        if (!companyGql || !selectedYear) {
            return [0, 2];
        }
        if (!company?.accountingConfigs?.length) {
            return [0, 2];
        }
        const yMin = Math.min(...companyGql.accountingYears);
        const kontoExtMinYear = company.accountingConfigs.find(v => v.year === yMin).kontoExt;
        if (formYear < yMin) {
            return [kontoExtMinYear, kontoExtMinYear];
        }
        const yMax = Math.max(...companyGql.accountingYears);
        const kontoExtMaxYear = company.accountingConfigs.find(v => v.year === yMax).kontoExt;
        return [kontoExtMaxYear, 2];
    };
    const formYear = Form.useWatch("year", form);
    const [kontoExtMin, kontoExtMax] = getLenMinMax(company, formYear);
    const kontoExtAllowedValues = [...Array(kontoExtMax - kontoExtMin + 1).keys()].map(v => v + kontoExtMin);

    const columns: TableColumnType<GQL.ICompanyAccountingYear>[] = [
        {
            title: "Year",
            dataIndex: "year",
            key: "year",
        },
        {
            title: "Kontenlänge",
            dataIndex: "kontoExt",
            key: "kontoExt",
            render: (cell, record) => record.kontoExt + 4,
        },
        {
            title: "SKR",
            dataIndex: "skr",
            key: "skr",
        },
        {
            title: "SOLL/IST",
            dataIndex: "taxation",
            key: "taxation",
        },
        {
            title: "WY start",
            dataIndex: "fiscalYearStart",
            key: "fiscalYearStart",
            render: (cell: string) => dayjs(cell).format("DD.MM.YYYY"),
        },
        {
            title: "",
            key: "actions",
            render: (cell, record) => {
                if (record.year === existingYearMax) {
                    return null;
                }
                return (
                    <Popconfirm
                        onConfirm={() => handleCopyAccounts(record.year)}
                        placement="top"
                        title={<FormattedMessage id="app.confirmation.header" />}
                        okText={<FormattedMessage id="app.button.confirm" />}
                        cancelText={<FormattedMessage id="app.button.cancel" />}
                    >
                        <Button shape="round">
                            <FormattedMessage id={"app.titles.copy_data_from"} />
                            &nbsp;{record.year + 1}
                        </Button>
                    </Popconfirm>
                );
            },
        },
    ];
    const dataSource = (company?.accountingConfigs || [])
        .map(v => ({ ...v, key: v.id }))
        .sort((a, b) => a.year - b.year);
    const skrWhitelist = accountingYears.length ? (company?.accountingConfigs || []).map(v => v.skr).pop() : null;
    const isYearBlockedBySKR = year && !hasSKR;
    return (
        <Modal
            maskClosable
            destroyOnClose
            width={800}
            style={{ top: 24 }}
            title={
                <span>
                    <FormattedMessage id="app.titles.company" /> {company ? company.name : <LoadingOutlined />}
                </span>
            }
            open={Boolean(companyId)}
            onOk={form.submit}
            okButtonProps={{ disabled: isYearBlockedBySKR }}
            okText={
                <span>
                    🎄&nbsp;
                    <FormattedMessage id={"app.button.create"} />
                </span>
            }
            onCancel={onHide}
            cancelText={intl.formatMessage({ id: "app.button.cancel" })}
        >
            <Spin spinning={loading}>
                <section>
                    <div>
                        <Table dataSource={dataSource} columns={columns} size={"small"} />
                    </div>
                    {!loading && !hasAccounting && (
                        <Alert
                            message="This company doesn't have accounting products enable so year could not be set. Please request administrator to enable accounting products"
                            type="warning"
                        />
                    )}
                    {hasAccounting && (
                        <Form onFinish={handleSubmit} layout="vertical" form={form}>
                            <Flex justify="space-between" className={"CompanyYearFormModal--form"}>
                                <Form.Item label="Year" name="year" rules={[validation.required(intl)]}>
                                    <Select
                                        allowClear
                                        options={accountingYearsNew.map(y => ({ label: y, value: y }))}
                                    />
                                </Form.Item>
                                <Form.Item label="WY start" name="fiscalYearStart" rules={[validation.required(intl)]}>
                                    <DatePicker
                                        format={"DD.MM.YYYY"}
                                        minDate={dayjs(year + "-01-01", dateFormat)}
                                        maxDate={dayjs(year + "-12-31", dateFormat)}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={<FormattedMessage id="app.fields.konto_len" />}
                                    name="kontoExt"
                                    rules={[validation.required(intl)]}
                                >
                                    <Select
                                        disabled={!formYear}
                                        options={[
                                            {
                                                label: "Standard",
                                                options: [
                                                    {
                                                        label: 4,
                                                        value: 0,
                                                        disabled: !kontoExtAllowedValues.includes(0),
                                                    },
                                                ],
                                            },
                                            {
                                                label: "Erweitert",
                                                options: [
                                                    {
                                                        label: 5,
                                                        value: 1,
                                                        disabled: !kontoExtAllowedValues.includes(1),
                                                    },
                                                    {
                                                        label: 6,
                                                        value: 2,
                                                        disabled: !kontoExtAllowedValues.includes(2),
                                                    },
                                                ],
                                            },
                                        ]}
                                    />
                                </Form.Item>
                                <Form.Item label="SKR" name="skr" rules={[validation.required(intl)]}>
                                    <Select
                                        options={[
                                            { label: 3, value: 3, disabled: skrWhitelist && skrWhitelist !== 3 },
                                            { label: 4, value: 4, disabled: skrWhitelist && skrWhitelist !== 4 },
                                        ]}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={<FormattedMessage id="app.fields.taxation" />}
                                    name="taxation"
                                    rules={[validation.required(intl)]}
                                >
                                    <Select<GQL.ICompanyTaxation>
                                        options={[
                                            { label: "SOLL", value: GQL.ICompanyTaxation.Soll },
                                            { label: "IST", value: GQL.ICompanyTaxation.Ist },
                                            { label: "Keine USt", value: GQL.ICompanyTaxation.Kust },
                                        ]}
                                    />
                                </Form.Item>
                            </Flex>
                            <Space>
                                {isYearBlockedBySKR && (
                                    <Alert
                                        message={`Unable to create this year. There is no SKR configured for the year ${year}, please approach administrator`}
                                        type="warning"
                                    />
                                )}
                                {copyDataFromYearInfo && (
                                    <Alert message={`Data will be copied from year ${copyDataFromYearInfo}`} />
                                )}
                            </Space>
                        </Form>
                    )}
                </section>
            </Spin>
        </Modal>
    );
};
