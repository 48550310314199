import React, { useEffect, useState } from "react";
import { ICellRendererParams } from "ag-grid-community";
import { Button, Flex } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { retrieveCurrencyRate } from "../../../../../scripts/infrastructure/queries/retrieveCurrencyRate";
import { TInvoicesListData } from "@inv/types";
import { ICurrencyCode } from "@binale-tech/shared/lib/graphql";
import { useApolloClient } from "@apollo/client";
import { decimalFormatter } from "@dms/scripts/helpers";

export const RateComponent = ({ value, data, node, setValue }: ICellRendererParams<TInvoicesListData>) => {
    const client = useApolloClient();
    const [rateValue, setRateValue] = useState<number>(null);
    const [isRateLoading, setIsRateLoading] = useState(false);

    useEffect(() => {
        setRateValue(value);
    }, [value]);

    if (!rateValue) {
        return null;
    }

    const documentDate = data.invoiceDate;

    const handleCurrencyClick = () => {
        setIsRateLoading(true);
        retrieveCurrencyRate({ client, documentDate, currencyCode: data.currency as ICurrencyCode })
            .then(rate => {
                setValue(rate);
                node.setData({ ...data, rate });
            })
            .finally(() => setIsRateLoading(false));
    };

    return (
        <Flex justify="space-between" align="center">
            <Button
                tabIndex={-1}
                size="small"
                type="text"
                icon={<DownloadOutlined />}
                style={{ borderRadius: "50%" }}
                onClick={handleCurrencyClick}
                disabled={!documentDate}
                loading={isRateLoading}
            />
            {decimalFormatter(rateValue, 4)}
        </Flex>
    );
};
