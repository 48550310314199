import {
    autoMappingFields,
    excludedFields,
    transactionFieldsDict,
} from "@banks/configs/constants/transactionFieldsDict";

export const getAutomappingKey = (key: string): Partial<keyof typeof transactionFieldsDict> | undefined => {
    return autoMappingFields[key.toLowerCase().trim() as keyof typeof autoMappingFields];
};

export const checkForExcluded = (key: string) => {
    return excludedFields[key.toLowerCase().trim()];
};

export const getHeaderKey = (key: string) => {
    switch (key.toLowerCase().trim()) {
        case "auftragskonto":
            return "myBankAccount";

        case "iban kontoinhaber":
            return "myBankIban";

        case "buchungstag":
            return "bookingDate";

        case "umsatzart":
            return "transactionType";

        case "buchungstext":
            return "bookingText";

        case "verwendungszweck":
            return "purpose";

        case "glaeubiger id":
            return "creditorId";

        case "mandatsreferenz":
            return "mandateReference";

        case "kundenreferenz (end-to-end)":
            return "customerReference";

        case "sammlerreferenz":
            return "collectorReference";

        case "lastschrift ursprungsbetrag":
            return "debitOriginalAmount";

        case "auslagenersatz ruecklastschrift":
            return "expenseReimbursementReturnDebit";

        case "beguenstigter/zahlungspflichtiger":
            return "counterpartyName";

        case "kontonummer":
            return "counterpartyIbanOrAccountNum";

        case "kontonummer/iban":
            return "counterpartyIbanOrAccountNum";

        case "bic (swift-code)":
            return "counterpartyBicOrBlz";

        case "betrag":
            return "amount";

        case "waehrung":
            return "currencyCode";

        case "währung":
            return "currencyCode";
        default:
            return null;
    }
};
