import React from "react";
import { ColDef, ICellRendererParams, ValueGetterParams } from "ag-grid-community";
import { FormattedMessage } from "react-intl";
import { AmountTypeKeys, AmountTypeTranslateKeys, DmsTableCols, TranslateDmsTableColumns } from "./consts";
import { DmsType, ITableDocument, tableModeEnum, TDmsTableCols } from "@dms/types";
import { GQL } from "@binale-tech/shared";
import { currencyFormatter, getMovementType } from "@dms/scripts/helpers";
import { documentSorter } from "@dms/modules/DocumentTableModule/helpers";
import { movementTypeTranslationKey } from "@dms/configs/translationConst";
import { DocumentSubTypeFormatted } from "@dms/modules/DocumentTableModule/components";
import { DmsTaxesOptions, DmsTypeOptions } from "@dms/configs/constants";
import dayjs from "dayjs";

import styles from "./DocumentTable.module.scss";
import { AbstractTableColumns } from "@app/components/shared/AgGridTable/columns/AbstractTableColumns";

export class DmsTableColumns extends AbstractTableColumns {
    private static get modalSelectedColumn(): Readonly<ColDef> {
        return {
            field: DmsTableCols.DRAG,
            pinned: "left",
            headerName: "",
            width: 50,
            filter: false,
            sortable: false,
            resizable: false,
            suppressMovable: true,
            suppressNavigable: true,
            lockPosition: true,
            headerCheckboxSelection: true,
            headerCheckboxSelectionCurrentPageOnly: true,
            checkboxSelection: true,
        };
    }

    private static get dragColumn(): Readonly<ColDef> {
        return {
            field: DmsTableCols.DRAG,
            pinned: "left",
            headerName: "",
            width: 74,
            filter: false,
            sortable: false,
            resizable: false,
            suppressMovable: true,
            suppressNavigable: true,
            lockPosition: true,
            headerCheckboxSelection: true,
            headerCheckboxSelectionCurrentPageOnly: true,
            checkboxSelection: true,
            cellClass: [styles.cellCentred, styles.agBinaleCell],
            cellRendererParams: (params: ICellRendererParams<ITableDocument>) => ({
                id: params.data?.key ?? null,
                typeDrag: "list",
            }),
            cellRenderer: "dragComponent",
        };
    }

    private static get dmsRowNumberColumn(): Readonly<ColDef> {
        return {
            ...this.rowNumberColumn,
        };
    }

    private static get attachedColumn(): Readonly<ColDef> {
        return {
            headerComponent: "headerAttached",
            field: DmsTableCols.IS_ATTACHED,
            filter: "filterAttached",
            floatingFilter: false,
            filterParams: {
                model: "all",
            },
            resizable: true,
            menuTabs: ["filterMenuTab"],
            width: 80,
            suppressMovable: true,
            lockPosition: true,
            checkboxSelection: false,
            valueGetter: ({ data }: ValueGetterParams<ITableDocument>) => {
                if (!data) {
                    return null;
                }

                return data.isAttached;
            },
        };
    }

    private static get documentDateColumn(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: TranslateDmsTableColumns.documentDate }),
            headerTooltip: this.intl?.formatMessage({ id: TranslateDmsTableColumns.documentDate }),
            field: DmsTableCols.DOCUMENT_DATE,
            width: 160,
            filter: "agTextColumnFilter",
            valueGetter: ({ data }: ValueGetterParams<ITableDocument>) => {
                if (!data) {
                    return null;
                }

                return data.documentDate;
            },
            comparator: (a, b) => documentSorter(a, b, "date"),
        };
    }

    private static get documentCreatedDateColumn(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: TranslateDmsTableColumns[DmsTableCols.CREATED_AT] }),
            headerTooltip: this.intl?.formatMessage({ id: TranslateDmsTableColumns[DmsTableCols.CREATED_AT] }),
            field: DmsTableCols.CREATED_AT,
            width: 160,
            cellClass: [styles.cellAlignCentred, styles.agBinaleCell],
            filter: false,
            valueGetter: ({ data }: ValueGetterParams<ITableDocument>) => {
                if (!data) {
                    return null;
                }
                return data?.createdAt ? dayjs(data.createdAt).format("DD.MM.YYYY HH:mm") : "";
            },
            comparator: (a, b) => documentSorter(a, b, "dateTime"),
        };
    }

    private static get partnerNameColumn(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: TranslateDmsTableColumns[DmsTableCols.PARTNER] }),
            headerTooltip: this.intl?.formatMessage({ id: TranslateDmsTableColumns[DmsTableCols.PARTNER] }),
            field: DmsTableCols.PARTNER,
            width: 160,
            filter: "agTextColumnFilter",
            valueGetter: ({ data }: ValueGetterParams<ITableDocument>) => {
                if (!data) {
                    return null;
                }

                let partner = data.partner?.name;

                if (partner) {
                    const index = partner.indexOf(": ");
                    if (index !== -1) {
                        partner = partner.slice(index + 2);
                    }
                }
                return partner;
            },
            comparator: (a, b) => documentSorter(a, b, "string"),
        };
    }

    private static get documentNumberColumn(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: TranslateDmsTableColumns[DmsTableCols.DOCUMENT_NUMBER] }),
            headerTooltip: this.intl?.formatMessage({ id: TranslateDmsTableColumns[DmsTableCols.DOCUMENT_NUMBER] }),
            field: DmsTableCols.DOCUMENT_NUMBER,
            width: 280,
            valueGetter: ({ data }: ValueGetterParams<ITableDocument>) => {
                if (!data) {
                    return null;
                }
                return data.documentNumber;
            },
            comparator: (a, b) => documentSorter(a, b, "string"),
        };
    }

    private static get currencyColumn(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: TranslateDmsTableColumns[DmsTableCols.CURRENCY] }),
            headerTooltip: this.intl?.formatMessage({ id: TranslateDmsTableColumns[DmsTableCols.CURRENCY] }),
            field: DmsTableCols.CURRENCY,
            width: 120,
            valueGetter: ({ data }: ValueGetterParams<ITableDocument>) => {
                if (!data) {
                    return null;
                }

                return data.documentAmount && !data.isAttachment ? data.currency : null;
            },
            comparator: (a, b) => documentSorter(a, b, "string"),
        };
    }

    private static get documentAmountColumn(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: TranslateDmsTableColumns[DmsTableCols.DOCUMENT_AMOUNT] }),
            headerTooltip: this.intl?.formatMessage({ id: TranslateDmsTableColumns[DmsTableCols.DOCUMENT_AMOUNT] }),
            field: DmsTableCols.DOCUMENT_AMOUNT,
            width: 110,
            filter: "agTextColumnFilter",
            filterParams: {
                textFormatter: (filterText: string) => filterText.replace(/[.\s]/g, ""),
            },
            cellRendererParams: {
                style: { textAlign: "right" },
            },
            valueGetter: ({ data }: ValueGetterParams<ITableDocument>) => {
                if (!data || data.isAttachment) {
                    return null;
                }
                return currencyFormatter(Number(data.documentAmount));
            },
            comparator: (a, b) => documentSorter(a, b, "number"),
        };
    }

    private static get documentOriginalAmountColumn(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({
                id: TranslateDmsTableColumns[DmsTableCols.DOCUMENT_ORIGINAL_AMOUNT],
            }),
            headerTooltip: this.intl?.formatMessage({
                id: TranslateDmsTableColumns[DmsTableCols.DOCUMENT_ORIGINAL_AMOUNT],
            }),
            field: DmsTableCols.DOCUMENT_ORIGINAL_AMOUNT,
            width: 110,
            filter: "agTextColumnFilter",
            filterParams: {
                textFormatter: (filterText: string) => filterText.replace(/[.\s]/g, ""),
            },
            cellRendererParams: {
                style: { textAlign: "right" },
            },
            valueGetter: ({ data }: ValueGetterParams<ITableDocument>) => {
                if (!data || data.isAttachment) {
                    return null;
                }
                return currencyFormatter(Number(data.originalAmount ? data.originalAmount : data.documentAmount));
            },

            comparator: (a, b) => documentSorter(a, b, "number"),
        };
    }

    private static get fileNameColumn(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: TranslateDmsTableColumns[DmsTableCols.FILE_NAME] }),
            headerTooltip: this.intl?.formatMessage({ id: TranslateDmsTableColumns[DmsTableCols.FILE_NAME] }),
            field: DmsTableCols.FILE_NAME,
            width: 200,
            sortable: true,
            valueGetter: ({ data }: ValueGetterParams<ITableDocument>) => {
                if (!data) {
                    return null;
                }
                return data.fileName;
            },
            comparator: (a, b) => documentSorter(a, b, "string"),
        };
    }

    private static get createdByColumn(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: TranslateDmsTableColumns[DmsTableCols.CREATED_BY] }),
            headerTooltip: this.intl?.formatMessage({ id: TranslateDmsTableColumns[DmsTableCols.CREATED_BY] }),
            field: DmsTableCols.CREATED_BY,
            width: 200,
            valueGetter: ({ data }: ValueGetterParams<ITableDocument>) => {
                if (!data) {
                    return null;
                }
                return data.createdBy?.email ?? "";
            },
            comparator: (a, b) => documentSorter(a, b, "string"),
        };
    }

    private static get vatNbColumn(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: TranslateDmsTableColumns[DmsTableCols.VAT_NB] }),
            headerTooltip: this.intl?.formatMessage({ id: TranslateDmsTableColumns[DmsTableCols.VAT_NB] }),
            field: DmsTableCols.VAT_NB,
            width: 200,
            comparator: (a, b, nA, nB) => {
                const combinedA = (nA?.data?.landCode ?? "") + (a ?? "");
                const combinedB = (nB?.data?.landCode ?? "") + (b ?? "");
                return combinedA.localeCompare(combinedB);
            },
            valueGetter: ({ data }: ValueGetterParams<ITableDocument>) => {
                if (!data) {
                    return null;
                }

                const value = data?.landCode + data?.UStIdNr;
                return value ? value.toString() : null;
            },
        };
    }

    private static get documentTypeColumn(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: TranslateDmsTableColumns[DmsTableCols.TYPE] }),
            headerTooltip: this.intl?.formatMessage({ id: TranslateDmsTableColumns[DmsTableCols.TYPE] }),
            field: DmsTableCols.TYPE,
            width: 150,
            sortable: true,
            filter: false,
            comparator: (a, b) => documentSorter(a, b, "string"),
            cellRenderer: ({ data }: ICellRendererParams<ITableDocument>) => {
                if (!data) {
                    return null;
                }
                const { type } = data;
                const translationKey = DmsTypeOptions.find(el => el.value === type)?.translationKey;
                return translationKey ? <FormattedMessage id={translationKey} /> : type;
            },
        };
    }

    private static get documentSubTypeColumn(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: TranslateDmsTableColumns[DmsTableCols.SUB_TYPE] }),
            headerTooltip: this.intl?.formatMessage({ id: TranslateDmsTableColumns[DmsTableCols.SUB_TYPE] }),
            field: DmsTableCols.SUB_TYPE,
            width: 150,
            cellRenderer: ({ data }: ICellRendererParams<ITableDocument>) => {
                if (!data || !data.subType) {
                    return null;
                }

                const { type, subType } = data;

                const translationKey = DmsTaxesOptions.find(el => el.value === subType)?.translationKey;
                return translationKey ? (
                    <FormattedMessage id={translationKey} />
                ) : (
                    <DocumentSubTypeFormatted type={type} subType={subType} />
                );
            },
            comparator: (a, b) => documentSorter(a, b, "string"),
        };
    }

    private static get descriptionColumn(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: TranslateDmsTableColumns[DmsTableCols.DESCRIPTION] }),
            headerTooltip: this.intl?.formatMessage({ id: TranslateDmsTableColumns[DmsTableCols.DESCRIPTION] }),
            field: DmsTableCols.DESCRIPTION,
            width: 300,
            valueGetter: ({ data }: ValueGetterParams<ITableDocument>) => {
                if (!data) {
                    return null;
                }
                return data.description;
            },
            comparator: (a, b) => documentSorter(a, b, "string"),
            filter: "agTextColumnFilter",
        };
    }

    private static get documentNumPages(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: TranslateDmsTableColumns[DmsTableCols.NUM_PAGES] }),
            headerTooltip: this.intl?.formatMessage({ id: TranslateDmsTableColumns[DmsTableCols.NUM_PAGES] }),
            field: DmsTableCols.NUM_PAGES,
            width: 110,
            filter: "agNumberColumnFilter",
            valueGetter: ({ data }: ValueGetterParams<ITableDocument>) => {
                if (!data) {
                    return null;
                }
                return data.numPages;
            },
            comparator: (a, b) => documentSorter(a, b, "number"),
        };
    }

    private static get documentCurrencyRate(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: TranslateDmsTableColumns[DmsTableCols.CURRENCY_RATE] }),
            headerTooltip: this.intl?.formatMessage({ id: TranslateDmsTableColumns[DmsTableCols.CURRENCY_RATE] }),
            field: DmsTableCols.CURRENCY_RATE,
            width: 110,
            sortable: true,
            valueGetter: ({ data }: ValueGetterParams<ITableDocument>) => {
                if (!data || data.currency === GQL.ICurrencyCode.Eur || !data?.currencyRate || data.isAttachment) {
                    return null;
                }
                return data.currencyRate.toFixed(4);
            },
            comparator: (a, b) => documentSorter(a, b, "number"),
            filter: "agNumberColumnFilter",
        };
    }

    private static get documentDeadlineDays(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: TranslateDmsTableColumns[DmsTableCols.DEADLINE_DAYS] }),
            headerTooltip: this.intl?.formatMessage({ id: TranslateDmsTableColumns[DmsTableCols.DEADLINE_DAYS] }),
            field: DmsTableCols.DEADLINE_DAYS,
            width: 110,
            sortable: true,
            valueGetter: ({ data }: ValueGetterParams<ITableDocument>) => {
                if (!data) {
                    return null;
                }
                return data.deadlineDays;
            },
            comparator: (a, b) => documentSorter(a, b, "number"),
        };
    }

    private static get documentDiscountAmount(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: TranslateDmsTableColumns[DmsTableCols.DISCOUNT_AMOUNT] }),
            headerTooltip: this.intl?.formatMessage({ id: TranslateDmsTableColumns[DmsTableCols.DISCOUNT_AMOUNT] }),
            field: DmsTableCols.DISCOUNT_AMOUNT,
            width: 110,
            valueGetter: ({ data }: ValueGetterParams<ITableDocument>) => {
                if (!data) {
                    return null;
                }
                return data.discountAmount;
            },
            comparator: (a, b) => documentSorter(a, b, "number"),
        };
    }

    private static get documentDiscountDate(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: TranslateDmsTableColumns[DmsTableCols.DISCOUNT_DATE] }),
            headerTooltip: this.intl?.formatMessage({ id: TranslateDmsTableColumns[DmsTableCols.DISCOUNT_DATE] }),
            field: DmsTableCols.DISCOUNT_DATE,
            width: 110,
            valueGetter: ({ data }: ValueGetterParams<ITableDocument>) => {
                if (!data) {
                    return null;
                }
                return data.discountDate;
            },
            comparator: (a, b) => documentSorter(a, b, "date"),
        };
    }

    private static get documentDiscountDays(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: TranslateDmsTableColumns[DmsTableCols.DISCOUNT_DAYS] }),
            headerTooltip: this.intl?.formatMessage({ id: TranslateDmsTableColumns[DmsTableCols.DISCOUNT_DAYS] }),
            field: DmsTableCols.DISCOUNT_DAYS,
            tooltipField: DmsTableCols.DISCOUNT_DAYS,
            width: 110,
            valueGetter: ({ data }: ValueGetterParams<ITableDocument>) => {
                if (!data) {
                    return null;
                }

                return data.discountDays;
            },
            comparator: (a, b) => documentSorter(a, b, "number"),
        };
    }

    private static get documentDiscountPercent(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: TranslateDmsTableColumns[DmsTableCols.DISCOUNT_PERCENT] }),
            headerTooltip: this.intl?.formatMessage({ id: TranslateDmsTableColumns[DmsTableCols.DISCOUNT_PERCENT] }),
            field: DmsTableCols.DISCOUNT_PERCENT,
            width: 110,
            valueGetter: ({ data }: ValueGetterParams<ITableDocument>) => {
                if (!data) {
                    return null;
                }

                return data.discountPercent;
            },
            comparator: (a, b) => documentSorter(a, b, "number"),
        };
    }

    private static get documentDueDate(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: TranslateDmsTableColumns[DmsTableCols.DUE_DATE] }),
            headerTooltip: this.intl?.formatMessage({ id: TranslateDmsTableColumns[DmsTableCols.DUE_DATE] }),
            field: DmsTableCols.DUE_DATE,
            width: 110,
            sortable: true,
            valueGetter: ({ data }: ValueGetterParams<ITableDocument>) => {
                if (!data) {
                    return null;
                }

                return data.dueDate;
            },
            comparator: (a, b) => documentSorter(a, b, "date"),
        };
    }

    private static get documentInterneNumber(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: TranslateDmsTableColumns[DmsTableCols.INTERNE_NUMBER] }),
            headerTooltip: this.intl?.formatMessage({ id: TranslateDmsTableColumns[DmsTableCols.INTERNE_NUMBER] }),
            field: DmsTableCols.INTERNE_NUMBER,
            width: 110,
            sortable: true,
            valueGetter: ({ data }: ValueGetterParams<ITableDocument>) => {
                if (!data) {
                    return null;
                }

                return data.interneNumber;
            },
            comparator: (a, b) => documentSorter(a, b, "string"),
        };
    }

    private static get documentNotes(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: TranslateDmsTableColumns[DmsTableCols.NOTES] }),
            headerTooltip: this.intl?.formatMessage({ id: TranslateDmsTableColumns[DmsTableCols.NOTES] }),
            field: DmsTableCols.NOTES,
            width: 110,
            filter: "agTextColumnFilter",
            valueGetter: ({ data }: ValueGetterParams<ITableDocument>) => {
                if (!data) {
                    return null;
                }

                return data.notes;
            },
            comparator: (a, b) => documentSorter(a, b, "string"),
        };
    }

    private static get documentPaymentType(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: TranslateDmsTableColumns[DmsTableCols.PAYMENT_TYPE] }),
            headerTooltip: this.intl?.formatMessage({ id: TranslateDmsTableColumns[DmsTableCols.PAYMENT_TYPE] }),
            field: DmsTableCols.PAYMENT_TYPE,
            width: 110,
            sortable: true,
            valueGetter: ({ data }: ValueGetterParams<ITableDocument>) => {
                if (!data) {
                    return null;
                }

                return data.paymentType;
            },
            comparator: (a, b) => documentSorter(a, b, "string"),
        };
    }

    private static get documentAmountTypeColumn(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: TranslateDmsTableColumns[DmsTableCols.DOCUMENT_AMOUNT_TYPE] }),
            headerTooltip: this.intl?.formatMessage({
                id: TranslateDmsTableColumns[DmsTableCols.DOCUMENT_AMOUNT_TYPE],
            }),
            field: DmsTableCols.DOCUMENT_AMOUNT_TYPE,
            width: 120,
            minWidth: 120,
            cellClass: [styles.cellClip, styles.agBinaleCell],
            cellRenderer: "amountTypeTag",
            valueGetter: ({ data: document }: ValueGetterParams<ITableDocument>) => {
                if (!document) {
                    return null;
                }

                const { type, isAttachment, documentAmount } = document;

                if (isAttachment) {
                    return this.intl?.formatMessage({ id: AmountTypeTranslateKeys[AmountTypeKeys.ATTACHMENT] });
                }

                if (documentAmount && documentAmount !== 0) {
                    const movementType = getMovementType(documentAmount, type as DmsType);

                    const messageKey = movementTypeTranslationKey[type as DmsType];

                    if (type === DmsType.ER) {
                        return this.intl?.formatMessage({
                            id:
                                documentAmount < 0
                                    ? AmountTypeTranslateKeys[AmountTypeKeys.CREDIT_NOTE]
                                    : AmountTypeTranslateKeys[AmountTypeKeys.INVOICE],
                        });
                    }
                    if (type === DmsType.Deb) {
                        return this.intl?.formatMessage({
                            id:
                                documentAmount > 0
                                    ? AmountTypeTranslateKeys[AmountTypeKeys.INVOICE]
                                    : AmountTypeTranslateKeys[AmountTypeKeys.CREDIT_NOTE],
                        });
                    }
                    return this.intl?.formatMessage({
                        id: movementType !== "zero" ? messageKey?.[movementType] : null,
                    });
                }

                return null;
            },
            sortable: true,
            comparator: (a, b) => documentSorter(a, b, "string"),
        };
    }

    private static get documentUpdatedAt(): Readonly<ColDef> {
        return {
            headerName: this.intl?.formatMessage({ id: TranslateDmsTableColumns[DmsTableCols.UPDATED_AT] }),
            headerTooltip: this.intl?.formatMessage({ id: TranslateDmsTableColumns[DmsTableCols.UPDATED_AT] }),
            field: DmsTableCols.UPDATED_AT,
            width: 180,
            sortable: true,
            valueGetter: ({ data }: ValueGetterParams<ITableDocument>) => {
                if (!data) {
                    return null;
                }

                return data?.updatedAt ? dayjs(data.updatedAt).format("DD.MM.YYYY HH:mm") : "";
            },
            comparator: (a, b) => documentSorter(a, b, "dateTime"),
        };
    }

    private static get dmsActions(): Readonly<ColDef> {
        return {
            ...this.actions,
            cellRendererParams: (params: ICellRendererParams<ITableDocument>) => ({
                documentKey: params.data?.key ?? null,
            }),
        };
    }

    private static get modalActions(): Readonly<ColDef> {
        return {
            headerComponent: "headerActions",
            field: DmsTableCols.ACTIONS,
            filter: "filterColor",
            floatingFilter: false,
            resizable: false,
            suppressMovable: true,
            width: 90,
            pinned: "right",
            cellClass: [styles.cellCentred, styles.agBinaleCell],
            cellRendererParams: (params: ICellRendererParams<ITableDocument>) => ({
                documentKey: params.data?.key ?? null,
                mode: tableModeEnum.modal,
            }),
            cellRenderer: "actionList",
        };
    }

    private static getColumn = (colKey: TDmsTableCols): Readonly<ColDef> => {
        switch (colKey) {
            case DmsTableCols.DRAG:
                return { ...this.dragColumn };
            case DmsTableCols.ROW_NUMBER:
                return { ...this.dmsRowNumberColumn };
            case DmsTableCols.IS_ATTACHED:
                return { ...this.attachedColumn };
            case DmsTableCols.DOCUMENT_DATE:
                return { ...this.documentDateColumn };
            case DmsTableCols.CREATED_AT:
                return { ...this.documentCreatedDateColumn };
            case DmsTableCols.PARTNER:
                return { ...this.partnerNameColumn };
            case DmsTableCols.DOCUMENT_NUMBER:
                return { ...this.documentNumberColumn };
            case DmsTableCols.CURRENCY:
                return { ...this.currencyColumn };
            case DmsTableCols.DOCUMENT_AMOUNT:
                return { ...this.documentAmountColumn };
            case DmsTableCols.DOCUMENT_ORIGINAL_AMOUNT:
                return { ...this.documentOriginalAmountColumn };
            case DmsTableCols.DOCUMENT_AMOUNT_TYPE:
                return { ...this.documentAmountTypeColumn };
            case DmsTableCols.FILE_NAME:
                return { ...this.fileNameColumn };
            case DmsTableCols.CREATED_BY:
                return { ...this.createdByColumn };
            case DmsTableCols.VAT_NB:
                return { ...this.vatNbColumn };
            case DmsTableCols.TYPE:
                return { ...this.documentTypeColumn };
            case DmsTableCols.SUB_TYPE:
                return { ...this.documentSubTypeColumn };
            case DmsTableCols.DESCRIPTION:
                return { ...this.descriptionColumn };
            case DmsTableCols.NUM_PAGES:
                return { ...this.documentNumPages };
            case DmsTableCols.CURRENCY_RATE:
                return { ...this.documentCurrencyRate };
            case DmsTableCols.DEADLINE_DAYS:
                return { ...this.documentDeadlineDays };
            case DmsTableCols.DISCOUNT_DATE:
                return { ...this.documentDiscountDate };
            case DmsTableCols.DISCOUNT_AMOUNT:
                return { ...this.documentDiscountAmount };
            case DmsTableCols.DISCOUNT_DAYS:
                return { ...this.documentDiscountDays };
            case DmsTableCols.DISCOUNT_PERCENT:
                return { ...this.documentDiscountPercent };
            case DmsTableCols.DUE_DATE:
                return { ...this.documentDueDate };
            case DmsTableCols.INTERNE_NUMBER:
                return { ...this.documentInterneNumber };
            case DmsTableCols.NOTES:
                return { ...this.documentNotes };
            case DmsTableCols.PAYMENT_TYPE:
                return { ...this.documentPaymentType };
            case DmsTableCols.UPDATED_AT:
                return { ...this.documentUpdatedAt };
            case DmsTableCols.ACTIONS:
                return { ...this.dmsActions };
        }
    };

    private static columnsForAllModalTables = [
        { ...this.modalSelectedColumn },
        { ...this.attachedColumn },
        { ...this.documentDateColumn },
        { ...this.partnerNameColumn },
    ];

    private static modalColumns: Record<string, Readonly<ColDef>[]> = {
        defaultDocumentsTableColumns: [
            ...this.columnsForAllModalTables,
            this.documentNumberColumn,
            this.documentAmountColumn,
            this.documentCurrencyRate,
            this.descriptionColumn,
            this.fileNameColumn,
            this.modalActions,
        ],

        newDocumentsTableColumns: [
            ...this.columnsForAllModalTables,
            this.fileNameColumn,
            this.createdByColumn,
            this.modalActions,
        ],

        allDocumentsTableColumns: [
            ...this.columnsForAllModalTables,
            this.documentNumberColumn,
            this.documentAmountColumn,
            this.documentCurrencyRate,
            this.descriptionColumn,
            this.fileNameColumn,
            this.documentTypeColumn,
            this.modalActions,
        ],
    };

    public static getColumns = (mode: tableModeEnum, activeKeys?: string[], configName?: string): ColDef[] => {
        if (mode === tableModeEnum.modal && configName) {
            return this.modalColumns[configName];
        }
        return activeKeys.map(item => this.getColumn(item as TDmsTableCols));
    };
}
