import React, { type FC, useContext } from "react";
import { FileUploaderContext } from "../../context/FileUploaderContext";
import { UploadFileItem } from "./UploadFileItem";

export const UploadFileList: FC = () => {
    const { uploadingFiles } = useContext(FileUploaderContext);

    if (!uploadingFiles) {
        return null;
    }

    const uploadingFilesArr = Object.keys(uploadingFiles).map(hash => {
        return (
            <div key={hash}>
                <UploadFileItem file={uploadingFiles[hash]} />
            </div>
        );
    });

    return (
        <div>
            <div>{uploadingFilesArr}</div>
        </div>
    );
};
