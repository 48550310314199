import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import { CustomHeaderProps } from "ag-grid-react";
import { transactionFieldsDict } from "@banks/configs/constants/transactionFieldsDict";
import { Select } from "antd";
import {
    PreviewParseDataContext,
    PreviewParseDataControlContext,
} from "@banks/modules/PreviewParseDataModule/context/PreviewParseDataContext";
import { checkForExcluded, getAutomappingKey } from "@banks/scripts/helpers/headerKeyTools";
import { OptionsValues } from "@banks/types/enums";

export const CustomTableHeader: FC<CustomHeaderProps> = ({ displayName }) => {
    const [value, setValue] = useState<string>();
    const { unmappedDataKeys, isResetIbanSelector } = useContext(PreviewParseDataContext);
    const { setFieldMatching, setIsResetIbanSelector } = useContext(PreviewParseDataControlContext);

    useEffect(() => {
        const autoValue = getAutomappingKey(displayName);
        if (autoValue) {
            return setValue(autoValue);
        }

        if (checkForExcluded(displayName)) {
            setValue(OptionsValues.UNMAPPED);
        }
    }, [displayName]);

    useEffect(() => {
        if (!isResetIbanSelector) {
            return;
        }
        setValue(null);
        setIsResetIbanSelector(false);
    }, [isResetIbanSelector]);

    const options = useMemo(() => {
        return unmappedDataKeys.map(el => {
            const key = el as keyof typeof transactionFieldsDict;
            return {
                label: transactionFieldsDict[key],
                value: el,
            };
        });
    }, [unmappedDataKeys]);

    const handleSelect = (value: keyof typeof transactionFieldsDict | OptionsValues.UNMAPPED) => {
        setValue(value);
        setFieldMatching(displayName, value);
    };

    const isDisabled = !!getAutomappingKey(displayName) || !!checkForExcluded(displayName);

    return (
        <div
            style={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
                padding: "15px 0",
            }}
        >
            {displayName !== "No" ? (
                <Select
                    value={value}
                    options={[{ label: "Unmapped", value: OptionsValues.UNMAPPED }, ...options]}
                    onSelect={arg =>
                        handleSelect(arg as unknown as keyof typeof transactionFieldsDict | OptionsValues.UNMAPPED)
                    }
                    placeholder="Select data field"
                    disabled={isDisabled}
                />
            ) : null}

            <div style={{ marginTop: 8, padding: "0 3px" }}>{displayName}</div>
        </div>
    );
};
