import { Col, Flex, Row, Typography } from "antd";
import React, { useContext } from "react";
import { BanksContext } from "../../../scripts/context/BanksContext";
import dayjs from "dayjs";
import { Utils } from "@binale-tech/shared";

const { Title, Text } = Typography;

const bankDate = dayjs().format("DD.MM.YYYY");

export const AllBanksStatistic = () => {
    const { banksData } = useContext(BanksContext);

    const banksValue = Object.values(banksData || []).reduce((total, bank) => {
        const balance = Object.values(bank.transactions || []).reduce(
            (sum, tr) => sum + (tr.amount ?? 0),
            bank.openingBalance ?? 0
        );
        return total + balance;
    }, 0);

    return (
        <Flex vertical style={{ height: "100%", padding: 24, backgroundColor: "#f9f9f9", borderRadius: 8 }}>
            <Flex justify={"center"}>
                <Title level={3} ellipsis>
                    All Banks
                </Title>
            </Flex>

            <Row>
                <Col span={24}>
                    <Flex vertical gap={5}>
                        <Title level={5} ellipsis>
                            Balance
                        </Title>
                        <Flex justify={"space-between"}>
                            <Text ellipsis>{bankDate}:</Text>
                            <Text ellipsis>
                                <span style={{ color: `${banksValue < 0 ? "#f5222d" : "#52c41a"}` }}>
                                    {Utils.CurrencyUtils.currencyFormat(Math.round(banksValue))}
                                </span>{" "}
                                EUR
                            </Text>
                        </Flex>
                    </Flex>
                </Col>
            </Row>
        </Flex>
    );
};
