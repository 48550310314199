import React, { FC, memo, useEffect, useState } from "react";
import { CheckCircleOutlined, CloseCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Flex, Space, Spin } from "antd";

type TProps = {
    isDisabled?: boolean;
    saveAll?: () => Promise<true | undefined>;
    saveSelected?: () => Promise<true | undefined>;
    selectedCount?: number;
};

export const SaveButtons: FC<TProps> = memo(({ saveAll, saveSelected, isDisabled, selectedCount }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [isError, setIsError] = useState(false);
    const [saveBtnDisabled, setSaveBtnDisabled] = useState<boolean>(isDisabled);

    const handleSave = async (saveAction: () => Promise<true | undefined>) => {
        setSaveBtnDisabled(true);
        setIsLoading(true);
        const res = await saveAction();
        setIsLoading(false);

        if (res) {
            setSuccess(true);
        } else {
            setIsError(true);
        }

        setTimeout(() => {
            setSuccess(false);
            setIsError(false);
            setSaveBtnDisabled(false);
        }, 3000);
    };

    return (
        <Flex gap={10}>
            <Space style={{ justifyContent: "flex-end", width: "100%" }}>
                {success && !isLoading && <CheckCircleOutlined style={{ fontSize: 24, color: "#52c41a" }} />}
                {isError && !isLoading && <CloseCircleOutlined style={{ fontSize: 24, color: "#ff4d4f" }} />}
                {isLoading && <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />}

                <Button onClick={() => handleSave(saveSelected)} disabled={saveBtnDisabled || !selectedCount}>
                    {`Save selected ${selectedCount ? `(${selectedCount})` : ""}`}
                </Button>
                <Button onClick={() => handleSave(saveAll)} disabled={saveBtnDisabled || isDisabled} type={"primary"}>
                    Save All
                </Button>
            </Space>
        </Flex>
    );
});
