import React, { type FC, useContext, useState } from "react";
import { Button, Divider } from "antd";
import { FileUploaderContext } from "../../context/FileUploaderContext";
import { FormattedMessage } from "react-intl";
import { NotUniqFile } from "./NotUniqFile";
import { TReducedFileInfo, TUploaderFileInfo, TUploadFiles } from "../../../../types";

export const NotUniqFileList: FC = () => {
    const [selectedFiles, setSelectedFiles] = useState<TUploadFiles>();
    const [unSelectedFiles, setUnSelectedFiles] = useState<TReducedFileInfo>();

    const { notUniqFiles, setNotUniqFiles, setUniqFiles, setRemovedFiles } = useContext(FileUploaderContext);

    if (!notUniqFiles) {
        return null;
    }

    const handleSelectNotUniqFiles = (
        hash: string,
        selected: TUploaderFileInfo | null,
        unSelected: TUploaderFileInfo[]
    ) => {
        if (!selected) {
            setNotUniqFiles(prev => {
                if (!prev) {
                    return;
                }
                const { [hash]: removeFile, ...data } = prev;
                setRemovedFiles(prevState => {
                    if (!prevState) {
                        return [...removeFile];
                    }
                    return [...prevState, ...removeFile];
                });
                return data;
            });
        }

        setSelectedFiles(prev => {
            if (!selected) {
                const { [hash]: _, ...data } = prev || {};
                return data;
            }
            return {
                ...prev,
                [selected.hash]: { ...selected, isUploaded: false },
            } as TUploadFiles;
        });

        setUnSelectedFiles(prev => {
            return {
                ...prev,
                [hash]: [...unSelected],
            };
        });
    };

    const handleConfirm = () => {
        setNotUniqFiles(undefined);
        setUniqFiles(prev => {
            return {
                ...prev,
                ...selectedFiles,
            };
        });

        if (!unSelectedFiles) {
            return;
        }
        filesRemover(unSelectedFiles);
    };

    const handleDeleteAll = () => {
        filesRemover(notUniqFiles);
    };

    const filesRemover = (files: TReducedFileInfo) => {
        Object.values(files).forEach(el => {
            setRemovedFiles(prev => {
                if (!prev) {
                    return [...el];
                }
                return [...prev, ...el];
            });
        });
    };

    return (
        <div>
            <Divider />
            {Object.keys(notUniqFiles).map(hash => {
                return (
                    <NotUniqFile
                        key={hash}
                        hash={hash}
                        notUniqFiles={notUniqFiles ? notUniqFiles[hash] : []}
                        handleSelectNotUniqFiles={handleSelectNotUniqFiles}
                    ></NotUniqFile>
                );
            })}
            <div style={{ width: "100%", marginTop: "10px", display: "flex", justifyContent: "flex-end" }}>
                <Button type={"primary"} onClick={handleConfirm}>
                    <FormattedMessage id="app.dms.confirmResolving" />
                </Button>
                <Button style={{ marginLeft: "10px" }} onClick={handleDeleteAll}>
                    <FormattedMessage id="app.dms.deleteAll" />
                </Button>
            </div>
            <Divider />
        </div>
    );
};
