import React, { FC } from "react";
import { AddBankModule, EditBankModule, UploadModule } from "@banks/modules";

export const BankModalList: FC = () => {
    return (
        <>
            <AddBankModule />
            <EditBankModule />
            <UploadModule />
        </>
    );
};
