import React, { type FC, useContext } from "react";
import { FileUploaderContext } from "../../context/FileUploaderContext";
import { HasBeenUploadedFileItem } from "./HasBeenUploadedFileItem";
import { Typography } from "antd";

export const HasBeenUploadedFiles: FC = () => {
    const { uploadedFiles } = useContext(FileUploaderContext);

    if (!uploadedFiles) {
        return null;
    }

    const uploadedFilesArr = Object.keys(uploadedFiles).map(hash => {
        return (
            <div key={hash}>
                <HasBeenUploadedFileItem fileData={uploadedFiles[hash]} />
            </div>
        );
    });

    return (
        <div style={{ marginBottom: "20px" }}>
            <Typography.Text type={"secondary"}>Uploaded {Object.keys(uploadedFiles).length} files</Typography.Text>
            {uploadedFilesArr}
        </div>
    );
};
