import React, { FC, PropsWithChildren } from "react";

import { CompanyContext } from "scripts/context/CompanyContext";
import { RecordsContext } from "scripts/context/recordsContext/RecordsCtx";
import { RecordsControlContext, convertGroupActions } from "scripts/context/recordsContext/RecordsControlCtx";
import {
    TableViewContext,
    TableViewContextProvider,
    useDefaultYearPeriodFilters,
} from "../../../scripts/context/tableViewContext/tableViewContext";
import { ProductKB } from "../../../scripts/core/Product";
import { RecordLogRepository } from "../../../scripts/infrastructure/repository/grRepositories";
import { GQL } from "@binale-tech/shared";
import { Navigate, useParams } from "react-router-dom";
import { AppRoutes } from "../../../scripts/routing/routeConstants";

export const KBProvider: FC<PropsWithChildren> = ({ children }) => {
    const { uuid } = useParams<"uuid">();
    const { companyGQL, yearConfig, yearKbs } = React.useContext(CompanyContext);
    const allRecords = React.useContext(RecordsContext);
    const allActions = React.useContext(RecordsControlContext);
    const defaultFilters = useDefaultYearPeriodFilters();

    const tableCtx = React.useMemo<React.ContextType<typeof TableViewContext>>(
        () => ({
            view: "kbOverview",
            product: new ProductKB(yearConfig.skr, companyGQL),
            productKey: GQL.IProductKey.Kb,
            moduleRecords: allRecords.recordsKB.groups.get(uuid)?.list || [],
            moduleActions: convertGroupActions(allActions.actionsKB, uuid),
            moduleLogLister: key => RecordLogRepository.KB.list({ path: [companyGQL?.id, uuid, key] }),
            selectedRecordGroup: uuid,
        }),
        [allActions.actionsKB, allRecords.recordsKB.groups, uuid, yearConfig, companyGQL]
    );
    const hasKB = Boolean(yearKbs.find(v => v.id === uuid));
    if (!hasKB) {
        return <Navigate to={AppRoutes.home} />;
    }

    return (
        <TableViewContextProvider tableCtx={tableCtx} defaultFilters={defaultFilters}>
            {children}
        </TableViewContextProvider>
    );
};
