import { GridOptions } from "ag-grid-community";
import { TParseTransaction } from "@banks/types";
import { baseOptions } from "@app/components/shared/AgGridTable/options/baseOptions";

export const gridOptions: GridOptions<TParseTransaction> = {
    ...baseOptions,
    defaultColDef: {
        resizable: true,
        minWidth: 50,
        enableCellChangeFlash: true,
    },
    rowGroupPanelShow: "never",
};
