import { CSSProperties } from "react";

export const SplitCardWidth = 1225;
export const DocumentsCardMinWidth = 750;

export const FormCardStyle: CSSProperties = {
    position: "fixed",
    top: 121,
    height: "calc(100vh - 221px)",
    zIndex: 50,
    boxShadow: "0px 1px 10px 1px #87919c",
};
