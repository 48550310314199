import { blue } from "@ant-design/colors";
import { CloseOutlined, CopyOutlined } from "@ant-design/icons";
import { IDocumentEnriched } from "@dms/types";
import { Button, ConfigProvider, Tooltip } from "antd";
import React, { type FC, ReactNode, useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import { FormWrapperContext } from "@dms/modules/DocumentFormModule/context/FormWrapperContext";

import styles from "./documentData.module.scss";

interface IProps {
    documentData?: IDocumentEnriched;
    children: ReactNode;
}

export const DocumentData: FC<IProps> = ({ documentData, children }) => {
    const [tooltipTitleId, setTooltipTitleId] = useState(<FormattedMessage id="app.dms.copyId" />);

    const { isGroupEditor } = useContext(FormWrapperContext);
    const navigate = useNavigate();

    const resetTooltipId = (open: boolean): void => {
        if (!open) {
            setTooltipTitleId(<FormattedMessage id="app.dms.copyId" />);
        }
    };

    const copyId = async (): Promise<void> => {
        if (documentData && !isGroupEditor) {
            await navigator.clipboard.writeText(documentData.key);
            setTooltipTitleId(<FormattedMessage id="app.dms.idCopied" />);
        }
    };

    return (
        <div>
            <div className={styles.dataWrapper}>
                <ConfigProvider
                    theme={{
                        token: {
                            colorBgContainer: blue[0],
                            colorBorder: blue[1],
                            colorText: blue.primary,
                        },
                    }}
                >
                    <Button
                        style={{ marginRight: "10px" }}
                        icon={<CloseOutlined />}
                        onClick={() => {
                            navigate("/documents");
                        }}
                    >
                        <span>
                            <FormattedMessage id="app.dms.closeEditor" />
                        </span>
                    </Button>
                    <Tooltip
                        title={isGroupEditor ? null : tooltipTitleId}
                        onOpenChange={(open: boolean) => {
                            resetTooltipId(open);
                        }}
                    >
                        <CopyOutlined className={isGroupEditor ? styles.disabledIcon : undefined} onClick={copyId} />
                    </Tooltip>
                </ConfigProvider>
            </div>
            <div className={styles.formWrapper}>{children}</div>
        </div>
    );
};
