import React from "react";
import { useIntl } from "react-intl";

import { CompanyContext, YearPeriodContext } from "scripts/context/CompanyContext";
import { UserContext } from "scripts/context/UserProvider";
import { BuContext } from "scripts/context/BuContext";
import { RecordsContext } from "scripts/context/recordsContext/RecordsCtx";
import { RecordsControlContext } from "scripts/context/recordsContext/RecordsControlCtx";
import { PaymentsContext, PaymentsControlContext } from "scripts/context/PaymentsProvider";
import { GenericTableViewActions, GenericTableViewStateProps } from "./GenericTableView";

import {
    TableFiltersContext,
    TableFiltersControlContext,
    TableViewContext,
} from "../../../scripts/context/tableViewContext/tableViewContext";
import { useCanWrite, useDefaultCategoriesByYearMonth } from "scripts/infrastructure/hooks";
import { useDocumentActions } from "../../../scripts/context/hooks/useDocumentActions";

type Props = {
    Component: React.ComponentClass<GenericTableViewStateProps & GenericTableViewActions>;
};
const GenericTableViewCtxWrapper: React.FC<Props> = props => {
    const { Component, ...restProps } = props;
    const intl = useIntl();
    const { view, product, productKey, moduleActions, moduleRecords, selectedRecordGroup } =
        React.useContext(TableViewContext);
    const { companyGQL, yearConfig } = React.useContext(CompanyContext);
    const { companyBuTimeframes } = React.useContext(BuContext);
    const yearPeriodContext = React.useContext(YearPeriodContext);
    const defaultCategories = useDefaultCategoriesByYearMonth();
    const recordActions = React.useContext(RecordsControlContext);
    const allRecords = React.useContext(RecordsContext);
    const { filters } = React.useContext(TableFiltersContext);
    const { setFilter, replaceFilters } = React.useContext(TableFiltersControlContext);
    const { onPaymentGql } = React.useContext(PaymentsControlContext);
    const payments = React.useContext(PaymentsContext);
    const user = React.useContext(UserContext);
    const canWrite = useCanWrite(productKey);
    const accountingYears = companyGQL?.accountingYears || [];
    const documentActions = useDocumentActions(selectedRecordGroup);

    return (
        <Component
            {...restProps}
            {...yearPeriodContext}
            records={moduleRecords}
            recordActions={recordActions}
            documentActions={documentActions}
            moduleActions={moduleActions}
            aggregatedRecordsData={allRecords}
            defaultCategories={defaultCategories}
            buTimeframes={companyBuTimeframes}
            payments={payments}
            onPaymentGql={onPaymentGql}
            user={user}
            companyGQL={companyGQL}
            yearConfig={yearConfig}
            accountingYears={accountingYears}
            canWrite={canWrite}
            view={view}
            intl={intl}
            filters={filters}
            setFilter={setFilter}
            replaceFilters={replaceFilters}
            product={product}
            productKey={productKey}
        />
    );
};
export default React.memo(GenericTableViewCtxWrapper);
