import React, { useCallback, useEffect, useMemo } from "react";
import { child, DataSnapshot } from "firebase/database";
import { GQL } from "@binale-tech/shared";

import Payment from "../models/Payment";
import { CompanyContext } from "./CompanyContext";
import { UserContext } from "./UserProvider";
import { paymentsSave } from "./mutations/paymentMutations.graphql";
import { refPayments } from "../api/firebase/firebaseRootRefs";
import { useGqlMutator } from "../graphql/useGqlMutator";
import { useCollectionListener } from "./hooks/useCollectionListener";

export interface PaymentsCtxData {
    list: Payment[];
    map: Map<string, Payment>;
    recordRelation: Map<string, Payment[]>;
    isLoaded: boolean;
}

const defaultPaymentData: PaymentsCtxData = { list: [], map: new Map(), recordRelation: new Map(), isLoaded: false };
export const PaymentsContext = React.createContext<PaymentsCtxData>(defaultPaymentData);

type PaymentController = {
    onPaymentGql: (v: Omit<GQL.IPaymentsSaveInput, "companyId">, hideMessages?: boolean) => Promise<GQL.ISaveSummary>;
};
export const PaymentsControlContext = React.createContext<PaymentController>({
    onPaymentGql: (v: Omit<GQL.IPaymentsSaveInput, "companyId">, hideMessages?: boolean) =>
        Promise.reject(hideMessages),
});

export const getPaymentsCtxData = (list: Payment[], isLoaded?: boolean): PaymentsCtxData => {
    const map = new Map<string, Payment>();
    const recordRelation = new Map<string, Payment[]>();
    list.forEach(payment => {
        map.set(payment.key, payment);
        payment.getRecordRelationKeys().forEach(pk => {
            if (!recordRelation.has(pk)) {
                recordRelation.set(pk, []);
            }
            recordRelation.get(pk).push(payment);
        });
    });
    return { recordRelation, list, map, isLoaded };
};

export const PaymentsProvider: React.FC<React.PropsWithChildren> = props => {
    const [payments, setPayments] = React.useState<PaymentsCtxData>(defaultPaymentData);
    const { companyGQL } = React.useContext(CompanyContext);
    const { isUserDataLoaded } = React.useContext(UserContext);
    const mutator = useGqlMutator();
    const companyId = companyGQL?.id;

    const shouldSkipLoad = useMemo(() => !companyId || !isUserDataLoaded, [companyId, isUserDataLoaded]);
    const ref = useMemo(() => child(refPayments, String(companyId)), [companyId]);
    const initializer = useCallback((snap: DataSnapshot) => Payment.unserialize(snap.val()), []);

    const paymentsMap = useCollectionListener(ref, initializer, shouldSkipLoad);

    useEffect(() => {
        setPayments(getPaymentsCtxData(Array.from(paymentsMap.values()), isUserDataLoaded));
    }, [paymentsMap, isUserDataLoaded]);

    const paymentControl: PaymentController = React.useMemo(
        () => ({
            onPaymentGql: (v: Omit<GQL.IPaymentsSaveInput, "companyId">, hideMessages?: boolean) => {
                const input: GQL.IPaymentsSaveInput = {
                    ...v,
                    companyId,
                };
                return mutator
                    .mutate<"paymentsSave", GQL.IPaymentsSaveInput>({
                        mutation: paymentsSave,
                        input,
                        hideMessages,
                    })
                    .then(res => res.paymentsSave);
            },
        }),
        [mutator, companyId]
    );
    return (
        <PaymentsContext.Provider value={payments}>
            <PaymentsControlContext.Provider value={paymentControl}>{props.children}</PaymentsControlContext.Provider>
        </PaymentsContext.Provider>
    );
};
