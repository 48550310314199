import React, { type FC } from "react";
import { CheckCircleOutlined } from "@ant-design/icons";

interface IProps {
    fileData: any;
}

export const HasBeenUploadedFileItem: FC<IProps> = ({ fileData }) => {
    const { file, url } = fileData;

    return (
        <div>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <a href={url} target="_blank" rel="noreferrer">
                    {file.name}
                </a>
                <div>
                    <CheckCircleOutlined style={{ color: "#52c41a" }} />
                </div>
            </div>
        </div>
    );
};
