import "./style.scss";

import { notification } from "antd";
import { type NotificationPlacement } from "antd/es/notification/interface";
import React, { type FC, type Key, useContext, useEffect, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { GroupDocumentLayout } from "@dms/components/DocumentLayout/GroupDocumentLayout";
import { SingleDocumentLayout } from "@dms/components/DocumentLayout/SingleDocumentLayout";
import { DmsAppContext, DmsAppControlContext, DmsDataContext } from "@dms/types/ContextTypes";

import { FormWrapperProvider } from "../DocumentFormModule/context/FormWrapperContext";
import { CarouselProvider } from "@dms/modules/DocumentFormModule/context/CarouselContext";
import { IDocumentEnriched } from "@dms/types";

export const DocumentModule: FC = () => {
    const [documentId, setDocumentId] = useState<Key | null>(null);
    const [groupKeys, setGroupKeys] = useState<Key[] | null>(null);
    const [documentData, setDocumentData] = useState<IDocumentEnriched>();
    const { selectedRowKeys } = useContext(DmsAppContext);
    const { addFocusedRows } = useContext(DmsAppControlContext);
    const { documentsKV } = useContext(DmsDataContext);
    const params = useParams<{ documentId: string }>();
    const [search] = useSearchParams();
    const [api, contextHolder] = notification.useNotification();

    const openNotification = (placement: NotificationPlacement): void => {
        api.info({
            message: "The file has been deleted",
            description: "This file has been deleted by another user",
            placement,
        });
    };

    const documentDataLoaded = useRef(false);

    useEffect(() => {
        if (params.documentId) {
            const id = params.documentId;
            const foundDocument = documentsKV[id];

            setDocumentId(id);
            addFocusedRows([id]);
            setDocumentData(foundDocument);
            setGroupKeys(null);
        }
    }, [addFocusedRows, params.documentId, documentsKV]);

    useEffect(() => {
        const groupKeysParam = search.getAll("key");

        if (groupKeysParam.length > 0) {
            setGroupKeys(groupKeysParam);
            setDocumentId(null);
        }
    }, [search]);

    useEffect(() => {
        if (selectedRowKeys.length !== 0) {
            setDocumentId(selectedRowKeys[0]);
        }
    }, [selectedRowKeys]);

    useEffect(() => {
        if (documentData) {
            documentDataLoaded.current = true;
        }
    }, [documentData]);

    useEffect(() => {
        if (!documentData && documentId && documentDataLoaded.current) {
            openNotification("top");
        }
    }, [documentId, documentData]);

    return (
        <div>
            {contextHolder}
            <FormWrapperProvider>
                {documentData && !groupKeys ? <SingleDocumentLayout documentData={documentData} /> : null}
                {groupKeys ? (
                    <CarouselProvider>
                        <GroupDocumentLayout />
                    </CarouselProvider>
                ) : null}
            </FormWrapperProvider>
        </div>
    );
};
