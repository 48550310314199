import dayjs from "dayjs";
import { type TParseTransaction } from "@banks/types";

export const getParsedDateRange = (parseRows: TParseTransaction[]) => {
    if (!parseRows.length) {
        return { firstData: undefined, lastData: undefined };
    }

    const parsRowsCopy = [...parseRows];

    parsRowsCopy.sort((a, b) => dayjs(a.bookingDate, "DD.MM.YYYY").unix() - dayjs(b.bookingDate, "DD.MM.YYYY").unix());

    const firstData = dayjs(parsRowsCopy[0].bookingDate, "DD.MM.YYYY");
    const lastData = dayjs(parsRowsCopy[parsRowsCopy.length - 1].bookingDate, "DD.MM.YYYY");

    return { firstData: firstData, lastData: lastData };
};
