import GenericTableViewCtxWrapper from "../../productSharedComponents/GenericTableViewCtxWrapper";
import React, { memo } from "react";
import { AbstractBillTableView } from "../AbstractBillTableView";
import { GenericRecord } from "scripts/models/GenericRecord";
import { GenericTableViewProps, GenericTableViewState } from "../../productSharedComponents/GenericTableView";
import { GenericToolbar } from "../../../components/toolbar/GenericToolbar";
import { GenericToolbarFilters } from "../../../../scripts/context/tableViewContext/tableViewFilters";
import { GenericRecordForm } from "../../../components/recordform/GenericRecordForm";
import { Subscription } from "rxjs";
import { ERProvider } from "./ERProvider";

interface State extends GenericTableViewState {
    disabledForm: boolean;
}

export class RechnungenViewClass<S extends State = State> extends AbstractBillTableView<S> {
    protected _subCat: Subscription;
    protected _subCre: Subscription;
    protected _subTag: Subscription;

    componentDidMount() {
        super.componentDidMount();
        this._subCat = this.selectCategoryEvent.subscribe(v => this.inlineFormRef.current.setItemCategoryCreditor(v));
        this._subCre = this.selectCreditorEvent.subscribe(v => this.inlineFormRef.current.setRecordCategoryCreditor(v));
        this._subTag = this.selectTagEvent.subscribe(v => this.inlineFormRef.current.setItemTag(v));
    }

    componentWillUnmount() {
        this._subCat?.unsubscribe();
        this._subCre?.unsubscribe();
        this._subTag?.unsubscribe();
    }

    protected getHeader(): React.ReactNode {
        return null;
    }

    protected getToolbarBlock() {
        const selectedToConfirm = this.state.tableItems.filter(v => v.selected && v.item.draft);
        return (
            <GenericToolbar
                showRemoved={this.state.showCancelled}
                categoryCreditorMode={this.props.product.getConfig().recordAccountMode}
                onToggleHidden={this.onToggleHidden}
                tableColumns={this.tableColumns}
                withKontenAnsicht
                withConfirm
                onConfirmClicked={() => this.onConfirm(selectedToConfirm.map(v => v.item.clone()))}
                toConfirm={selectedToConfirm.length}
            />
        );
    }

    protected getDoppelErfassungCheckEnabled() {
        return true;
    }

    protected filtersSnapshot: GenericTableViewProps["filters"];

    protected onRechnungsnummerBlurString = (num: string) => {
        if (!this.getDoppelErfassungCheckEnabled()) {
            return;
        }
        const duplicatesPredicate: (record: GenericRecord) => boolean = record =>
            record.num === num && record.date.getFullYear() === this.props.year;

        let sameNumberRercords = this.props.records.filter(duplicatesPredicate);
        if (this.state.activeRecord) {
            sameNumberRercords = sameNumberRercords.filter(b => b.key !== this.state.activeRecord.key);
        }
        if (sameNumberRercords.length > 0) {
            this.filtersSnapshot = this.props.filters;
            this.props.replaceFilters(
                new Map([[GenericToolbarFilters.Duplicates, { predicate: duplicatesPredicate, value: true }]])
            );

            this.setState({
                disabledForm: true,
            });
        }
    };

    private onDoppelErfassungConfirm = () => {
        this.props.replaceFilters(this.filtersSnapshot);
        this.setState({ disabledForm: false }, () => {
            setTimeout(() => this.inlineFormRef.current.focusAfterRechnung(), 200);
        });
    };
    protected getInlineForm() {
        const formProps = this.getInlineFormProps();
        return (
            <GenericRecordForm
                {...formProps}
                onRechnungsnummerBlur={this.onRechnungsnummerBlurString}
                showDoppelErfassungAlert={this.state.disabledForm}
                onDoppelErfassungConfirm={this.onDoppelErfassungConfirm}
            />
        );
    }

    render(): React.ReactNode {
        return this.getFormPageWithLeftTable();
    }
}

export const ERView = memo(function ERView() {
    return (
        <ERProvider>
            <GenericTableViewCtxWrapper Component={RechnungenViewClass} />
        </ERProvider>
    );
});
