import { transactionFieldsDict } from "@banks/configs/constants/transactionFieldsDict";

export const getIbanKey = (
    fieldMatching: Record<string, keyof typeof transactionFieldsDict | "unmapped">
): string | undefined => {
    let ibanKey: string;
    Object.entries(fieldMatching).forEach(el => {
        if (el[1] === "myBankIban") {
            ibanKey = el[0];
        }
    });

    return ibanKey;
};
