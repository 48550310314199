import "./FileUploader.css";

import { InboxOutlined } from "@ant-design/icons";
import { Button, Upload, type UploadProps } from "antd";
import { type UploadChangeParam } from "antd/es/upload";
import React, { type FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";

import { DocumentsApi } from "@dms/scripts/DocumentsApi/DocumentsApi";

import { HadUploadedFiles } from "./components/HadBeenUploaded/HadUploadedFiles";
import { HasBeenUploadedFiles } from "./components/HasBeenUploaded/HasBeenUploadedFiles";
import { UploadFileList } from "./components/IsUploadingFiles/UploadFileList";
import { NotUniqFileList } from "./components/NotUniqFiles/NotUniqFileList";
import { NotValidFiles } from "./components/NotValidFiles/NotValidFiles";
import { FileUploaderContext } from "./context/FileUploaderContext";
import { hashCalculate } from "../../../scripts/infrastructure/helpers/hashCalculate";
import { debounce } from "@dms/scripts/helpers";

interface IProps {
    onReady: () => void;
}

const { Dragger } = Upload;

const propsUpload: UploadProps = {
    name: "file",
    multiple: true,
    showUploadList: false,
    beforeUpload: () => false,
    fileList: [],
    capture: undefined,
};

export const FileUploader: FC<IProps> = ({ onReady }) => {
    const [notValidFiles, setNotValidFiles] = useState<UploadChangeParam[] | []>([]);
    const [innerHeight, setInnerHeight] = useState(window.innerHeight);

    const {
        setAllFilesInfo,
        uniqFiles,
        uploadedFiles,
        isDisabled,
        notUniqFiles,
        setUploadedFiles,
        setAddedFilesCount,
        setIsDisabled,
        hadUploadedFiles,
        setHadUploadedFiles,
        isDndFile,
        setIsDndFile,
    } = useContext(FileUploaderContext);

    const handleResize = useCallback(
        debounce(() => setInnerHeight(window.innerHeight), 200),
        []
    );

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const height = useMemo((): number | undefined => {
        if (isDndFile || (!uploadedFiles && !hadUploadedFiles)) {
            return innerHeight * 0.65;
        }
    }, [innerHeight, isDndFile, uploadedFiles, hadUploadedFiles]);

    const handleResetNotValidFiles = () => {
        if (!isDisabled) {
            setNotValidFiles([]);
        }
    };

    const handleChange = async (info: UploadChangeParam<any>) => {
        setIsDndFile(false);

        if (info.file.type !== "application/pdf") {
            setNotValidFiles(prev => [...prev, info]);
            return;
        }

        const isValidPDF = await DocumentsApi.checkPDF(info.file);
        if (!isValidPDF) {
            setNotValidFiles(prev => [...prev, info]);
            return;
        }

        await handleCalcHash(info);
    };

    const handleCalcHash = async (info: UploadChangeParam<any>) => {
        setUploadedFiles(undefined);
        setHadUploadedFiles(undefined);
        setAddedFilesCount(prev => prev + 1);
        setIsDisabled(true);

        const hash = await hashCalculate(info.file);

        setAllFilesInfo(prev => {
            return [
                ...prev,
                {
                    ...info,
                    hash,
                },
            ];
        });
    };

    return (
        <>
            <div onClick={handleResetNotValidFiles}>
                <Dragger
                    {...propsUpload}
                    onChange={handleChange}
                    onDrop={handleResetNotValidFiles}
                    disabled={isDisabled}
                    height={height}
                    className={"BinaleFileUploader__draggerFullHeight"}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                        <FormattedMessage id="app.dms.clickOrDrug" />
                    </p>
                    <p className="ant-upload-hint">
                        <FormattedMessage id="app.dms.supportFor" />
                    </p>
                </Dragger>
            </div>
            {notValidFiles.length ? (
                <div style={{ marginTop: "15px" }}>{<NotValidFiles notValidFiles={notValidFiles} />}</div>
            ) : null}
            {hadUploadedFiles && (
                <div style={{ marginTop: "15px" }}>
                    {" "}
                    <HadUploadedFiles />
                </div>
            )}
            {uploadedFiles && (
                <div style={{ marginTop: "15px" }}>
                    {" "}
                    <HasBeenUploadedFiles />
                </div>
            )}
            {notUniqFiles && (
                <div style={{ marginTop: "15px" }}>
                    <NotUniqFileList />
                </div>
            )}
            {uniqFiles && (
                <div style={{ marginTop: "15px" }}>
                    {" "}
                    <UploadFileList />
                </div>
            )}
            {(uniqFiles || notUniqFiles || uploadedFiles || hadUploadedFiles || notValidFiles.length > 0) && (
                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "40px" }}>
                    <Button type={"primary"} disabled={isDisabled} onClick={onReady}>
                        <FormattedMessage id="app.button.done" />
                    </Button>
                </div>
            )}
        </>
    );
};
