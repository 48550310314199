import React, { useContext, useEffect } from "react";
import { Bu, Periods } from "@binale-tech/shared";
import { CloseOutlined } from "@ant-design/icons";
import { Form, Input } from "antd";
import { FormattedMessage } from "react-intl";
import { SelectValue } from "antd/es/select";

import BinaleForm from "appearance/components/shared/form/BinaleForm";
import { CompanyContext, YearPeriodContext } from "scripts/context/CompanyContext";
import { BuContext } from "scripts/context/BuContext";
import { KontoContext } from "scripts/context/KontoEntitiesProvider";
import { BuTaxesSKR } from "scripts/models/BuTaxUtils";
import { GenericRecordProperties, TableProperties } from "scripts/core/Product";
import { useCategoriesByYearMonth } from "scripts/infrastructure/hooks";

import {
    AntButtonBlock,
    BruttoInputBlock,
    CombinedComboboxBlock,
    SelectBlockAnt,
    TagComboboxBlock,
} from "../../../../components/shared/form/FormBlocks";
import { GenericRecord } from "../../../../../scripts/models";
import {
    defaultPredicates,
    GenericToolbarFilters,
} from "../../../../../scripts/context/tableViewContext/tableViewFilters";
import { TableColumns } from "../../../../columns/ColumnConfig";
import { TableExportType } from "../../../../../scripts/exporters/Exporters";
import {
    TableFiltersContext,
    TableFiltersControlContext,
    TableViewContext,
} from "scripts/context/tableViewContext/tableViewContext";
import { ToolbarComponents } from "appearance/components/toolbar/components";

import { Layout } from "../../../../components/shared/Layout";
import "./ToolbarAuswertung.css";

export interface IAuswertungToolbarProps {
    onDownload?: (type: TableExportType, columns?: string[], timeframe?: { from?: Date; to?: Date }) => Promise<void>;
    tableColumns?: TableColumns<GenericRecord>;
}
export const CommonAuswertungToolbar: React.FC<IAuswertungToolbarProps> = props => {
    const { companyBuTimeframes } = React.useContext(BuContext);
    const { productKey, view } = React.useContext(TableViewContext);
    const { companyGQL, yearConfig, programSettingsProvider } = React.useContext(CompanyContext);
    const { creditors, debitors } = React.useContext(KontoContext);
    const { year, period, onChangeYear, onChangePeriod } = useContext(YearPeriodContext);
    const { filters } = useContext(TableFiltersContext);
    const { setFilter, setFilters, resetFilters } = useContext(TableFiltersControlContext);
    const accountingYears = companyGQL?.accountingYears || [];

    const settings = programSettingsProvider(productKey);
    const { userCategories } = useCategoriesByYearMonth();

    const onUstChange = (buString: string) => {
        const bu =
            buString === null || buString === undefined || buString === "all" ? null : (Number(buString) as Bu.Bu);
        setFilter("bu", {
            predicate: defaultPredicates.bu(bu),
            value: bu,
        });
    };

    const onKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === "Esc" || e.key === "Escape") {
            return resetFilters();
        }
    };

    const taxList = BuTaxesSKR.listBuTaxesForYearPeriod(yearConfig?.skr, year, 1, companyBuTimeframes);

    const buOptions: SelectValue[] = taxList.map(v => ({
        key: v.bu.toString(),
        value: v.bu,
        label: v.bu === Bu.Bu.KU ? "KU" : v.text,
    }));

    buOptions.unshift({ key: "all", label: "Alle", value: undefined });

    const onDownload = (type: TableExportType, columns: string[]) => {
        if (props.onDownload) {
            const filterYear = filters.get("year").value;
            const filterPeriod = filters.get("period")?.value;
            if (!Number.isFinite(filterPeriod)) {
                return props.onDownload(type, columns, {
                    from: new Date(filterYear, 0, 1),
                    to: new Date(filterYear, 12, 1),
                });
            }
            const { month, day } = Periods.getMonthAndDay(filterPeriod);
            return props.onDownload(type, columns, {
                from: new Date(filterYear, month, day ?? 1),
                to: new Date(filterYear, day ? month : month + 1, day ? day + 1 : 0),
            });
        }
        return Promise.resolve();
    };

    useEffect(() => {
        setFilters([
            ["year", { predicate: defaultPredicates.year(year), value: year }],
            ["period", { predicate: defaultPredicates.period(period), value: period }],
        ]);
    }, [setFilters, year, period]);

    const exportColumns: string[] = [
        TableProperties.ComputedNr,
        GenericRecordProperties.RecordDatum,
        GenericRecordProperties.RecordCategoryCreditorNum,
        GenericRecordProperties.RecordCategoryCreditorName,
        GenericRecordProperties.ItemCategoryCreditorNum,
        GenericRecordProperties.ItemCategoryCreditorName,
        GenericRecordProperties.RecordBrutto,
        GenericRecordProperties.ItemBuchungstext,
        GenericRecordProperties.ItemKS,
    ];

    return (
        <BinaleForm className="GenericToolbar AuswertungToolbar" onKeyDown={onKeyDown} disableEnterNavigation>
            <Layout style={{ flexWrap: "nowrap" }}>
                <Form.Item label="&nbsp;">
                    <ToolbarComponents.DateSection
                        period={period}
                        year={year}
                        years={accountingYears}
                        withAll
                        withArrows
                        onPeriodChange={onChangePeriod}
                        onYearChange={onChangeYear}
                    />
                </Form.Item>
                <CombinedComboboxBlock
                    showHint
                    allowClear
                    placeholder={<FormattedMessage id="app.global.not_selected" />}
                    items={[...debitors, ...creditors, ...userCategories]}
                    value={filters.get(GenericToolbarFilters.Konto)?.value}
                    onChange={value =>
                        setFilter(GenericToolbarFilters.Konto, {
                            predicate: defaultPredicates.konto(value),
                            value,
                        })
                    }
                />
                <ToolbarComponents.NumField
                    value={filters.get("num")?.value}
                    setValue={value =>
                        setFilter("num", {
                            predicate: defaultPredicates.num(value),
                            value,
                        })
                    }
                />
                {settings.useKs && (
                    <TagComboboxBlock
                        allowClear
                        placeholder={<FormattedMessage id="app.global.not_selected" />}
                        value={filters.get("tag")?.value}
                        onChange={value =>
                            setFilter("tag", {
                                predicate: defaultPredicates.tag(value),
                                value,
                            })
                        }
                    />
                )}
                <BruttoInputBlock
                    value={filters.get("brutto")?.value}
                    onChange={({ amount: value }) =>
                        setFilter("brutto", {
                            predicate: defaultPredicates.brutto(value),
                            value,
                        })
                    }
                />
                <SelectBlockAnt
                    className="AuswertungToolbar__Select"
                    label={<FormattedMessage id="app.fields.vat%" />}
                    value={typeof filters.get("bu")?.value === "number" ? filters.get("bu").toString() : "all"}
                    onChange={onUstChange}
                    options={buOptions}
                />
                {!settings.hideBuchText && (
                    <Form.Item label={<FormattedMessage id="app.fields.buchtext" />} style={{ width: 130 }}>
                        <Input
                            value={filters.get("text")?.value}
                            onChange={e =>
                                setFilter("text", {
                                    predicate: defaultPredicates.text(e.target.value),
                                    value: e.target.value,
                                })
                            }
                            onFocus={e => e.target.select()}
                        />
                    </Form.Item>
                )}
                {settings.useBuchText2 && (
                    <Form.Item label={<FormattedMessage id="app.fields.buchtext2" />} style={{ width: 130 }}>
                        <Input
                            value={filters.get("text2")?.value}
                            onChange={e =>
                                setFilter("text2", {
                                    predicate: defaultPredicates.text2(e.target.value),
                                    value: e.target.value,
                                })
                            }
                            onFocus={e => e.target.select()}
                        />
                    </Form.Item>
                )}
                {settings.useBelegfeld2 && (
                    <Form.Item label={<FormattedMessage id="app.fields.internal_num" />} style={{ width: 130 }}>
                        <Input
                            value={filters.get("belegfeld2")?.value}
                            onChange={e =>
                                setFilter("belegfeld2", {
                                    predicate: defaultPredicates.belegfeld2(e.target.value),
                                    value: e.target.value,
                                })
                            }
                            onFocus={e => e.target.select()}
                        />
                    </Form.Item>
                )}
                <AntButtonBlock icon={<CloseOutlined />} onClick={resetFilters} shape={"circle"} />
                <div style={{ flexGrow: 1 }} />
                <Form.Item label={<span>&nbsp;</span>}>
                    <ToolbarComponents.ExportBtn
                        tableColumns={props.tableColumns}
                        onDownload={onDownload}
                        exportColumns={exportColumns}
                    />
                </Form.Item>
                <Form.Item label={<span>&nbsp;</span>}>
                    <ToolbarComponents.AnsichtBtn tableColumns={props.tableColumns} view={view} />
                </Form.Item>
            </Layout>
        </BinaleForm>
    );
};
