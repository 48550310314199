import React, { useMemo } from "react";
import { AccountMaps, KontoContext } from "../KontoEntitiesProvider";
import { BuContext } from "../BuContext";
import { BuTaxesSKR } from "../../models/BuTaxUtils";
import { Category, Creditor, Debitor, Tag } from "scripts/models";
import { CompanyContext } from "../CompanyContext";
import {
    DataSnapshot,
    Reference,
    refRecordsBank,
    refRecordsDeb,
    refRecordsER,
    refRecordsErA,
    refRecordsFE,
    refRecordsKb,
    refRecordsLA,
    refRecordsPos,
} from "scripts/api/firebase/firebaseRootRefs";
import { GQL } from "@binale-tech/shared";
import {
    GenericRecord,
    RecordBank,
    RecordDeb,
    RecordER,
    RecordERAnzahlung,
    RecordFE,
    RecordKB,
    RecordLA,
    RecordPOS,
} from "scripts/models/GenericRecord";
import { Subject } from "rxjs";
import { UserContext } from "../UserProvider";
import { auth } from "scripts/api/firebase/firebase";
import { child, get, onChildAdded, onValue, orderByPriority, query } from "firebase/database";
import { logger } from "scripts/infrastructure/logger";
import { useEnrichAccounts } from "../hooks/useEnrichAccounts";

export type RecordsCtxData = {
    recordsER: RecordsData;
    recordsAZ: RecordsData;
    recordsFE: RecordsData;
    recordsPOS: RecordsData;
    recordsLA: RecordsData;
    recordsDeb: RecordsData;
    recordsBank: GroupedRecordsData;
    recordsKB: GroupedRecordsData;
    allRecords: AllRecordsData;
};

type RecordsData = {
    list: GenericRecord[];
    map: Map<string, GenericRecord>;
    snapshot?: DataSnapshot;
    isLoaded: boolean;
};

export type AllRecordsData = Omit<RecordsData, "snapshot"> & {
    groupsReverseIndex: Map<string, string>;
};
type GroupedRecordsData = {
    groups: Map<string, RecordsData>;
    combinedList: GenericRecord[];
    isGroupListLoaded: boolean;
};
type UnifiedRecordsDataPayload = {
    records?: {
        productId: GQL.IProductKey;
        initFn: (v: any) => GenericRecord;
        data?: {
            groupId?: string;
            recordsSnapshot: DataSnapshot | null;
            isLoaded: boolean;
        };
        groups?: {
            list: string[];
            isLoaded: boolean;
        };
    };
    accounts?: AccountMaps;
};

const defaultRecordsData: RecordsData = {
    list: [],
    map: new Map(),
    isLoaded: false,
};
const defaultGroupedRecordsData: GroupedRecordsData = {
    combinedList: [],
    groups: new Map(),
    isGroupListLoaded: false,
};
const defaultAllRecordsData: AllRecordsData = {
    ...defaultRecordsData,
    groupsReverseIndex: new Map(),
};

const defaultContextValue = {
    recordsER: defaultRecordsData,
    recordsAZ: defaultRecordsData,
    recordsFE: defaultRecordsData,
    recordsPOS: defaultRecordsData,
    recordsLA: defaultRecordsData,
    recordsDeb: defaultRecordsData,
    recordsBank: defaultGroupedRecordsData,
    recordsKB: defaultGroupedRecordsData,
    allRecords: defaultAllRecordsData,
};
export const getDefaultRecordsValue = () => defaultContextValue;
export const RecordsContext = React.createContext<RecordsCtxData>(defaultContextValue);

const snapListToData = (
    snap: DataSnapshot,
    init: (v: any) => GenericRecord,
    year?: number
): Pick<RecordsData, "list" | "map"> => {
    // year = year ?? new Date().getFullYear();
    // const yearsToLoad = new Set([year - 1, year]);
    const v = snap && snap.exists() ? snap.val() : {};
    const map = new Map();
    Object.entries(v || {}).forEach(([key, value]) => {
        // if (yearsToLoad.has((value as any).year)) {
        map.set(key, init(value));
        // }
    });
    const list = Array.from(map.values());
    return { list, map };
};

export const getAllRecordsData: (
    data: Omit<React.ContextType<typeof RecordsContext>, "allRecords">
) => React.ContextType<typeof RecordsContext>["allRecords"] = ({
    recordsER,
    recordsAZ,
    recordsFE,
    recordsPOS,
    recordsLA,
    recordsDeb,
    recordsKB,
    recordsBank,
}) => {
    const list: GenericRecord[] = [
        ...recordsER.list,
        ...recordsAZ.list,
        ...recordsFE.list,
        ...recordsPOS.list,
        ...recordsLA.list,
        ...recordsDeb.list,
        ...recordsKB.combinedList,
        ...recordsBank.combinedList,
    ];
    const map = new Map();
    list.forEach(r => {
        map.set(r.key, r);
    });
    const groupsReverseIndex = new Map<string, string>();
    recordsKB.groups.forEach((payload, groupKey) => {
        payload.list.forEach(record => {
            groupsReverseIndex.set(record.key, groupKey);
        });
    });
    recordsBank.groups.forEach((payload, groupKey) => {
        payload.list.forEach(record => {
            groupsReverseIndex.set(record.key, groupKey);
        });
    });
    return { list, map, groupsReverseIndex, isLoaded: true };
};

const snapKBToData = (
    snapshot: DataSnapshot,
    processRecord: (v: GenericRecord) => GenericRecord,
    year?: number
): Pick<RecordsData, "list" | "map"> => {
    // year = year ?? new Date().getFullYear();
    // const yearsToLoad = new Set([year - 1, year]);
    const dataObj = snapshot?.val() || {};
    const list = Object.keys(dataObj)
        // .filter(k => yearsToLoad.has(dataObj[k].year))
        .map((k: string) => {
            const record = dataObj[k];
            record.priority = snapshot.child(k).priority;
            return processRecord(new RecordKB(record));
        });
    list.sort((a, b) => {
        const ay = a.date.getFullYear();
        const by = b.date.getFullYear();
        if (ay !== by) {
            return ay - by;
        }
        const ap = a.period;
        const bp = b.period;
        if (ap !== bp) {
            return ap - bp;
        }
        return Number(a.priority) - Number(b.priority);
    });
    const map = new Map();
    list.forEach(gr => map.set(gr.key, gr));
    return { list, map };
};
const snapBankToData = (
    snapshot: DataSnapshot,
    processRecord: (v: GenericRecord) => GenericRecord,
    year?: number
): Pick<RecordsData, "list" | "map"> => {
    // year = year ?? new Date().getFullYear();
    // const yearsToLoad = new Set([year - 1, year]);
    const dataObj = snapshot?.val() || {};
    const list = Object.keys(dataObj)
        // .filter(k => yearsToLoad.has(dataObj[k].year))
        .map((k: string) => {
            return processRecord(new RecordBank(dataObj[k]));
        });
    list.sort((a, b) => {
        const ay = a.date.getFullYear();
        const by = b.date.getFullYear();
        if (ay !== by) {
            return ay - by;
        }
        const ap = a.period;
        const bp = b.period;
        if (ap !== bp) {
            return ap - bp;
        }
        const at = a.date.getTime();
        const bt = b.date.getTime();
        if (at !== bt) {
            return at - bt;
        }
        return a.key.localeCompare(b.key);
    });
    const map = new Map();
    list.forEach(gr => map.set(gr.key, gr));
    return { list, map };
};

const updateGroupedState = (
    groupKey: string,
    newData: Pick<RecordsData, "list" | "map">,
    prevState: GroupedRecordsData
) => {
    const groups = new Map(prevState.groups);
    groups.set(groupKey, { ...newData, isLoaded: true });
    const combinedList: GenericRecord[] = [];
    groups.forEach(v => {
        combinedList.push(...v.list);
    });
    return { combinedList, groups };
};
type ForwardOpts = {
    ref: Reference;
    companyId: string;
    productId: GQL.IProductKey;
    init: (v: any) => GenericRecord;
    sub: Subject<UnifiedRecordsDataPayload>;
    // name: string;
};
const forwardToPipe = (opts: ForwardOpts) => {
    const { ref, companyId, init, sub, productId } = opts;
    const fbRef = child(ref, companyId);
    let unsubscribe: ReturnType<typeof onValue>;
    get(fbRef)
        .then(snap => {
            sub.next({ records: { data: { recordsSnapshot: snap, isLoaded: true }, productId, initFn: init } });
            unsubscribe = onValue(fbRef, recordsSnapshot => {
                sub.next({ records: { data: { recordsSnapshot, isLoaded: true }, productId, initFn: init } });
            });
        })
        .catch((e: Error) => {
            if (e.message === "Permission denied") {
                sub.next({ records: { data: { recordsSnapshot: null, isLoaded: true }, productId, initFn: init } });
            }
        });

    return () => {
        unsubscribe && unsubscribe();
        sub.next({ records: { data: { recordsSnapshot: null, isLoaded: false }, productId, initFn: init } });
    };
};

type ForwardGroupOpts = ForwardOpts & {
    orderByPriority?: boolean;
};

const forwardGroupedDataToPipe = (opts: ForwardGroupOpts) => {
    const fbRef = child(opts.ref, opts.companyId);
    const fbRefStr = fbRef.toString();
    const unsubscribes: ReturnType<typeof onChildAdded>[] = [];
    auth.currentUser?.getIdToken().then(accessToken => {
        // here we fetch keys of the collection
        // structure in the DB is following
        // receipts
        // -- uuid1
        // -- uuid2
        // we need this 'uuid1' and 'uuid2'
        fetch(`${fbRefStr}/.json?shallow=true&auth=${accessToken}`)
            .then(res => {
                if (res.status === 401) {
                    throw new Error("Permission denied");
                }
                return res.json();
            })
            .then(res => {
                const keys = Object.keys(res || {});

                opts.sub.next({
                    records: { groups: { list: keys, isLoaded: true }, productId: opts.productId, initFn: opts.init },
                });
                // when there are groups already, then child_added will react to all of them on initialisation
                const subscriber = (groupRef: DataSnapshot) => {
                    const groupKey = groupRef.key;
                    const childRef = child(fbRef, groupKey);
                    const ref = opts.orderByPriority ? query(childRef, orderByPriority()) : childRef;

                    const unsubscribe = onValue(ref, (recordsSnapshot: DataSnapshot) => {
                        opts.sub.next({
                            records: {
                                productId: opts.productId,
                                initFn: opts.init,
                                data: {
                                    isLoaded: true,
                                    recordsSnapshot,
                                    groupId: groupKey,
                                },
                            },
                        });
                    });
                    unsubscribes.push(unsubscribe);
                };
                const unsubscribe = onChildAdded(fbRef, subscriber);
                unsubscribes.push(unsubscribe);
            })
            .catch((e: Error) => {
                if (e.message === "Permission denied") {
                    opts.sub.next({
                        records: { groups: { list: [], isLoaded: true }, productId: opts.productId, initFn: opts.init },
                    });
                } else {
                    logger.crit(e);
                }
            });
    });
    return () => {
        unsubscribes.forEach(unsubscribe => {
            unsubscribe();
        });
        opts.sub.next({
            records: { groups: { list: [], isLoaded: false }, productId: opts.productId, initFn: opts.init },
        });
    };
};

const NULL_COMPANY = "///___empty___///";

export const RecordsProvider: React.FC<React.PropsWithChildren> = props => {
    const { isLoaded: isCompanyLoaded, yearConfig } = React.useContext(CompanyContext);
    const { isUserDataLoaded, selectedCompany } = React.useContext(UserContext);
    const { accountMaps } = React.useContext(KontoContext);

    const unifiedDataRef = React.useRef(new Subject<UnifiedRecordsDataPayload>());
    const [recordsER, setRecordsER] = React.useState<RecordsData>(defaultRecordsData);
    const [recordsAZ, setRecordsAZ] = React.useState<RecordsData>(defaultRecordsData);
    const [recordsFE, setRecordsFE] = React.useState<RecordsData>(defaultRecordsData);
    const [recordsPOS, setRecordsPOS] = React.useState<RecordsData>(defaultRecordsData);
    const [recordsLA, setRecordsLA] = React.useState<RecordsData>(defaultRecordsData);
    const [recordsDeb, setRecordsDeb] = React.useState<RecordsData>(defaultRecordsData);
    const [recordsBank, setRecordsBank] = React.useState<GroupedRecordsData>(defaultGroupedRecordsData);
    const [recordsKB, setRecordsKB] = React.useState<GroupedRecordsData>(defaultGroupedRecordsData);

    const [allRecords, setAllRecords] = React.useState<AllRecordsData>(defaultAllRecordsData);

    const enrichAccounts = useEnrichAccounts();

    React.useEffect(() => {
        unifiedDataRef.current.next({ accounts: accountMaps });
    }, [accountMaps]);

    const companyUuid = useMemo(() => {
        if (!isUserDataLoaded) {
            return null;
        }
        if (!selectedCompany) {
            return NULL_COMPANY;
        }
        if (isCompanyLoaded) {
            return selectedCompany;
        }
        return null;
    }, [isUserDataLoaded, selectedCompany, isCompanyLoaded]);
    const resetData = React.useCallback((isLoaded: boolean) => {
        console.log("resetting data", { isLoaded });
        setRecordsER({ ...defaultRecordsData, isLoaded });
        setRecordsAZ({ ...defaultRecordsData, isLoaded });
        setRecordsFE({ ...defaultRecordsData, isLoaded });
        setRecordsPOS({ ...defaultRecordsData, isLoaded });
        setRecordsLA({ ...defaultRecordsData, isLoaded });
        setRecordsDeb({ ...defaultRecordsData, isLoaded });
        setRecordsBank({ ...defaultGroupedRecordsData, isGroupListLoaded: isLoaded });
        setRecordsKB({ ...defaultGroupedRecordsData, isGroupListLoaded: isLoaded });
    }, []);

    React.useEffect(() => {
        const destructors: (() => void)[] = [];
        logger.log("Loading company data for:", companyUuid);
        destructors.push(() => resetData(false));
        if (companyUuid === NULL_COMPANY) {
            resetData(true);
        } else if (companyUuid) {
            destructors.push(
                forwardToPipe({
                    ref: refRecordsER,
                    companyId: companyUuid,
                    productId: GQL.IProductKey.Er,
                    sub: unifiedDataRef.current,
                    init: v => new RecordER(v),
                })
            );
            destructors.push(
                forwardToPipe({
                    ref: refRecordsErA,
                    companyId: companyUuid,
                    productId: GQL.IProductKey.ErA,
                    sub: unifiedDataRef.current,
                    init: v => new RecordERAnzahlung(v),
                })
            );
            destructors.push(
                forwardToPipe({
                    ref: refRecordsFE,
                    companyId: companyUuid,
                    productId: GQL.IProductKey.Fe,
                    sub: unifiedDataRef.current,
                    init: v => new RecordFE(v),
                })
            );
            destructors.push(
                forwardToPipe({
                    ref: refRecordsPos,
                    companyId: companyUuid,
                    productId: GQL.IProductKey.Pos,
                    sub: unifiedDataRef.current,
                    init: v => new RecordPOS(v),
                })
            );
            destructors.push(
                forwardToPipe({
                    ref: refRecordsLA,
                    companyId: companyUuid,
                    productId: GQL.IProductKey.La,
                    sub: unifiedDataRef.current,
                    init: v => new RecordLA(v),
                })
            );
            destructors.push(
                forwardToPipe({
                    ref: refRecordsDeb,
                    companyId: companyUuid,
                    productId: GQL.IProductKey.Deb,
                    sub: unifiedDataRef.current,
                    init: v => new RecordDeb(v),
                })
            );
            destructors.push(
                forwardGroupedDataToPipe({
                    ref: refRecordsBank,
                    companyId: companyUuid,
                    productId: GQL.IProductKey.Bank,
                    sub: unifiedDataRef.current,
                    init: v => new RecordBank(v),
                })
            );
            destructors.push(
                forwardGroupedDataToPipe({
                    ref: refRecordsKb,
                    companyId: companyUuid,
                    productId: GQL.IProductKey.Kb,
                    sub: unifiedDataRef.current,
                    init: v => new RecordKB(v),
                    orderByPriority: true,
                })
            );
        }

        return () => {
            logger.log("Offloading triggered, previous id:", companyUuid);
            destructors.forEach(fn => fn());
        };
    }, [companyUuid, resetData]);

    React.useEffect(() => {
        const destructors: (() => void)[] = [];
        const unifiedSubscription = unifiedDataRef.current.subscribe(event => {
            if (event.records) {
                const { productId, groups, initFn, data } = event.records;
                if (groups) {
                    console.log("us / records / group", productId, groups.isLoaded, groups);
                    const initialisationData: Omit<GroupedRecordsData, "isGroupListLoaded"> = {
                        combinedList: [],
                        groups: new Map(groups.list.map(key => [key, defaultRecordsData])),
                    };
                    if (productId === GQL.IProductKey.Kb) {
                        setRecordsKB({ ...initialisationData, isGroupListLoaded: groups.isLoaded });
                    } else if (productId === GQL.IProductKey.Bank) {
                        setRecordsBank({ ...initialisationData, isGroupListLoaded: groups.isLoaded });
                    }
                } else if (data) {
                    console.log("us / records / data", productId, data.isLoaded, data);
                    const { recordsSnapshot, isLoaded, groupId } = data;
                    if (!isLoaded) {
                        // full data reset is happenning synchronously in
                        return;
                    }

                    const getNonGroupRecordsData = () => {
                        const processedData: Pick<RecordsData, "list" | "map"> = recordsSnapshot
                            ? snapListToData(recordsSnapshot, initFn, yearConfig?.year)
                            : { list: [], map: new Map() };
                        const recordsData: RecordsData = {
                            snapshot: recordsSnapshot,
                            list: processedData.list,
                            map: processedData.map,
                            isLoaded: true,
                        };
                        recordsData.list.forEach(enrichAccounts);
                        return recordsData;
                    };
                    if (productId === GQL.IProductKey.Er) {
                        setRecordsER(getNonGroupRecordsData());
                    } else if (productId === GQL.IProductKey.ErA) {
                        setRecordsAZ(getNonGroupRecordsData());
                    } else if (productId === GQL.IProductKey.Deb) {
                        setRecordsDeb(getNonGroupRecordsData());
                    } else if (productId === GQL.IProductKey.Fe) {
                        setRecordsFE(getNonGroupRecordsData());
                    } else if (productId === GQL.IProductKey.Pos) {
                        setRecordsPOS(getNonGroupRecordsData());
                    } else if (productId === GQL.IProductKey.La) {
                        setRecordsLA(getNonGroupRecordsData());
                    } else if (productId === GQL.IProductKey.Kb) {
                        setRecordsKB(prevState => {
                            const { list, map } = snapKBToData(recordsSnapshot, enrichAccounts, yearConfig?.year);
                            return {
                                ...updateGroupedState(groupId, { list, map }, prevState),
                                isGroupListLoaded: true,
                            };
                        });
                    } else if (productId === GQL.IProductKey.Bank) {
                        setRecordsBank(prevState => {
                            const { list, map } = snapBankToData(recordsSnapshot, enrichAccounts, yearConfig?.year);
                            return {
                                ...updateGroupedState(groupId, { list, map }, prevState),
                                isGroupListLoaded: true,
                            };
                        });
                    }
                }
            } else if (event.accounts) {
                console.log("us / accounts", event.accounts);
                const reprocessFn = (prev: RecordsData): RecordsData => {
                    prev.list.forEach(enrichAccounts);
                    return {
                        ...prev,
                    };
                };
                setRecordsER(reprocessFn);
                setRecordsAZ(reprocessFn);
                setRecordsDeb(reprocessFn);
                setRecordsFE(reprocessFn);
                setRecordsPOS(reprocessFn);
                setRecordsLA(reprocessFn);
                setRecordsBank(prev => {
                    prev.combinedList.forEach(enrichAccounts);
                    return { ...prev };
                });
                setRecordsKB(prev => {
                    prev.combinedList.forEach(enrichAccounts);
                    return { ...prev };
                });
            }
        });
        destructors.push(() => unifiedSubscription.unsubscribe());

        return () => {
            console.log(">>> destructing unified subscription");
            destructors.forEach(fn => fn());
        };
    }, [enrichAccounts, yearConfig?.year]);

    React.useEffect(() => {
        const aggregatedData = {
            recordsER,
            recordsAZ,
            recordsFE,
            recordsPOS,
            recordsLA,
            recordsDeb,
            recordsBank,
            recordsKB,
        };
        setAllRecords(getAllRecordsData(aggregatedData));
    }, [recordsER, recordsAZ, recordsFE, recordsPOS, recordsLA, recordsDeb, recordsBank, recordsKB]);

    const recordsData: RecordsCtxData = React.useMemo(
        () => ({
            recordsER,
            recordsAZ,
            recordsFE,
            recordsPOS,
            recordsLA,
            recordsDeb,
            recordsBank,
            recordsKB,
            allRecords,
        }),
        [recordsER, recordsAZ, recordsFE, recordsPOS, recordsLA, recordsDeb, recordsBank, recordsKB, allRecords]
    );

    return <RecordsContext.Provider value={recordsData}>{props.children}</RecordsContext.Provider>;
};
