import { useGqlMutator } from "../../../scripts/graphql/useGqlMutator";
import {
    invoiceCreate,
    invoiceUpdate,
    invoiceDelete,
} from "../../../scripts/context/mutations/invoicesMutation.graphql";
import { TInvoiceDelete, TInvoicesValues } from "@inv/types";

export class InvoicesApi {
    private static _companyId: string;
    private static _mutator: ReturnType<typeof useGqlMutator>;

    static set companyId(id: string) {
        this._companyId = id;
    }

    static set mutator(mutator: ReturnType<typeof useGqlMutator>) {
        this._mutator = mutator;
    }

    static invoiceCreate = async (input: TInvoicesValues) => {
        await this._mutator.mutate({
            mutation: invoiceCreate,
            input: { ...input, companyId: this._companyId },
            hideMessages: true,
        });
    };

    static invoiceUpdate = async (input: TInvoicesValues) => {
        await this._mutator.mutate({
            mutation: invoiceUpdate,
            input: { ...input, companyId: this._companyId },
            hideMessages: true,
        });
    };

    static invoiceDelete = async (input: TInvoiceDelete) => {
        const { id } = input;
        await this._mutator.mutate({
            mutation: invoiceDelete,
            input: { id, companyId: this._companyId },
            hideMessages: true,
        });
    };
}
