import React, { createContext, FC, ReactNode, useState } from "react";
import { ITotalState, TInvoiceFormColumnsConfig } from "@inv/types";
import { InvoiceFormColumnsConfig } from "@inv/modules/InvoiceTableModule/config/invoiceFormColumnsConfig";

type TValue = {
    columnsConfig: TInvoiceFormColumnsConfig;
    isOpenSettingsCol: boolean;
    totalState: ITotalState;
    currency: string;
};

type TActionValue = {
    setColumnsConfig: (newValue: TInvoiceFormColumnsConfig) => void;
    setIsOpenSettingsCol: (newValue: boolean) => void;
    setTotalState: (newValue: ITotalState) => void;
    setCurrency: (newValue: string) => void;
};

const initialValue: TValue = {
    columnsConfig: InvoiceFormColumnsConfig.InitColumnConfig,
    isOpenSettingsCol: false,
    totalState: {
        subtotal: null,
        tax0: null,
        tax7: null,
        tax19: null,
        total: null,
    },
    currency: null,
};

const initialActionValue = {
    setColumnsConfig: () => {},
    setIsOpenSettingsCol: () => {},
    setTotalState: () => {},
    setCurrency: () => {},
};

export const InvoiceTableContext = createContext<TValue>(initialValue);
export const InvoiceTableControlContext = createContext<TActionValue>(initialActionValue);

type TProps = {
    children?: ReactNode;
};

export const InvoiceTableContextProvider: FC<TProps> = ({ children }) => {
    const [columnsConfig, setColumnsConfig] = useState<TInvoiceFormColumnsConfig>(initialValue.columnsConfig);
    const [isOpenSettingsCol, setIsOpenSettingsCol] = useState<boolean>(false);
    const [totalState, setTotalState] = useState<ITotalState>(initialValue.totalState);
    const [currency, setCurrency] = useState<string | null>(null);

    const value = {
        columnsConfig,
        isOpenSettingsCol,
        totalState,
        currency,
    };

    const actions = {
        setColumnsConfig,
        setIsOpenSettingsCol,
        setTotalState,
        setCurrency,
    };

    return (
        <InvoiceTableContext.Provider value={value}>
            <InvoiceTableControlContext.Provider value={actions}>{children}</InvoiceTableControlContext.Provider>
        </InvoiceTableContext.Provider>
    );
};
