import React, { FC } from "react";
import { Card } from "antd";
import { Link } from "react-router-dom";

import { AppRoutes } from "scripts/routing/routeConstants";

interface Props {
    title?: React.ReactNode;
    width?: number;
}

export const AuthLayout: FC<React.PropsWithChildren<Props>> = ({ title, children, width }) => {
    return (
        <div className="auth__container">
            <div className="auth__background_image" />
            <div className="auth__container__card">
                <Card title={title} style={{ width: width ?? 500 }}>
                    {children}
                </Card>
            </div>
            <div className="auth__legal_links">
                <Link style={{ marginRight: 15 }} to={AppRoutes.impressum}>
                    Impressum
                </Link>
                <Link style={{ marginRight: 15 }} to={AppRoutes.haftungsausschluss}>
                    Haftungsausschluss
                </Link>
                <Link style={{ marginRight: 15 }} to={AppRoutes.datenschutz}>
                    Datenschutz
                </Link>
            </div>
        </div>
    );
};
