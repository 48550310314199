import React, { createContext, FC, ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { child, get, onValue } from "firebase/database";
import { refBanks } from "../api/firebase/firebaseRootRefs";
import { logger } from "../infrastructure/logger";

import { TBanksData } from "@banks/types";
import { useGqlMutator } from "../graphql/useGqlMutator";
import { BanksApi } from "@banks/scripts/api";
import { CompanyContext } from "./CompanyContext";
import { BanksAppContextProvider, ParseContextProvider } from "@banks/scripts/context";

type TValue = {
    banksData: TBanksData;
    companyId: string;
};

type TActionValue = {};

export const BanksContext = createContext<TValue>({} as TValue);
export const BanksControlContext = createContext<TActionValue>({} as TActionValue);

type TProps = {
    children: ReactNode;
};

export const BanksContextProvider: FC<TProps> = ({ children }) => {
    const [banksData, setBanksData] = useState<TBanksData>();

    const mutator = useGqlMutator();
    const { companyGQL } = useContext(CompanyContext);
    const companyId = companyGQL?.id;

    useEffect(() => {
        BanksApi.mutator = mutator;
        BanksApi.companyId = companyId;
    }, [companyId, mutator]);

    useEffect(() => {
        const destructors: (() => void)[] = [];
        if (companyId) {
            const fbRef = child(refBanks, `${companyId}/banks`);
            let unsubscribe: ReturnType<typeof onValue>;
            get(fbRef)
                .then(snap => {
                    setBanksData(snap.val() || {});
                    unsubscribe = onValue(fbRef, subscriptionSnap => {
                        setBanksData(subscriptionSnap.val() || {});
                    });
                })
                .catch((e: Error) => {
                    if (e.message === "Permission denied") {
                        setBanksData({});
                    } else {
                        logger.crit(e, "DocumentProvider: ");
                    }
                });

            destructors.push(() => {
                unsubscribe && unsubscribe();
            });
        } else {
            setBanksData({});
        }

        return () => {
            destructors.forEach(fn => fn());
        };
    }, [companyId]);

    const value = useMemo(() => {
        return {
            banksData,
            companyId,
        };
    }, [banksData, companyId]);

    const actions = {};

    return (
        <BanksContext.Provider value={value}>
            <BanksControlContext.Provider value={actions}>{children}</BanksControlContext.Provider>
        </BanksContext.Provider>
    );
};
