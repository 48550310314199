import React, { type FC } from "react";
import { DocumentListModule } from "@dms/modules/DocumentListModule/DocumentListModule";

export const DocumentListPage: FC = () => {
    return (
        <div style={{ paddingRight: 20 }}>
            <DocumentListModule />
        </div>
    );
};
