import { createContext } from "react";
import { IGenericRecord } from "../../../../scripts/models/Interfaces";
import { useFormRefsHtml } from "../hooks/useFormRefsHtml";
import { PaymentPrototype } from "../../../../scripts/models/Payment";
import { useFormRefsData } from "../hooks/useFormRefsData";
import { GenericRecord } from "../../../../scripts/models";

export type ExternalRecordFormProps = {
    record?: IGenericRecord;

    showDoppelErfassungAlert?: boolean;
    onDoppelErfassungConfirm?: () => void;
    onRechnungsnummerBlur?: (v: string) => void;

    onClear: () => void;
    onSave: (v: IGenericRecord, payment?: PaymentPrototype) => void;
    isTemplate?: boolean;
};

type RecordFormProps = ExternalRecordFormProps & {
    isUpdating: boolean;
    yearBound: number;
    periodBound: number;
    disabled: boolean;
    isTemplate?: boolean;

    refsHtml: ReturnType<typeof useFormRefsHtml>;
    refsData: ReturnType<typeof useFormRefsData>;
};
export const RecordFormPropsContext = createContext<RecordFormProps>({
    isUpdating: false,
    record: null,
    yearBound: 0,
    periodBound: null,
    disabled: false,

    refsHtml: {} as any,
    refsData: {} as any,

    showDoppelErfassungAlert: false,
    onDoppelErfassungConfirm: () => {},
    onRechnungsnummerBlur: () => {},

    onClear: () => {},
    onSave: (v: IGenericRecord, payment?: PaymentPrototype) => {},
});
