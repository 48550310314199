import React, { FC } from "react";
import { InvoiceTableModule } from "@inv/modules/InvoiceTableModule/InvoiceTableModule";
import { InvoiceFormItemWrapper } from "@inv/components/CreateInvoicingForm/components/InvoiceFormItemWrapper";
import { InvoiceInputs, InvoiceFormBlocksTranslate } from "@inv/types";
import { useIntl } from "react-intl";
import { Flex, Form } from "antd";
import { InvoiceCurrencySelect } from "@inv/components/InvoiceTableBlock/components/InvoiceCurrencySelect";
import { GrossNetSwitch } from "@inv/components/InvoiceTableBlock/components/GrossNetSwitch/GrossNetSwitch";

export const LineItemsBlock: FC = () => {
    const intl = useIntl();

    return (
        <InvoiceFormItemWrapper title={intl.formatMessage({ id: InvoiceFormBlocksTranslate.LINE_ITEMS })}>
            <Flex style={{ width: "100%", marginBottom: 20 }} align={"center"} justify="space-between">
                <Form.Item name={InvoiceInputs.CURRENCY_CODE} style={{ marginBottom: "0" }}>
                    <InvoiceCurrencySelect />
                </Form.Item>
                <GrossNetSwitch />
            </Flex>
            <InvoiceTableModule />
        </InvoiceFormItemWrapper>
    );
};
