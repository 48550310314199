import React, { createContext, FC, ReactNode, useEffect, useState } from "react";
import { TInvoicesValues, TInvoiceTotalView } from "@inv/types";
import { GQL } from "@binale-tech/shared";

type TValue = {
    invoiceTotalView: TInvoiceTotalView;
    initialValues: TInvoicesValues;
};

type TActionValue = {
    setInvoiceTotalView: (value: TInvoiceTotalView) => void;
    setInitialValues: (value: TInvoicesValues) => void;
};

const initialValue: TValue = {
    invoiceTotalView: "Gross",
    initialValues: {
        customer: null,
        countryCode: null,
        city: null,
        address: null,
        zipCode: null,
        street: null,
        invoiceNumber: null,
        customerNumber: null,
        date: null,
        documentTitle: null,
        introductionText: null,
        deliveryDate: null,
        serviceDate: null,
        servicePeriodDays: null,
        servicePeriodMonths: null,
        currencyCode: GQL.ICurrencyCode.Eur,
        lineItems: [],
        paymentTerm: null,
        description: null,
    },
};

const initialActionValue = {
    setInvoiceTotalView: () => {},
    setInitialValues: () => {},
};

export const InvoiceFormContext = createContext<TValue>(initialValue);
export const InvoiceFormControlContext = createContext<TActionValue>(initialActionValue);

type TProps = {
    children?: ReactNode;
};

export const InvoiceFormContextProvider: FC<TProps> = ({ children }) => {
    const [invoiceTotalView, setInvoiceTotalView] = useState<TInvoiceTotalView>("Gross");
    const [initialValues, setInitialValues] = useState<TInvoicesValues>(initialValue.initialValues);

    const value = { invoiceTotalView, initialValues };
    const actions = { setInvoiceTotalView, setInitialValues };

    return (
        <InvoiceFormContext.Provider value={value}>
            <InvoiceFormControlContext.Provider value={actions}>{children}</InvoiceFormControlContext.Provider>
        </InvoiceFormContext.Provider>
    );
};
