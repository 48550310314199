export enum InvoiceColumns {
    DRAG = "drag",
    POSITION = "position",
    PRODUCTS_SERVICES = "productsServices",
    UNIT = "unit",
    QUANTITY = "quantity",
    PRICE = "price",
    DISCOUNT = "discount",
    TAX = "tax",
    TOTAL = "total",
    ACTIONS = "actions",
}

export enum NavigationKeys {
    LEFT = "ArrowLeft",
    UP = "ArrowUp",
    RIGHT = "ArrowRight",
    DOWN = "ArrowDown",
    ENTER = "Enter",
    ESCAPE = "Escape",
}

export enum InvoiceInputs {
    CUSTOMER = "customer",
    COUNTRY_CODE = "countryCode",
    CITY = "city",
    ADDRESS = "address",
    ZIP_CODE = "zipCode",
    HOUSE_NUMBER = "houseNumber",
    STREET = "street",
    INVOICE_NUMBER = "invoiceNumber",
    CUSTOMER_NUMBER = "customerNumber",
    DATE = "date",
    DOCUMENT_TITLE = "documentTitle",
    INTRODUCTION_TEXT = "introductionText",
    DELIVERY_DATE = "deliveryDate",
    SERVICE_DATE = "serviceDate",
    SERVICE_PERIOD_DAYS = "servicePeriodDays",
    SERVICE_PERIOD_MONTHS = "servicePeriodMonths",
    CURRENCY_CODE = "currencyCode",
    LINE_ITEMS = "lineItems",
    PAYMENT_TERM = "paymentTerm",
    DESCRIPTION = "description",
}

export enum InvoiceFormInputTranslate {
    CUSTOMER = "app.invoice.customer",
    ADDRESS = "app.invoice.address",
    COUNTRY_CODE = "app.invoice.countryCode",
    CITY = "app.invoice.city",
    ZIP_CODE = "app.invoice.zeep",
    STREET = "app.invoice.street",
    HOUSE_NUMBER = "app.invoice.houseNumber",
    INVOICE_NUMBER = "app.invoice.invoiceNum",
    CUSTOMER_NUMBER = "app.invoice.customerNum",
    DATE = "app.invoice.date",
    DELIVERY_DATE = "app.invoice.deliveryDate",
    SERVICE_DATE = "app.invoice.serviceDate",
    SERVICE_PERIOD_DAYS = "app.invoice.servicePeriodDays",
    SERVICE_PERIOD_MONTHS = "app.invoice.servicePeriodMonths",
    NO_DELIVERY_DATE = "app.invoice.noShowDate",
    DOCUMENT_TITLE = "app.invoice.invoiceTitle",
    INTRODUCTION_TEXT = "app.invoice.introductionText",
    POSITION = "app.invoice.position",
    PRODUCTS_SERVICES = "app.invoice.productService",
    UNIT = "app.invoice.unit",
    QUANTITY = "app.invoice.quantity",
    PRICE = "app.invoice.price",
    TAX = "app.invoice.tax",
    DISCOUNT = "app.invoice.discount",
    TOTAL = "app.invoice.total",
    NEW_LINE = "app.invoice.newLine",
    BRUTTO = "app.invoice.brutto",
    NETTO = "app.invoice.netto",
    CURRENCY = "app.fields.currency",
    SUB_TOTAL = "app.invoice.subTotalNet",
    VAT = "app.invoice.vat",
    TOTAL_AMOUNT = "app.invoice.totalAmount",
    PAYMENT_TERM = "app.invoice.paymentTerm",
    DESCRIPTION = "app.invoice.description",
    PIECE = "app.invoice.piece",
    HOUR = "app.invoice.hour",
    CANCEL = "app.button.cancel",
    CACHING = "app.invoice.caching",
    GO_PREVIEW = "app.invoice.goToPreview",
}

export enum InvoiceFormBlocksTranslate {
    CUSTOMER_DETAIL = "app.invoice.customerDetails",
    INVOICE_DETAILS = "app.invoice.invoiceDetails",
    HEADER_SECTION = "app.invoice.head",
    LINE_ITEMS = "app.invoice.lineItems",
    FOOTER_SECTION = "app.invoice.foot",
}

export enum InvoicePagesTitle {
    CREATE_INVOICE = "app.invoice.createInvoice",
    INVOICES_LIST = "app.invoice.invoicesList",
}

export enum InvoicesListColumn {
    INVOICE_ROW_NUMBER = "invoiceRowNumber",
    INVOICE_DATE = "invoiceDate",
    INVOICE_NUMBER = "invoiceNumber",
    SERVICE_DESCRIPTION = "serviceDescription",
    CONTACT = "contact",
    PROJECT = "project",
    SERVICE_DATE = "serviceDate",
    ORIGINAL_AMOUNT = "originalAmount",
    CURRENCY = "currency",
    RATE = "rate",
    AMOUNT_NETTO = "amountNetto",
    VAT_RATE = "vatRate",
    VAT = "vat",
    AMOUNT_BRUTTO = "amountBrutto",
    ACTION = "action",
}

export const InvoicesListColumnTranslate = {
    [InvoicesListColumn.INVOICE_DATE]: "app.invoiceList.invoiceDate",
    [InvoicesListColumn.INVOICE_NUMBER]: "app.invoice.invoiceNum",
    [InvoicesListColumn.SERVICE_DESCRIPTION]: "app.invoiceList.serviceDescription",
    [InvoicesListColumn.CONTACT]: "app.fields.contact",
    [InvoicesListColumn.PROJECT]: "app.invoiceList.project",
    [InvoicesListColumn.SERVICE_DATE]: "app.invoice.serviceDate",
    [InvoicesListColumn.ORIGINAL_AMOUNT]: "app.fields.originalAmount",
    [InvoicesListColumn.CURRENCY]: "app.fields.currency",
    [InvoicesListColumn.RATE]: "app.fields.currency.rate",
    [InvoicesListColumn.AMOUNT_NETTO]: "app.invoiceList.amountNetto",
    [InvoicesListColumn.VAT_RATE]: "app.fields.vat%",
    [InvoicesListColumn.VAT]: "app.fields.vat",
    [InvoicesListColumn.AMOUNT_BRUTTO]: "app.invoiceList.amountBrutto",
    [InvoicesListColumn.ACTION]: "app.fields.actions",
};
